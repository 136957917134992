import React, { useState, useEffect } from 'react';
import "./joinTelegram.css";
import { tabName } from "../../../../src/utils/constants";
import JoinTelegramModalSingle from '../../../components/common/joinTelegramModalSingle';
import MobileTab from './tabs/mobileTab';
import DesktopTab from './tabs/desktopTab';
import { Link } from 'react-router-dom';
import GaEventsTracker from "../../../components/common/GaEventsTracker";


const JoinTelegrams = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const useGaEventsTracker = GaEventsTracker("External Links")

    const [openLive, setOpenLive] = useState(false)

    const [activeTab, setActiveTab] = useState("Mobile Tab")
    const [open, setOpen] = useState(false)
    const [url, setUrl] = useState("");


    const isActive = (tab) => {
        if (activeTab === tab) {
            return "active";
        }
        return "";
    }
    const renderTab = () => {
        switch (activeTab) {
            case tabName.MobileTab:
                return <MobileTab
                    OnClickPopHandle={onClickPopHandle} Url={url}
                    Open={open} OpenModal={() => setOpen(!true)} />
                break;
            case tabName.DesktopTab:
                return <DesktopTab OnClickPopHandle={onClickPopHandle} Url={url}
                    Open={open} OpenModal={() => setOpen(!true)} />
                break;
            default:
                return null
                break;
        }
    }

    const onClickPopHandle = (url, value) => {
        setOpen(value)
        setUrl(url)
    }

    const Redirect1 = (e) => {
        e.preventDefault()
        const section = document.querySelector("#howjoinStep");
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        useGaEventsTracker('event', 'howToJoin', {'event_category': 'Telegram'})
      }

    const ContactFacebook = () => {
        onClickPopHandle("https://www.facebook.com/MalaysiaWarrants/", true)
        useGaEventsTracker('event', 'Facebook', {'event_category': 'Telegram'})
    }

    return (
        <>
            <div id="telegram" className="pageobj" style={{}}>
                <div className="welcometop">
                    <div className="video-wrapper">
                        <div className="welcomeimgtop xs-none">
                            <img src="/img/telegram/section1/header-desktop-1600x450.png" />
                        </div>

                        <div className="welcomeimgtop xs-view">
                            <img src="/img/telegram/section1/header-mobile-1170x570.png" />
                        </div>

                    </div>
                </div>
                <div className="jumbotron">
                    <div className="container-fluid">
                        <p className="lead" />
                        <h1
                            onClick={() => onClickPopHandle("https://t.me/Malaysiawarrants", true)}
                            className="broker_img title_join"

                        >
                            <b>Join us on Telegram </b>
                            @MalaysiaWarrants{" "}
                            <img
                                className="imgtab"
                                src="/img/telegram/section1/t_logo.png"
                            />
                        </h1>
                        <p />
                    </div>
                </div>
                <nav className="tabnav container-fluid sec_for_top">
                    <div className="row tabnav-row" style={{ display: 'block', overflow: "hidden" }}>
                        <div className="col-xs-9 col-sm-6 mobile_tele" style={{ position: 'relative', display: 'inline-block', float: 'left' }}>
                            <div className="navbox">
                                <img
                                    style={{ width: "100%" }}
                                    src="/img/telegram/section1/phoneOnly.png"
                                />
                                <div className="phoneScreen screenScroll">
                                    <img
                                        style={{ width: "100%" }}
                                        src="/img/telegram/section1/phoneScreen-scrollable.png"
                                    />
                                </div>
                                <div
                                    onClick={() => onClickPopHandle("https://web.telegram.org/#/im?p=@Malaysiawarrants", true)}
                                    className="joinLink hidden-xs phone_join"

                                >
                                    &nbsp;
                                </div>
                                <div
                                    className="joinLink visible-xs phone_join"
                                    onClick={() => onClickPopHandle("https://t.me/Malaysiawarrants", true)}

                                >
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className='how_to_join'>
                        <Link to={'#howjoinStep'} onClick={Redirect1} >
                           <img
                                style={{ width: "100%" }}
                                src="/img/telegram/section1/howToJoin.png"
                            />
                          </Link>
                        </div>

                        <div className="col-xs-6 text_top_sec" style={{ width: '100%', maxWidth: '50%', display: 'inline-block' }}>
                            <div className="navbox howjoin">
                                <div id="howjoinStep">
                                    <div className="section" id="tab1">
                                        <p style={{ marginBottom: '15px' }}>
                                            Get timely updates on structured warrants as well as the foreign
                                            and local markets to help your daily trading and decision
                                            making!
                                        </p>
                                        <p className="howJoinSubTitle">
                                            If you already have the Telegram application:
                                        </p>
                                        <button
                                            onClick={() => onClickPopHandle("https://t.me/Malaysiawarrants", true)}
                                            className="btn broker_img now_join"
                                            style={{
                                                backgroundColor: "#0066A5",
                                                color: "#ffffff",
                                                padding: "0 12px",
                                                marginLeft: "15px",
                                                verticalAlign: "baseline",
                                                diaplay: "none",
                                            }}
                                        >
                                            Join now
                                        </button>
                                        <p className="howJoinTitle">How do I join?</p>
                                        <table id="viewtab" className="ctab">
                                            <tbody>
                                                <tr>
                                                    <td onClick={() => setActiveTab(tabName.MobileTab)} className={`ctab-item ${isActive(tabName.MobileTab)}`} id="forMobile" data-value="M">
                                                        <span>for mobile</span>
                                                    </td>
                                                    <td className="ctab-item-space">&nbsp;</td>
                                                    <td
                                                        onClick={() => setActiveTab(tabName.DesktopTab)}
                                                        className={`ctab-item ${isActive(tabName.DesktopTab)}`}
                                                        id="forDesktop"
                                                        data-value="D"
                                                    >
                                                        <span>for desktop</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {renderTab()}
                                        <div className="section alldone">
                                            <div className="alldone-text">
                                                <p>
                                                    All done! Stay tuned for Telegram exclusive content coming
                                                    up.
                                                </p>
                                            </div>
                                            <div className="cta-btn">
                                                <Link to="/education/getstarted" target="_blank" onClick={useGaEventsTracker('event', 'getStarted', {'event_category': 'Telegram'})}>
                                                    <img src="/img/telegram/section1/CTAbutton.png" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-3 howToJoin d-none">
                            <div className="navbox">
                                <a href="#section1">
                                    <img
                                        className="imgtab  howjoin-btn"
                                        style={{ width: "100%" }}
                                        src="/img/telegram/section1/howToJoin.png"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
                <div id='joindirect'></div>

                <div className="container-fluid">
                    <div id="section2" />
                    <div className="section" id="tab2">
                        <p className="video_title">
                            Watch our new animated video series for a fun and simple way to learn
                            about warrants
                        </p>
                        <div className="video_img">
                            <div id="video_ep1" className="video_ep_img">
                                <Link
                                    to="/education/warrantvideosind/StructuredWarrants"
                                    target="_blank"
                                    onClick={useGaEventsTracker('event', 'animatedVideos', {'event_category': 'Telegram'})}
                                >
                                    <img src="/img/telegram/section2/EP1.png" />
                                </Link>
                            </div>
                            <div id="video_ep2" className="video_ep_img">
                                <Link
                                    to="/education/warrantvideosind/whyTradeWarrants"
                                    target="_blank"
                                    onClick={useGaEventsTracker('event', 'animatedVideos', {'event_category': 'Telegram'})}
                                >
                                    <img src="/img/telegram/section2/EP2.png" />
                                </Link>
                            </div>
                            <div id="video_ep3" className="video_ep_img">
                                <Link
                                    to="/education/warrantvideosind/howToSelectWarrants"
                                    target="_blank"
                                    onClick={useGaEventsTracker('event', 'animatedVideos', {'event_category': 'Telegram'})}
                                >
                                    <img src="/img/telegram/section2/EP3.png" />
                                </Link>
                            </div>
                            <div id="video_ep4" className="video_ep_img">
                                <Link
                                    to="/education/warrantvideosind/monitorWarrantsPrice"
                                    target="_blank"
                                    onClick={useGaEventsTracker('event', 'animatedVideos', {'event_category': 'Telegram'})}
                                >
                                    <img src="/img/telegram/section2/EP4.png" />
                                </Link>
                            </div>
                            <div id="video_ep5" className="video_ep_img">
                                <Link
                                    to="/education/warrantvideosind"
                                    target="_blank"
                                    onClick={useGaEventsTracker('event', 'animatedVideos', {'event_category': 'Telegram'})}
                                >
                                    <img src="/img/telegram/section2/EP5.png" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div id="section3" />
                    <div className="section" id="tab3">
                        <p className="contact_title">Contact us if you have any questions </p>
                        <table className="contact_img bottom-pc">
                            <tbody>
                                <tr>
                                    <td>
                                        <a href="tel:+60 3 2059 8840" onClick={useGaEventsTracker('event', 'phone', {'event_category': 'Telegram'})}>
                                            <img
                                                className="contact_phone"
                                                src="/img/telegram/section3/phone.png"
                                            />
                                        </a>
                                    </td>
                                    <td>
                                        <a href="tel:+60 3 2059 8840" onClick={useGaEventsTracker('event', 'phone', {'event_category': 'Telegram'})}>
                                            <span className="contact_phone">&nbsp;+603 2059 8840</span>
                                        </a>
                                    </td>
                                    <td>
                                        <a href="mailto:info@malaysiawarrants.com.my" onClick={useGaEventsTracker('event', 'email', {'event_category': 'Telegram'})}>
                                            <img
                                                className="contact_email"
                                                src="/img/telegram/section3/email.png"
                                            />
                                        </a>
                                    </td>
                                    <td>
                                        <a href="mailto:info@malaysiawarrants.com.my" onClick={useGaEventsTracker('event', 'email', {'event_category': 'Telegram'})}>
                                            <span className="contact_email">
                                                &nbsp;info@malaysiawarrants.com.my
                                            </span>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>
                                        <span
                                            className="broker_img"
                                            onClick={ContactFacebook}
                                        >
                                            <img
                                                className="contact_facebook "
                                                src="/img/telegram/section3/facebook.png"
                                            />
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                            className="broker_img"
                                            onClick={ContactFacebook}
                                        >
                                            <span className="contact_facebook">
                                                &nbsp;@MalaysiaWarrants
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        <Link to="/education/whymacquarie" target="_blank" onClick={useGaEventsTracker('event', 'whyMacquarie', {'event_category': 'Telegram'})}>
                                            <img
                                                className="contact_whyMacq"
                                                src="/img/telegram/section3/whyMacq.png"
                                            />
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/education/whymacquarie" target="_blank" onClick={useGaEventsTracker('event', 'whyMacquarie', {'event_category': 'Telegram'})}>
                                            <span className="contact_whyMacq ">&nbsp;Why Macquarie?</span>
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="contact_img bottom-pe">
                            <tbody>
                                <tr style={{ margin: "20px 0 15px 0" }}>
                                    <td>
                                        <a href="tel:+60 3 2059 8840">
                                            <img
                                                className="contact_phone"
                                                src="/img/telegram/section3/phone.png"
                                            />
                                        </a>
                                    </td>
                                    <td>
                                        <a href="tel:+60 3 2059 8840">
                                            <span className="contact_phone">&nbsp;+603 2059 8840</span>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href="mailto:info@malaysiawarrants.com.my">
                                            <img
                                                className="contact_email"
                                                src="/img/telegram/section3/email.png"
                                            />
                                        </a>
                                    </td>
                                    <td>
                                        <a href="mailto:info@malaysiawarrants.com.my">
                                            <span className="contact_email">
                                                &nbsp;info@malaysiawarrants.com.my
                                            </span>
                                        </a>
                                    </td>
                                </tr>
                                <tr style={{ margin: "15px 0 15px 0" }}>
                                    <td style={{ textAlign: "center" }}>
                                        <span
                                            className="broker_img"
                                            onClick={() => onClickPopHandle("https://www.facebook.com/MalaysiaWarrants/", true)}

                                        // link="https://www.facebook.com/MalaysiaWarrants/"
                                        >
                                            <img
                                                className="contact_facebook"
                                                src="/img/telegram/section3/facebook.png"
                                            />
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                            className="broker_img"
                                            onClick={() => onClickPopHandle("https://www.facebook.com/MalaysiaWarrants/", true)}
                                        // link="https://www.facebook.com/MalaysiaWarrants/"
                                        >
                                            <span onClick={() => onClickPopHandle("https://www.facebook.com/MalaysiaWarrants/", true)}
                                                className="contact_facebook">
                                                &nbsp;@MalaysiaWarrants
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Link to="/education/whymacquarie" target="_blank">
                                            <img
                                                className="contact_whyMacq"
                                                src="/img/telegram/section3/whyMacq.png"
                                            />
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/education/whymacquarie" target="_blank">
                                            <span className="contact_whyMacq">&nbsp;Why Macquarie?</span>
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <JoinTelegramModalSingle
                Url={url}
                Open={open} OpenModal={() => setOpen(!true)} />
        </>
    );
}

export default JoinTelegrams;