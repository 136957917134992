import React, { useState, useEffect } from 'react';
import JoinTelegramModal from '../../../../components/common/joinTelegramModal';
import GaEventsTracker from "../../../../components/common/GaEventsTracker";

const MobileTab = ({OnClickPopHandle}) => {
    
    const useGaEventsTracker = GaEventsTracker("External Links")
    // const [openLive, setOpenLive] = useState(false)

    const AndroidDownload = () => {
        OnClickPopHandle("https://play.google.com/store/apps/details?id=org.telegram.messenger",true)
        useGaEventsTracker('event', 'downloadApp', {'event_category': 'Telegram'})
    }

    const AppleDownload = () => {
        OnClickPopHandle("https://itunes.apple.com/app/telegram-messenger/id686449807",true)
        useGaEventsTracker('event', 'downloadApp', {'event_category': 'Telegram'})
    }

    const ClickhereM = () => {
        OnClickPopHandle("https://t.me/Malaysiawarrants",true)
        useGaEventsTracker('event', 'openOnMobile', {'event_category': 'Telegram'})
    }

    return (
        <>
        <div className="activeM">
            <div className="row step-row">
            <div className="col-md-1">
                <img src="/img/telegram/section1/no1.png" />
            </div>
            <div className="col-md-11">
                <p className="howJoinSubTitle">
                Download the Telegram application
                <br />
                <em className="small">
                    Skip this step if you already have the app
                </em>
                </p>
                <div>
                <span
                    className="androidDownload broker_img "
                    style={{marginRight: "5px"}}
                    onClick={AndroidDownload}
                >
                    <img
                    className="forMDimg"
                    src="/img/telegram/section1/forMobile/mobile-forAndroid.png"
                    />
                </span>
                <span
                    className="appleDownload broker_img"
                    onClick={AppleDownload}
                >
                    <img
                    className="forMDimg"
                    src="/img/telegram/section1/forMobile/mobile-forApple.png"
                    />
                </span>
                </div>
            </div>
            </div>
            <div className="row step-row">
            <div className="col-md-1">
                <img src="/img/telegram/section1/no2.png" />
            </div>
            <div className="col-md-11">
                <p className="howJoinSubTitle">
                Sign in with your mobile number
                </p>
                <img
                className="for-img forMDimg"
                src="/img/telegram/section1/forMobile/mobile-2signIn.png"
                />
            </div>
            </div>
            <div className="row step-row">
            <div className="col-md-1">
                <img src="/img/telegram/section1/no3.png" />
            </div>
            <div className="col-md-11">
                <p className="howJoinSubTitle">
                Search “Malaysia Warrants” on the app, or{" "}
                <span
                    className="clickhereM broker_img"
                    style={{ color: "#0675C2" }}
                    onClick={ClickhereM}
                >
                    click here
                </span>
                </p>
                <img
                id="mobile-3"
                className="for-img forMDimg"
                src="/img/telegram/section1/forMobile/mobile-3search.png"
                />
            </div>
            </div>
            <div className="row step-row">
            <div className="col-md-1">
                <img src="/img/telegram/section1/no4.png" />
            </div>
            <div className="col-md-11">
                <p className="howJoinSubTitle">
                Tap on the join button at the bottom of your screen
                </p>
            </div>
            </div>
        </div>
    </>
    )
};

export default MobileTab;