import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./warrantHeatmap.css";
import WarrantHeatmapModal from "../../../components/common/WarrantHeatmapModal"
import WarrantTermsModal from "../../../components/common/warrantTermsModal"
import OutsideClickHandler from "react-outside-click-handler";
import GaEventsTracker from "../../../components/common/GaEventsTracker";



const WarrantHeatmaps = (props) => {

  const useGaEventsTracker = GaEventsTracker("External Links")

  const [page,setPage] = useState(1)
  const [heatmapVal, setHeatmapVal] = useState("")
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    var urlVal = window.location.pathname.split("/")
    if(urlVal.length > 3){
      setHeatmapVal(urlVal[3])
    }
    else{
      setHeatmapVal("")
    }
  }, [])

  const [open, setOpen] = useState(false)
	const [url,setUrl] = useState("");

  const onClickPopHandle=(url,value)=>{
    setOpen(value)
    setUrl(url)
  }

  const [open1, setOpen1] = useState(false)
	const [url1,setUrl1] = useState("");

  const onClickPopHandle1=(url1,value1)=>{
    setOpen1(value1)
    setUrl1(url1)
  }





  function AffinHwang() {
    useGaEventsTracker('event', 'Click', {'event_category': 'AHIB'})
    onClickPopHandle("https://einvest.affinhwang.com/",true) 
  }

  function HongLeong() {
    useGaEventsTracker('event', 'Click', {'event_category': 'HLIB'})
    onClickPopHandle("https://www.hlebroking.com/v3/",true) 
  }

  function MalaccaSecurities() {
    useGaEventsTracker('event', 'Click', {'event_category': 'MalaccaSec'})
    onClickPopHandle("https://www.mplusonline.com.my/macsecos/index.asp",true) 
  }

  function PublicBank() {
    useGaEventsTracker('event', 'Click', {'event_category': 'PublicBank'})
    onClickPopHandle("https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx",true) 
  }

  function UobKayHian() {
    useGaEventsTracker('event', 'Click', {'event_category': 'UOB'})
    onClickPopHandle("https://www.utrade.com.my/",true) 
  }

    return (

        <>
        <div id="recognia" className="pageobj" style={{}}>
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">Warrant tools</Link>
              </li>
              <li className="active">Warrant heatmap</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h1>Warrant heatmap</h1>
              <div style={{ textAlign: "right" }}>
                <div
                  className="btn btn-primary whatsthis"
                  data-bs-toggle="collapse" 
                  href="#ppboxHTML3" 
                  role="button" 
                  aria-expanded="false" 
                  aria-controls="ppboxHTML3"
                  style={{ marginBottom: "5px", marginRight: "5px" }}
                 
                 onClick={()=>document.getElementById("ppboxHTML3").style.display = "block"}
                >
                  What's this?
                </div>
                <input
                  className="btn btn-primary tradeEl tradeBtn"
                  defaultValue="Trade warrants here"
                  type="button"
                  style={{ marginBottom: "5px" }}
                  data-bs-toggle="collapse" 
                  href="#tradeDropDown1" 
                  role="button" 
                  aria-expanded="false" 
                  aria-controls="tradeDropDown1"
                  id='tradeButton31'
                />
                <OutsideClickHandler 
                      onOutsideClick={()=>{
                        document.getElementById("tradeDropDown1").classList.remove("show")
                        document.getElementById("tradeButton31").setAttribute("aria-expanded","false")
                      }}
                  >
                <div className="tradeDropDown tradeEl collapse" id="tradeDropDown1">
                  <Link
                    className="btn btn-primary tradeEl"
                    to="/education/getstarted"
                    type="button"
                    target="_blank"
                    onClick={useGaEventsTracker('event', 'Click', {'event_category': 'GetStarted'})}
                  >
                    How to get started
                  </Link>
                  <div className="dropdown-or tradeEl">
                    <hr className="tradeEl" />
                    <p className="tradeEl">or</p>
                    <hr className="tradeEl" />
                  </div>
                  <p className="tradeEl">Select your broker:</p>
                  <div className="row tradeEl">
                    <input
                      className="btn btn-primary broker_link tradeEl"
                      defaultValue="Affin Hwang"
                      type="button"
                      onClick={AffinHwang}
                    />
                    <input
                      className="btn btn-primary broker_link tradeEl"
                      defaultValue="Hong Leong"
                      type="button"
                      onClick={HongLeong}
                    />
                  </div>
                  <div className="row tradeEl">
                    <input
                      className="btn btn-primary broker_link tradeEl"
                      defaultValue="Malacca Securities"
                      type="button"
                      onClick={MalaccaSecurities}
                    />
                    <input
                      className="btn btn-primary broker_link tradeEl"
                      defaultValue="Public Bank"
                      type="button"
                      onClick={PublicBank}
                    />
                  </div>
                  <div className="row tradeEl">
                    <input
                      className="btn btn-primary broker_link tradeEl"
                      defaultValue="UOB Kay Hian"
                      type="button"
                      onClick={UobKayHian}
                    />
                    <input
                      className="btn btn-primary broker_link tradeEl"
                      style={{ visibility: "hidden" }}
                      defaultValue="Phillip "
                      type="button"
                    />
                  </div>
                </div>
                </OutsideClickHandler>
              </div>
            </div>
            <p>
              The warrant heatmap allows you to quickly see which underlying shares or
              indices are bullish or bearish in the short to medium term. This new
              tool is based on the{" "}
              <a className="broker_link2" onClick={()=>onClickPopHandle1("https://tradevsa.com/",true)}>TradeVSA</a>
              {" "}proprietary algorithm, which uses the Volume Spread Analysis
              methodology.
              <br />
              <br />
              Search for your preferred underlyings or filter for an underlying with a
              strong momentum or trend. You can use the charts to see the TradeVSA
              indicators and easily view all associated Macquarie warrants.
              <br />
              <br />
              <span className="xs-none">
                Please mouseover each column’s header for more information.
              </span>
            </p>
            <div className="select-area" style={{ lineHeight: 1 }}>
              <div className="showtable">
                <span>
                  Please mouseover each column’s header for more information.
                </span>
              </div>
              <div className="howtouse whatsthis" onClick={()=>document.getElementById("ppboxHTML3").style.display = "block"}>What's this?</div>
            </div>
            <div id="recogdiv" style={{ paddingTop: "20px" }}>
              <div id="rciframediv">
                {heatmapVal == "" || heatmapVal === null || heatmapVal === undefined ? <iframe
                  id="rciframe"
                  className='heatmap'
                  style={{ width: "100%", borderStyle: "none", height: "630px" }}
                  src="https://tradevsa.com/macq/warrant_heatmap"
                ></iframe> : <iframe
                  id="rciframe"
                  className='heatmap'
                  style={{ width: "100%", borderStyle: "none", height: "630px" }}
                  src={`https://tradevsa.com/macq/warrant_heatmap?underlying=${heatmapVal}`}
                ></iframe>}
              </div>
            </div>
            <div style={{ paddingTop: "20px", fontSize: "13px" }}>
              <em>
                © TradeVSA System Sdn Bhd. The chart above and the methodology used to
                derive the "Weekly Momentum" and "Monthly Trend" figures have been
                developed and are owned by TradeVSA System Sdn Bhd ("TradeVSA"). No
                part of the information contained herein may be copied, either in
                whole or in part, or distributed to any other person. All content
                herein are provided for information purposes only and do not
                constitute an offer, a solicitation of an offer, or any advice or
                recommendation by Macquarie Capital Securities (Malaysia) Sdn Bhd
                ("MCSM"), TradeVSA or its content providers to conclude any
                transaction. Neither MCSM nor TradeVSA nor its content providers
                represent or warrant the completeness, reliability, accuracy,
                timeliness or fitness for any purpose of any of the material, and are
                not responsible for any damages or losses however caused arising in
                connection with any use of the content herein, including without
                limitation any direct, indirect, special or consequential loss and
                loss of profits whether in part or in full.
              </em>
            </div>
          </div>
        </div>
      </div>
      <div id="ppboxHTML" className="overlay recognia" style={{ display: "none" }}>
        <div className="overlay-inner">
          <div style={{ position: "relative" }}>
            <div className="popupbox-content">
              <div>
                <div className="signup-layer">
                  <div className="page-header">
                    <h3>Important notice</h3>
                  </div>
                  <p className="small">
                    You are now entering a third party website which is neither
                    operated nor sponsored by Macquarie. Macquarie is not responsible
                    for the content, cookie collection, personal data collection,
                    trading and services provided on such website. You should review
                    the terms and conditions of third party websites and contact the
                    operators of such websites if you have any queries.
                    <br />
                    <br />
                    Investment in warrants involves risk. These links to third party
                    websites do not constitute an offer, a solicitation of an offer,
                    or any advice or recommendation to participate in any transaction.
                    You should consult your own professional advisors to ensure that
                    any decision made is suitable with regards to your circumstances
                    and financial position. Please note that the list of brokers is
                    not exhaustive and the inclusion of the brokers on the list does
                    not imply that the brokers or their services are endorsed by
                    Macquarie.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <input
                      className="btn btn-primary continue"
                      defaultValue="Continue"
                      type="button"
                    />
                    <input
                      className="btn btn-primary cancel"
                      defaultValue="Cancel"
                      type="button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="ppboxHTML2" className="overlay recognia" style={{ display: "none" }}>
        <div className="overlay-inner">
          <div style={{ position: "relative" }}>
            <div className="popupbox-content">
              <div className="ppc" key="subscribe-newsletter">
                <div className="signup-layer">
                  <div className="page-header">
                    <h3>Important notice</h3>
                  </div>
                  <p className="small">
                    You are now entering a third party website which is neither
                    operated nor sponsored by Macquarie. Macquarie is not responsible
                    for the content, cookie collection, personal data collection,
                    trading and services provided on such website. You should review
                    the terms and conditions of third party websites and contact the
                    operators of such websites if you have any queries.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <input
                      className="btn btn-primary continue"
                      defaultValue="Continue"
                      type="button"
                    />
                    <input
                      className="btn btn-primary cancel"
                      defaultValue="Cancel"
                      type="button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div 
      id="ppboxHTML3"
       className="overlay2 recognia collapse"
      
       >
        <div className="overlay-inner">
          <div style={{ position: "relative" }}>
            <div className="popupbox-content">
              <div>
                <div className="signup-layer">
                  <div className="page-header" style={{ marginTop: 0 }}>
                    <h3>How to use the Warrant Heatmap</h3>
                   
                    <a className="btn-close" onClick={()=>{document.getElementById("ppboxHTML3").style.display = "none"
                                                            setPage(1)}} />
                  </div>
                  {page === 1  && <div className={page === 1 && "content content_p1 active"}>
                    <p>
                      <span className="page_title">Introduction</span>
                    </p>
                    <p>
                      Make full use of our Warrant Heatmap to quickly determine the
                      short term momentum or medium term trend of an underlying.
                    </p>
                    <p>
                      The <strong>Weekly Momentum and Monthly Trend</strong> columns
                      display a score of how bullish (positive scores; the higher the
                      stronger) or bearish (negative scores; the lower the stronger)
                      TradeVSA’s proprietary algorithm is on that underlying. The
                      Weekly Momentum is for a weekly lookout of 4 to 12 weeks, while
                      the Monthly Trend is for a monthly lookout of 3 to 6 months.
                      Please note that these are{" "}
                      <strong>strength scores or meters</strong> and are not an
                      indication of how high or low the underlying will move.
                    </p>
                    <p>Below are features of the Warrant Heatmap:</p>
                    <ul>
                      <li>
                        <strong>Search box</strong> – view the momentum or trend and
                        the available warrants of your preferred underlying by typing
                        its name or stock code in the search box
                      </li>
                      <li>
                        <strong>Reset button</strong> – click on this button to clear
                        all searches and filters, and return to the default page
                      </li>
                      <li>
                        <strong>Filter by</strong> – simply click on the filter tool
                        with the dropdown to filter available underlyings based on
                        their momentum or trend. Full bullish means that the
                        underlying is bullish in weekly momentum and monthly trend.
                      </li>
                      <li>
                        <strong>Entries selection</strong> – select the maximum number
                        of entries (underlyings) you would like displayed on one page
                      </li>
                      <li>
                        <strong>Momentum/trend color legend</strong> – shows the
                        strength of a momentum or trend with different colors so that
                        you can interpret the heatmap easily
                        <ul>
                          <li>
                            <strong style={{ color: "#ff0000" }}>Red</strong> – Strong
                            bearish
                          </li>
                          <li>
                            <strong style={{ color: "#ffb600" }}>Orange</strong> –
                            Weak bearish
                          </li>
                          <li>
                            <strong style={{ color: "#a8d08d" }}>Light green</strong>{" "}
                            – Weak bullish
                          </li>
                          <li>
                            <strong style={{ color: "#00b050" }}>Dark green</strong> –
                            Strong bullish
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <img
                      style={{ maxWidth: "400px", width: "100%" }}
                      src="/img/heatmap/img-pg1.png" />
                  </div>}
                  {page === 2 &&
                  <div className={page === 2 && "content content_p2 active"}>
                    <p>
                      <span className="page_title">
                        View technical analysis charts
                      </span>
                    </p>
                    <p>
                      <strong>
                        The Warrant Heatmap table itself is interactive!
                      </strong>{" "}
                      You can <strong>view the 3 or 6 months chart</strong> of an
                      underlying by simply clicking on its stock/index code under
                      column ‘TA Chart’.
                    </p>
                    <img
                      style={{ maxWidth: "400px", width: "100%" }}
                      src="/img/heatmap/img-pg2.png"
                    />
                    <p>
                      You are able to see when a <strong>Green Pentagon</strong> (up
                      signal) or <strong>Red Pentagon</strong> (down signal) appears
                      at the bottom and top of the chart, respectively. For your ease
                      of viewing, after a Green Pentagon, the chart has a blue
                      background, and after a Red Pentagon, the chart has a yellow
                      background. Other than the pentagons, you will also be able to
                      see other indicators that represent signs of strength or
                      weakness appear on the chart. Watch the{" "}
                      <a href="https://youtu.be/mJMennSUQVI" target="_blank">
                        video tutorial
                      </a>{" "}
                      for more information on the TradeVSA indicators.
                    </p>
                  </div>}
                  {page === 3 &&
                  <div className={page ===3 && "content content_p3 active"}>
                    <p>
                      <span className="page_title">
                        View associated Macquarie warrants
                      </span>
                    </p>
                    <p>
                      You can also view an underlying’s{" "}
                      <strong>associated Macquarie warrants</strong> and their
                      important terms by clicking on its stock/index short name under
                      column ‘Underlying Stock / Index’.
                    </p>
                    <img
                      style={{ maxWidth: "400px", width: "100%" }}
                      src="/img/heatmap/img-pg3.png"
                    />
                    <br />
                    <br />
                    <p>
                      Easily see if a warrant is a trending warrant, near expiry or
                      sold out with the colors under column ‘Type’.
                    </p>
                    <img
                      style={{ maxWidth: "320px", width: "80%" }}
                      src="/img/heatmap/img-pg3-2.png"
                    />
                  </div>}
                  <div style={{ textAlign: "center", marginTop: "10px" }} className="modal-pagination">
                    <span 
                    className="page pagelt"
                    onClick={()=>page >=2 && setPage(page-1)}
                    >&lt;</span>
                    <span 
                    className={page === 1 ? "page page1 active" : ""}
                    onClick={()=>setPage(1)}
                    >1</span>
                    <span 
                    className={page === 2 ? "page page2 active" : ""}
                    onClick={()=>setPage(2)}
                    >2</span>
                    <span 
                    className={page === 3 ? "page page3 active" : ""}
                    onClick={()=>setPage(3)}

                    >3</span>
                    <span 
                    className="page pagegt"
                    onClick={()=>page <= 2 && setPage(page+1)}
                    >&gt;</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <WarrantHeatmapModal 
          Url={url1} 
          Open={open1} OpenModal={()=> setOpen1(!true)}></WarrantHeatmapModal>
        <WarrantTermsModal 
          Url={url} 
          Open={open} OpenModal={()=> setOpen(!true)}></WarrantTermsModal>
      </>
      

      );
}
 
export default WarrantHeatmaps;