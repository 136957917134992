import React, {Component} from "react";
import Highcharts from 'highcharts/highstock'


export default class Callchart extends Component {
    constructor(props) {
        super(props)
        var callDataArr = props.callData
        var chartDataArr = props.chartData
        var parseChartData = this.parseChartData(callDataArr, chartDataArr)
        if(parseChartData.length > 0){
            var column1Val = parseChartData.map((d) => Number(d.column1))
            var column2Val = parseChartData.map((d) => Number(d.column2))
            var dateArrVal = parseChartData.map((d) => d.date)
            this.getUnderlyingDataCall(column1Val, column2Val, dateArrVal)
        }
    }


    parseChartData = (dataarr, chartArr) => {
        var dataProvider = [];
        var date, call, hp;
        if (dataarr) {
            dataarr.map(function (item) {
                date = item.date;
                call = parseFloat(item.sum) * -1;
                chartArr.map(function (item2) {
                    if (item2.date == date) {
                        hp = parseFloat(item2.open);
                        return false;
                    }
                });
                dataProvider.push({
                    "date": date,
                    "column1": hp,
                    "column2": call
                });
            });
        }
        return dataProvider;
    }


    getUnderlyingDataCall = (column1Val, column2Val, dateArrVal) => {
        Highcharts.chart('callChart', {
            chart: {
                zoomType: 'xy'
            },
            colors: [
                '#F7941E',
                "#000"
            ],
            title: {
                text: 'Historical Price and Call Money Flow (Millions)',
                align: 'center'
            },
            
            xAxis: [{
                categories: dateArrVal,
                crosshair: true
            }],
            yAxis: [{ // Secondary yAxis
                gridLineWidth: 0,
                title: {
                    text: 'Historical Price',
                    style: {
                        color: "#000",
                        fontWeight: 700
                    }
                },
                labels: {
                    format: '{value}',
                    style: {
                        color: "#000",
                    }
                }
        
            },
            { // Primary yAxis
                labels: {
                    format: '{value}',
                    style: {
                        color: "#000"
                    }
                },
                title: {
                    text: 'Call Money Flow (Millions)',
                    style: {
                        color: "#000",
                        fontWeight: 700
                    }
                },
                opposite: true
        
            }],
            tooltip: {
                shared: true
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                x: 80,
                verticalAlign: 'top',
                y: 55,
                floating: true,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || // theme
                    'rgba(255,255,255,0.25)'
            },
            series: [{
                name: 'Call Money Flow',
                type: 'column',
                yAxis: 1,
                data: column2Val
                // tooltip: {
                //     valueSuffix: ''
                // }
        
            }, {
                name: 'Historical Price',
                type: 'spline',
                data: column1Val,
                // tooltip: {
                //     valueSuffix: ''
                // }
            }],
            // responsive: {
            //     rules: [{
            //         condition: {
            //             maxWidth: 500
            //         },
            //         chartOptions: {
            //             legend: {
            //                 floating: false,
            //                 layout: 'horizontal',
            //                 align: 'center',
            //                 verticalAlign: 'bottom',
            //                 x: 0,
            //                 y: 0
            //             },
            //             yAxis: [{
            //                 labels: {
            //                     align: 'right',
            //                     x: 0,
            //                     y: -6
            //                 },
            //                 showLastLabel: false
            //             }, {
            //                 labels: {
            //                     align: 'left',
            //                     x: 0,
            //                     y: -6
            //                 },
            //                 showLastLabel: false
            //             }, {
            //                 visible: false
            //             }]
            //         }
            //     }]
            // }
        });        
    }
         
    render() {
      return (
        <div
            id="callChart"
            className="chartbox-bars"
            style={{position: "relative"}}
        >   
        </div>
      );
    }
  }