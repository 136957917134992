import React, { useState, useEffect,Fragment } from 'react';
import WingrabVouche from './WingrabVouchers';
import "./wingrabVoucher.css" 


const WingrabVouchers = (props) => {
    return ( 
        <Fragment> 
        <WingrabVouche/>
        </Fragment> 
     );
}


export default WingrabVouchers;