import React from "react"
import { Modal, ModalBody } from 'reactstrap';
function ErrorModal({open,setOpenMsg}) {
  return (
    <>
      <Modal style={{ position: 'relative' }} centered isOpen={open} className="modal-lg mt-5 success-speak invalid-email" onClick={() => setOpenMsg(false)} >
        <ModalBody >
          <h4>Invalid email</h4>
        </ModalBody>
      </Modal>
    </>
  )
}
export default ErrorModal;
