
import React, { useState, useEffect } from 'react';
import "../warrantDocument.css";
import moment from 'moment';
import {Base_Url} from "../../../../utils/apiUrl"



const ProspectusTabs = ({WarrantDocumentsProspectus}) => {

  
    return ( 
        
      <div  className="tabcontent" style={{}}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: "15%" }} className="text-center">
              No
            </th>
            <th className="left">File name</th>
            <th style={{ width: "20%" }} className="text-center">
              Date
            </th>
            <th className="text-center">View</th>
          </tr>
        </thead>
        <tbody>
        {WarrantDocumentsProspectus && WarrantDocumentsProspectus.map((data,idx)=>( 
        <tr className="rowtmpl_props"  key={idx}>
            <td className="text-center idx">{idx+1}</td>
            <td className="file_name">{data.file_name}</td>
            <td className="text-center date">{(moment(data.update_date).format("DD MMM YY"))}</td>
            <td className="text-center view">
              <a  target="_blank" href={`${Base_Url}/apimqmy/pdf?type=prospectus&id=${data.symbol}`}>
                <img src="/img/bullet/pdf.png" />
              </a>
            </td>
         </tr>
         )) }

        </tbody>
      </table>
    </div>
     );
}
 
export default ProspectusTabs;