import React, { useState, useEffect } from "react";
import "./warrantComparison.css";
import Select from "react-select";
import { get, isArray } from "lodash";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import { Link } from "react-router-dom";
import tableConfig from "./comparisonConfig";
import ReactTooltip from "react-tooltip";
import WarrantComparsionModal from "../../../components/common/WarrantComparsionModal";
import { toast } from "react-toastify";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import LoaderSpin from "../../../components/common/LoaderSpin";
import OutsideClickHandler from "react-outside-click-handler";
import GaEventsTracker from "../../../components/common/GaEventsTracker";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const WarrantComparisons = (props) => {

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const api = new API();

  const useGaEventsTracker = GaEventsTracker("External Links")

  const [symbolList, setSymbolList] = useState([]);
  const [compareList, setCompareList] = useState([]);
  const [selectedCompare, setSelectedCompare] = useState([]);
  const [newRic, setNewRic] = useState("");
  const [ricValue, setRicValue] = useState();
  const [liveMatrixSelectedName, setLiveMatrixSelectedName] = useState("");
  const [qid, setQid] = useState(Date.now());
  const [open, setOpen] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cloneArr,setCloneArr] = useState([])
  const [load,setLoad] = useState(false)

  const [boxVisibility, setBoxVisibility] = useState("hide");

  const [advImgVal, setAdvImgVal] = useState([])


  useEffect(() => {
    getHomePopup()
  }, [])

  const getUnderlyingTickerDisplayName = (val,name,type)=>{
    let UnderlyingtickerdisplaynameMap = JSON.parse(window.localStorage.getItem('UnderlyingtickerdisplaynameMap'))
    let value = UnderlyingtickerdisplaynameMap.filter((v)=>{
      return v.underlying_ric == val
    })
    if(value.length>0){
      if(type==1){
        return value[0].underlying_name
      }else{
        return value[0].underlying_ticker
      }
    }else{
      return name
    }
  }

  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };
  
  const showNewBox = () => {
      if(boxVisibility === "hide"){
        setBoxVisibility("show")
      }else if(boxVisibility === "show"){
        setBoxVisibility("hide")
      }else{
        setBoxVisibility("hide")
      }
  }

  useEffect(() => {
    getWarrantComparisonsSelect();
  }, []);

  useEffect(() => {
    if (ricValue) {
      getComparisonsData();
    }
  }, [ricValue]);

  const getWarrantComparisonsSelect = async () => {
    let data = {
      mode: 2,
      qid,
    };

    let warrantComparisonsResult = await api.get(
      config.warrantUnderlying,
      true,
      data
    );

    let comparisonsHLListRes = get(warrantComparisonsResult, "hllist", []);
    let comparisonsListRes = get(warrantComparisonsResult, "list", []);

    const liveIndexSymbolData = comparisonsListRes.map(
      ({
        underlying_ticker,
        underlying_name,
        underlying_ticker: value,
        underlying_ric,
      }) => {
        // {
        //   if(underlying_name=='N225'){
        //     return {
        //       label:`Nikkei Stock Average (Nikkei 225)`,
        //       value,
        //       underlying_ticker,
        //       underlying_ric,
        //     }
        //   }else{
        //     return  {
        //       label: `${underlying_name} ${"("}${underlying_ticker}${")"}`,
        //       value,
        //       underlying_ticker,
        //       underlying_ric,
        //     }
        //   }
        // }
        return  {
          label: `${getUnderlyingTickerDisplayName(underlying_ric,underlying_name,1)} ${"("}${getUnderlyingTickerDisplayName(underlying_ric,underlying_ticker,2)}${")"}`,
          value,
          underlying_ticker,
          underlying_ric,
        }
      }
      
      
      // ({
      //   label: `${underlying_name} ${"("}${underlying_ticker}${")"}`,
      //   value,
      //   underlying_ticker,
      //   underlying_ric,
      // })
    );
    setSymbolList(liveIndexSymbolData);
  };

  const getComparisonsData = async (e, index) => {
    setIsLoading(true);
    setLoad(true)
    
    let data = {
      type: "u",
      ric: ricValue,
      qid:qid
    };

    let LiveMatrixResult = await api.get(config.warrantComparison, true, data);
    let comparisonsHLListRes = get(LiveMatrixResult, "comparison", []);
    let tempArr =
      comparisonsHLListRes.length > 0 &&
      comparisonsHLListRes
        .splice(1, comparisonsHLListRes.length)
        .map((item) => ({ ...item, checked: false }));
    setIsLoading(false);
    setLoad(false)
    //setCollapse(tempArr.length > 0);
    setCollapse(true)
    setCompareList(tempArr);
    setCloneArr([])
  };
  const getSelectedValue = (e) => {
    const { value, label, underlying_ric } = e;
    setNewRic(underlying_ric);
    setRicValue(underlying_ric);
    setLiveMatrixSelectedName(label);
    setCompareList([]);
    setCollapse(false);
    //getComparisonsData();
    
  };

  const onClickPopHandle = (url, value) => {
    setOpen(value);
    setUrl(url);
  };

  const renderCount = () => {
    let count = 0;
    Array.isArray(compareList) &&
      compareList.forEach((element) => {
        if (element.checked) {
          count += 1;
        }
      });
    return count;
  };

  const changeCheck = (selectedIndex,value) => {
    
    var status = compareList[selectedIndex].checked
    // if(compareList[selectedIndex].checked === true){
    //   compareList[selectedIndex].checked = false
    // }

    if (compareList.filter(({ checked }) => checked).length < 5) {
      let tempArr = [...compareList];
      var status = tempArr[selectedIndex].checked
      tempArr[selectedIndex].checked = !tempArr[selectedIndex].checked;
      var tempArr1=!status ? [...cloneArr,tempArr[selectedIndex]] : cloneArr.filter((d)=>d.ticker != value)
      
      setCloneArr(tempArr1)
    } 
    else {
      if(compareList[selectedIndex].checked === true){
        let tempArr = [...compareList];
      var status = tempArr[selectedIndex].checked
      tempArr[selectedIndex].checked = !tempArr[selectedIndex].checked;
      var tempArr1=!status ? [...cloneArr,tempArr[selectedIndex]] : cloneArr.filter((d)=>d.ticker != value)
     
      setCloneArr(tempArr1)
      }else{
        alert("You can only select up to five options");
      }
      
    }
  };

  const WarrantComparisonTable = (props) => {
    const getComparisonRow = () => {
      if (props.config != null) {
        // for each table field/column
        if ("fields" in props.config) {
          const rows = props.config["fields"].map((field, fieldIndex) => {
            const columns = [];

            // header
            const tooltipID = field.className + "-tooltip";
            const columnHeader = (
              <th key={field.name}>
                <span data-tip data-for={tooltipID} className="ttip">
                  {field.name}
                </span>
                <ReactTooltip
                  id={tooltipID}
                  delayHide={100}
                  place="right"
                  type="warning"
                  effect="solid"
                >
                  {field.tooltip()}
                </ReactTooltip>
              </th>
            );
            columns.push(columnHeader);

            // data
            const warrantdata = props.warrantdata;
            if (warrantdata != null && warrantdata.length > 0) {
              warrantdata.forEach((warrant, dataIndex) => {
                columns.push(field.columnData(warrant, dataIndex));
              });
            }
            const row = (
              <tr key={field.className} className={field.className}>
                {columns}
              </tr>
            );
            return row;
          });
          return rows;
        }
      }

      return null;
    };

    return (
      <div id="comparetbl">
        <table className="table table-striped">
          <tbody>{getComparisonRow()}</tbody>
        </table>
      </div>
    );
  };

  const getSelectedWarrantData = () => {
 
    let warrantDataSelected = [];
    // Array.isArray(compareList) &&
    //   compareList
    //     .filter(({ checked }) => checked)
    //     .forEach((ticker) => {
    //       
    //       const warrantdata = compareList.find((data) => {
    //         return data.ticker;
    //       });
          Array.isArray(cloneArr) &&
          cloneArr
            .filter(({ checked }) => checked)
            .forEach((ticker) => {
              
              const warrantdata = cloneArr.find((data) => {
                return data.ticker;
              });
          if (typeof warrantdata != "undefined") {
            warrantdata["label"] = liveMatrixSelectedName;
            
            warrantDataSelected.push(ticker);
          }
        });
    return warrantDataSelected;
   

           
    
  };


  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#007fff" : null,
        color: "#333333",
        color: isFocused ? "#fff" : null,
        textAlign: 'center',
        fontSize: '14px',
        zIndex: '1000',
        padding: '3px 10px',
      };
    },
  }

  const WarrantSummary = () => {
    let panelCollapseClass = "panel-collapse collapse";
    let faTriangle = faSortUp;
    let className = "result-sort-up";
    if (!collapse) {
      panelCollapseClass += " in";
      faTriangle = faSortDown;
      className = "result-sort-down";
    }
    const disable = () => {
      if (compareList.length === 0) {
        return <div className="disabled-area"></div>;
      }
      return <></>;
    };

    const clickCollapse = () => {
      if (collapse && compareList.length > 0) {
        //setCollapse(!collapse);
        setCollapse(false);
      }
      else setCollapse(true)
    };

    return (
      <div className="panel-group" id="accordion">
        <div className="panel panel-default">
          <div className="panel-heading">
            {disable()}
            <div onClick={clickCollapse}>
              
              <h4 id="collapse_title2" className="panel-title text-center">
                Select warrant: <span className="bold">{renderCount()}</span>
                <span className="arrow-icon">
                  <FontAwesomeIcon
                    icon={faTriangle}
                    onClick={clickCollapse}
                    className={className}
                  />
                </span>
              </h4>
            </div>
          </div>
          {/* {collapse && (
            <div> */}
              {/* {isLoading ? (
                <div className="loader-div warrentComp_Loader">
                  <LoaderSpin isLoading={isLoading} isVisible />
                </div>
              ) : ( */}
              
              {/* )} */}
            {/* </div>
          )} */}
        </div>
      </div>
    );
  };

  function AffinHwang() {
    useGaEventsTracker('event', 'Click', {'event_category': 'AHIB'})
    onClickPopHandle("https://einvest.affinhwang.com/",true) 
  }

  function HongLeong() {
    useGaEventsTracker('event', 'Click', {'event_category': 'HLIB'})
    onClickPopHandle("https://www.hlebroking.com/v3/",true) 
  }

  function MalaccaSecurities() {
    useGaEventsTracker('event', 'Click', {'event_category': 'MalaccaSec'})
    onClickPopHandle("https://www.mplusonline.com.my/macsecos/index.asp",true) 
  }

  function PublicBank() {
    useGaEventsTracker('event', 'Click', {'event_category': 'PublicBank'})
    onClickPopHandle("https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx",true) 
  }

  function UobKayHian() {
    useGaEventsTracker('event', 'Click', {'event_category': 'UOB'})
    onClickPopHandle("https://www.utrade.com.my/",true) 
  }




  return (
    <>
      <div id="dwcompare" className="pageobj" style={{}}>
        <h1 id="ric" style={{ display: "none" }} />
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">Warrant tools</Link>
              </li>
              <li className="active">Warrant comparison</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 sm-w-100">
              <div className="section">
                <div className="page-header">
                  <h1>Warrant comparison</h1>
                  <div>
                    <input
                      className="btn btn-primary tradeEl tradeBtn"
                      defaultValue="Trade warrants here"
                      type="button"
                      data-bs-toggle="collapse"
                      href="#tradeBtn1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="tradeBtn1"
                      id="tradeButton31"
                    />
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        document
                          .getElementById("tradeBtn1")
                          .classList.remove("show");
                        document
                          .getElementById("tradeButton31")
                          .setAttribute("aria-expanded", "false");
                      }}
                    >
                      <div
                        className="tradeDropDown tradeEl collapse"
                        id="tradeBtn1"
                      >
                        <Link
                          className="btn btn-primary tradeEl"
                          to="/education/getstarted"
                          type="button"
                          target="_blank"
                          onClick={useGaEventsTracker("event", "Click", {
                            event_category: "GetStarted",
                          })}
                        >
                          How to get started
                        </Link>
                        <div className="dropdown-or tradeEl">
                          <hr className="tradeEl" />
                          <p className="tradeEl">or</p>
                          <hr className="tradeEl" />
                        </div>
                        <p className="tradeEl">Select your broker:</p>
                        <div className="row tradeEl">
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            defaultValue="Affin Hwang"
                            type="button"
                            onClick={AffinHwang}
                          />
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            defaultValue="Hong Leong"
                            type="button"
                            onClick={HongLeong}
                          />
                        </div>
                        <div className="row tradeEl">
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            link="https://www.mplusonline.com.my/macsecos/"
                            defaultValue="Malacca Securities"
                            type="button"
                            onClick={MalaccaSecurities}
                          />
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            link="https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx"
                            defaultValue="Public Bank"
                            type="button"
                            onClick={PublicBank}
                          />
                        </div>
                        <div className="row tradeEl">
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            link="https://www.utrade.com.my/"
                            defaultValue="UOB Kay Hian"
                            type="button"
                            onClick={UobKayHian}
                          />
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            style={{ visibility: "hidden" }}
                            link="https://www.utrade.com.my/"
                            defaultValue="Phillip "
                            type="button"
                          />
                        </div>
                      </div>
                    </OutsideClickHandler>
                  </div>
                </div>
                <p>
                  Compare warrants and their key factors before making your
                  decision.{" "}
                </p>
                <p className="small">
                  You may compare up to five warrants at time
                </p>
                <Select
                  placeholder={"Select underlying"}
                  onChange={(e) => getSelectedValue(e)}
                  options={symbolList}
                  styles={colourStyles}
                />
                <WarrantSummary />
                {collapse && (
                  <div className="table-outer">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <th className="text-center">Warrant name</th>
                          <th className="text-center">Issuer</th>
                          <th className="text-center">Exercise price</th>
                          <th className="text-center">Exercise ratio</th>
                          <th className="text-center">Last trading date</th>
                        </tr>
                        {load && (
                          <div className="loader-div warrentComp_Loader">
                            <LoaderSpin isLoading={load} isVisible />
                          </div>
                        )}
                        {Array.isArray(compareList) &&
                          compareList.map(
                            (
                              {
                                underlying_name,
                                ticker,
                                ric,
                                dsply_nmll,
                                issuer_name,
                                exercise_price,
                                conv_ratio,
                                last_trading_date,
                                checked,
                              },
                              index
                            ) => (
                              <tr className="data-row" key={`#-${index}`}>
                                <td>
                                  <div className="checkbox">
                                    <label>
                                      <span>
                                        <input
                                          className="float-left position-unset"
                                          type="checkbox"
                                          checked={checked}
                                          onClick={() => {
                                            changeCheck(index, ticker, ric);
                                          }}
                                          onChange={e => {}}
                                        />
                                      </span>
                                      <Link
                                        to={`${"/tools/livematrix/"}${ticker}`}
                                        className="color-hyperlink"
                                      >
                                        {dsply_nmll}
                                      </Link>
                                    </label>
                                  </div>
                                </td>
                                <td className="textCenter">
                                  {issuer_name.split(" ")[0]}
                                </td>
                                <td className="textCenter">{exercise_price}</td>
                                <td className="textCenter">{conv_ratio}</td>
                                <td className="textCenter">
                                  {last_trading_date}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                )}
                <div id="orientationcontainer" className="show-xs-portrait">
                  <img src="/img/bullet/rotate.png" />
                  <span style={{ marginLeft: "10px" }}>
                    Rotate to view more
                  </span>
                </div>
                <WarrantComparisonTable
                  config={tableConfig}
                  warrantdata={getSelectedWarrantData()}
                />
                <hr className="hr-hmgbm" />
                <p className="small">
                  All market data is delayed 15 mins unless otherwise indicated
                  by timestamp.
                </p>
                <div
                  className="panel-group md-up-none"
                  id="defination_comparision"
                >
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <button type="button" onClick={() => showNewBox()}>
                        <span className="glyphicon glyphicon glyphicon-question-sign pull-right" />
                        <div
                          id="collapse_title3"
                          className="panel-title text-center color-primary"
                        >
                          <span>Definition &amp; disclaimer</span>
                          <img src="/img/bullet/question.png" alt="Image" />
                        </div>
                      </button>
                    </div>
                    <div
                      id="collapse_dwcompare3"
                      className={`${boxVisibility}`}
                      aria-expanded="true"
                    >
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <td>Warrant name</td>
                            <td>
                              Each warrant has an assigned name. The name
                              contains information about the warrant.
                            </td>
                          </tr>
                          <tr>
                            <td>Underlying</td>
                            <td>
                              The stock or index over which the Warrant is
                              listed.
                            </td>
                          </tr>
                          <tr>
                            <td>Issuer</td>
                            <td>
                              The Bank or Securities company that issues the
                              Warrant.
                            </td>
                          </tr>
                          <tr>
                            <td>Type</td>
                            <td>
                              Calls increase in value as share price rises, Puts
                              increase in value as share price falls.
                            </td>
                          </tr>
                          <tr>
                            <td>Exercise price</td>
                            <td>
                              The price at which you can buy (calls) or sell
                              (puts) the underlying
                              <br /> share or index at expiry.
                            </td>
                          </tr>
                          <tr>
                            <td>Expiry</td>
                            <td>The date at which the warrant will expire.</td>
                          </tr>
                          <tr>
                            <td>Last trading date</td>
                            <td>
                              The last day to buy or sell the warrant. This date
                              is the 2nd trading day prior to the expiry date.{" "}
                              <Link
                                target="_blank"
                                to="/edu/single-share-calls/last-trading-day"
                              >
                                Click here
                              </Link>{" "}
                              for more information.
                            </td>
                          </tr>
                          <tr>
                            <td>Exercise ratio</td>
                            <td>
                              The number of warrants that entitles you to one
                              unit of the underlying share or index at expiry.
                            </td>
                          </tr>
                          <tr>
                            <td>Moneyness</td>
                            <td>
                              ITM (in-the-money), OTM (out-of-the-money), ATM
                              (at-the-money).{" "}
                              <Link
                                target="_blank"
                                to="/edu/understanding-warrant-structure/moneyness"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about moneyness.
                            </td>
                          </tr>
                          <tr>
                            <td>Bid</td>
                            <td>
                              The highest current buy price of the Warrant. This
                              is the value that most accurately reflects the
                              'value' of the Warrant at any point in time.
                            </td>
                          </tr>
                          <tr>
                            <td>Ask</td>
                            <td>
                              The lowest current sell price for the Warrant.
                            </td>
                          </tr>
                          <tr>
                            <td>Last</td>
                            <td>
                              The last executed trade price for the warrant. Be
                              careful when using this as a reference price as
                              warrants do not trade as frequently as shares; the
                              last trade may have occurred some time ago.
                            </td>
                          </tr>
                          <tr>
                            <td>Prior</td>
                            <td>
                              Best bid price at close of previous day's trading.
                            </td>
                          </tr>
                          <tr>
                            <td>Change</td>
                            <td>
                              Current bid price for the Warrant compared to the
                              Prior (the previous day's close).{" "}
                              <Link
                                target="_blank"
                                to="/edu/monitoring-price-changes/closing-times"
                              >
                                Click here
                              </Link>{" "}
                              for more info.
                            </td>
                          </tr>
                          <tr>
                            <td>Underlying price</td>
                            <td>
                              The last traded price for the underlying shares.
                            </td>
                          </tr>
                          <tr>
                            <td>Volume</td>
                            <td>
                              Total traded volume in the Warrant for the day.
                            </td>
                          </tr>
                          <tr>
                            <td>Delta</td>
                            <td>
                              Estimates how a warrant moves in relation to the
                              underlying stock/index. Puts have negative deltas
                              because their prices increase as the underlying
                              stock/index falls. Note, if your warrant has a WPS
                              that this will need to be taken into account.
                            </td>
                          </tr>
                          <tr>
                            <td>Delta per warrant</td>
                            <td>
                              Delta/Warrant Per Share.{" "}
                              <Link
                                target="_blank"
                                to="/edu/useful-warrant-terms/delta"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about Delta per warrant.
                            </td>
                          </tr>
                          <tr>
                            <td>Premium</td>
                            <td>
                              Also refered to as the "Time Value" of the
                              warrant. Note that a higher premium could be due
                              to the warrant being longer dated or due to higher
                              gearing.{" "}
                              <Link
                                target="_blank"
                                to="/edu/useful-warrant-terms/premium"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about premium.
                            </td>
                          </tr>
                          <tr>
                            <td>Effective gearing</td>
                            <td>
                              Indicates your leveraged exposure. An effective
                              gearing of 5X tells you a 1% move in the
                              underlying stock/index results in an approximate
                              5% move in your Warrant price.{" "}
                              <Link
                                target="_blank"
                                to="/edu/useful-warrant-terms/effective-gearing"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about effective gearing.
                            </td>
                          </tr>
                          <tr>
                            <td>Implied volatility</td>
                            <td>
                              The higher the implied volatility, the higher the
                              price of the Warrant (all other factors held
                              constant).{" "}
                              <Link
                                target="_blank"
                                to="/edu/useful-warrant-terms/implied-volatility"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about implied volatility.
                            </td>
                          </tr>
                          <tr>
                            <td>Theta</td>
                            <td>
                              The daily time decay of the warrant expressed in
                              percentage terms.{" "}
                              <Link
                                target="_blank"
                                to="/edu/understanding-warrant-structure/time-decay"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about Theta.
                            </td>
                          </tr>
                          <tr>
                            <td>Live matrix</td>
                            <td>
                              Makes trading warrant easy by showing investors
                              where our bid prices will be throughout the day
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 sm-none xs-none">
              
              <Slider {...settings} className="ad-section custom">
                {advImgVal &&
                  advImgVal.map((data, index) => {
                    return (
                      <a target="_blank" href={data.link} key={index}>
                        <div>
                          <img src={data.imgSrc} />
                        </div>
                      </a>
                    );
                  })}
              </Slider>

              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Live matrix</h2>
                </div>
                <p className="section-p">
                  Makes trading warrant easy by showing investors where our bid
                  prices will be throughout the day
                </p>
                <div className="section-qlink-img section-qlink-lmatrix">
                  <Link to="/tools/livematrix/" className="section-qlink-achor">
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Warrant search</h2>
                </div>
                <p className="section-p">
                  Find the right warrant according to your investment criteria.
                </p>
                <div className="section-qlink-img section-qlink-dwsearch">
                  <Link
                    to="/tools/warrantsearch"
                    className="section-qlink-achor"
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Warrant terms</h2>
                </div>
                <p className="section-p">
                  Everything you need to know about your selected warrant is
                  found here!
                </p>
                <div className="section-qlink-img section-qlink-dwterm">
                  <Link
                    to="/tools/warrantterms"
                    className="section-qlink-achor"
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WarrantComparsionModal
        Url={url}
        Open={open}
        OpenModal={() => setOpen(!true)}
      />
    </>
  );
};

export default WarrantComparisons;
