import React, { Fragment } from 'react';
import IndexWarrants from './IndexWarrants';
import "./indexWarrant.css" 


const IndexWarrant = (props) => {
    return ( 
        <Fragment> 
        <IndexWarrants/>
        </Fragment> 
     );
}


export default IndexWarrant;