import React, { useState, useEffect } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ColumnBarChart from '../../../components/Charts/ColumnBarChart';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WarrantMarketTurnOver = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  const api = new API()
  const date = new Date();
  const [marketTurnOverAll, setMarketTurnOverAll] = useState([])
  const [marketTurnOverIndex, setMarketTurnOverIndex] = useState([])
  const [marketTurnOverStock, setMarketTurnOverStock] = useState([])

  const [advImgVal, setAdvImgVal] = useState([])


  useEffect(() => {
    getHomePopup()
  }, [])
  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };

  useEffect(() => {
    getMarketTurnOverResult()
  }, [])

  const getMarketTurnOverResult = async () => {
    let marketTurnOverResult = await api.get(config.marketTurnOver, true, { type: "dwturnover", qid: Date.now() })
    let marketTurnOverRes = get(marketTurnOverResult, "dwturnover", [])
    let marketTurnOverAllRes = get(marketTurnOverRes, 'all', [])
    let marketTurnOverIndexRes = get(marketTurnOverRes, 'index', [])
    let marketTurnOverStockRes = get(marketTurnOverRes, 'stock', [])
    setMarketTurnOverAll(marketTurnOverAllRes)
    setMarketTurnOverIndex(marketTurnOverIndexRes)
    setMarketTurnOverStock(marketTurnOverStockRes)
  }

  const updateDate = marketTurnOverAll && marketTurnOverAll[0] && marketTurnOverAll[0].stats_date_obj
  return (
    <div id="dwturnover" className="pageobj">
      <h1 id="ric" style={{ display: "none" }} />
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwstat">Warrant statistics</Link>
            </li>
            <li className="active">Warrant market turnover</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="topimg hidden-sm hidden-xs">
          <img src="/img/bg/top-marketturnover.jpg" />
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="section">
              <div className="page-header">
                <h1>Warrant market turnover</h1>
              </div>
              <p>
                How active was the warrant market today and how did it compare
                to previous days?
              </p>
              <div className="tblh">
                <div className="tblh-left">
                  <h2 className="small">Warrant market turnover - Total</h2>
                </div>
                <div className="tblh-right">
                  <div className="lastupdatebox hidden-xs">
                    Last update :{" "}
                    <span>{moment(date).format("DD MMM YY")}</span>
                  </div>
                </div>
              </div>
              <div id="totalTbl" className="tbl">
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Total market turnover (MYR)</th>
                      <th>Total warrant turnover (MYR)</th>
                      <th>Total warrant turnover as % of market turnover</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketTurnOverAll &&
                      marketTurnOverAll.map(
                        (turnOverAllData, turnOverAllIndex) => (
                          <tr
                            className="rowtmpl"
                            key={turnOverAllIndex}
                            idx={turnOverAllIndex}
                          >
                            <td className="stats_date_obj">
                              {turnOverAllData.stats_date_obj}
                            </td>
                            <td className="total">{turnOverAllData.total}</td>
                            <td className="dw">{turnOverAllData.dw}</td>
                            <td className="pct">
                              {(
                                Math.round(turnOverAllData.pct * 10) / 10
                              ).toFixed(1)}
                              %
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
              <div className="chartbox-market-turn-over">
                <div className="tblh">
                  <div className="tblh-left">
                    <h2 className="small">
                      Warrant market contribution by segment
                    </h2>
                  </div>
                </div>
                <ColumnBarChart
                  MarketTurnOverStock={[...marketTurnOverStock].reverse()}
                  MarketTurnOverIndex={[...marketTurnOverIndex].reverse()}
                />
              </div>
              <div className="tblh">
                <div className="tblh-left">
                  <h2 className="small">Warrant market turnover - Index</h2>
                </div>
                <div className="tblh-right">
                  <div className="lastupdatebox hidden-xs">
                    Last update :{" "}
                    <span>{moment(date).format("DD MMM YY")}</span>
                  </div>
                </div>
              </div>
              <div id="indexTbl" className="tbl">
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Total warrant market turnover (MYR)</th>
                      <th>Total index warrant turnover (MYR)</th>
                      <th>Index warrant % of market turnover</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketTurnOverIndex &&
                      marketTurnOverIndex.map(
                        (turnOverIndexData, turnOverIndexIdx) => (
                          <tr
                            className="rowtmpl"
                            key={turnOverIndexIdx}
                            idx={turnOverIndexIdx}
                          >
                            <td className="stats_date_obj">
                              {turnOverIndexData.stats_date_obj}
                            </td>
                            <td className="total">{turnOverIndexData.total}</td>
                            <td className="dw">{turnOverIndexData.dw}</td>
                            <td className="pct">
                              {(
                                Math.round(turnOverIndexData.pct * 10) / 10
                              ).toFixed(1)}
                              %
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
              <div className="tblh">
                <div className="tblh-left">
                  <h2 className="small">
                    Warrant market turnover - Single stock
                  </h2>
                </div>
                <div className="tblh-right">
                  <div className="lastupdatebox hidden-xs">
                    Last update :{" "}
                    <span>{moment(date).format("DD MMM YY")}</span>
                  </div>
                </div>
              </div>
              <div id="stockTbl" className="tbl">
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Total warrant market turnover (MYR)</th>
                      <th>Total single stock warrant turnover (MYR)</th>
                      <th>Single stock warrant % of market turnover</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketTurnOverStock &&
                      marketTurnOverStock.map(
                        (turnOverStockData, turnOverStockIdx) => (
                          <tr
                            className="rowtmpl"
                            key={turnOverStockIdx}
                          >
                            <td className="stats_date_obj">
                              {turnOverStockData.stats_date_obj}
                            </td>
                            <td className="total">{turnOverStockData.total}</td>
                            <td className="dw">{turnOverStockData.dw}</td>
                            <td className="pct">
                              {(
                                Math.round(turnOverStockData.pct * 10) / 10
                              ).toFixed(1)}
                              %
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
              <p className="small">
                All market data is delayed 15 mins unless otherwise indicated by
                timestamp.
              </p>
            </div>
          </div>
          <div className="hidden-xs hidden-sm col-md-4">            
            <Slider {...settings} className="ad-section custom">
              {advImgVal &&
                advImgVal.map((data, index) => {
                  return (
                    <a target="_blank" href={data.link} key={index}>
                      <div>
                        <img src={data.imgSrc} />
                      </div>
                    </a>
                  );
                })}
            </Slider>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Live matrix</h2>
              </div>
              <p className="section-p">
                Makes trading warrant easy by showing investors where our bid
                prices will be throughout the day
              </p>
              <div className="section-qlink-img section-qlink-lmatrix">
                <Link to="/tools/livematrix/" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant search</h2>
              </div>
              <p>
                Find the right warrant according to your investment criteria.
              </p>
              <div className="section-qlink-img section-qlink-dwsearch">
                <Link to="/tools/warrantsearch" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant calculator</h2>
              </div>
              <p>
                Estimates your future warrant price given a change in underlying
                price, time or implied volatility
              </p>
              <div className="section-qlink-img section-qlink-dwcalculator">
                <Link
                  to="/tools/warrantcalculator"
                  className="section-qlink-achor"
                >
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WarrantMarketTurnOver;
