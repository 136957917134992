import React, { useState, useEffect } from 'react';
import FreqAskedQues from './FreqAskedQues';
import './freqAskedQues.css';


const FrequentlyAskedQues = (props) => {
    return ( 
        <FreqAskedQues/>
        
     );
}

export default FrequentlyAskedQues;