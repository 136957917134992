import React, { useState, useEffect } from 'react';
import "./warrantDocument.css";
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { config } from '../../../utils/apiUrl';
import API from '../../../utils/apiCalling';
import { tabName } from '../../../utils/constants';
import NoticeTabs from './tabs/Notice';
import TermsheetTabs from './tabs/Termsheet';
import ProspectusTabs from './tabs/Prospectus';



const WarrantDocuments = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const api = new API()


  const [warrantDocumentsNotice, setWarrantDocumentsNotice] = useState([])
  const [warrantDocumentsProspectus, setWarrantDocumentsProspectus] = useState([])
  const [warrantDocumentsTermsheet, setWarrantDocumentsTermsheet] = useState([])

  
  const [activeTab, setActiveTab] = useState("Prospectus")


  const isActive = (tab) => {
    if (activeTab === tab) {
      return "active";
    }
    return "";
  }

  const renderTab = () => {
    switch (activeTab) {
      case tabName.NoticeTab:
        return <NoticeTabs WarrantDocumentsNotice={warrantDocumentsNotice}/>
        break;
      case tabName.TermsheetTab:
        return <TermsheetTabs SetWarrantDocumentsTermsheet={setWarrantDocumentsTermsheet} WarrantDocumentsTermsheet={warrantDocumentsTermsheet}/>
        break;
      case tabName.ProspectusTab:
        return <ProspectusTabs WarrantDocumentsProspectus={warrantDocumentsProspectus}/>
        break;
      default:
        return null
        break;
    }
  }


  useEffect(() => {
    getWarrantDocuments()
  }, [])


  const getWarrantDocuments = async () => {
    let WarrantDocumentsResult = await api.get(config.warrantDocuments, true, { m:0,qid: Date.now() })
    let WarrantDocumentsRes = get(WarrantDocumentsResult,  [])
    let WarrantDocumentsResNotice = get(WarrantDocumentsResult,"notice",  [])
    let WarrantDocumentsResProspectus = get(WarrantDocumentsResult,"prospectus",  [])
    let WarrantDocumentsResTermsheet = get(WarrantDocumentsResult,"termsheet",  [])
    setWarrantDocumentsNotice(WarrantDocumentsResNotice)
    setWarrantDocumentsProspectus(WarrantDocumentsResProspectus)
    setWarrantDocumentsTermsheet(WarrantDocumentsResTermsheet)
    
  }

    return (
        <div id="document" className="pageobj" style={{}}>
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">Warrant tools</Link>
              </li>
              <li className="active">Warrant documents</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h1>Warrant documents</h1>
            </div>
            <p className="section-txt">
              Provides access to all of Macquarie's listing documents, prospectus,
              terms and conditions relevant for the warrants that are issued. It is
              advised that you review these documents before investing.
            </p>
            <div className="tablist">
              <div id="topTabs" className="tablist-left">
                <table>
                  <tbody>
                    <tr>
                      <td onClick={() => setActiveTab(tabName.ProspectusTab)} 
                      className={` ${isActive(tabName.ProspectusTab)}`}
                      >
                        <div
                          className="tablist-item"
                        >
                          <div className="tablist-item-txt">Base prospectus</div>
                        </div>
                      </td>
                      <td onClick={() => setActiveTab(tabName.TermsheetTab)} 
                      className={` ${isActive(tabName.TermsheetTab)}`}>
                        <div
                          className="tablist-item"
                        >
                          <div className="tablist-item-txt">Term sheets</div>
                        </div>
                      </td>
                      <td onClick={() => setActiveTab(tabName.NoticeTab)} 
                      className={` ${isActive(tabName.NoticeTab)}`}>
                        <div
                          className="tablist-item"
                        >
                          <div className="tablist-item-txt">
                            Expiry &amp;
                            <br />
                            Adjustment notices
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="tabcontent-holder">
             
            {renderTab()}
            </div>
            <hr className="hr-hmgbm" />
          </div>
        </div>
      </div>
      
      );
}
 
export default WarrantDocuments;