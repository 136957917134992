import {Link} from "react-router-dom"

function getData(fieldName, data) {
    const fieldNameSplit = fieldName.split("-");
    const fieldNameUse = fieldNameSplit[fieldNameSplit.length - 1];
    if (fieldNameUse in data) {
      return data[fieldNameUse];
    }
    return "noData";
  }
  const getUnderlyingTickerDisplayName = (val,name,type)=>{
    let UnderlyingtickerdisplaynameMap = JSON.parse(window.localStorage.getItem('UnderlyingtickerdisplaynameMap'))
    let value = UnderlyingtickerdisplaynameMap.filter((v)=>{
      return v.underlying_ric == val
    })
    if(value.length>0){
      if(type==1){
        return value[0].underlying_name
      }else{
        return value[0].underlying_ticker
      }
    }else{
      return name
    }
  }
  const tableConfig = {
    fields: [
      {
        className: "rw-ticker",
        dataHasTip: "0",
        name: "Warrant name",
        columnData: function (data, index) {
          const value = getData("ticker", data);
          const href = "/tools/livematrix/" + value;
          return (
            <td key={this.dataHasTip + "-" + index}>
              <a href={href}>{data.dsply_nmll}</a>
            </td>
          );
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Warrant name:</strong>&nbsp; Each warrant has an assigned name. The name contains information about the warrant.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-underlying_ric",
        dataHasTip: "1",
        name: "Underlying",
        columnData: function (data, index) {
          // const tickerName = getData("underlying_ticker", data);
          const tickerName = getUnderlyingTickerDisplayName(getData("underlying_ric", data),getData("underlying_ticker", data),2)
          const underlying_ricValue = getData("underlying_ric", data);
          const href =
            "/tools/underlying/" +
            underlying_ricValue;
          return (
            <td key={this.dataHasTip + "-" + index}>
              <a href={href}>{tickerName}</a>
            </td>
          );
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Underlying:</strong> The stock or index over which the Warrant is listed.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-issuer_code",
        dataHasTip: "2",
        name: "Issuer",
        columnData: function (data, index) {
          const issueCode = getData("issuer_code", data);
          const value = getData(this.className, data);
          return (
            <td key={this.dataHasTip + "-" + index} className={issueCode == "Macquarie" ? "text-bold" : ""}>
              {value}
            </td>
          );
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Issuer:</strong> The Bank or Securities company that issues the Warrant.
              </p>
            </div>
          );
        },
      },
      
      {
        className: "rw-view",
        dataHasTip: "3",
        name: "Live matrix",
        columnData: function (data, index) {
          
          const issueCode = getData("issuer_code", data);
          const value = getData("ticker", data);
          const href = "/tools/livematrix/" + value;
          return ( issueCode == "Macquarie" ?
            <td key={this.dataHasTip + "-" + index}>
              <a className="btn btn-primary" href={href}>
                View
              </a>
            </td> : 
            <td key={this.dataHasTip + "-" + index}></td>
          );
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Live matrix:</strong> Makes trading warrant easy by showing investors where our bid prices will be throughout the day
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-type",
        dataHasTip: "4",
        name: "Type",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Type:</strong> Calls increase in value as share price rises, Puts increase in value as share price falls.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-exercise_price",
        dataHasTip: "5",
        name: "Exercise price",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Exercise price:</strong> The price at which you can buy (calls) or sell (puts) the underlying
share or index at expiry.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-maturity",
        dataHasTip: "6",
        name: "Expiry",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Expiry:</strong> The date at which the warrant will expire.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-last_trading_date",
        dataHasTip: "7",
        name: "Last trading date",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Last trading date:</strong> The last day to buy or sell the warrant. This date is the 2nd trading day prior to the expiry date. <Link target="_blank" to="/edu/single-share-calls/last-trading-day">Click here</Link> for more information.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-conv_ratio",
        dataHasTip: "8",
        name: "Exercise ratio",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Exercise ratio:</strong> The number of warrants that entitles you to one unit of the underlying share or index at expiry.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-moneyness",
        dataHasTip: "9",
        name: "Moneyness",
        columnData: function (data, index) {
          const moneyness = getData(this.className, data);
          const percent = getData("percent_moneyness", data);
          let moneynessName = "ATM";
          if (moneyness === "1") {
            moneynessName = "ITM";
          } else if (moneyness === "2") {
            moneynessName = "OTM";
          }
          let upDown = "val ";
          if (moneynessName === "OTM") {
            upDown += "down";
          } else if (moneynessName === "ITM") {
            upDown += "up";
          }
          const value = percent + "% " + moneynessName;
          return (
            <td key={this.dataHasTip + "-" + index} className={upDown}>
              {value}
            </td>
          );
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Moneyness:</strong> ITM (in-the-money), OTM (out-of-the-money), ATM (at-the-money). <Link target="_blank" to="/edu/understanding-warrant-structure/moneyness">Click here</Link> to learn more about moneyness.
                
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-BID",
        dataHasTip: "10",
        name: "Bid",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Bid:</strong> The highest current buy price of the Warrant. This is the value that most accurately reflects the 'value' of the Warrant at any point in time.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-ASK",
        dataHasTip: "11",
        name: "Ask",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Ask:</strong> The lowest current sell price for the Warrant.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-TRDPRC_1",
        dataHasTip: "12",
        name: "Last",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Last:</strong> The last executed trade price for the warrant. Be careful when using this as a reference price as warrants do not trade as frequently as shares; the last trade may have occurred some time ago.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-HST_CLOSE",
        dataHasTip: "13",
        name: "Prior",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Prior:</strong> Best bid price at close of previous day's trading.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-BID_NETCHNG",
        dataHasTip: "14",
        name: "Change (MYR) ",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          let upDown = "val ";
          if (value.indexOf("-") > -1) {
            upDown += "down";
          } else if (value.indexOf("+") > -1) {
            upDown += "up";
          }
          return (
            <td key={this.dataHasTip + "-" + index} className={upDown}>
              {value}
            </td>
          );
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Change:</strong> Current bid price for the Warrant compared to the Prior (the previous day's close). <Link target="_blank" to="/edu/monitoring-price-changes/closing-times">Click here</Link> for more info.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-underlying_price",
        dataHasTip: "15",
        name: "Underlying price",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Underlying price:</strong> The last traded price for the underlying shares.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-ACVOL_1",
        dataHasTip: "16",
        name: "Volume",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Volume:</strong> Total traded volume in the Warrant for the day.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-delta",
        dataHasTip: "17",
        name: "Delta (%)",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Delta:</strong> Estimates how a warrant moves in relation to the underlying stock/index. Puts have negative deltas because their prices increase as the underlying stock/index falls. Note, if your warrant has a WPS that this will need to be taken into account.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-delta_per_wrnt",
        dataHasTip: "18",
        name: "Delta per warrant (%)",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Delta per warrant:</strong> Delta/Warrant Per Share. <Link target="_blank" to="/edu/useful-warrant-terms/delta">Click here</Link> to learn more about Delta per warrant.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-premium",
        dataHasTip: "19",
        name: "Premium (%)",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Premium:</strong> Also refered to as the "Time Value" of the warrant. Note that a higher premium could be due to the warrant being longer dated or due to higher gearing. <Link target="_blank" to="/edu/useful-warrant-terms/premium">Click here</Link> to learn more about premium.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-effective_gearing",
        dataHasTip: "20",
        name: "Effective gearing (x)",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Effective gearing:</strong> Indicates your leveraged exposure. An effective gearing of 5X tells you a 1% move in the underlying stock/index results in an approximate 5% move in your Warrant price. <Link target="_blank" to="/edu/useful-warrant-terms/effective-gearing">Click here</Link> to learn more about effective gearing.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-implied_volatility",
        dataHasTip: "21",
        name: "Implied volatility (%)	",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Implied volatility:</strong> The higher the implied volatility, the higher the price of the Warrant (all other factors held constant). <Link target="_blank" to="/edu/useful-warrant-terms/implied-volatility">Click here</Link> to learn more about implied volatility.
              </p>
            </div>
          );
        },
      },
      {
        className: "rw-theta",
        dataHasTip: "22",
        name: "Theta (%)",
        columnData: function (data, index) {
          const value = getData(this.className, data);
          return <td key={this.dataHasTip + "-" + index}>{value}</td>;
        },
        tooltip: function () {
          return (
            <div className="bg-tooltip">
              <p className="tooltip-content">
                <strong>Theta:</strong> The daily time decay of the warrant expressed in percentage terms. <Link target="_blank" to="/edu/understanding-warrant-structure/time-decay">Click here</Link> to learn more about Theta.
              </p>
            </div>
          );
        },
      },
    ],
  };
  
  export default tableConfig;
  