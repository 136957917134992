import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import "./FooterInfo.css";

const PrivacyPolicy =()=> {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (

        <div id="privacypolicy" className="pageobj">
            <div className="breadcrumbbar">
                <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li>
                            Macquarie
                        </li>
                        <li className="active">Privacy policy</li>
                    </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="section">
                    <div className="page-header">
                        <h1>Privacy policy</h1>
                    </div>
              

                <div className="title">Macquarie Capital Securities (Malaysia) SDN BHD
                    <br />
                    Macquarie Equipment Leasing SDN BHD</div> 
                <p><Link to={{ pathname: "http://www.macquarie.com/my/about/disclosures/malaysia-privacy-policy-my" }} target="_top">View Malay version</Link></p>

                <div className="title">Personal data notice relating to the Personal Data Protection Act 2010 (The "Act")</div>
                <p>This personal data notice applies to any person whose personal data is processed by or on behalf of Macquarie
                    Capital Securities (Malaysia) Sdn Bhd or Macquarie Equipment Leasing Sdn. Bhd. (“we” or “us”). Terms not
                    defined in this notice shall have the meaning given to them in the Act (if any).</p>

                <p>If you provide or you have provided to us any personal data of another individual including your directors,
                    officers and/or employees, you (i) represent and warrant that you have obtained sufficient informed
                    consent from each of such individuals to the processing of his/her personal data by us, (ii) will provide
                    us with evidence of such consent when requested by us, and (iii) shall provide a copy of this notice to
                    each of such individuals. References to “you” in this notice shall include all relevant data subjects.</p>

                <p>Please note that this notice may be amended from time to time and the data subject shall visit <Link to={{ pathname: "https://www.macquarie.com/au/en/disclosures/privacy-and-cookies.html"}} target="_top">
                        https://www.macquarie.com/au/en/disclosures/privacy-and-cookies.html </Link> on a regular basis for updates
                    and changes.</p>

                <div className="title">The personal data we process</div>
                <p>The personal data processed by us may include one or more of the following: your name, address, proof of
                    identification (eg. National Registration Identity Card, driver’s license and passport), contact details,
                    email address, occupation, sample of signature, information of any past breaches of or convictions under
                    any law, your financial position, results from third party searches, the relevant products or services
                    provided to you and any other personal data required for the purposes set out in paragraph 3 below
                    (collectively “<strong>your personal data</strong>”).</p>

                <p>Sensitive personal data may include such things as information on physical or mental health or medical
                    condition, political opinions, religious or other similar beliefs, commission or alleged commission of any
                    offence.</p>

                <p>Although it is not obligatory to provide your personal data to us, failure to supply such personal data
                    may result in us being unable to open or continue accounts or provide brokerage or financial services.</p>

                <div className="title"> Purposes</div>
                <p>We may process your personal data for the following purposes:</p>
                <ul>
                    <li>to fulfil our legal or regulatory obligations under applicable laws and rules, such as those relating to
                        anti-money laundering and counter-terrorism financing (including identification verification);</li>
                    <li>to consider applications for products and/or services (including assessing and/or verifying your
                        creditworthiness);</li>
                    <li>to communicate with you including responding to your enquiries;</li>
                    <li>to provide you with information on products, services and/or events offered/organised by us, our affiliates
                        or our business partners;</li>
                    <li>to perform control and risk management functions;</li>
                    <li>to enforce our rights; and</li>
                    <li>any other purpose that is incidental or ancillary to the above purposes.</li>
                    </ul>

            <div className="title">Sources</div>
            <p>Your personal data is collected from various sources, including information you have provided to us,
                information from third parties and information in the public domain.</p>

            <div className="title">Access and request for correction</div>
            <p>You may contact us by writing to us if you wish to request for access to your personal data that we retain
                about you, request for your personal data retained by us to be corrected, or withdraw your consent to the
                processing or use of your personal data by us. We may charge a reasonable fee for processing your request.
                We may also refuse to comply with your request in accordance with the Act. Any request, inquiries or
                complaints can be sent to privacy@macquarie.com by email or attention to the Complaint Officer at Level 10,
                Menara Dion, 27 Jalan Sultan Ismail, 50250 Kuala Lumpur, Malaysia (Tel: +60 3 2059 8833).</p>

            <div className="title">Disclosure of personal data</div>
            <p>We may share your personal data with entities within the Macquarie Group of companies including all related
                companies, subsidiaries, holding companies and associated companies, and may disclose your personal data
                (whether in or outside Malaysia):</p>

            <ul>

                <li>as required by applicable laws or rules, such as those relating to anti-money laundering and
                    counter-terrorism financing, or as required by regulatory, supervisory, law enforcement or governmental
                    bodies; </li>
                <li>to any court of competent jurisdiction in defense of claims or enforcement of rights; </li>
                <li>to service providers who provide services in connection with our products and services; </li>
                <li>to our professional advisors on a need-to-know basis; or </li>
                <li>any other persons which we reasonably think fit. </li>
            </ul>

            <div className="title">Transfer of information</div>
            <p>Your personal data may be transferred to a place outside of Malaysia.</p>

            <p>In the event of any inconsistency or conflict between the English language version and the Bahasa Malaysia
                version of this Personal Data Notice, the English language version shall prevail.</p>

            <p>By providing to us your personal data and/or should you continue to trade with us (where applicable), you
                hereby consent to the collection, processing, use, disclosure and retention of your personal data in
                accordance with all of the foregoing.</p>

            <div className="title">Macquarie Capital Securities (Malaysia) Sdn Bhd</div>
            <div className="title">Macquarie Equipment Leasing Sdn Bhd</div>
            <p>February 2014</p>

        </div>
      </div >

</div>

  );
}

export default PrivacyPolicy;