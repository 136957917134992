import React, { useState, useEffect,Fragment } from 'react';
import WarrantSpreads from './WarrantSpreads';
import "../../../common.css";
import "../../../investorAcademy.css";


const WarrantSpread = (props) => {
    return ( 
        <Fragment> 
            <WarrantSpreads/>
        </Fragment> 
     );
}


export default WarrantSpread;