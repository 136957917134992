import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom"
import "./warrantHero.css"



const WarrantHero = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
        <div id="hero2015" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home?news">Market news</Link>
              </li>
              
              <li><Link to="/marketnews/activities">Activities</Link>
              </li>
              <li className="active">Warrant Hero 2015</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                <div className="page-header">
                  <h1>Warrant Hero 2015</h1>
                  
                </div>
                <img className="photo-main" src="/img/activities/warrant_hero_2015.jpg" alt="warrant hero 2015" />
                <p className="section-p">
                  The Warrant Hero 2015 challenge ran for 12 weeks from 3 August 2015 to 23 October 2015, during which participants competed in Malaysia’s first virtual warrant competition, gaining real-time experience of trading without investing real money, promoting financial education in a risk-free environment. Our top performing Warrant Hero traders collectively took home RM100,000 in cash prizes!
                </p>
                <div className="prize-row row">
                  <div className="prize-col col-xs-12 col-sm-6">
                    <h3 className="prize-ttl">Grand</h3>
                    <div className="prize-cell first">
                      <img src="/img/activities/prize_1st.png" />
                      <div className="prize-cell-ttl">Winner</div>
                      <div className="prize-cell-val">RM10,000</div>
                    </div>
                    <div className="prize-cell">
                      <img src="/img/activities/prize_2nd.png" />
                      <div className="prize-cell-ttl">1st Runner Up</div>
                      <div className="prize-cell-val">RM6,000</div>
                    </div>
                    <div className="prize-cell last">
                      <img src="/img/activities/prize_3rd.png" />
                      <div className="prize-cell-ttl">2nd Runner Up</div>
                      <div className="prize-cell-val">RM3,500</div>
                    </div>
                  </div>
                  <div className="prize-col col-xs-12 col-sm-6">
                    <h3 className="prize-ttl">Fortnightly Prize x 6</h3>
                    <div className="prize-cell first">
                      <img src="/img/activities/prize_1st.png" />
                      <div className="prize-cell-ttl">Winner</div>
                      <div className="prize-cell-val">RM5,000</div>
                    </div>
                    <div className="prize-cell">
                      <img src="/img/activities/prize_2nd.png" />
                      <div className="prize-cell-ttl">1st Runner Up</div>
                      <div className="prize-cell-val">RM3,000</div>
                    </div>
                    <div className="prize-cell last">
                      <img src="/img/activities/prize_3rd.png" />
                      <div className="prize-cell-ttl">2nd Runner Up</div>
                      <div className="prize-cell-val">RM2,000</div>
                    </div>
                    <p className="prize-info">Plus Fortnightly Trade Quest Prizes of RM1,000 x 3 and a pre-launch prize of RM2,000.</p>
                  </div>
                </div>
                <div className="gallery">
                  <img className="gallery-img" src="/img/activities/hero_1st.jpg" />
                  <img className="gallery-img" src="/img/activities/hero_2nd.jpg" />
                  <img className="gallery-img last" src="/img/activities/hero_3rd.jpg" />
                </div>
                <img className="photo-follow" src="/img/activities/highlights_followus.jpg" alt="Follow us on malaysiawarrant" />
                <p className="bottom-p">
                  A special thank you from Macquarie and Trade Hero to all who participated in Warrant Hero 2015 and made this event a success!
                </p>
                <div className="bottom-row row">
                  <div className="bottom-col col-xs-12">
                    <p>Want to find out more about warrants and trading? Click to visit our Investor Academy or Bursa Market Place.</p>
                  </div>
                </div>
                <div className="bottom-row row">
                  <div className="bottom-col col-xs-12 col-sm-6 col-sm-pull-right">
                    <Link to="//edu" target="_blank"><img src="/img/activities/investoracademy.png" alt="Investor Academy" /></Link>
                  </div>
                  <div className="bottom-col col-xs-12 col-sm-6">
                    <Link to={{pathname:"https://www.bursamarketplace.com/"}} target="_blank"><img src="/img/activities/bursamktplc.png" alt="BursaMKTPLC" /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
}
export default WarrantHero;