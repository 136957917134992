class API {
    async get(url, haveParams = false, params = {}) {
        
            try {
                // const headers = new Headers();
                // let token =  await this.getToken()
                const request = await fetch(`${url}${haveParams ? `?${new URLSearchParams(params)}` : ''}`, {
                    method: 'GET',

                })
                const response = await request.json();
                return response
            } catch (error) {
                 return ({
                    success: false,
                    error
                })
            }
        


    }


    async post(url, body) {
        
            const data = Object.keys(body).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);
            }).join('&');
            
        try {
            const headers = new Headers();
            headers.set('Content-Type', 'application/x-www-form-urlencoded');
            const request = await fetch(url, {
                method: 'POST',
                headers,
                body: data
            })
            const response = await request.json();
            if(response){
                return response;
            }else{
                return null
            }
        } catch (error) {
            return ({
                success: false,
                error
            })

        }
    }

    async put(url, body) {
        // let token =  await this.getToken()
        // body.token = token
        try {
            const headers = new Headers();
            headers.set('Content-Type', 'application/json');
            const request = await fetch(url, {
                method: 'PUT',
                headers,
                body: JSON.stringify(body)
            })
            const response = await request.json();
            return response;
        } catch (error) {
            return {
                success: false,
                error
            }
        }
    }

    async delete(url, params) {
        // let token =  await this.getToken()
        // body.token = token

        try {
            const headers = new Headers();
            headers.set('Content-Type', 'application/json');
            const request = await fetch(`${url}/${params}`, {
                method: 'DELETE',
                headers,
            })
            const response = await request.json();
            return response;
        } catch (error) {
            return {
                success: false,
                error
            }
        }
    }

    async getToken() {
        const token = await '' 
        return token
    }

    

    
}


export default API;