import { Toast } from 'bootstrap';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import "./subscribe.css";
import { toast } from "react-toastify"
import SubscribeModalImg from '../../../components/common/subscribeModalImg';
import SpeakModal from '../../WarrantEducation/SuccessSpeak/SpeakModal'
import validator from 'validator'

const Subscribes = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const api = new API()

    const [email, setEmail] = useState();
    const [openLive, setOpenLive] = useState(false)
    const [openMsg, setOpenMsg] = useState(false)
    const [emailMsg, setEmailMsg] = useState(false)

    const validateEmail = (e) => {
        var email = e.target.value

        if (validator.isEmail(email)) {
            setEmail(email)
        } else {
            setEmailMsg(true)
            setEmail()
        }
    }

    function valueSubmit(e) {
        if (!email) {
            setEmailMsg(true)
        }
        else {
            submit(e)
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        let data = {
            email
        }
        let res = await api.post(`${config.subscription}${"?p=register"}`, data)
            .then((res) => {
                setOpenMsg(true)
                setTimeout(() => setOpenMsg(false), [2500])
            })
            .catch((error) => console.log(error))
    }

    return (
        <>
            <div id="subscribe" className="pageobj">
                <div className="breadcrumbbar">
                    <div className="container-fluid">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/home?news">Market news </Link>
                            </li>
                            <li className="active">Subscribe </li>
                        </ol>
                    </div>
                </div>
                <div className="stripe-bg" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section">
                                <div
                                    className="bg-layer  subscribe_bg_sec1"
                                    style={{ height: "1000px" }}
                                >
                                    <img src="/img/marketnews/bg-subscribe.jpg"/>
                                </div>
                                <div
                                    className="bg-layer subscribe_bg_sec2"
                                    style={{ height: "1000px" }}
                                >
                                    <img src="/img/marketnews/u2004_a.png"/>
                                </div>
                                <div className="content-layer">
                                    <div className="page-header" style={{ marginTop: 0 }}>
                                        <h1 className="color-primary">KEEP UP TO DATE</h1>
                                        <div className="title_2">with the latest news</div>
                                        <div className="title_3">
                                            Sign up for Macquarie’s daily newsletter
                                        </div>
                                        <div className="title_4">
                                            Start each day with the inside scoop on what's happening in the
                                            warrants market.
                                        </div>
                                        <div
                                            id="view-sample-btn"
                                            data-bs-toggle="collapse"
                                            href="#popup-image"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="popup-image"
                                            onClick={() => setOpenLive(true)}
                                        >View sample</div>
                                    </div>
                                    <div className="tips-layer">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-4">
                                                <div>
                                                    <div className="tip-icon">
                                                        <img src="/img/marketnews/subscribe_1.png" />
                                                    </div>
                                                    <div className="tip-item">
                                                        <div className="tip-title">Daily highlights</div>
                                                        Including Macquarie Research updates, technical analysis
                                                        from Recognia and updates on the latest events moving the
                                                        markets.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-4">
                                                <div>
                                                    <div className="tip-icon">
                                                        <img src="/img/marketnews/subscribe_2.png" />
                                                    </div>
                                                    <div className="tip-item">
                                                        <div className="tip-title">End of day insights</div>
                                                        Keeps you in touch with all the action from the warrant
                                                        market. Hear about what other investors are buying and
                                                        selling.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-4">
                                                <div>
                                                    <div className="tip-icon">
                                                        <img src="/img/marketnews/subscribe_3.png" />
                                                    </div>
                                                    <div className="tip-item">
                                                        <div className="tip-title">Overnight market wrap</div>
                                                        Highlights the major news events from the US and European
                                                        markets. What is going to affect the market today?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shadow-layer">
                                        <div className="signup-layer">
                                            <div className="page-header">
                                                <h3>Sign up now</h3>
                                            </div>
                                            <input
                                                type="text"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => {
                                                    validateEmail(e)
                                                    setEmailMsg(false)
                                                }}
                                                placeholder="Email address" />
                                            <div className="warning">
                                                {emailMsg && <span>Invalid email</span>}
                                            </div>
                                            <p className="small">
                                                By clicking “Submit”, you confirm that you would like to
                                                receive Macquarie’s daily newsletter and invitations for
                                                warrant seminars, and you acknowledge that Macquarie’s website
                                                administrators may have access to your personal data for the
                                                processing of such data on behalf of Macquarie Capital
                                                Securities Malaysia Sdn Bhd and other companies of Macquarie
                                                group (“Macquarie”). You may view Macquarie’s privacy policy
                                                with regard to collection and management of personal data via
                                                the links at the bottom of this page.
                                                <br />
                                                <br />
                                                <input className="agreechckbox" type="checkbox" />
                                                Tick here if you agree to allow Macquarie to use your personal
                                                data for purposes of marketing and advertising of financial
                                                products (including using your data for market research and
                                                analysis and sending focus group invitations to you).
                                                <br />
                                                <br />
                                            </p>
                                            <button
                                                id="submitBtn"
                                                onClick={(e) => valueSubmit(e)}
                                            >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SubscribeModalImg Open={openLive} OpenModel={() => setOpenLive(!true)} />
            <div id="popup-customized" className="subscribe" style={{ display: "none" }}>
                <div>
                    <h5>You have successfully registered for our daily newsletter!</h5>
                </div>
            </div>
            {openMsg && <SpeakModal open={openMsg} />}
        </>
    );
}
export default Subscribes;
