import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const SelectingWarrentImg = (props) => {
    const {
        OpenModel,
        Open,
        Data
    } = props;

    return (
       
        <div className="inner-area">
            <Modal centered isOpen={Open} style={{ maxWidth: "90%", maxHeight: "1000px", margin:"0px auto" }} className="imgModal  how_put_sec mt-0 mb-0">


                <img className="btn-close" style={{ background:"#000", top:"-24px", width:"20px", height:"20px"}} onClick={OpenModel} src="/img/bullet/icon-close-white.png"/>
                <img
                    className="image"
                    src={Data}
                    style={{ width: "100%", maxWidth:"767", height: "auto" }}
                />
            </Modal>
        </div>
    );
}

export default SelectingWarrentImg;