import React, { useState, useEffect,Fragment } from 'react';
import UnderstandingWarrantStructures from './UnderstandingWarrantStructures';
import "../common.css";
import "../investorAcademy.css" 


const UnderstandingWarrantStructure = (props) => {
    return ( 
        <Fragment> 
        <UnderstandingWarrantStructures/>
        </Fragment> 
     );
}


export default UnderstandingWarrantStructure;