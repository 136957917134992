import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
function Guest3() {
   const refreshPage = () => {
     window.location.reload(false);
   };
  return (
    <div className="videorack-body">
      <div className="videorack-content swiper-container swiper-container-horizontal">
        <div className="swiper-wrapper">
          <div
            className="swiper-slide swiper-slide-active"
            style={{
              paddingLeft: "30px",
              paddingRight: "35px",
              // width: "1090px",
            }}
          >
            <Carousel
              showArrows={true}
              emulateTouch
              showStatus={false}
              autoFocus
              infiniteLoop={false}
              showIndicators={false}
            >
              <div>
                <table className="videorack-page ">
                  <tbody>
                    <tr>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/RehMF8yfkNM/13">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/RehMF8yfkNM/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter One{" "}
                            <br />
                            Importance of the S&P 500
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={1}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/9DKwtSiGJTA/61">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/9DKwtSiGJTA/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span> Chapter Two{" "}
                            <br />
                            Performance of the S&P post-
                            <br />
                            election
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={2}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/v1pCDeLGQwI/62">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/v1pCDeLGQwI/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Three{" "}
                            <br /> Trump policy outlook
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={3}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/EkTueibHkuw/63">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/EkTueibHkuw/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Four
                            <br />
                            Volatility, dispersion-correlation and
                            <br /> possible returns on S&P 500
                          </div>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="videorack-page hidden-xs hidden-sm">
                  <tbody>
                    <tr>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/o4LQhShybkA/64">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/o4LQhShybkA/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Five
                            <br />
                            What is happening in other markets

                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={1}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/609AeFxPhD0/65">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/609AeFxPhD0/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Six{" "}
                            <br />
                            What happens under a
                            <br />
                            protectionist scenario

                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={2}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/YNyHOt4TeuM/66">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/YNyHOt4TeuM/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Seven
                            <br />
                            Likelihood of a crisis for Asia ?

                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={3}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/ihOVmDxOyfs/67">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/ihOVmDxOyfs/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Eight
                            <br /> S&P 500 vs Malaysia
                          </div>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="videorack-page hidden-xs hidden-sm">
                  <tbody>
                    <tr>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/9CHsaBWuHSM/68">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/9CHsaBWuHSM/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Nine
                            <br />
                            S&P 500's forward P/E is at its highest since 2004, should we be worried?
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={1}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/5TH7J19EFaA/69">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/5TH7J19EFaA/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Ten{" "}
                            <br />
                            Reasons to trade S&P warrants

                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={2}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/N5MGzl_-U1c/70">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/N5MGzl_-U1c/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Eleven
                            <br />
                            Example of using S&P puts

                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={3}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/eqVaa6BJVhQ/71">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/eqVaa6BJVhQ/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter Twelve
                            <br /> Example of using S&P calls
                          </div>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guest3;
