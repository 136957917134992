import React, { useEffect, useState, Fragment } from "react";
import "./warrantTerms.css";
import Select from "react-select";
import { get } from "lodash";
import API from "../../../utils/apiCalling";
import { config, Base_Url } from "../../../utils/apiUrl";
import { Link } from "react-router-dom";
import WarrantTermsModal from "../../../components/common/warrantTermsModal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import TermsChart from "../../../components/Charts/TermsChart";
import ReactTooltip from "react-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import GaEventsTracker from "../../../components/common/GaEventsTracker";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WarrantTerms = () => {

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  const useGaEventsTracker = GaEventsTracker("External Links")

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const api = new API();

  const [symbolList, setSymbolList] = useState([]);
  // const [qid, setQid] = useState("1645071046879");
  const [type, setType] = useState("warrantdata");
  const [ric, setRic] = useState("");
  const [liveMatrixSelectedName, setLiveMatrixSelectedName] = useState("");
  const [liveIndexSymbolObj, setLiveIndexSymbolObj] = useState({});
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [underlyingric, setUnderlyingric] = useState(null);
  const [underlyingcurr, setUnderlyingcurr] = useState("");
  const [chartdata, sethartdata] = useState(null);
  const [lastupdated, setLastupdated] = useState(null);
  const [activeTab, setActiveTab] = useState("D");
  const [chartDataArr, setChartDataArr] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const [ricAndCode, setRicAndCode] = useState("");
  const [upAndDown, setUpAndDown] = useState("");
  const [upAndDown1, setUpAndDown1] = useState("");
  const [qid, setQid] = useState(Date.now());
  const [UnderlyingBid, setUnderlyingBid] = useState([]);
  const [localRic,setLocalRic] = useState(JSON.parse(window.localStorage.getItem("ric")) === null ? [] : JSON.parse(window.localStorage.getItem("ric")))

  const [boxVisibility, setBoxVisibility] = useState("hide");
  const [advImgVal, setAdvImgVal] = useState([]);
  const [underlying_curr, setUnderlying_curr] = useState("HKD");

  useEffect(() => {
    getHomePopup()
  }, [])

  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };
  const getUnderlyingTickerDisplayName = (val,name,type)=>{
    let UnderlyingtickerdisplaynameMap = JSON.parse(window.localStorage.getItem('UnderlyingtickerdisplaynameMap'))
    let value = UnderlyingtickerdisplaynameMap.filter((v)=>{
      return v.underlying_ric == val
    })
    if(value.length>0){
      if(type==1){
        return value[0].underlying_name
      }else{
        return value[0].underlying_ticker
      }
    }else{
      return name
    }
  }
  const showNewBox = () => {
      if(boxVisibility === "hide"){
        setBoxVisibility("show")
      }else if(boxVisibility === "show"){
        setBoxVisibility("hide")
      }else{
        setBoxVisibility("hide")
      }
  }

  useEffect(() => {
    getWarrantTermsSelect();
  }, []);  

  useEffect(() => {
    if (ricAndCode !== "") getWarrantChartData();
  }, [activeTab]);


  useEffect(() => {
    if (ric !== "") getWarrantToolsLiveMatrix();
    // fetch(
    //   `${Base_Url}/apimqmy/ComparisonDataJSON?type=u&ric=${ricAndCode}&qid=${qid}`
    // )
    //   .then((resp) => resp.json())
    //   .then((data) => setUnderlyingBid(data))
    //   .catch((error) => console.log(error));
  }, [liveMatrixSelectedName]);




  const getWarrantTermsSelect = async () => {
    let data = {
      qid,
    };
    let LiveIndexSymbolResult = await api.get(
      config.homeLiveIndexFuturesSymbol,
      true,
      data
    );
    let liveIndexSymbolRes = get(LiveIndexSymbolResult, "symbols", []);
    const liveIndexSymbolData = liveIndexSymbolRes.map(
      ({ name, security_code, ric: value }) => ({
        label: `${name} ${"("}${security_code}${")"}`,
        value,
        security_code,
      })
    );
    let selectedOneName =
      liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].name;
    let selectedOneCode =
      liveIndexSymbolRes &&
      liveIndexSymbolRes[0] &&
      liveIndexSymbolRes[0].security_code;
    // let selectedOneRic = liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].ric
    // let selectedOneUnderlyingRic = liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].underlying_ric
    let tempSelectedName = selectedOneName + " (" + selectedOneCode + ") ";
    setRic(liveIndexSymbolRes[0].ric);
    setSymbolList(liveIndexSymbolData);
    setLiveMatrixSelectedName(tempSelectedName);
  };

  const getWarrantToolsLiveMatrix = async () => {
    let data = {
      type,
      ric,
      qid,
    };
    let LiveIndexSymbolResult = await api.get(
      config.marketTurnOver,
      true,
      data
    );
    let liveIndexSymbolRes = get(LiveIndexSymbolResult, ric, {});
    let underlying_ric = liveIndexSymbolRes.underlying_ric;
    
    setRicAndCode(underlying_ric);
    

    if (underlying_ric) {
      getWarrantChartData(underlying_ric);
      fetch(
        `${Base_Url}/apimqmy/ComparisonDataJSON?type=u&ric=${underlying_ric}&qid=${qid}`
      )
        .then((resp) => resp.json())
        .then((data) => setUnderlyingBid(data))
        .catch((error) => console.log(error));
      
    }
    setLiveIndexSymbolObj(liveIndexSymbolRes);
    setUnderlying_curr(liveIndexSymbolRes['underlying_curr'])
    const x=liveIndexSymbolRes.BID_NETCHNG.split("-"); 
    const y=liveIndexSymbolRes.BID_NETCHNG.split("-"); 
   
    x[0]=='' &&  x[1]!=='' ? setUpAndDown("down") :  x[0]!='' ? setUpAndDown("up") : setUpAndDown("");
    y[0]=='' &&  y[1]!=='' ? setUpAndDown1("down") :  y[0]!='' ? setUpAndDown1("up") : setUpAndDown1("");

    // liveIndexSymbolRes.BID_PCTCHNG.includes("-") 
    // ? setUpAndDown1("down")
    // : liveIndexSymbolRes.BID_PCTCHNG.includes("+")
    // ? setUpAndDown1("up") 
    // : setUpAndDown1("");

  };

  const getWarrantChartData = async (newRicAndCode = ricAndCode) => {
    let data = {
      TYPE: "JSON",
      RIC: newRicAndCode + ";" + ric,
      PERIODICITY: activeTab,
      d: qid,
    };
    let WarrantChartResult = await api.get(
      config.warrantTermsAlert,
      true,
      data
    );
    let warrantChartResData = get(WarrantChartResult, "data", []);
    let warrantChartResData1 = get(WarrantChartResult, "data1", []);
    setChartDataArr(WarrantChartResult);
    setChartData(warrantChartResData);
    setChartData1(warrantChartResData1);
  };

  const getSelectedValue = (e) => {
    const { value, label, security_code } = e;

    setLiveMatrixSelectedName(label);
    setRic(value);
    var temp = [value,...localRic]
    
    setLocalRic(temp)
    window.localStorage.setItem("ric",JSON.stringify(temp));
    window.history.replaceState(
      "wrrantterms",
      "Warrant Terms | Malaysia Warrants | Macquarie",
      `/tools/warrantterms/${security_code}`
    );
  };

  const onClickPopHandle = (url, value) => {
    setOpen(value);
    setUrl(url);
  };

  const isActive = (tab) => {
    if (activeTab === tab) {
      return "active";
    }
    return "";
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#4a87c0" : null,
        color: "#333333",
        color: isFocused ? "#fff" : null,
        border: isFocused ? "1px solid #003eff" : null,
        textAlign: "center",
        fontSize: "14px",
        padding: "3px 10px",
      };
    },
  };

  function getValue() {
    return (
      liveIndexSymbolObj.ACVOL_1_SCALED.replace(/\,/g, "") - 12
    ).toLocaleString();
  }

  function AffinHwang() {
    useGaEventsTracker('event', 'Click', {'event_category': 'AHIB'})
    onClickPopHandle("https://einvest.affinhwang.com/",true) 
  }

  function HongLeong() {
    useGaEventsTracker('event', 'Click', {'event_category': 'HLIB'})
    onClickPopHandle("https://www.hlebroking.com/v3/",true) 
  }

  function MalaccaSecurities() {
    useGaEventsTracker('event', 'Click', {'event_category': 'MalaccaSec'})
    onClickPopHandle("https://www.mplusonline.com.my/macsecos/index.asp",true) 
  }

  function PublicBank() {
    useGaEventsTracker('event', 'Click', {'event_category': 'PublicBank'})
    onClickPopHandle("https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx",true) 
  }

  function UobKayHian() {
    useGaEventsTracker('event', 'Click', {'event_category': 'UOB'})
    onClickPopHandle("https://www.utrade.com.my/",true) 
  }

  return (
    <div id="dwterms" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home">Warrant tools</Link>
            </li>
            <li className="active">Warrant terms</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 w-100-md">
            <div className="section">
              <div className="page-header">
                <h1>Warrant terms</h1>
                <div>
                  <input
                    className="btn btn-primary tradeEl tradeBtn"
                    defaultValue="Trade warrants here"
                    type="button"
                    data-bs-toggle="collapse"
                    href="#tradeEl5"
                    id="tradeButton31"
                    role="button"
                    aria-expanded="false"
                    aria-controls="tradeEl5"
                  />
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      document
                        .getElementById("tradeEl5")
                        .classList.remove("show");
                      document
                        .getElementById("tradeButton31")
                        .setAttribute("aria-expanded", "false");
                    }}
                  >
                    <div
                      className="tradeDropDown tradeEl collapse"
                      id="tradeEl5"
                    >
                      <Link
                        className="btn btn-primary tradeEl"
                        to="/education/getstarted"
                        type="button"
                        target="_blank"
                        onClick={useGaEventsTracker("event", "Click", {
                          event_category: "GetStarted",
                        })}
                      >
                        How to get started
                      </Link>
                      <div className="dropdown-or tradeEl">
                        <hr className="tradeEl" />
                        <p className="tradeEl">or</p>
                        <hr className="tradeEl" />
                      </div>
                      <p className="tradeEl">Select your broker:</p>
                      <div className="row tradeEl">
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          defaultValue="Affin Hwang"
                          type="button"
                          onClick={AffinHwang}
                        />
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          link="https://www.hlebroking.com/v3/"
                          defaultValue="Hong Leong"
                          type="button"
                          onClick={HongLeong}
                        />
                      </div>
                      <div className="row tradeEl">
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          link="https://www.mplusonline.com.my/macsecos/"
                          defaultValue="Malacca Securities"
                          type="button"
                          onClick={MalaccaSecurities}
                        />
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          link="https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx"
                          defaultValue="Public Bank"
                          type="button"
                          onClick={PublicBank}
                        />
                      </div>
                      <div className="row tradeEl">
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          link="https://www.utrade.com.my/"
                          defaultValue="UOB Kay Hian"
                          type="button"
                          onClick={UobKayHian}
                        />
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          style={{ visibility: "hidden" }}
                          link="https://www.utrade.com.my/"
                          defaultValue="Phillip "
                          type="button"
                        />
                      </div>
                    </div>
                  </OutsideClickHandler>
                </div>
              </div>
              <p style={{ marginBottom: "10px" }}>
                Everything you need to know about your selected warrant is found
                here.
              </p>
              {/* <div
              className="searchbox searchbox-toppage input-group"
              style={{ display: "none" }}
            > */}
              <div id="powersearch">
                <Select
                  placeholder={"Select warrant"}
                  onChange={(e) => getSelectedValue(e)}
                  options={symbolList}
                  styles={colourStyles}
                  value={"Select warrant"}
                />
              </div>

              <div id="dwsummary" style={{ position: "relative" }}>
                <div className="ricbar">
                  <div id="ticker" className="tickerbar bgcolor-03">
                    {liveMatrixSelectedName}
                  </div>
                  <div>
                    <Link
                      to="/tools/livematrix/"
                      id="viewlm"
                      type="button"
                      className="ricbar-btn btn btn-block btn-primary"
                    >
                      View live matrix
                    </Link>
                  </div>
                </div>
                <table id="pricetbl" className="uppertbl">
                  <colgroup>
                    <col style={{ backgroundColor: "#E7E8E8", padding: 3 }} />
                    <col
                      style={{
                        backgroundColor: "#F5F6F7",
                        padding: 3,
                        textAlign: "left",
                      }}
                    />
                    <col
                      style={{
                        backgroundColor: "#F5F6F7",
                        padding: 3,
                        textAlign: "right",
                      }}
                    />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td className="uppertbl-col-bid" rowSpan={2}>
                        <div className="bidprice lbl">Bid price</div>
                        <div id="bidval" className="bidprice val">
                          {liveIndexSymbolObj.BID}
                        </div>
                      </td>
                      <td className="uppertbl-col-chglbl">Change</td>
                      <td
                        id="bidchg"
                        // className={"uppertbl-col-chgval val " + upAndDown}
                        className={
                          liveIndexSymbolObj.BID_NETCHNG > 0
                            ? "uppertbl-col-chgval val up"
                            : liveIndexSymbolObj.BID_NETCHNG < 0
                            ? "uppertbl-col-chgval val down"
                            : "uppertbl-col-chgval val"
                        }
                      >
                        {liveIndexSymbolObj.BID_NETCHNG}
                      </td>
                    </tr>
                    <tr>
                      <td className="uppertbl-col-pchglbl">%Change</td>
                      <td
                        id="bidpchg"
                        className={
                          liveIndexSymbolObj.BID_PCTCHNG_2DP > 0
                            ? "uppertbl-col-pchgval val up"
                            : liveIndexSymbolObj.BID_PCTCHNG_2DP < 0
                            ? "uppertbl-col-pchgval val down"
                            : "uppertbl-col-pchgval val"
                        }
                      >
                        {liveIndexSymbolObj.BID_PCTCHNG_2DP}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="srtable" style={{ position: "relative" }}>
                <table className="table">
                  <tbody>
                    <tr>
                      <th className="srtable-leftlbl bgcolor-01">Prior</th>
                      <td className="srtable-leftval bgcolor-01 val HST_CLOSE">
                        {liveIndexSymbolObj.HST_CLOSE}
                      </td>
                      <th className="srtable-rightlbl bgcolor-02">
                        Last trade
                      </th>
                      <td className="srtable-rightval bgcolor-02 val TRDPRC_1">
                        {liveIndexSymbolObj.TRDPRC_1}
                      </td>
                    </tr>
                    <tr>
                      <th className="srtable-leftlbl">Open</th>
                      <td className="srtable-leftval val OPEN_PRC">
                        {chartData1[0]?.open}
                      </td>
                      <th className="srtable-rightlbl">Traded volume ('000)</th>
                      <td className="srtable-rightval val ACVOL_1_SCALED">
                        {Object.keys(liveIndexSymbolObj).length !== 0 &&
                          liveIndexSymbolObj.ACVOL_1_SCALED}
                      </td>
                    </tr>
                    <tr>
                      <th className="srtable-leftlbl bgcolor-01">High</th>
                      <td className="srtable-leftval bgcolor-01 val HIGH_1">
                        {chartData1.reduce(
                          (acc, shot) =>
                            (acc = acc > shot.high ? acc : shot.high),
                          0
                        )}
                      </td>
                      <th className="srtable-rightlbl bgcolor-02">
                        Traded value ('000 MYR)
                      </th>
                      <td className="srtable-rightval bgcolor-02 val TURNOVER_SCALED">
                        {Object.keys(liveIndexSymbolObj).length !== 0 &&
                          liveIndexSymbolObj.TURNOVER_SCALED}
                      </td>
                    </tr>
                    <tr>
                      <th className="srtable-leftlbl">Low</th>
                      <td className="srtable-leftval val LOW_1">
                        {Math.min.apply(
                          null,
                          chartData1.map(function (item) {
                            return item.low;
                          })
                        )}
                      </td>
                      <th className="srtable-rightlbl">Underlying bid price</th>
                      <td className="srtable-rightval val underlying_price">
                        {/* {UnderlyingBid?.comparison[0]?.BID} */}
                        {Object.keys(UnderlyingBid).length !== 0 &&
                          UnderlyingBid.comparison[0].BID}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table id="periodtab" className="ctab">
                <tbody>
                  <tr>
                    <td
                      onClick={() => setActiveTab("D")}
                      className={`ctab-item ${isActive("D")}`}
                      data-value="D"
                    >
                      1D
                    </td>
                    <td className="ctab-item-space">&nbsp;</td>
                    <td
                      onClick={() => setActiveTab("W")}
                      className={`ctab-item ${isActive("W")}`}
                      data-value="W"
                    >
                      1W
                    </td>
                    <td className="ctab-item-space">&nbsp;</td>
                    <td
                      onClick={() => setActiveTab("M")}
                      className={`ctab-item ${isActive("M")}`}
                      data-value="M"
                    >
                      1M
                    </td>
                    <td className="ctab-item-space">&nbsp;</td>
                    <td
                      onClick={() => setActiveTab("Y")}
                      className={`ctab-item ${isActive("Y")}`}
                      data-value="Y"
                    >
                      MAX
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="chart-legend">
                <div className="row">
                  <div className="chart-legend-left col-sm-6">
                    <div className="chart-legend-item">
                      Warrant bid price (<span id="ccy-warrant">MYR</span>)
                      <span className="chart-legend-bar warrant" />
                    </div>
                  </div>
                  <div className="chart-legend-right col-sm-6">
                    <div className="chart-legend-item">
                      Underlying price (<span id="ccy-underlying">{underlying_curr}</span>)
                      <span className="chart-legend-bar underlying" />
                    </div>
                  </div>
                </div>
              </div>

              <TermsChart dataArr={chartDataArr} period={activeTab} underlying_curr={underlying_curr} />

              <h3 style={{ marginTop: "30px", marginBottom: "10px" }}>
                Indicator
              </h3>
              <div id="indtable" style={{ position: "relative" }}>
                <table className="table table-closed table-striped sm-none xs-none">
                  <tbody>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_1"}>
                          Warrant code
                        </span>
                        <ReactTooltip
                          id={"tooltip_1"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Warrant code:</strong> Each warrant has a
                              unique 6-digit identification code.{" "}
                              <Link
                                target="_blank"
                                to="/edu/what-are-warrant/warrant-named"
                              >
                                Click here
                              </Link>{" "}
                              for a more detailed explanation.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val ticker">
                        {" "}
                        {liveIndexSymbolObj.ticker}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_2"}>
                          Effective gearing (x)
                        </span>
                        <ReactTooltip
                          id={"tooltip_2"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Effective gearing:</strong> Indicates your
                              leveraged exposure. An effective gearing of 5X
                              tells you a 1% move in the underlying stock/index
                              results in an approximate 5% move in your Warrant
                              price.
                              <Link
                                to="/edu/useful-warrant-terms/effective-gearing"
                                target="_blank"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about effective gearing.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val effective_gearing">
                        {liveIndexSymbolObj.effective_gearing}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_3"}>
                          Underlying
                        </span>
                        <ReactTooltip
                          id={"tooltip_3"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Underlying:</strong>
                              The stock or index over which the Warrant is
                              listed.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val underlying_ticker">
                        <Link
                          to={`/tools/underlying/${ricAndCode}`}
                          target="_blank"
                        >
                          {getUnderlyingTickerDisplayName(liveIndexSymbolObj.underlying_ric,liveIndexSymbolObj.underlying_name,1)}
                        </Link>
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_4"}>
                          Delta (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_4"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Delta:</strong>
                              Estimates how a warrant moves in relation to the
                              underlying stock/index. Puts have negative deltas
                              because their prices increase as the underlying
                              stock/index falls. Note, if your warrant has a WPS
                              that this will need to be taken into account.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val delta">{liveIndexSymbolObj.delta}</td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_5"}>
                          Type
                        </span>
                        <ReactTooltip
                          id={"tooltip_5"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Type:</strong>
                              Calls increase in value as share price rises, Puts
                              increase in value as share price falls.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val type">{liveIndexSymbolObj.type}</td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_6"}>
                          Delta per warrant (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_6"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Delta per warrant:</strong>
                              Delta/Warrant Per Share.{" "}
                              <Link
                                to="/edu/useful-warrant-terms/delta"
                                target="_blank"
                              >
                                Click here{" "}
                              </Link>{" "}
                              to learn more about Delta per warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val delta_per_wrnt">
                        {liveIndexSymbolObj.delta_per_wrnt}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_7"}>
                          Issuer
                        </span>
                        <ReactTooltip
                          id={"tooltip_7"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Issuer:</strong>
                              The Bank or Securities company that issues the
                              Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val issuer_name">
                        {liveIndexSymbolObj.issuer_name}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_8"}>
                          Sensitivity
                        </span>
                        <ReactTooltip
                          id={"tooltip_8"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Sensitivity:</strong>
                              Shows you how much the underlying stock or index
                              must move for a corresponding one tick (or minimum
                              spread) movement in the warrant. For warrants that
                              cost less than MYR1.00, one tick is MYR0.005.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val sensitivity">
                        {liveIndexSymbolObj.sensitivity}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_9"}>
                          Exercise price
                        </span>
                        <ReactTooltip
                          id={"tooltip_9"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Exercise price:</strong>
                              The price at which you can buy (calls) or sell
                              (puts) the underlying share or index at expiry.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val exercise_price">
                        {liveIndexSymbolObj.exercise_price}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_10"}>
                          Break even price at expiry
                        </span>
                        <ReactTooltip
                          id={"tooltip_10"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Break even price at expiry:</strong>
                              If you are still holding the warrant at expiry,
                              this is the price that the shares/index must
                              exceed (fall below) for calls (puts) for you to
                              make a profit on your purchase price.
                              <Link
                                to="/edu/useful-warrant-terms/break-even-price"
                                target="_blank"
                              >
                                {" "}
                                Click here
                              </Link>{" "}
                              here for a more detailed description.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val breakeven_price">
                        {liveIndexSymbolObj.breakeven_price}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_11"}>
                          Most recent trade date
                        </span>
                        <ReactTooltip
                          id={"tooltip_11"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Most recent trade date:</strong>
                              The last date that the warrant recorded a trade.
                              Note: Warrant do not trade every day, this has
                              implications when calculating price changes.
                              <Link
                                to="/edu/monitoring-price-changes/last-traded-price"
                                target="_blank"
                              >
                                {" "}
                                Click here
                              </Link>{" "}
                              for more info.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val TRADE_DATE">
                        {liveIndexSymbolObj.TRADE_DATE}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_12"}>
                          Premium (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_12"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Premium:</strong>
                              Also refered to as the "Time Value" of the
                              warrant. Note that a higher premium could be due
                              to the warrant being longer dated or due to higher
                              gearing.
                              <Link
                                to="/edu/useful-warrant-terms/premium"
                                target="_blank"
                              >
                                {" "}
                                Click here
                              </Link>{" "}
                              to learn more about premium.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val premium">
                        {liveIndexSymbolObj.premium}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_13"}>
                          Expiry
                        </span>
                        <ReactTooltip
                          id={"tooltip_13"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Expiry:</strong>
                              The date at which the warrant will expire.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val maturity">
                        {liveIndexSymbolObj.maturity}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_14"}>
                          Implied volatility (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_14"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Implied volatility:</strong>
                              The higher the implied volatility, the higher the
                              price of the Warrant (all other factors held
                              constant).{" "}
                              <Link
                                to="/edu/useful-warrant-terms/implied-volatility"
                                target="_blank"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about implied volatility.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val implied_volatility">
                        {liveIndexSymbolObj.implied_volatility}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_15"}>
                          Last trading date
                        </span>
                        <ReactTooltip
                          id={"tooltip_15"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Last trading date:</strong>
                              The last day to buy or sell the warrant. This date
                              is the 2nd trading day prior to the expiry date.{" "}
                              <Link
                                to="/edu/single-share-calls/last-trading-day"
                                target="_blank"
                              >
                                Click here{" "}
                              </Link>{" "}
                              for more information.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val last_trading_date">
                        {liveIndexSymbolObj.last_trading_date}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_16"}>
                          Theta (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_16"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Theta:</strong>
                              The daily time decay of the warrant expressed in
                              percentage terms.{" "}
                              <Link
                                to="/edu/understanding-warrant-structure/time-decay"
                                target="_blank"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about Theta.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val theta">{liveIndexSymbolObj.theta}</td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_17"}>
                          Time to maturity
                        </span>
                        <ReactTooltip
                          id={"tooltip_17"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Time to maturity:</strong>
                              The number of days left until the expiry date of
                              the warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val days_to_maturity">
                        {liveIndexSymbolObj.days_to_maturity} days
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_18"}>
                          Intrinsic value per warrant
                        </span>
                        <ReactTooltip
                          id={"tooltip_18"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Intrinsic value per warrant:</strong>
                              This is the difference between the exercise price
                              and the current share price. For call warrant, if
                              the exercise price is below the current share
                              price it has intrinsic value, vice versa for puts.
                              Please{" "}
                              <Link
                                to="/edu/understanding-warrant-structure/intrinsic-value-time-value"
                                target="_blank"
                              >
                                click here
                              </Link>{" "}
                              for more information on intrinsic value.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val intrinsic_value">
                        {liveIndexSymbolObj.intrinsic_value}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_19"}>
                          Exercise ratio
                        </span>
                        <ReactTooltip
                          id={"tooltip_19"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Exercise ratio:</strong>
                              The number of warrants that entitles you to one
                              unit of the underlying share or index at expiry.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val conv_ratio">
                        {liveIndexSymbolObj.conv_ratio}
                      </td>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_20"}>
                          Moneyness
                        </span>
                        <ReactTooltip
                          id={"tooltip_20"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Moneyness:</strong>
                              ITM (in-the-money), OTM (out-of-the-money), ATM
                              (at-the-money).{" "}
                              <Link
                                to="/edu/understanding-warrant-structure/moneyness"
                                target="_blank"
                              >
                                Click here{" "}
                              </Link>{" "}
                              to learn more about moneyness.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td
                        className={
                          liveIndexSymbolObj.moneyness == "2"
                            ? "val mny down"
                            : "val mny up"
                        }
                      >
                        {liveIndexSymbolObj.percent_moneyness}%{" "}
                        {liveIndexSymbolObj.moneyness === "2" ? "OTM" : "ITM"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-closed table-striped sm-view xs-view">
                  <tbody>
                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_1"}>
                          Warrant code
                        </span>
                        <ReactTooltip
                          id={"tooltip_1"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Warrant code:</strong> Each warrant has a
                              unique 6-digit identification code.{" "}
                              <Link
                                target="_blank"
                                to="/edu/what-are-warrant/warrant-named"
                              >
                                Click here
                              </Link>{" "}
                              for a more detailed explanation.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val ticker">
                        {" "}
                        {liveIndexSymbolObj.ticker}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_3"}>
                          Underlying
                        </span>
                        <ReactTooltip
                          id={"tooltip_3"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Underlying:</strong>
                              The stock or index over which the Warrant is
                              listed.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val underlying_ticker">
                        <Link to={`/tools/underlying/${ricAndCode}`}>
                          {liveIndexSymbolObj.underlying_name}
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_5"}>
                          Type
                        </span>
                        <ReactTooltip
                          id={"tooltip_5"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Type:</strong>
                              Calls increase in value as share price rises, Puts
                              increase in value as share price falls.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val type">{liveIndexSymbolObj.type}</td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_7"}>
                          Issuer
                        </span>
                        <ReactTooltip
                          id={"tooltip_7"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Issuer:</strong>
                              The Bank or Securities company that issues the
                              Warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val issuer_name">
                        {liveIndexSymbolObj.issuer_name}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_9"}>
                          Exercise price
                        </span>
                        <ReactTooltip
                          id={"tooltip_9"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Exercise price:</strong>
                              The price at which you can buy (calls) or sell
                              (puts) the underlying share or index at expiry.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val exercise_price">
                        {liveIndexSymbolObj.exercise_price}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_11"}>
                          Most recent trade date
                        </span>
                        <ReactTooltip
                          id={"tooltip_11"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Most recent trade date:</strong>
                              The last date that the warrant recorded a trade.
                              Note: Warrant do not trade every day, this has
                              implications when calculating price changes.
                              <Link
                                to="/edu/monitoring-price-changes/last-traded-price"
                                target="_blank"
                              >
                                {" "}
                                Click here
                              </Link>{" "}
                              for more info.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val TRADE_DATE">
                        {liveIndexSymbolObj.TRADE_DATE}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_13"}>
                          Expiry
                        </span>
                        <ReactTooltip
                          id={"tooltip_13"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Expiry:</strong>
                              The date at which the warrant will expire.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val maturity">
                        {liveIndexSymbolObj.maturity}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_15"}>
                          Last trading date
                        </span>
                        <ReactTooltip
                          id={"tooltip_15"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Last trading date:</strong>
                              The last day to buy or sell the warrant. This date
                              is the 2nd trading day prior to the expiry date.{" "}
                              <Link
                                to="/edu/single-share-calls/last-trading-day"
                                target="_blank"
                              >
                                Click here{" "}
                              </Link>{" "}
                              for more information.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val last_trading_date">
                        {liveIndexSymbolObj.last_trading_date}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_17"}>
                          Time to maturity
                        </span>
                        <ReactTooltip
                          id={"tooltip_17"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Time to maturity:</strong>
                              The number of days left until the expiry date of
                              the warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val days_to_maturity">
                        {liveIndexSymbolObj.days_to_maturity} days
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_19"}>
                          Exercise ratio
                        </span>
                        <ReactTooltip
                          id={"tooltip_19"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Exercise ratio:</strong>
                              The number of warrants that entitles you to one
                              unit of the underlying share or index at expiry.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val conv_ratio">
                        {liveIndexSymbolObj.conv_ratio}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_2"}>
                          Effective gearing (x)
                        </span>
                        <ReactTooltip
                          id={"tooltip_2"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Effective gearing:</strong> Indicates your
                              leveraged exposure. An effective gearing of 5X
                              tells you a 1% move in the underlying stock/index
                              results in an approximate 5% move in your Warrant
                              price.
                              <Link
                                to="/edu/useful-warrant-terms/effective-gearing"
                                target="_blank"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about effective gearing.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val effective_gearing">
                        {liveIndexSymbolObj.effective_gearing}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_4"}>
                          Delta (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_4"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Delta:</strong>
                              Estimates how a warrant moves in relation to the
                              underlying stock/index. Puts have negative deltas
                              because their prices increase as the underlying
                              stock/index falls. Note, if your warrant has a WPS
                              that this will need to be taken into account.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val delta">{liveIndexSymbolObj.delta}</td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_6"}>
                          Delta per warrant (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_6"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Delta per warrant:</strong>
                              Delta/Warrant Per Share.{" "}
                              <Link
                                to="/edu/useful-warrant-terms/delta"
                                target="_blank"
                              >
                                Click here{" "}
                              </Link>{" "}
                              to learn more about Delta per warrant.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val delta_per_wrnt">
                        {liveIndexSymbolObj.delta_per_wrnt}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_8"}>
                          Sensitivity
                        </span>
                        <ReactTooltip
                          id={"tooltip_8"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Sensitivity:</strong>
                              Shows you how much the underlying stock or index
                              must move for a corresponding one tick (or minimum
                              spread) movement in the warrant. For warrants that
                              cost less than MYR1.00, one tick is MYR0.005.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val sensitivity">
                        {liveIndexSymbolObj.sensitivity}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_10"}>
                          Break even price at expiry
                        </span>
                        <ReactTooltip
                          id={"tooltip_10"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Break even price at expiry:</strong>
                              If you are still holding the warrant at expiry,
                              this is the price that the shares/index must
                              exceed (fall below) for calls (puts) for you to
                              make a profit on your purchase price.
                              <Link
                                to="/edu/useful-warrant-terms/break-even-price"
                                target="_blank"
                              >
                                {" "}
                                Click here
                              </Link>{" "}
                              here for a more detailed description.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val breakeven_price">
                        {liveIndexSymbolObj.breakeven_price}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_12"}>
                          Premium (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_12"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Premium:</strong>
                              Also refered to as the "Time Value" of the
                              warrant. Note that a higher premium could be due
                              to the warrant being longer dated or due to higher
                              gearing.
                              <Link
                                to="/edu/useful-warrant-terms/premium"
                                target="_blank"
                              >
                                {" "}
                                Click here
                              </Link>{" "}
                              to learn more about premium.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val premium">
                        {liveIndexSymbolObj.premium}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_14"}>
                          Implied volatility (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_14"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Implied volatility:</strong>
                              The higher the implied volatility, the higher the
                              price of the Warrant (all other factors held
                              constant).{" "}
                              <Link
                                to="/edu/useful-warrant-terms/implied-volatility"
                                target="_blank"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about implied volatility.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val implied_volatility">
                        {liveIndexSymbolObj.implied_volatility}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_16"}>
                          Theta (%)
                        </span>
                        <ReactTooltip
                          id={"tooltip_16"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Theta:</strong>
                              The daily time decay of the warrant expressed in
                              percentage terms.{" "}
                              <Link
                                to="/edu/understanding-warrant-structure/time-decay"
                                target="_blank"
                              >
                                Click here
                              </Link>{" "}
                              to learn more about Theta.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val theta">{liveIndexSymbolObj.theta}</td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_18"}>
                          Intrinsic value per warrant
                        </span>
                        <ReactTooltip
                          id={"tooltip_18"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Intrinsic value per warrant:</strong>
                              This is the difference between the exercise price
                              and the current share price. For call warrant, if
                              the exercise price is below the current share
                              price it has intrinsic value, vice versa for puts.
                              Please{" "}
                              <Link
                                to="/edu/understanding-warrant-structure/intrinsic-value-time-value"
                                target="_blank"
                              >
                                click here
                              </Link>{" "}
                              for more information on intrinsic value.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td className="val intrinsic_value">
                        {liveIndexSymbolObj.intrinsic_value}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className="ttip" data-tip data-for={"tooltip_20"}>
                          Moneyness
                        </span>
                        <ReactTooltip
                          id={"tooltip_20"}
                          delayHide={500}
                          place="right"
                          type="warning"
                          effect="solid"
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              <strong>Moneyness:</strong>
                              ITM (in-the-money), OTM (out-of-the-money), ATM
                              (at-the-money).{" "}
                              <Link
                                to="/edu/understanding-warrant-structure/moneyness"
                                target="_blank"
                              >
                                Click here{" "}
                              </Link>{" "}
                              to learn more about moneyness.
                            </p>
                          </div>
                        </ReactTooltip>
                      </th>
                      <td
                        className={
                          liveIndexSymbolObj.moneyness == "2"
                            ? "val mny down"
                            : "val mny up"
                        }
                      >
                        {liveIndexSymbolObj.percent_moneyness}%{" "}
                        {liveIndexSymbolObj.moneyness === "2" ? "OTM" : "ITM"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="disclaimer sm-none xs-none">
                <p className="small">
                  The warrant price displayed in Prior, Open, High and Low is
                  based on the 'bid price' for the warrant rather than the
                  'traded price'. The intraday graph also uses the warrant bid
                  price to generate the warrant price history. We do this to
                  provide a more accurate representation of the warrant value at
                  each particular point in time and a more accurate reflection
                  of warrant price changes. Warrants do not trade as frequently
                  as shares and sometimes may not record a trade for a long
                  period of time though the bid/offer prices may continue to
                  move, therefore using the bid price is usually the best
                  indicator of warrant value. For a more detailed
                  description&nbsp;
                  <Link
                    className="text-lowercase"
                    style={{
                      color: "#0675C2",
                      fontFamily: '"MCQGlobal_W_Md"',
                    }}
                    target="_blank"
                    to="/edu/monitoring-price-changes"
                  >
                    Click here
                  </Link>
                  .<br />
                  <br />
                </p>
                <hr className="hr-hmgbm" />
                <p className="small">
                  All market data is delayed 15 mins unless otherwise indicated
                  by timestamp.
                </p>
              </div>

              <div id="defination_term" className="panel-group md-up-none">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <button type="button" onClick={() => showNewBox()}>
                      <span className="glyphicon glyphicon glyphicon-question-sign pull-right" />
                      <div
                        id="collapse_title3"
                        className="panel-title text-center color-primary"
                      >
                        <span>Definition &amp; disclaimer</span>
                        <img src="/img/bullet/question.png" alt="Image" />
                      </div>
                    </button>
                  </div>
                  <div
                    id="collapse_dwcompare3"
                    className={`${boxVisibility}`}
                    aria-expanded="true"
                  >
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>Warrant code</td>
                          <td>
                            Each warrant has a unique 6-digit identification
                            code.{" "}
                            <Link
                              target="_blank"
                              to="/edu/what-are-warrant/warrant-named"
                            >
                              Click here
                            </Link>{" "}
                            for a more detailed explanation.
                          </td>
                        </tr>
                        <tr>
                          <td>Underlying</td>
                          <td>
                            The stock or index over which the Warrant is listed.
                          </td>
                        </tr>
                        <tr>
                          <td>Type</td>
                          <td>
                            Calls increase in value as share price rises, Puts
                            increase in value as share price falls.
                          </td>
                        </tr>
                        <tr>
                          <td>Issuer</td>
                          <td>
                            The Bank or Securities company that issues the
                            Warrant.
                          </td>
                        </tr>
                        <tr>
                          <td>Exercise price</td>
                          <td>
                            The price at which you can buy (calls) or sell
                            (puts) the underlying
                            <br /> share or index at expiry.
                          </td>
                        </tr>
                        <tr>
                          <td>Most recent trade date</td>
                          <td>
                            The last date that the warrant recorded a trade.
                            Note: Warrant do not trade every day, this has
                            implications when calculating price changes.{" "}
                            <Link
                              target="_blank"
                              to="/edu/monitoring-price-changes/last-traded-price"
                            >
                              Click here
                            </Link>{" "}
                            for more info.
                          </td>
                        </tr>
                        <tr>
                          <td>Expiry</td>
                          <td>The date at which the warrant will expire.</td>
                        </tr>
                        <tr>
                          <td>Last trading date</td>
                          <td>
                            The last day to buy or sell the warrant. This date
                            is the 2nd trading day prior to the expiry date.{" "}
                            <Link
                              target="_blank"
                              to="/edu/single-share-calls/last-trading-day"
                            >
                              Click here
                            </Link>{" "}
                            for more information.
                          </td>
                        </tr>
                        <tr>
                          <td>Time to maturity</td>
                          <td>
                            The number of days left until the expiry date of the
                            warrant.
                          </td>
                        </tr>
                        <tr>
                          <td>Exercise ratio</td>
                          <td>
                            The number of warrants that entitles you to one unit
                            of the underlying share or index at expiry.
                          </td>
                        </tr>
                        <tr>
                          <td>Effective gearing (x)</td>
                          <td>
                            Indicates your leveraged exposure. An effective
                            gearing of 5X tells you a 1% move in the underlying
                            stock/index results in an approximate 5% move in
                            your Warrant price.{" "}
                            <Link
                              target="_blank"
                              to="/edu/useful-warrant-terms/effective-gearing"
                            >
                              Click here
                            </Link>{" "}
                            to learn more about effective gearing.
                          </td>
                        </tr>
                        <tr>
                          <td>Delta</td>
                          <td>
                            Estimates how a warrant moves in relation to the
                            underlying stock/index. Puts have negative deltas
                            because their prices increase as the underlying
                            stock/index falls. Note, if your warrant has a WPS
                            that this will need to be taken into account.
                          </td>
                        </tr>
                        <tr>
                          <td>Delta per warrant</td>
                          <td>
                            Delta/Warrant Per Share.{" "}
                            <Link
                              target="_blank"
                              to="/edu/useful-warrant-terms/delta"
                            >
                              Click here
                            </Link>{" "}
                            to learn more about Delta per warrant.
                          </td>
                        </tr>
                        <tr>
                          <td>Sensitivity</td>
                          <td>
                            Shows you how much the underlying stock or index
                            must move for a corresponding one tick (or minimum
                            spread) movement in the warrant. For warrants that
                            cost less than MYR1.00, one tick is MYR0.005.
                          </td>
                        </tr>
                        <tr>
                          <td>Break even price at expiry</td>
                          <td>
                            If you are still holding the warrant at expiry, this
                            is the price that the shares/index must exceed (fall
                            below) for calls (puts) for you to make a profit on
                            your purchase price.{" "}
                            <Link
                              target="_blank"
                              to="/edu/useful-warrant-terms/break-even-price"
                            >
                              Click here
                            </Link>{" "}
                            here for a more detailed description.
                          </td>
                        </tr>
                        <tr>
                          <td>Premium (%)</td>
                          <td>
                            Also refered to as the "Time Value" of the warrant.
                            Note that a higher premium could be due to the
                            warrant being longer dated or due to higher gearing.{" "}
                            <Link
                              target="_blank"
                              to="/edu/useful-warrant-terms/premium"
                            >
                              Click here
                            </Link>{" "}
                            to learn more about premium.
                          </td>
                        </tr>
                        <tr>
                          <td>Implied volatility (%)</td>
                          <td>
                            The higher the implied volatility, the higher the
                            price of the Warrant (all other factors held
                            constant).{" "}
                            <Link
                              target="_blank"
                              to="/edu/useful-warrant-terms/implied-volatility"
                            >
                              Click here
                            </Link>{" "}
                            to learn more about implied volatility.
                          </td>
                        </tr>
                        <tr>
                          <td>Theta (%)</td>
                          <td>
                            The daily time decay of the warrant expressed in
                            percentage terms.{" "}
                            <Link
                              target="_blank"
                              to="/edu/understanding-warrant-structure/time-decay"
                            >
                              Click here
                            </Link>{" "}
                            to learn more about Theta.
                          </td>
                        </tr>
                        <tr>
                          <td>Intrinsic value per warrant</td>
                          <td>
                            This is the difference between the exercise price
                            and the current share price. For call warrant, if
                            the exercise price is below the current share price
                            it has intrinsic value, vice versa for puts. Please{" "}
                            <Link
                              target="_blank"
                              to="/edu/understanding-warrant-structure/intrinsic-value-time-value"
                            >
                              click here
                            </Link>{" "}
                            for more information on intrinsic value.
                          </td>
                        </tr>
                        <tr>
                          <td>Moneyness</td>
                          <td>
                            ITM (in-the-money), OTM (out-of-the-money), ATM
                            (at-the-money).{" "}
                            <Link
                              target="_blank"
                              to="/edu/understanding-warrant-structure/moneyness"
                            >
                              Click here
                            </Link>{" "}
                            to learn more about moneyness.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="small" style={{ marginBottom: "10px" }}>
                      The warrant price displayed in Prior, Open, High and Low
                      is based on the 'bid price' for the warrant rather than
                      the 'traded price'. The intraday graph also uses the
                      warrant bid price to generate the warrant price history.
                      We do this to provide a more accurate representation of
                      the warrant value at each particular point in time and a
                      more accurate reflection of warrant price changes.
                      Warrants do not trade as frequently as shares and
                      sometimes may not record a trade for a long period of time
                      though the bid/offer prices may continue to move,
                      therefore using the bid price is usually the best
                      indicator of warrant value. For a more detailed
                      description{" "}
                      <Link to="/edu/monitoring-price-changes">click here</Link>
                      . <br />
                      <br />
                    </p>

                    <hr />
                    <p className="small">
                      All market data is delayed 15 mins unless otherwise
                      indicated by timestamp
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 sm-none xs-none">
            <Slider {...settings} className="ad-section custom">
              {advImgVal &&
                advImgVal.map((data, index) => {
                  return (
                    <a target="_blank" href={data.link} key={index}>
                      <div>
                        <img src={data.imgSrc} />
                      </div>
                    </a>
                  );
                })}
            </Slider>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Live matrix</h2>
              </div>
              <p>
                Makes trading warrant easy by showing investors where our bid
                prices will be throughout the day
              </p>
              <div className="section-qlink-img section-qlink-lmatrix">
                <Link to="/tools/livematrix/" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant search</h2>
              </div>
              <p>
                Find the right warrant according to your investment criteria.
              </p>
              <div className="section-qlink-img section-qlink-dwsearch">
                <Link to="/tools/warrantsearch" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <WarrantTermsModal
        Url={url}
        Open={open}
        OpenModal={() => setOpen(!true)}
      />
    </div>
  );
};

export default WarrantTerms;
