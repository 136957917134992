import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
function Guest6(fn) {
  const refreshPage = () => {
    window.location.reload(true);
  };
  const fn6 = () => {
    window.location.href = "/education/warrantvideosind/0-oAB7TNvmY/16";
  };

  const fn4 = () => {
    window.location.href = "/education/warrantvideosind/l1abz3e-J3Q/14";
  };
  const fn5 = () => {
    window.location.href = "/education/warrantvideosind/5ZZ-mHqOOf4/15";
  };
  const fn7 = () => {
    window.location.href = "/education/warrantvideosind/LD9tX8U2yeA/17";
  };
  return (
    <div className="videorack-body">
      <div className="videorack-content swiper-container swiper-container-horizontal">
        <div className="swiper-wrapper">
          <div
            className="swiper-slide swiper-slide-active"
            style={{
              paddingLeft: "30px",
              paddingRight: "35px",
              width: "1090px",
            }}
          >
            <Carousel
              showArrows={true}
              emulateTouch
              showStatus={false}
              autoFocus
              infiniteLoop={false}
              showIndicators={false}
            >
              <div>
                <table className="videorack-page ">
                  <tbody>
                    <tr>
                    <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                          // onClick={refreshPage}
                          onClick={fn7}
                        >
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/LD9tX8U2yeA/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            {/* <span className="text-bold"></span>Chapter One{" "}
                            <br /> */}
                            Quick market talk with Experts | Daryl Guppy Sep
                            2023
                          </div>
                        </div>
                      </td>

                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                          // onClick={refreshPage}
                          onClick={fn6}
                        >
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/0-oAB7TNvmY/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            {/* <span className="text-bold"></span>Chapter One{" "}
                            <br /> */}
                            Quick market talk with Experts | Daryl Guppy June
                            2023
                          </div>
                        </div>
                      </td>

                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                          // onClick={refreshPage}
                          onClick={fn5}
                        >
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/5ZZ-mHqOOf4/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            {/* <span className="text-bold"></span>Chapter One{" "}
                            <br /> */}
                            Quick market talk with Experts | Daryl Guppy May
                            2023
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                          // onClick={refreshPage}
                          onClick={fn4}
                        >
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/l1abz3e-J3Q/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            {/* <span className="text-bold"></span>Chapter One{" "}
                            <br /> */}
                            Quick market talk with Experts | Daryl Guppy Mar
                            2023
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{
                            display: "inline-block",
                            visibility: "hidden",
                          }}
                          onClick={refreshPage}
                        >
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/l1abz3e-J3Q/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter One{" "}
                            <br />
                            Trading the market with warrants
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{
                            display: "inline-block",
                            visibility: "hidden",
                          }}
                          onClick={refreshPage}
                        >
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/l1abz3e-J3Q/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Chapter One{" "}
                            <br />
                            Trading the market with warrants
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guest6;
