import React, { useState, useEffect } from 'react';
import YourFirstSteps from './YourFirstSteps';
import './yourFirstSteps.css';


const YourFirstStep = (props) => {
    return ( 
        <YourFirstSteps/> 
     );
}
 
export default YourFirstStep;