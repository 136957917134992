import React, { useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

 const RangeSliderUnderlyingPriceComponent = (props) => {
  const {Value, SetValue, max} = props;
  return (
    <RangeSlider 
     step={0.1} tooltip={"off"} variant={"warning"}
      value={Value} max={max} min={0.00}
      onChange={changeEvent => {
        SetValue(changeEvent.target.value)
      }}
    />
  );

};

export default RangeSliderUnderlyingPriceComponent;