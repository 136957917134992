import axios from "axios"
import { config, Base_Url } from './apiUrl';

const url = `${Base_Url}/apimqmy/MoneyFlowChartJSON`

export  const getMoneyFlowData = (underlying,date,ranking) =>
    axios.get(`${config.moneyFlow}?underlying=${underlying}&date=${date}&ranking=${ranking}&qid=${Date.now()}`)

export  const getMoneyFlowDataIndex = (underlying,date,ranking) =>
    axios.get(`${config.moneyFlow}?underlying=${underlying}&date=${date}&ranking=${ranking}&isIndices=true&qid=${Date.now()}`)   

export  const getMoneyFlowChart = (ric) =>
    axios.get(`${url}?category=1&RIC=${ric}&PERIODICITY=Y&qid=${Date.now()}`)

export  const getMoneyFlowChartCall = (ssn,ric) =>
    axios.get(`${url}?type=call&ssn=${ssn}&ric=${ric}`)

export  const getMoneyFlowChartPut = (ssn,ric) =>
    axios.get(`${url}?type=put&ssn=${ssn}&ric=${ric}`)

export  const postUnSubscribe = (email) =>
    axios.post(`${Base_Url}/apimqmy/UnsubscribeServlet?email=${email}`)