import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';


const SelectingWarrantCommonTabs = ({ Location, Hist }) => {

    const pathName = get(Location, "pathname", "")

    return (
        <div className='customSteps'>
            <ul> {pathName == "/edu/select-warrant/selecting-warrant" ? <img src='/img/education/fourstep0.png' alt="image" /> : pathName == "/edu/select-warrant/selecting-warrant/time-frame" ? <img src='/img/education/fourstep1.png' alt="image" /> : pathName == "/edu/select-warrant/selecting-warrant/gearing-level" ? <img src='/img/education/fourstep2.png' alt="image" /> : pathName == "/edu/select-warrant/selecting-warrant/select-implied-volatility" ? <img src='/img/education/fourstep3.png' alt="image" /> : pathName == "/edu/select-warrant/selecting-warrant/warrant-spread" ? <img src='/img/education/fourstep4.png' alt="image" /> : ""}
            
                <li className={pathName === '/edu/select-warrant/selecting-warrant/time-frame' ? 'active' : null}>
                    <Link to='/edu/select-warrant/selecting-warrant/time-frame'></Link>
                </li>
                <li className={pathName === '/edu/select-warrant/selecting-warrant/gearing-level' ? 'active' : null}>
                    <Link to='/edu/select-warrant/selecting-warrant/gearing-level'></Link>
                </li>
                <li className={pathName === '/edu/select-warrant/selecting-warrant/select-implied-volatility' ? 'active' : null}>
                    <Link to='/edu/select-warrant/selecting-warrant/select-implied-volatility'></Link>
                </li>
                <li className={pathName === '/edu/select-warrant/selecting-warrant/warrant-spread' ? 'active' : null}>
                    <Link to='/edu/select-warrant/selecting-warrant/warrant-spread'></Link>
                </li>
            </ul>
        </div>);
}

export default SelectingWarrantCommonTabs;