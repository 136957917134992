import React, { useState, useEffect,Fragment } from 'react';
import WarrantDocuments from './WarrantDocuments';
import "./warrantDocument.css"


const WarrantDocument = (props) => {
    return ( 
        <Fragment>
        <WarrantDocuments/>
        </Fragment> 
     );
}
 

export default WarrantDocument;