import React, { useState, useEffect,Fragment } from 'react';
import GearingLevels from './GearingLevels';
import "../../../common.css";
import "../../../investorAcademy.css";


const GearingLevel = (props) => {
    return ( 
        <Fragment> 
            <GearingLevels/>
        </Fragment> 
     );
}


export default GearingLevel;