import Select, { components, createFilter } from "react-select";
import React, { useCallback, useEffect, useState,useRef } from "react";
import "./styles.css"

const Input = (props) => <components.Input {...props}  className="selectCompInput" />;

function ReactSelect({ data, placeholder, getUnderlyingData, status, inputValWarr, clearName,hashArray }) { 
  const getUnderlyingTickerDisplayName = (val,name,type)=>{
    let UnderlyingtickerdisplaynameMap = JSON.parse(window.localStorage.getItem('UnderlyingtickerdisplaynameMap'))
    let value = UnderlyingtickerdisplaynameMap.filter((v)=>{
      return v.underlying_ric == val
    })
    if(value.length>0){
      if(type==1){
        return value[0].underlying_name
      }else{
        return value[0].underlying_ticker
      }
    }else{
      return name
    }
  }
  useEffect(() => {
    if(isReturn){
      var OPTIONS = data.map((d) => ({
        // label: status === "true" ? `${d.dsply_nmll} (${d.security_code})` : `${d.underlying_name} (${d.underlying_ticker})`,
        label: status === "true" ? `${d.dsply_nmll} (${d.security_code})` : `${getUnderlyingTickerDisplayName(d.underlying_ric,d.underlying_name,1)} (${getUnderlyingTickerDisplayName(d.underlying_ric,d.underlying_ticker,2)})`,
        value: status === "true" ? d.ric.concat(",").concat(d.security_code) : d.underlying_ric
      }))
      SetOPTIONS(OPTIONS)
      setIsReturn(false)
      let index = null;
      index = OPTIONS.filter((d,i)=>{
        return d.value.indexOf(hashArray)!=-1
          
      })
      if(index){
        SetDefaultValue(index)
      }
    }
  },[data])
    const colourStyles2 = {
        option: (provided,state) => {
            return {
              backgroundColor: "#fff",
              backgroundColor: state.isFocused ? "#007fff" : null ,
              color: "red",
              color: state.isFocused ? "#ffffff !important" : null,
              fontSize: "10px !important",
              fontSize: state.isFocused ? "18px !important" : "14px !important",
              padding: "2px 14px 2px 5.6px",
              cursor: "pointer",
              borderTop: state.isFocused ? "1px solid #003eff" : null,
              borderBottom: state.isFocused ? "1px solid #003eff" : null,
              lineHeight: state.isFocused ? "26px !important" : "16px !important",
              textAlign: "left",
              width: "100%",
              marginBottom: "3px"
            };
          },
          input:(provided,state)=>{
            return {
              ...provided,
              display:"flex",
              textAlign: "left",
              width: "100%",
            }
          },
      };

  // This needs to become a controlled component so track state
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isReturn, setIsReturn] = useState(true);
  const [OPTIONS, SetOPTIONS] = useState([]);
  const [defaultValue, SetDefaultValue] = useState('');

  const changeValWarrant = () => {
    if(inputValWarr == false){
    }else{
    }
  }
  useEffect(() => {
    changeValWarrant()
  },[inputValWarr])

  useEffect(() => {
    if(clearName === false){
      setInputValue()
    }
  }, [clearName])

  const onInputChange = (inputValue, { action }) => {
    // onBlur => setInputValue to last selected value

    if (action === "input-blur") {
      setInputValue(value ? value.label : "");
      setIsEditing(false);
    }

    // onInputChange => update inputValue
    if (action === "input-change") {
      setInputValue(inputValue);
      setIsEditing(true);
    }
  };

  const filterOption = useCallback(
    (candidate, input) => {
      if (!isEditing && input === value?.label) return true;

      return createFilter()(candidate, input);
    },
    [isEditing, value]
  );



  const onChange = (option) => {
    // setValue(option);
    // setIsEditing(false);
    SetDefaultValue(option)
    getUnderlyingData(option.value)
    // setInputValue(option ? option.label : "");
  };
  const selectRef = useRef();
  const onMenuOpen = (e) => {
    window.setTimeout(() => {
      const { focusedOptionRef } = selectRef.current;
      focusedOptionRef && focusedOptionRef.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  return (
      <Select
        options={OPTIONS}
        ref={selectRef}
        // value={value}
        // inputValue={inputValue}
        // onInputChange={onInputChange}
        onChange={onChange}
        placeholder={placeholder}
        styles={colourStyles2}
        value={defaultValue}
        // controlShouldRenderValue={false}
        // components={{
        //   Input
        // }}
        // onMenuOpen={onMenuOpen}
        // filterOption={filterOption}
      />
  );
}

export default function SelectComp(props) { 
  return (
      <ReactSelect {...props}/>
  );
}

    // const filterOption = ({ label, value }, string) => {
    //     if (string === "") return true;
    //     const parsedString = string.split(/[, ]+/);
    //     for (const string of parsedString) {
    //         var str = string.toLowerCase()
    //         var lb = label.toLowerCase()
    //         var vl = value.toLowerCase()
    //         if (str !== "" && (lb.includes(str) || vl.includes(str)))
    //             return true;
    //     }
    //     return false;
    // };


