import React, { useState, useEffect,Fragment } from 'react';
import WarrantGuestSpeakerVideos from './WarrantGuestSpeakerVideos';
import "./warrantGuestSpeakerVideo.css" 


const WarrantGuestSpeakerVideo = (props) => {
    return ( 
        <Fragment>
        <WarrantGuestSpeakerVideos/>
        </Fragment> 
     );
}


export default WarrantGuestSpeakerVideo;