import React, { useState, useEffect,Fragment } from 'react';
import MoneyFlowChart from './MoneyFlowChart';
import "../MoneyFlow/moneyFlows.css" 


const MoneyFlowCharts = (props) => {
    return ( 
        <Fragment>
            <MoneyFlowChart/>
        </Fragment> 
     );
}
 

export default MoneyFlowCharts;