import React, { useState, useEffect } from "react";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import { get } from "lodash";
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WarrantEducation = () => {
  const api = new API();

  const [educationSeminar, setEducationSeminar] = useState([]);

  const [advImgVal, setAdvImgVal] = useState([])


  useEffect(() => {
    getHomePopupStat()
  }, [])

  const getHomePopupStat = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if (homePopupResult.length > 0) {
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, { adv_id: homePopupResult[i].id });
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };

  useEffect(() => {
    getHomeEducationSeminar();
  }, []);

  const getHomeEducationSeminar = async () => {
    let homeEducationSeminarResult = await api.get(
      config.homeEducationSeminar,
      true,
      { m: "dwedu", qid: Date.now() }
    );
    let seminarEdu = get(homeEducationSeminarResult, "seminar", []);
    setEducationSeminar(seminarEdu);

  };

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  return (
    <div id="dwedu" className="tabcontent">
      <p className="nav-dtxt show md-up-none" data-value="dwedu">
        New to warrants? We will help you get started and grow your
        knowledge.
      </p>
      <div className="frame-lgmd">
        <div className="row">
          <div className="col-md-6">
            <div className="holder-qlink-dwvideo">
              <div id="qlink-dwvideo" className="section">
                <div className="page-header">
                  <h1 className="small">Warrant videos</h1>
                </div>
                <p>
                  Check out our guest speakers and animated education videos.
                </p>
                <div className="videoplayer">
                  <div className="videoplayer-container">
                    <iframe
                      frameBorder={0}
                      allowFullScreen
                      // src="https://www.youtube.com/embed/videoseries?list=PLVy-KcVix8Qf0fgqEpUiBigAG4eZ2IL3X&autoplay=0&index=0&rel=0&wmode=opaque"
                      src="https://www.youtube.com/embed/DupqIvOz4LU?autoplay=0&index=17&rel=0&wmode=opaque"
                    />
                  </div>
                </div>
                <div className="section-btn-wapper">
                  <Link
                    to="/education/warrantvideos"
                    type="button"
                    className="btn btn-primary"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
            <div className="holder-qlink-investoracademy xs-view">
              <div id="qlink-investoracademy" className="section">
                <div className="page-header">
                  <h1 className="small">Investor academy</h1>
                </div>
                <p>Everything you need to know about warrants.</p>
                <div className="section-qlink section-qlink-investoracademy ">
                  <div className="section-qlink-achor xs xs-none">
                    <Link
                      target="_blank"
                      to="/edu"
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div>
                </div>
                <div className="section-btn-wapper xs-view">
                  <Link target="_blank" to="/edu" type="button" className="btn btn-primary">View more</Link>
                </div>
              </div>
            </div>
            <div className="holder-seminar sm-none md-up-none">
              <div id="seminar" className="section">
                <div className="page-header">
                  <h1 className="small">Seminars</h1>
                </div>
                <p>
                  The best way to improve your knowledge of warrants. Sign up
                  for a free seminar!
                </p>
                <div
                  className="seminar-table-holder"
                  style={{
                    height: 150,
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <table
                    className="table table-striped"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        {/*<th>Time</th>*/}
                        <th>Title</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      {educationSeminar &&
                        educationSeminar.map((seminarData, index) => (
                          <tr
                            className="seminartmpl"
                            key={`first${index}`}
                          >
                            <td
                              className="date"
                            >{seminarData.date.slice(0, -1)}</td>
                            <td
                              className="title"
                            >
                              <Link to={"/education/seminar?seminar=" + seminarData.id}>
                                {seminarData.en_title}
                              </Link>
                            </td>
                            <td
                              className="venue"
                            >
                              {seminarData.en_venue === ""
                                ? "-"
                                : seminarData.en_venue}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="section-btn-wapper">
                  <Link
                    to="/education/seminar"
                    type="button"
                    className="btn btn-primary"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
            <div className="holder-qlink-faq xs-view">
              <div id="qlink-faq" className="section">
                <div className="page-header">
                  <h1 className="small">Frequently asked questions</h1>
                </div>
                <p>Some of the frequently asked questions about warrants.</p>
                <div className="section-qlink section-qlink-faq ">
                  <div className="section-qlink-achor xs-none">
                    <Link
                      to="/education/faq"
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div>
                </div>
                <div className="section-btn-wapper xs-view">
                  <Link to="/education/faq" type="button" className="btn btn-primary">View more</Link>
                </div>
              </div>
            </div>
            <div className="holder-qlink-dwindex">
              <div id="qlink-dwindex" className="section">
                <div className="page-header">
                  <h1 className="small">Index warrants</h1>
                </div>
                <p>What is an index warrant and why is it so popular?</p>
                <div className="section-qlink section-qlink-indexdw">
                  <div className="section-qlink-achor xs-none">
                    <Link
                      to="/education/indexwarrants"
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div>
                </div>
                <div className="section-btn-wapper xs-view">
                  <Link to="/education/indexwarrants" type="button" className="btn btn-primary">View more</Link>
                </div>
              </div>
            </div>
            <div className="holder-seminar sm-none xs-none">
              <div id="seminar" className="section">
                <div className="page-header">
                  <h1 className="small">Seminars</h1>
                </div>
                <p>
                  The best way to improve your knowledge of warrants. Sign up
                  for a free seminar!
                </p>
                <div
                  className="seminar-table-holder"
                  style={{
                    height: 150,
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <table
                    className="table table-striped"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        {/*<th>Time</th>*/}
                        <th>Title</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      {educationSeminar &&
                        educationSeminar.map((seminarData, indexVal) => (
                          <tr
                            className="seminartmpl"
                            key={`second${indexVal}`}
                          >
                            <td
                              className="date"
                            >{seminarData.date.slice(0, -1)}</td>
                            <td
                              className="title"
                            >
                              <Link to={"/education/seminar?seminar=" + seminarData.id}>
                                {seminarData.en_title}
                              </Link>
                            </td>
                            <td
                              className="venue"
                            >
                              {seminarData.en_venue === ""
                                ? "-"
                                : seminarData.en_venue}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="section-btn-wapper">
                  <Link
                    to="/education/seminar"
                    type="button"
                    className="btn btn-primary"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
            <div className="holder-qlink-faq md-up-none xs-none">
              <div id="qlink-faq" className="section">
                <div className="page-header">
                  <h1 className="small">Frequently asked questions</h1>
                </div>
                <p>Some of the frequently asked questions about warrants.</p>
                <div className="section-qlink section-qlink-faq ">
                  <div className="section-qlink-achor">
                    <Link
                      to="/education/faq"
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="holder-qlink-investoracademy xs-none">
              <div id="qlink-investoracademy" className="section">
                <div className="page-header">
                  <h1 className="small">Investor academy</h1>
                </div>
                <p>Everything you need to know about warrants.</p>
                <div className="section-qlink section-qlink-investoracademy ">
                  <div className="section-qlink-achor">
                    <Link
                      target="_blank"
                      to="/edu"
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div id="video-dwedu-lgmd" className="holder-advertisment">
              <div id="advertisment" className="section advertising-banner">
                <div className="swiper-container swiper-container-horizontal">
                  <div className="swiper-wrapper"></div>
                  <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                    <span className="swiper-pagination-bullet swiper-pagination-bullet-active" />
                  </div>
                </div>
              </div>
            </div>
            <div id="video-dwstat-lgmd" className="col-md-6 holder-advertisment">

              <Slider {...settings} className="ad-section custom">
                {advImgVal && advImgVal.map((data, indexSlider) => {
                  return (
                    <div className="gallery-item">
                      <Link
                        className="homeimg-achor home_banner1"
                        target="_blank"
                        to={data.link}
                        key={indexSlider}
                      >
                        <img
                          style={{ width: "100%", }}
                          src={data.imgSrc}
                        />
                      </Link>
                    </div>
                  )
                })}
              </Slider>
            </div>
            <div className="holder-seminar md-up-none xs-none">
              <div id="seminar" className="section">
                <div className="page-header">
                  <h1 className="small">Seminars</h1>
                </div>
                <p>
                  The best way to improve your knowledge of warrants. Sign up
                  for a free seminar!
                </p>
                <div
                  className="seminar-table-holder"
                  style={{
                    height: 150,
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <table
                    className="table table-striped"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        {/*<th>Time</th>*/}
                        <th>Title</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      {educationSeminar &&
                        educationSeminar.map((seminarData, dataindex) => (
                          <tr
                            className="seminartmpl"
                            key={`third${dataindex}`}
                          >
                            <td
                              className="date"
                            >{seminarData.date.slice(0, -1)}</td>
                            <td
                              className="title"
                            >
                              <Link to={"/education/seminar?seminar=" + seminarData.id}>
                                {seminarData.en_title}
                              </Link>
                            </td>
                            <td
                              className="venue"
                            >
                              {seminarData.en_venue === ""
                                ? "-"
                                : seminarData.en_venue}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="section-btn-wapper">
                  <Link
                    to="/education/seminar"
                    type="button"
                    className="btn btn-primary"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
            <div className="holder-qlink-faq sm-none xs-none">
              <div id="qlink-faq" className="section">
                <div className="page-header">
                  <h1 className="small">Frequently asked questions</h1>
                </div>
                <p>Some of the frequently asked questions about warrants.</p>
                <div className="section-qlink section-qlink-faq ">
                  <div className="section-qlink-achor">
                    <Link
                      to="/education/faq"
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarrantEducation;
