import React from "react"
function HowCalculate(){
    return(
        <>
         
                        <h2>
                            How to calculate the cash settlement amount at expiry?
                        </h2>
                        <p>
                            Your index warrant will be automatically exercised if you hold it until expiry. If the Cash Settlement Amount (“CSA”) is positive, Macquarie will make a cheque payment to you within 7 market days after expiry. 
                        </p>
                        <img style={{maxWidth: "574px"}} src="/img/education/graphnew5.png" /> 
                        <p>The FX rate component takes into consideration the underlying indexes that are denominated in a foreign currency and not MYR.</p>                              
                        <h5 className="text-bold">
                            Example of an S&amp;P 500® call warrant:
                        </h5>
                        <img  style={{maxWidth: "505px"}} src="/img/education/graphnew6.png" />
                        <h5 className="text-bold">
                            Example of an FBMKLCI put warrant:
                        </h5>
                        <img  style={{maxWidth: "522px"}} src="/img/education/graphnew7.png" />
                    
        </>
    )
}
export default HowCalculate