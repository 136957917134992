import React, { useState, useEffect,Fragment } from 'react';
import HowWeHedges from './HowWeHedges';
import "../../common.css";
import "../../investorAcademy.css";


const HowWeHedge = (props) => {
    return ( 
        <Fragment> 
            <HowWeHedges/>
        </Fragment> 
     );
}


export default HowWeHedge;