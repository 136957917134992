import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "../../../src/pages/WarrantEducation/SuccessSpeak/successSpeaks.css";


const SuccessSpeaksModal = (props) => {
    const {OpenModal, Url,Open,} = props;
    const toggle = (index) => {}
      
    return ( 
      <Modal backdropClassName={"success-fade"} style={{ position: 'relative' }} centered isOpen={Open} toggle={OpenModal} className="modal-lg mt-5 success-speak">
        <a className="btn-close" onClick={OpenModal} />
        <ModalBody>
          <div style={{position: 'relative'}}>
            <div className="popupbox-content">
              <iframe src={Url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
            </div>
          </div>
        </ModalBody>
      </Modal>
     );
}
 
export default SuccessSpeaksModal;