import React from "react"
import ReactTooltip from "react-tooltip";
import SessionData from './SessionData'

export default function ToolTipData() {
  return (
    <>
      <div id="srtable">
        <ul className="indicator-list list-unstyled list-inline">
          <li>
            <div className="indicator-item small">
              {" "}
              <span className="icon-hotdw indicator-item-icon" />
              <span
                className="ttip"
                data-tip
                data-for={"tooltip_1000"}
              >
                Trending warrants
              </span>
              <ReactTooltip
                id={"tooltip_1000"}
                delayHide={100} 
                place="right"
                type="warning"
                effect="solid"
                clickable={true}
              >
                <div className="bg-tooltip">
                  <p className="tooltip-content">
                    <strong>Trending warrants:</strong> The Trending warrants are selected by Macquarie as the daily highlights for investors. The market maker will aim to provide high liquidity for these warrants and, if possible,
                    will try (but is not obliged) to sell to investors queuing at the market maker’s best bid price.

                  </p>
                </div>
              </ReactTooltip>
            </div>
          </li>
          <li>
            <div className="indicator-item small">
              {" "}
              <span className="icon-liquidity indicator-item-icon" />
              <span
                className="ttip"
                data-tip
                data-for={"tooltip_2000"}
              >
                Highest liquidity
              </span>
              <ReactTooltip
                id={"tooltip_2000"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
                clickable={true}
              >
                <div className="bg-tooltip">
                  <p className="tooltip-content">
                    <strong>Highest liquidity:</strong> Highlights the Macquarie SW in each underlying with the largest volume on the
                    current bid and offer, indicating the highest liquidity. This is often also the most actively traded SW.
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </li>
          <li>
            <div className="indicator-item small">
              {" "}
              <span
                className="icon-expiry indicator-item-icon"
              />
              <span
                className="ttip"
                data-tip
                data-for={"tooltip_3000"}
              >
                Near expiry
              </span>
              <ReactTooltip
                id={"tooltip_3000"}
                delayHide={100}
                place="right"
                type="warning"
                effect="solid"
                clickable={true}
              >
                <div className="bg-tooltip">
                  <p className="tooltip-content">
                    <strong>Near expiry: </strong> Indicates SWs with less than 1 month to expiry. These warrants have higher
                    holding costs due to a higher rate of time decay, so please exercise caution when trading these warrants.
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </li>
          <li>
            <div className="indicator-item small">
              {" "}
              <span className="icon-soldout indicator-item-icon" />
              <span
                className="ttip"
                data-tip
                data-for={"tooltip_4000"}
              >
                Inventory sold-out
              </span>
              <ReactTooltip
                id={"tooltip_4000"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
                clickable={true}
              >
                <div className="bg-tooltip">
                  <p className="tooltip-content">
                    <strong>Inventory sold-out:</strong>  SWs with this indicator are either sold out or close to being sold out, meaning the market maker may no longer provide an offer price or have widened spreads by raising the offer price. Existing holders are not disadvantaged as the bid price is still maintained at its fair price. However, please exercise caution if you are looking to buy this SW as the market price may be
                    inflated by other investors queuing in the market. Do check the Live Matrix for the warrant’s fair bid price.
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </li>
          <li>
            <div className="indicator-item small">
              {" "}
              <span className="icon-new indicator-item-icon" />
              <span
                className="ttip"
                data-tip
                data-for={"tooltip_51000"}
              >
                New warrant
              </span>
              <ReactTooltip
                id={"tooltip_51000"}
                delayHide={100}
                place="right"
                type="warning"
                effect="solid"
                clickable={true}
              >
                <div className="bg-tooltip">
                  <p className="tooltip-content">
                    <strong>New warrant:</strong> SWs with this indicator are relatively new, 
                    i.e. listed in the last week.

                  </p>
                </div>
              </ReactTooltip>
            </div>
          </li>
          <li>
            <div className="indicator-item small">
              {" "}
              <span className="icon-responsive indicator-item-icon" />
              <span
                className="ttip"
                data-tip
                data-for={"tooltip_6000"}
              >
                More responsive
              </span>
              <ReactTooltip
                id={"tooltip_6000"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
                clickable={true}
              >
                <div className="bg-tooltip">
                  <p className="tooltip-content">
                    <strong>More responsive:</strong> SWs with this indicator are more responsive to price movements in the underlying.
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </li>
          <li>
            <div className="indicator-item small">
              {" "}
              <span className="icon-effGearing indicator-item-icon" />
              <span
                className="ttip"
                data-tip
                data-for={"tooltip_7000"}
              >
                High effective gearing
              </span>
              <ReactTooltip
                id={"tooltip_7000"}
                delayHide={100}
                place="right"
                type="warning"
                effect="solid"
                clickable={true}
              >
                <div className="bg-tooltip">
                  <p className="tooltip-content">
                    <strong> High effective gearing:</strong>  SWs with this indicator have relatively higher effective gearing level, meaning the SW price will move at a larger percentage relative to a price movement in the underlying,
                    translating to a higher profit potential but also higher level of risk.
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </li>
          <li>
            <div className="indicator-item small">
              {" "}
              <span className="icon-timeDecay indicator-item-icon" />
              <span
                className="ttip"
                data-tip
                data-for={"tooltip_8000"}
              >
                Low time decay
              </span>
              <ReactTooltip
                id={"tooltip_8000"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
                clickable={true}
              >
                <div className="bg-tooltip">
                  <p className="tooltip-content">
                    <strong>Low time decay:</strong> SWs with this indicator have relatively lower time decay, meaning lower holding costs for investors. Generally,
                    all else constant, longer dated SWs have lower time decay than shorter dated SWs and vice versa.
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </li>
        </ul>
        <p>* Our market making hours (in Malaysia Time) are as follows:</p>
        <SessionData />

      </div>
    </>
  )
}
