import React, { useState, useEffect,Fragment } from 'react';
import WarrantComparisons from './WarrantComparisons';
import "./warrantComparison.css" 


const WarrantComparison = (props) => {
    return ( 
        <Fragment>
        <WarrantComparisons/>
        </Fragment> 
     );
}
 

export default WarrantComparison;