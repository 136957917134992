import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import  "./Chinesepage.css"
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
// import WarrantTermsModal from '../../../components/common/warrantTermsModal';
// import API from '../../../utils/apiCalling';
// import { config } from '../../../utils/apiUrl';
// import GaEventsTracker from "../../../components/common/GaEventsTracker";
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const WhatAreWarrants = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const generalTerms = {
    A:[
      {   
          enTtile:'Ask Price',
          chTtile:'卖出价; 沽盘价；卖盘价',
          enText:'“Ask” refers to the best price at which a market-maker will sell the warrants at. Thus, if an investor wants to buy warrants, they look at the ask price.',
          chText:'“卖盘价”是指做市商出售认股权证的最佳价格。因此，如果投资者想购买认股权证，他们会查看卖出价。',
          img:'',
          enButton:'',
          chButton:'',
          link:''
      },
      {   
          enTtile:'Ask Volume',
          chTtile:'卖出量; 沽盘量；卖盘量',
          enText:'Indicates the total number of warrants provided at the current best ask price for the warrant.',
          chText:'表示按认股权证当前最佳卖盘价的总数。',
          img:'',
          enButton:'',
          chButton:'',
          link:''
      },
    ],
    B:[
      {   
        enTtile:'Bid Price',
        chTtile:'回购价; 买价；买盘价',
        enText:'The term "bid" refers to the best price that the market-maker will pay to purchase the warrants from investors at any given time.  Thus, if investors wanted to sell warrants, they look at the bid price. ',
        chText:'"买盘价"是指做市商在任何特定时间向投资者购买认股权证所支付的最佳价格。因此，如果投资者想出售认股权证，他们会查看回购价。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      },
      {   
        enTtile:'Bid Volume',
        chTtile:'回购量; 买量；买盘量',
        enText:'Indicates the total number of warrants provided at the current best bid price for the warrant.',
        chText:'表示按认股权证当前最佳买盘价提供的总数。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      },
    ],
    D:[
      {   
        enTtile:'Delta',
        chTtile:'对冲值',
        enText:'Delta shows the approximate change in the warrant price for a small change in the underlying share (or asset) price. ',
        chText:'对冲值反映相关资产价格变化时，认股证理论价格的预期变幅。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      },
    ],
    E:[
      {   
        enTtile:'Exercise Price',
        chTtile:'行使价',
        enText:'This is the fixed price at which investors are entitled to buy the underlying share on the expiry date for call warrants (or sell, in the case of put warrants). ',
        chText:'这是指投资者有权在认购权证到期日购买（或在认沽权证的情况下出售）相关股票的固定价格。',
        img:'',
          enButton:'',
          chButton:'',
          link:''
      },
      {   
        enTtile:'Exercise Ratio',
        chTtile:'换股比率 ',
        enText:'Exercise Ratioshows the number of warrants needed to exchange for one underlying share/index futures at expiry.',
        chText:'换股比率是指在认股证到期时，多少份认股证才可以换取一股相关正股/一个期指点数的比率。',
        img:'',
          enButton:'',
          chButton:'',
          link:''
      },
    ],
    H:[
      {   
        enTtile:'Hedging',
        chTtile:'对冲；套利',
        enText:'Investors commonly use put warrants as a cost-effective way to protect themselves against any adverse price movements in the underlying. This refers to taking an offsetting position in a related security to protect the value of an asset or a portfolio. ',
        chText:'投资者通常使用认沽权证作为一种成本有效的方式来保护自己免受资产任何不利价格变动的影响。这是指在相关证券中采取抵消头寸以保护资产或投资组合的价值。',
        img:'',
          enButton:'',
          chButton:'',
          link:''
      },
    ],
    L:[
      {   
        enTtile:'Liquidity',
        chTtile:'流通性',
        enText:'Liquidity is a measure of how easily the warrant can be traded. Macquarie as a market maker aims to provide continuous buy and sell quotes in their warrants. In doing so, the market makers provide liquidity in the warrants so that investors can easily enter and exit their trades.',
        chText:'流通性是权证交易难易程度的衡量标准。麦格理作为做市商，旨在为其认股权证提供持续的买卖报价。在此过程中，做市商为认股权证提供流动性，以便投资者可以轻松买入和卖出。',
        img:'',
          enButton:'',
          chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/edu/market-marker/liquidity-vs-volume'
      },
    ],
    S:[
      {   
        enTtile:'Sensitivity',
        chTtile:'敏感度',
        enText:'A warrant’s “sensitivity” is an estimate of how much the underlying price needs to move for a corresponding one tick (or minimum spread) movement in the price of the warrant.',
        chText:'权证的敏感度，是反映相关资产价格需升跌多少，才可令权证上落一个价位(或最低差价)',
        img:'',
          enButton:'',
          chButton:'',
          link:''
      },
    ],
    T:[
      {   
        enTtile:'Traded volume',
        chTtile:'交易量',
        enText:'Total volume traded in the Warrant for the current day. A warrant that has not traded for the day does not necessarily mean that it is not liquid.',
        chText:'当日权证的总交易量。当日没有交易的权证并不一定意味着它没有流动性。',
        img:'',
        enButton:'Learn more',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/edu/market-marker/liquidity-vs-volume'
      }
    ]
  }
  const warrantTerms = {
    C:[
      {   
        enTtile:'Call warrant',
        chTtile:'认购权证',
        enText:'A call warrant is a financial instrument that gives the holder the right to buy the underlying asset at a specific price on or before a specified date. Call warrant increases in value when underlying asset price rises, thus is suitable for bullish investors.',
        chText:'认购权证赋予持有人在指定日期或之前以特定价格购买标的资产的权利。认购权证随着相关资产价格上涨而增值，适合看涨的投资者。',
        img:'img/ChinesePage/Call_warrants.png',
        enButton:'',
        chButton:'',
        link:''
      }
    ],
    E:[
      {   
        enTtile:'Effective gearing',
        chTtile:'实际杠杆比率',
        enText:'Measures the percentage change in warrant price for every 1% change in the underlying price. For instance, a warrant with effective gearing of 10x should move approximately 10% for a 1% movement in the underlying share. In addition, effective gearing allows you to gauge how much one should invest into the warrants.',
        chText:'实际杠杆比率衡量相关资产价格每变动 1%，权证价格变动的百分比。例如，实际杠杆比率为 10 倍的认股权证，当相关资产变动 1% 时，其变动幅度应约为 10%。此外，实际杠杆比率可以让您衡量应投资多少该认股权证。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      },
      {   
        enTtile:'Expiry',
        chTtile:'到期日',
        enText:'Unlike shares, warrants cannot be held forever. The future date at which the warrant will cease to exist is known as expiry. For both calls and puts, the longer a warrant has time remaining until expiry, the higher the value of the warrants.',
        chText:'与股票不同，认股权证有到期日。对于认股权证和认沽权证来说，认股权证距离到期日越长，其价值就越高。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      }
    ],
    G:[
      {   
        enTtile:'Gearing',
        chTtile:'杠杆比率；资本负债比率；杠杆效应',
        enText:'The gearing shows the ratio between the price of the underlying security and the warrant price',
        chText:'杠杆效应显示正股价格与认股权证价格之间的比率。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      }
    ],
    I:[
      {   
        enTtile:'Implied Volatility',
        chTtile:'引伸波幅',
        enText:'Implied volatility is a key factor affecting a warrant’s pricing. It is the level of volatility that is implied by the current market price of the warrant, and represented as a percentage. Implied volatility can be used to compare the relative price of similar warrants. The higher the level, the more expensive the warrant (all other pricing factors held constant).',
        chText:'引伸波幅是影响认股权证价格的重要元素，此数据反映市场对相关资产未来价格波动的预期，并以百分比方式表达。引伸波幅可用作比较条款相近的认股权证中价格的高低。在其他因素不变下，引伸波幅愈高，意味该认股证的价格愈昂贵。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      },
      {   
        enTtile:'Index warrants',
        chTtile:'指数权证',
        enText:'Unlike warrants on individual stocks, index warrants give you exposure to a sector or market, as their value are linked to the performance of a benchmark index and pre-defined basket of shares respectively. Investors should know that all index warrants issued by Macquarie tracks their respective futures for the relevant futures contract month and not the index level.  Futures contracts have expiration dates, which is why the index warrants will have to switch to track the new contract month accordingly. ',
        chText:'与个股的认股权证不同，指数认股权证让您能够接触某个行业或市场，因为它们的价值分别与指数和预先定义的一篮子股票的表现挂钩。投资者应该知道麦格理发行的所有指数权证均追踪相关月份期货合约，而非指数水平。期货合约均有到期日，这就是为什么指数权证必须相应地转换以追踪新合约月份的原因。',
        img:'/img/ChinesePage/Index_warrants_image.png',
        enButton:'Learn more',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/education/indexwarrants'
      }
    ],
    P:[
      {   
        enTtile:'Price change',
        chTtile:'价格变化',
        enText:'The most accurate way to calculate the price change for a warrant is to look at the change in bid prices over the period. The bid price shows the price at which you can sell your warrants and is therefore the best representation of its value at any point in time.',
        chText:'最准确计算认股权证价格变化的方法是查看一段时间内回购价的变化。回购价显示您可以出售认股权证的价格，因此是其在任何时间点价值的最佳代表。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      },
      {   
        enTtile:'Put warrant',
        chTtile:'认沽权证',
        enText:'A put warrant is a financial instrument that gives the holder the right to sell the underlying asset at a specific price on or before a specified date. Put warrant increases in value when underlying asset price falls, thus is suitable for bearish investors.',
        chText:'认沽权证赋予持有人在指定日期或之前以特定价格出售相关资产的权利。认沽权证随着相关资产价格下跌而增值，适合看跌的投资者。',
        img:'/img/ChinesePage/Put_warrants.png',
        enButton:'',
        chButton:'',
        link:''
      }
    ],
    T:[
      {   
        enTtile:'Time decay',
        chTtile:'时间值损耗',
        enText:"The time value portion of a warrant's price will slowly reduce throughout the life of the warrant. This process is called 'time decay', or 'theta'. The theta of a warrant is not constant and will speed up as the warrant approaches its expiry. A general rule of thumb is that a warrant loses 1/3 of its time value in the first 2/3 of its life.",
        chText:'认股权证的时间值部分，会随着其逐渐接近到期日而递减，此过程称为「时间值损耗」。随着到期日渐近，认股证的时间值损耗亦会加快。一般来说，认股证在由推出至到期日前的首三分之二时间中，约损耗全期约三分之一的时间值。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      }
    ],
    U:[
      {   
        enTtile:'Underlying asset',
        chTtile:'相关资产；指定资产',
        enText:'Warrant investors expect structured warrants to track their underlyings accordingly. For call warrants, this means the warrant price will increase as the underlying increases and vice versa. However, there may be instances where the warrants do not fully track the underlying. ',
        chText:'认股权证投资者预料权证能够相应地追踪其相关资产。对于认购权证来说，这意味着权证价格将随着相关资产的上涨而上涨，反之亦然。然而，在某些情况下，认股权证可能无法完全追踪相关资产。',
        img:'',
        enButton:'',
        chButton:'',
        link:''
      }
    ]
  }
  const warrantTools = {
    L:[
      {   
        enTtile:'Live Matrix Plus',
        chTtile:'实时报价表',
        enText:'Macquarie’s Live Matrix shows you where Macquarie warrant’s bid and offer prices will be, in real time!',
        chText:'麦格理的实时报价表向您显示麦格理权证的买入价和卖出实时报价！',
        img:'/img/ChinesePage/Live_matrix.png',
        enButton:'Visit now',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/tools/livematrix/'
      }
    ],
    T:[
      {   
        enTtile:'Technical event® lookup',
        chTtile:'Technical event ®搜寻',
        enText:'One of the useful trading tools available on our website is the Technical Event® Lookup, allowing investors to gain access to a free and unbiased technical analysis perspective on any underlying instruments of interest. ',
        chText:'我们网站上提供的有用交易工具之一是Technical event ®搜寻，让投资者能够获得免费且公正的技术分析视角。',
        img:'',
        enButton:'Visit now',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/tools/recognia'
      }
    ],
    U:[
      {   
        enTtile:'Underlying charts',
        chTtile:'相关资产图表',
        enText:"Our charts allow investors to perform technical analysis on the warrants' underlying.",
        chText:'我们的相关资产图表让投资者对认股权证的相关资产进行技术分析。',
        img:'',
        enButton:'Visit now',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/tools/underlying'
      }
    ],
    W:[
      {   
        enTtile:'Warrant calculator',
        chTtile:'权证计算器',
        enText:'Warrant calculator allo investors to estimates the theoretical price of a warrant based on your input of the underlying price, date or implied volatility.',
        chText:'权证计算器让投资者根据输入相关资产的价格、日期或引伸波幅去估计权证的理论价格。',
        img:'',
        enButton:'Visit now',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/tools/warrantcalculator'
      },
      {   
        enTtile:'Warrant comparison',
        chTtile:'权证比较',
        enText:'Compare warrants and their key factors before making your decision.',
        chText:'在做出投资决定之前，比较权证及其关键因素。',
        img:'',
        enButton:'Visit now',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/tools/warrantcomparison'
      },
      {   
        enTtile:'Warrant documents',
        chTtile:'权证文件',
        enText:"Warrant documents provide access to all of Macquarie's listing documents, prospectus, terms and conditions relevant for the warrants that are issued. It is advised that you review these documents before investing.",
        chText:'权证文件工具可供投资者查阅麦格理所有的上市文件、招股说明书、与已发行认股权证相关的条款和条件。建议您在投资前查看这些文件。',
        img:'',
        enButton:'Visit now',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/tools/document'
      },
      {   
        enTtile:'Warrant heatmap',
        chTtile:'权证热图',
        enText:'The warrant heatmap allows you to quickly see which underlying shares or indices are bullish or bearish in the short to medium term.',
        chText:'权证热图可让您快速了解哪些相关股票或指数在中短期内看涨或看跌。',
        img:'',
        enButton:'Visit now',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/tools/heatmap'
      },
      {   
        enTtile:'Warrant search',
        chTtile:'权证搜寻器',
        enText:'Warrant serach allow investors to tind the right warrant according to your investment criteria.',
        chText:'权证搜寻器可让投资者根据自己的投资标准寻找合适的认股证。',
        img:'',
        enButton:'Visit now',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/tools/warrantsearch'
      },
      {   
        enTtile:'Warrant terms',
        chTtile:'权证条款',
        enText:'Everything you need to know about your selected warrant is found here.',
        chText:'您可以在这里找到有关权证所有需要了解的资料。',
        img:'',
        enButton:'Visit now',
        chButton:'点击查看',
        link:'https://malaysiawarrants.com.my/tools/warrantterms'
      }
    ]
  }

  const [index, setIndex] = useState(1)
  const [btnIndex, setbtnIndex] = useState()
  const [tableData, setTableData] = useState(null)
  const [pageNum, setPageNum] = useState()
  const onClickChangeIndex = (value) => {
    setIndex(value)
  }
  useEffect(() => {
    getHomePopup()
    onClickChangeBtnIndex(1)
  }, [])
  const getHomePopup = async () => {
    // let homePopupResult = await api.get(
    //   config.advImgPopup,
    //   true,
    // );
    // let items = [];
  };
  const onClickChangeBtnIndex = (value)=>{
    setbtnIndex(value)
    if(value==1){
      setTableData(generalTerms)
      setPageNum(Object.keys(generalTerms)[0])
    }else if(value == 3){
      setTableData(warrantTerms)
      setPageNum(Object.keys(warrantTerms)[0])
    }else{
      setTableData(warrantTools)
      setPageNum(Object.keys(warrantTools)[0])
    }
  }
  return (
    <>
      <div id="WhatAreWarrants" className="pageobj" style={{}}>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                <div className="page-header" style={{borderColor:'#C2E8FF',paddingBottom:'15px'}}>
                  <h1 style={{fontWeight:"bold"}}>什么是认股权证</h1>
                  <p style={{width:'100%',margin:'5px 0px'}}>认股权证是由银行和金融机构发行的杠杆衍生工具，并在马来西亚证券交易所上市。 认股权证从其追踪相关资产的价格变动中获得价值。</p>
                  <p style={{width:'100%',margin:'5px 0px'}}>认股权与公司认股权证不同，公司认股权证由相关公司自行发行。 认股权将有一个做市商提供买入价和卖出价的报价，而公司认股权证则没有。 您可以通过名称来识别认股权，例如<b> ABC-C1 </b>表示认购权证，<b>ABC-H1</b> 表示认沽权证，而公司认购权证使用<b>“W”</b>前缀，例如ABC-WA。</p>
                  <p style={{width:'100%',margin:'15px 0px'}}>当相关资产价格上升时，认购权证价值同步上升</p>
                  <p style={{width:'100%',margin:'5px 0px'}}>当相关资产价格下跌时，认沽权证价值同步上升</p>
                  <p style={{width:'100%',margin:'5px 0px'}}>*（假设其他因素保持不变）</p>
                </div>
                <div className='page-top'>
                <h3  style={{fontWeight:"bold"}}>如何开始交易权证？</h3>
                <h5>点击以下步骤以了解更多信息</h5>
                <div  className='page-top-div'>
                    <div onClick={()=>onClickChangeIndex(1)} className={index==1?'active':''}>
                      {
                        index==1?<img src="/img/ChinesePage/Icon_1_Clicked.png" alt="" />:<img src="/img/ChinesePage/Icon_1_Unclicked.png" alt="" />
                      }
                        <p style={{color:index==1?'#1C78B1':'#fff'}}>1.知识</p>
                    </div>
                    <div  onClick={()=>onClickChangeIndex(2)} className={index==2?'active':''}>
                      {
                        index==2?<img src="/img/ChinesePage/Icon_2_Clicked.png" alt="" />:<img src="/img/ChinesePage/Icon_2_Unclicked.png" alt="" />
                      }
                        <p style={{color:index==2?'#1C78B1':'#fff'}}>2.中央存管系统账户</p>
                    </div>
                    <div  onClick={()=>onClickChangeIndex(3)} className={index==3?'active':''}>
                      {
                        index==3?<img src="/img/ChinesePage/Icon_3_Clicked.png" alt="" />:<img src="/img/ChinesePage/Icon_3_Unclicked.png" alt="" />
                      }
                        <p style={{color:index==3?'#1C78B1':'#fff'}}>3.选择认股权证</p>
                    </div>
                    <div  onClick={()=>onClickChangeIndex(4)} className={index==4?'active':''}>
                    {
                        index==4?<img src="/img/ChinesePage/Icon_4_Clicked.png" alt="" />:<img src="/img/ChinesePage/Icon_4_Unclicked.png" alt="" />
                      }
                        <p style={{color:index==4?'#1C78B1':'#fff'}}>4.如何买卖认股权证？</p>
                    </div>
                    <div  onClick={()=>onClickChangeIndex(5)} className={index==5?'active':''}>
                    {
                        index==5?<img src="/img/ChinesePage/Icon_5_Clicked.png" alt="" />:<img src="/img/ChinesePage/Icon_5_Unclicked.png" alt="" />
                      }
                        <p style={{color:index==5?'#1C78B1':'#fff'}}>5.客服热线</p>
                    </div>
                </div>
                  <div className='page-top-main'>
                          <div style={{display:index==1?'block':'none',padding: '40px'}}>
                              <p>在开始交易之前，您需要做的第一件事就是提高您对认股权证的了解！永远不要买入一些你不明白的东西。查看我们的一些学习资源</p>
                              <div className="noFlex" style={{ marginRight: "0px",marginTop:'50px',display: 'flex' }}>
                                <div className="coll-4">
                                  <div>
                                    <Link to="/edu" target="_blank">投资者教室 （英文）
                                    </Link>
                                  </div>
                                </div>
                                <div className="coll-4">
                                  <div>
                                    <Link to="/education/seminar" target="_blank">讲座会
                                    </Link>
                                  </div>
                                </div>
                                <div className="coll-4">
                                  <div>
                                    <Link
                                      to="/education/warrantvideosind/x4nSIsrlnsM/1"
                                      target="_blank"
                                    >
                                      动画视频
                                    </Link>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div style={{display:index==2?'block':'none',padding: '40px'}} className='tableClass'>
                    <p>在开始交易前，您需要拥有一个中央存管系统 (CDS) 账户和一个股票经纪公司的交易账户。</p>
                    {/* <p>在开始交易前，您需要在股票经纪公司拥有中央存管系统 (CDS) 账户。如果您已有 CDS 帐户，请看3.</p> */}
                    {/* <p>这里是您可以联系的马来西亚各股票经纪公司的顾问名单</p> */}
                    {/* <div className="row" style={{ marginRight: "0px",marginTop:'50px' }}>
                      <table className="broker-table table table-responsive table-striped" style={{width:'100%'}}>
                        <thead>
                          <tr>
                            <th>股票经纪公司</th>
                            <th>姓名</th>
                            <th>联系电话</th>
                            <th>电子邮件</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="broker-td-logo">
                              <div  className="broker_img">
                                <img src="/img/started/AffinHwang_logo.png" />
                              </div>
                            </td>
                            <td className="broker-td-contact">
                              <span>Angeline Lai</span>
                            </td>
                            <td className="broker-td-contact">
                              <a href="tel:+60321458273">
                                <span className="call_no">+603 2145 8273</span>
                                <span className="call_icon"></span>
                              </a>
                            </td>
                            <td className="broker-td-email">
                              <a href="mailto:premiumbroking@affinhwang.com">
                                <span className="call_no">
                                  premiumbroking@affinhwang.com
                                </span>
                                <span className="mail_icon"></span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="broker-td-logo">
                              <div className="broker_img">
                                <img src="/img/started/HongLeong_logo.png" />
                              </div>
                            </td>
                            <td className="broker-td-contact">
                              <span>Yee Edd Mund</span>
                            </td>
                            <td className="broker-td-contact">
                              <a href="tel:+60320808609">
                                <span className="call_no">+603 2080 8609</span>
                                <span className="call_icon"></span>
                              </a>
                            </td>
                            <td className="broker-td-email">
                              <a href="mailto:emyee@hlib.hongleong.com.my">
                                <span className="call_no">
                                  emyee@hlib.hongleong.com.my
                                </span>
                                <span className="mail_icon"></span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="broker-td-logo">
                              <div
                                className="broker_img"
                                
                              >
                                <img src="/img/started/MalaccaSec_logo.png" />
                              </div>
                            </td>
                            <td className="broker-td-contact">
                              <span>Elky Liam</span>
                            </td>
                            <td className="broker-td-contact">
                              <a href="tel:+60322012012">
                                <span className="call_no">+603 2201 2012</span>
                                <span className="call_icon"></span>
                              </a>
                            </td>
                            <td className="broker-td-email">
                              <a href="mailto:elkyliam@malaccasecurities.com.my">
                                <span className="call_no">
                                  elkyliam@malaccasecurities.com.my
                                </span>
                                <span className="mail_icon"></span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="broker-td-logo">
                              <div className="broker_img">
                                <img src="/img/started/PublicInvest_logo.png" />
                              </div>
                            </td>
                            <td className="broker-td-contact">
                              <span>Boo Wei Chai</span>
                            </td>
                            <td className="broker-td-contact">
                              <a href="tel:+60321766003">
                                <span className="call_no">+603 2176 6003</span>
                                <span className="call_icon"></span>
                              </a>
                            </td>
                            <td className="broker-td-email">
                              <a href="mailto:booweichai@publicbank.com.my">
                                <span className="call_no">
                                  booweichai@publicbank.com.my
                                </span>
                                <span className="mail_icon"></span>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="broker-td-logo">
                              <div className="broker_img" >
                                <img src="/img/started/UOB_logo.png" />
                              </div>
                            </td>
                            <td className="broker-td-contact">
                              <span>Edmund Lew</span>
                            </td>
                            <td className="broker-td-contact">
                              <a href="tel:+60362056127">
                                <span className="call_no">+603 6205 6127</span>
                                <span className="call_icon"></span>
                              </a>
                            </td>
                            <td className="broker-td-email">
                              <a href="mailto:edmundlew@uobkayhian.com.my">
                                <span className="call_no">
                                  edmundlew@uobkayhian.com.my
                                </span>
                                <span className="mail_icon"></span>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> */}
                          </div>

                          <div style={{display:index==3?'block':'none',padding: '40px'}}>
                              <p>现在您已准备就绪，如何选择适合您的认股权证？查看我们的动画视频的第 3 集或参考认股权证搜索工具以查看所有可用认股权证的列表。如果您交易麦格理的认股权证，您应该参考实时报价表，以了解麦格理的买入价及卖出价。</p>
                              <div className="row" style={{ marginRight: "0px",marginTop:'50px' }}>
                                <div className="coll-4">
                                  <div>
                                    <Link to="/education/warrantvideosind/VCzKXItjqbc/4" target="_blank">动画视频第3集
                                    </Link>
                                  </div>
                                </div>
                                <div className="coll-4">
                                  <div>
                                    <Link to="/tools/warrantsearch" target="_blank">认购权证搜寻器
                                    </Link>
                                  </div>
                                </div>
                                <div className="coll-4">
                                  <div>
                                    <Link
                                      to="/tools/livematrix"
                                      target="_blank"
                                    >
                                      实时报价表
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <p style={{marginTop:'15px'}}>您也可以参考我们主页上的趋势权证列表来了解交易思路</p>
                          </div>

                          <div style={{display:index==4?'block':'none',padding: '40px'}}>
                              <p>在经纪商的交易平台上，您将能够查看在马来西亚证券交易所上市的所有认股权证，就像股票一样。一旦您使用麦格理网站上的工具为您选择了正确的认股权证，您就可以像买卖股票一样简单地买卖。如果您不确定如何购买或出售证券，请咨询您的经纪人。</p>
                          </div>

                          <div style={{display:index==5?'block':'none',padding: '40px'}}>
                              <p>如果您需要任何帮助，请致电 +603 2059 8840 或发送电子邮件至 info@malaysiawarrants.com.my 与我们联系。您还可以订阅我们的时事通讯并在 Facebook 和 Telegram 上关注我们，了解认股权证市场的每日亮点和重要更新！</p>
                              <div className="btn-subscribe-area">
                                <Link to="/marketnews/subscribe" target="_blank" className="dybt" style={{padding:'20px 60px',color:'#000'}}>
                                  订阅
                                </Link>
                                <a
                                  className="logo-fb"
                                  href="https://www.facebook.com/MalaysiaWarrants"
                                  target="_blank"
                                  style={{margin:'0px 20px'}}
                                >
                                  <img src="/img/started/FB-logo.png"  className='page_icon'/>
                                </a>
                                <a
                                  className="logo-telegram"
                                  href="https://t.me/Malaysiawarrants"
                                  target="_blank"
                                >
                                  <img src="/img/started/Telegram-logo.png" className='page_icon'/>
                                </a>
                              </div>
                          </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='page-banner container-fluid'>
            <div>
                 <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-12">
                    <div className="section" style={{background:'transparent',color:'#fff'}}>
                        <div className="page-header" style={{borderColor:'#C2E8FF',paddingBottom:'15px',borderBottom:'0px'}}>
                        <h1 style={{fontWeight:"bold",color:'#fff'}}>认股权证术语英汉词汇表</h1>
                        <h1 style={{fontWeight:"bold",width:'100%',margin:'5px 0px',color:'#fff'}}>English-Chinese Glossary of Warrant terms </h1>
                        <hr  style={{color:'#fff',opacity: '1'}}/>
                        <p style={{color:'#fff'}}>这词汇表为您提供权证交易中常用的一些英汉术语参考</p>
                        <p style={{width:'100%',margin:'5px 0px',color:'#fff'}}>This glossary provides you a reference of some English-Chinese terms commonly used in trading warrants </p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div className='container-fluid'>
        <div className="section">
          <div className="row">
                <div className='col-md-3'>
                  <h3 style={{fontWeight:'bold',paddingLeft:'20px'}}>词汇表 <br />Glossary</h3>
                  <hr style={{margin:'5px'}}/>
                    <div onClick={()=>onClickChangeBtnIndex(1)} className={btnIndex==1?'btnactive Glossary_button':'Glossary_button'}> 通用术语<br />General terms</div>
                    <div  onClick={()=>onClickChangeBtnIndex(2)} className={btnIndex==2?'btnactive Glossary_button':'Glossary_button'}> 认股权证工具<br />Warrant tools</div>
                    <div  onClick={()=>onClickChangeBtnIndex(3)} className={btnIndex==3?'btnactive Glossary_button':'Glossary_button'}> 认股权证术语<br />Warrant terms</div>
                </div>
                <div className='col-md-9'>
                  <div className='pageBar'>
                    <ul style={{    overflow: 'auto'}}>
                      {
                        tableData&&Object.keys(tableData).map((item,index)=>{
                          return <li className={pageNum==item?'pageActive':'pageStyle'} onClick={()=>setPageNum(item)}>{item}</li> 
                        })
                      }
                    </ul>
                  </div>
                  <div className='page_main'>
                      <div className="container-fluid">
                        {
                          tableData&&tableData[pageNum].map((item,index)=>{
                            return (
                              <div className="row">
                                <div className="col-md-6 pageTitle"  style={{borderTop:'1px solid #C3E7F9'}}>
                                  <p>{item.enTtile}</p>
                                  <p>{item.chTtile}</p>
                                </div>
                                <div className="col-md-6"  style={{borderTop:'1px solid #C3E7F9'}}>
                                  <p>{item.chText}</p>
                                  <img src={item.img} alt="" style={{width:'100%'}}/>
                                  {
                                    item.chButton!=''&&<a href={item.link}
                                        style={{
                                          color: '#000',
                                          fontWeight: 'bold',
                                          padding: '10px 35px',
                                          background: '#4AA1D8',
                                          marginTop: '15px',
                                          float: 'right',
                                          borderRadius: '15px'
                                        }}
                                      >
                                        {item.chButton}
                                      </a>
                                    }
                                  
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                  </div>
                </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WhatAreWarrants;
