import React, { useState } from "react";
import Select, { components } from "react-select";
import "./example.css";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
            <label style={{margin: "0px",width: "75%"}}>{props.label}</label>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    style={{width: "25%",margin: "0px"}}
                />{" "}
                
            </components.Option>
            
        </div>
    );
};

function LocationSelect({setLocationDataVal}) {

    const colourStyles2 = {
        option: (styles, { isFocused,isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#f5f5f5" : null,
                backgroundColor: isSelected ? "#337ab7" : null,
                color: "#333",
                color: isFocused ? "#262626" : null,
                color: isSelected ? "#fff" : null,
                fontSize: "14px",
                padding: "1px 35px",
                textAlign: "left",
                width: "100%",
                display: "flex",
                alignItems: "center",
                height: "26px"
            };
        },
    };
    // const [values, setValues] = useState([])
    const [selectedValue, setSelectedValue] = useState([]);
    const locationList = [
        {
            // id: 1,
            label: "Johor",
            value: "johor"
        },
        {
            // id: 2,
            label: "Kedah",
            value: "kedah"
        },
        {
            // id: 3,
            label: "Kuala Lumpur",
            value: "kualalampur"
        },
        {
            // id: 4,
            label: "Malacca",
            value: "malacca"
        },
        {
            // id: 5,
            label: "Negeri Sembilan",
            value: "negeri"
        },
        {
            // id: 6,
            label: "Penang",
            value: "penang"
        },
        {
            // id: 7,
            label: "Perak",
            value: "perak"
        },
        {
            // id: 8,
            label: "Sabah",
            value: "sabah"
        },
        {
            // id: 9,
            label: "Sarawak",
            value: "sarawak"
        },
        {
            // id: 10,
            label: "Selangor",
            value: "selangor"
        },
        {
            // id: 11,
            label: "Terengganu",
            value: "terengganu"
        }
    ]

    const handleChange = (e) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
        setLocationDataVal(Array.isArray(e) ? e.map(x => x.value) : [])
    }

    return (
        <div>
            <Select
                closeMenuOnSelect={false}
                isMulti
                isClearable
                options={locationList}
                onChange={handleChange}
                value={locationList.filter(obj => selectedValue.includes(obj.value))}
                hideSelectedOptions={false}
                // components={{ MultiValue, Option }}
                placeholder="Preferred location"
                allowSelectAll={true}
                styles={colourStyles2}
            />
        </div>
    );
}
export default LocationSelect;

const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };
    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;
    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};

const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 1;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.name);
    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};
