import React from 'react'
import ReactGA from 'react-ga';

const GaEventsTracker = (category = "event") => {
    const trackEvent = (action = "Click", label = "label") => {
        // ReactGA.event(category, action, label);
        ReactGA.event({
            category: category,
            action: action,
            label: label,
          });
    };
    return trackEvent;
}

export default GaEventsTracker