import { Button } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "../../../../src/assets/css/custom.css";
import HotlineModal from "./HotlineModal";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import { get } from "lodash";

const Header = () => {
  const api = new API();
  const [navbarOpen, setnavbarOpen] = useState(false)
  const location = useLocation()
  const [visible, setvisible] = useState(false)
  const history = useHistory()
  const [show, setShow] = useState(false);
  const [educationSeminarHome, setEducationSeminarHome] = useState([]);

  useEffect(() => {
    var path = location.pathname
    var patharray = path.split("/")
    if (patharray[1] === "edu") {
      setvisible(true)
    } else {
      setvisible(false)
    }
  }, [location])


  useEffect(() => {
      setnavbarOpen(false)
  }, [location])


  useEffect(() => {
    getHomeEducationSeminar();
  }, []);

  const getHomeEducationSeminar = async () => {
    let homeEducationSeminarResult = await api.get(
      config.homeEducationSeminar,
      true,
      { m: "dwedu", qid: Date.now() }
    );
    let seminarEdu = get(homeEducationSeminarResult, "seminar", []);
    setEducationSeminarHome(seminarEdu);
    
  };

  const pageRedirect = (e) => {
    if (e.keyCode == 13) {
      var val = e.target.value
      history.push("/search?"+val)
      // history.push({ pathname: "/search", search: val })
    }
  }

  return (
    <>
      <header>
        <div className="mobile_overlay">
          <nav
            id="topnavbar"
            className="navbar navbar-inverse navbar-fixed-top navbar-larger p-0"
          >
            <div className="container-fluid">
              <div className="navbar-header m-0">
                <button
                  type="button"
                  className="navbar-toggle collapsed d-none d-sm-none d-md-none"
                  data-toggle="collapse"
                  data-target="#navbar"
                  aria-expanded="false"
                  aria-controls="navbar"
                >
                  <div id="navbar-hamburger">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </div>
                  <div id="navbar-close" className="hidden">
                    <span className="glyphicon glyphicon-remove" />
                  </div>
                </button>
                <a
                  href="tel:+60 3 2059 8840"
                  className="nav-icon icon-phone d-none"
                />
                <Link className="navbar-brand m-0 d-block" to="/home">
                  <img src="/img/home/logo.png" alt="Macquarie logo fgdh" />{" "}
                  <span style={{ position: "relative", top: 2 }}>
                    {" "}
                    | Malaysia Warrants
                  </span>
                  {visible === true ?  <span
                    id="investor"
                    style={{ color: "#ffda44", position: "relative", top: 2 }}
                  >
                    <span style={{ color: "#ffffff" }}>:</span> Investor academy
                  </span>: ""}
                </Link>
              </div>









              <div
                id="navbar"
                className="navmenu navbar-collapse collapse d-block"
              >
                
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <Link className="dropmenu" to="/home">
                      Warrant tools
                    </Link>
                    <div className="dropmenu-content nav-icon-tooltip center">
                      <div className="icon-tooltip-bg" />
                      <div className="icon-tooltip-content">
                        <div className="dropmenu-row">
                          <Link to="/tools/livematrix/">
                            Live Matrix Plus
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/tools/warrantsearch">
                            Warrant search
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/tools/warrantterms">
                            Warrant terms
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/tools/warrantcalculator">
                            Warrant calculator
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/tools/underlying">
                            Underlying charts
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/tools/warrantcomparison">
                            Warrant comparison
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/tools/document">
                            Warrant documents
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/tools/heatmap">
                            Warrant heatmap
                          </Link>
                        </div>
                        {/* <div className="dropmenu-row">
                          <Link to="/tools/recognia">
                            Technical Event® Lookup
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </li>




                  <li>
                    <Link className="dropmenu" to="/home?dwstat">
                        Warrant statistics
                    </Link>
                    <div
                      style={{
                        display: "none",
                        top: 64,
                        left: "-34px",
                        width: 220,
                      }}
                      className="dropmenu-content nav-icon-tooltip center"
                    >
                      <div className="icon-tooltip-bg" />
                      <div className="icon-tooltip-content">
                        <div className="dropmenu-row">
                          <Link to="/marketdata/toptradedstocks">
                            Top traded stocks
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/marketdata/toptradedwarrants">
                            Top traded warrants
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/marketdata/turnoverdist">
                            Turnover distribution
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/marketdata/turnover">
                            Warrant market turnover
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/marketdata/moneyflow">
                            Money flow
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>



                  <li>
                    <Link className="dropmenu" to="/home?edu">
                      Warrant education
                    </Link>
                    <div
                      style={{
                        display: "none",
                        top: 64,
                        left: "-36px",
                        width: 220,
                      }}
                      className="dropmenu-content nav-icon-tooltip center"
                    >
                      <div className="icon-tooltip-bg" />
                      <div className="icon-tooltip-content">
                        <div className="dropmenu-row">
                            <Link to="/education/whymacquarie">
                                  Why Macquarie?
                            </Link>
                        </div>
                        <div className="dropmenu-row">
                          <div
                            className="navmbl-panel-heading"
                            data-bs-toggle="collapse"
                            data-bs-target="#mblmenu5"
                            style={{ cursor: "pointer", padding: "0 10px" }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              New to warrants
                              <i className="fa fa-angle-down float-right"></i>
                            </div>
                          </div>
                          <div
                            id="mblmenu5"
                            className="navmbl-panel-collapse-mdlg collapse"
                          >
                            <div className="navmblg-row navmbl-panel">
                              <Link to="/education/getstarted">
                                How to get started
                              </Link>
                            </div>
                            <div className="navmblg-row navmbl-panel">
                              <Link to="/FirstStep">
                                Your first step
                              </Link>
                            </div>
                            
                            <div className="navmblg-row navmbl-panel">
                              <Link to="/education/warrantvideos">
                                Educational videos
                              </Link>
                            </div>
                            <div className="navmblg-row navmbl-panel">
                              <Link to="/SuccessSpeaks">
                                Success Speaks
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="dropmenu-row">
                             <Link to="/education/warrantvideosind/y7Zkk7yuoDs/6">
                                How to use the Live Matrix
                              </Link>
                        </div>
                        <div className="dropmenu-row">
                              <Link to="/education/indexwarrants">
                                Index warrants
                              </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/warrant-education/HKunderlyings">Gain exposure to HK market</Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/education/glossaryCN">English - Chinese Glossary</Link>
                        </div>
                        <div className="dropmenu-row">
                              <Link to="/education/warrantvideos/#GuestSpeakerVideo">
                                Guest speaker videos
                              </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/edu">
                            Investor academy
                          </Link>
                        </div>
                        
                        <div className="dropmenu-row">
                          <Link to="/education/faq">
                          FAQ
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/education/seminar">
                            Seminars
                          </Link>
                        </div>
                        
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link className="dropmenu" to="/home?news">
                      Market news
                    </Link>
                    <div
                      style={{
                        display: "none",
                        top: 64,
                        left: "-46px",
                        width: 220,
                      }}
                      className="dropmenu-content nav-icon-tooltip center"
                    >
                      <div className="icon-tooltip-bg" />
                      <div className="icon-tooltip-content">
                        <div className="dropmenu-row">
                          <Link to="/telegram">
                            Join us on Telegram
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/marketnews/dailyhighlights">
                            Daily highlights
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/marketnews/eodinsights">
                            End of day insights
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/marketnews/overnightmarkets">
                            Overnight market wrap
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/marketnews/subscribe">
                            Subscribe
                          </Link>
                        </div>
                        {/* <div className="dropmenu-row">
                          <Link to="/marketnews/activities">
                            Activities
                          </Link>
                        </div>
                        <div className="dropmenu-row">
                          <Link to="/marketnews/photogallery">
                            Photo gallery
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="space" style={{ width: 30, height: 15 }} />
                  </li>
                  <li className="li-icon">
                    <a
                      id="search-dropdown"
                      type="button"
                      className="dropmenu nav-icon icon-search"
                    />
                    <div
                      style={{ display: "none", top: 64, left: "-186px" }}
                      className="dropmenu-content nav-icon-tooltip"
                    >
                      <div className="icon-tooltip-bg" />
                      <div className="icon-tooltip-content">

                        <input
                          type="text"
                          id="searchMq"
                          className="form-control hsearchbox"
                          placeholder="Search Macquarie"
                          onKeyDown={(e) => pageRedirect(e)}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="li-icon">
                    <a type="button" className="dropmenu nav-icon icon-phone" />
                    <div
                      style={{ display: "none", top: 64, left: "-204px" }}
                      className="dropmenu-content nav-icon-tooltip"
                    >
                      <div className="icon-tooltip-bg" />
                      <div className="icon-tooltip-content">
                        <i>
                          <a href="tel:+60 3 2059 8840">+60 3 2059 8840</a>
                          <br />
                          <a href="mailto:info@malaysiawarrants.com.my">
                            info@malaysiawarrants.com.my
                          </a>
                        </i>
                      </div>
                    </div>
                  </li>
                </ul>
                {!visible && educationSeminarHome.length > 0 ? <Link
                  className="nav navbar-nav navbar-right visible-md visible-lg navbar-brand seminarbtn"
                  to="/education/seminar"
                >
                  <img src="/img/home/button.gif" alt="seminar" />
                </Link> : ''}
              </div>




              <li className="li-icon11">
                {!visible && educationSeminarHome.length > 0 ? <Link
                  className="nav-icon navbar-brand seminarbtn xs-none"
                  to="/education/seminar"
                >
                  <img src="/img/home/button.gif" alt="seminar" />
                </Link> : ''}
                <a type="button" className="dropmenu nav-icon icon-phone" href="tel:+60 3 2059 8840" />
              </li>





              <div className="header_tab_nav">
                <ul>
                  <div style={{
                    margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", paddingBottom:
                      4, fontFamily: '"MCQGlobal_W_Md"'
                  }}>

                    <div data-bs-toggle="collapse" data-bs-target="#header_tab" style={{ cursor: "pointer" }} id="hamburger_img">

                      <img src="/img/home/hamburgerHeader.png" alt="seminar" onClick={() => setnavbarOpen(true)} style={{ display: navbarOpen ? "none" : "block" }} />

                      <img src="/img/home/cross_img.png" alt="seminar" onClick={() => setnavbarOpen(false)} style={{ display: navbarOpen ? "block" : "none" }} />

                    </div>

                    <div id="header_tab" className={navbarOpen ? `collapsed` :  `collapse`} style={{ margin: 0, fontWeight: 400, borderTop: "1px solid rgb(255, 255, 255, 0.5)" }}>
                    
                        <div className="tab_nav_sec">

                          <div className="search_bar">
                            <div className="input">
                              <input
                                type="text"
                                id="searchMq"
                                className="form-control hsearchbox"
                                placeholder="Search Macquarie"
                                onKeyDown={(e) => pageRedirect(e)} 
                              />
                              <div className="li-icon">
                                <a
                                  id="search-dropdown"
                                  type="button"
                                  className="dropmenu nav-icon icon-search">
                                </a>
                              </div>
                            </div>
                            <div className="icon_mob">
                              <ul className="list_icon">
                                <li><a target="_blank" className="sharebtn-sbm btn" href="/marketnews/subscribe"></a> </li>
                                <li><a className="sharebtn-hlm btn" onClick={() => setShow(true)}></a></li>
                                <li><a target="_black" className="sharebtn-fbm btn" type="button" href="https://www.facebook.com/MalaysiaWarrants/"></a></li>
                                <li><a target="_black" className="sharebtn-tlm btn" type="button" href="/telegram"></a></li>
                                {!visible && educationSeminarHome.length > 0 ? 
                                  <li><Link
                                  className="nav-icon navbar-brand seminarbtn xs"
                                  to="/education/seminar"
                                >
                                  <img src="/img/home/button.gif" alt="seminar" />
                                </Link></li> : ''}
                              </ul>
                            </div>
                          </div>
                          <div className="menu_sec">
                            <div className="menu_submenu">
                              <ul className="first_ul">
                                <li className="li_first">
                                  <Link className="dropmenu" to="/home">
                                    Warrant tools
                                  </Link>
                                </li>
                                <li>
                                  <ul className="second_ul">
                                    <li ><Link to="/tools/livematrix/"> Live Matrix Plus</Link></li>
                                    <li ><Link to="/tools/warrantsearch"> Warrant search</Link></li>
                                    <li><Link to="/tools/warrantterms">Warrant terms</Link></li>
                                    <li><Link to="/tools/warrantcalculator">Warrant calculator</Link></li>
                                    <li><Link to="/tools/underlying"> Underlying charts</Link></li>
                                    <li><Link to="/tools/warrantcomparison"> Warrant comparison</Link></li>
                                    <li>  <Link to="/tools/document">Warrant documents</Link></li>
                                    <li>  <Link to="/tools/heatmap">Warrant heatmap</Link></li>
                                    {/* <li><Link to="/tools/recognia"> Technical Event® Lookup</Link></li> */}
                                  </ul>
                                </li>
                              </ul>
                            </div>
                            <div className="menu_submenu">
                              <ul className="first_ul">
                                <li className="li_first">
                                  <Link className="dropmenu" to="/home?dwstat">
                                    Warrant statistics
                                  </Link>
                                </li>
                                <li>
                                  <ul className="second_ul">
                                    <li><Link to="/marketdata/toptradedstocks"> Top traded stocks</Link></li>
                                    <li> <Link to="/marketdata/toptradedwarrants">Top traded warrants</Link></li>
                                    <li>  <Link to="/marketdata/turnoverdist"> Turnover distribution</Link></li>
                                    <li> <Link to="/marketdata/turnover"> Warrant market turnover</Link></li>
                                    <li> <Link to="/marketdata/moneyflow">Money flow</Link></li>
                                  </ul>
                                </li>
                              </ul>
                            </div>




                            <div className="menu_submenu">
                              <ul className="first_ul">
                                <li className="li_first">
                                  <Link className="dropmenu" to="/home?edu">
                                    Warrant education
                                  </Link>
                                </li>
                                <li>
                                  <ul className="second_ul">
                                    <li>
                                        <Link to="/education/whymacquarie">Why Macquarie?</Link>
                                    </li>
                                    <li className="drop_li">
                                      
                                      <ul>
                                        <div style={{
                                          margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", fontFamily: '"MCQGlobal_W_Md"'
                                        }}>

                                          <div data-bs-toggle="collapse" data-bs-target="#mblmenu2111" style={{ cursor: "pointer" }}>
                                            <li className="new_to">
                                              New to warrants
                                              <i className="fa fa-angle-down float-right"></i>
                                            </li>
                                          </div>
                                          <div id="mblmenu2111" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "1px solid #999" }}>
                                            <li>
                                              <Link to="/education/getstarted">
                                                How to get started
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/FirstStep">
                                                Your first step
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/education/warrantvideos">
                                                Educational videos
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/SuccessSpeaks">
                                                Success Speaks
                                              </Link>
                                            </li>
                                          </div>

                                        </div>
                                      </ul>
                                    </li>


                                    
                                    <li>
                                        <Link to="/education/warrantvideosind/y7Zkk7yuoDs/6">How to use the Live Matrix</Link>
                                    </li>
                                    <li>
                                        <Link to="/education/indexwarrants">Index warrants</Link>
                                    </li>
                                    <li>
                                    <Link to="/warrant-education/HKunderlyings">Gain exposure to HK market</Link>
                                    </li>
                                    <li>
                                    <Link to="/education/glossaryCN">English - Chinese Glossary</Link>
                                    </li>
                                    <li>
                                        <Link to="/education/warrantvideos/#GuestSpeakerVideo">Guest speaker videos </Link>
                                    </li>
                                    
                                    <li>
                                      <Link to="/edu"> Investor academy</Link>
                                    </li>
                                    <li>
                                      <Link to="/education/faq">FAQ</Link>
                                    </li>
                                    <li>
                                      <Link to="/education/seminar">Seminars</Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>




                            <div className="menu_submenu">
                              <ul className="first_ul">
                                <li className="li_first">
                                  <Link className="dropmenu" to="/home?news">
                                    Market news
                                  </Link>
                                </li>
                                <li>
                                  <ul className="second_ul">
                                    <li> <Link to="/telegram">Join us on Telegram</Link></li>
                                    <li> <Link to="/marketnews/dailyhighlights"> Daily highlights</Link></li>
                                    <li> <Link to="/marketnews/eodinsights">End of day insights</Link></li>
                                    <li><Link to="/marketnews/overnightmarkets">Overnight market wrap</Link></li>
                                    <li> <Link to="/marketnews/subscribe"> Subscribe</Link></li>
                                    {/* <li> <Link to="/marketnews/activities">Activities</Link></li>
                                    <li> <Link to="/marketnews/photogallery">Photo gallery</Link></li> */}
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>



                          <div className="menu_sec_mob" id="accordionParent">


                            <li className="drop_li">
                              <ul>
                                <div style={{
                                  margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", fontFamily: '"MCQGlobal_W_Md"'
                                }}>

                                  <div data-bs-toggle="collapse" data-bs-target="#mblmenu10" style={{ cursor: "pointer" }}>
                                    <li className="new_to">
                                      Warrant tools
                                      <i className="fa fa-angle-down float-right"></i>
                                    </li>
                                  </div>
                                  <div id="mblmenu10" data-bs-parent="#accordionParent" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "none" }}>
                                    <li >
                                      <Link to="/tools/livematrix/"> Live Matrix Plus </Link>
                                      </li>
                                      <li ><Link to="/tools/warrantsearch"> Warrant search</Link></li>
                                    <li><Link to="/tools/warrantterms">Warrant terms</Link></li>
                                    <li><Link to="/tools/warrantcalculator">Warrant calculator</Link></li>
                                    <li><Link to="/tools/underlying"> Underlying charts</Link></li>
                                    <li><Link to="/tools/warrantcomparison"> Warrant comparison</Link></li>
                                    <li>  <Link to="/tools/document">Warrant documents</Link></li>
                                    <li>  <Link to="/tools/heatmap">Warrant heatmap</Link></li>
                                    {/* <li><Link to="/tools/recognia"> Technical Event® Lookup</Link></li> */}
                                  </div>

                                </div>
                              </ul>
                            </li>



                            <li className="drop_li">
                              <ul>
                                <div style={{
                                  margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", fontFamily: '"MCQGlobal_W_Md"'
                                }}>

                                  <div data-bs-toggle="collapse" data-bs-target="#mblmenu11" style={{ cursor: "pointer" }}>
                                    <li className="new_to">
                                      Warrant statistics
                                      <i className="fa fa-angle-down float-right"></i>
                                    </li>
                                  </div>
                                  <div id="mblmenu11" data-bs-parent="#accordionParent" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "none" }}>
                                    <li><Link to="/marketdata/toptradedstocks"> Top traded stocks</Link></li>
                                    <li> <Link to="/marketdata/toptradedwarrants">Top traded warrants</Link></li>
                                    <li>  <Link to="/marketdata/turnoverdist"> Turnover distribution</Link></li>
                                    <li> <Link to="/marketdata/turnover"> Warrant market turnover</Link></li>
                                    <li> <Link to="/marketdata/moneyflow">Money flow</Link></li>
                                  </div>

                                </div>
                              </ul>
                            </li>



                            <li className="drop_li">
                              <ul>
                                <div style={{
                                  margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", fontFamily: '"MCQGlobal_W_Md"'
                                }}>

                                  <div data-bs-toggle="collapse" data-bs-target="#mblmenu12" style={{ cursor: "pointer" }}>
                                    <li className="new_to">
                                      Warrant education
                                      <i className="fa fa-angle-down float-right"></i>
                                    </li>
                                  </div>
                                  <div id="mblmenu12" data-bs-parent="#accordionParent" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "none" }}>
                                    <li>
                                        <Link to="/education/whymacquarie">Why Macquarie?</Link>
                                    </li>
                                    <li className="drop_li drop_li2" >
                                      <ul>
                                        <div style={{
                                          margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", fontFamily: '"MCQGlobal_W_Md"'
                                        }}>

                                          <div data-bs-toggle="collapse" data-bs-target="#mblmenu15" style={{ cursor: "pointer" }}>
                                            <li className="new_to_inner">
                                              New to warrants
                                              <i className="fa fa-angle-down float-right"></i>
                                            </li>
                                          </div>
                                          <div id="mblmenu15" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderColor: "#999" }}>
                                            <li>
                                              <Link to="/education/getstarted">
                                                How to get started
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/FirstStep">
                                                Your first step
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/education/warrantvideos">
                                                Educational videos
                                              </Link>
                                            </li>
                                            <li className="last_li">
                                              <Link to="/SuccessSpeaks">
                                                Success Speaks
                                              </Link>
                                            </li>
                                          </div>

                                        </div>
                                      </ul>
                                    </li>
                                    <li>
                                        <Link to="/education/warrantvideosind/y7Zkk7yuoDs/6">How to use the Live Matrix</Link>
                                    </li>
                                    <li>
                                        <Link to="/education/indexwarrants">Index warrants</Link>
                                    </li>
                                    <li>
                                    <Link to="/warrant-education/HKunderlyings">Gain exposure to HK market</Link>
                                    </li>       
                                    <li>
                                    <Link to="/education/glossaryCN">English - Chinese Glossary</Link>
                                    </li>
                                    <li>
                                        <Link to="/education/warrantvideos/#GuestSpeakerVideo">Guest speaker videos </Link>
                                    </li>
                                    <li>
                                      <Link to="/edu"> Investor academy</Link>
                                    </li>
                                    <li>
                                      <Link to="/education/faq">Frequently asked questions</Link>
                                    </li>
                                    <li>
                                      <Link to="/education/seminar">Seminars</Link>
                                    </li>
                                  </div>

                                </div>
                              </ul>
                            </li>



                            <li className="drop_li">
                              <ul>
                                <div style={{
                                  margin: 0, padding: 0, borderBottom: "none", fontSize: 14, color: "#fff", fontFamily: '"MCQGlobal_W_Md"'
                                }}>

                                  <div data-bs-toggle="collapse" data-bs-target="#mblmenu13" style={{ cursor: "pointer" }}>
                                    <li className="new_to">
                                      Market news
                                      <i className="fa fa-angle-down float-right"></i>
                                    </li>
                                  </div>
                                  <div id="mblmenu13" data-bs-parent="#accordionParent" className="navmbl-panel-collapse-mdlg collapse" style={{ margin: 0, fontWeight: 400, borderTop: "none" }}>
                                    <li> <Link to="/telegram">Join us on Telegram</Link></li>
                                    <li> <Link to="/marketnews/dailyhighlights"> Daily highlights</Link></li>
                                    <li> <Link to="/marketnews/eodinsights">End of day insights</Link></li>
                                    <li><Link to="/marketnews/overnightmarkets">Overnight market wrap</Link></li>
                                    <li> <Link to="/marketnews/subscribe"> Subscribe</Link></li>
                                    {/* <li> <Link to="/marketnews/activities">Activities</Link></li>
                                    <li> <Link to="/marketnews/photogallery">Photo gallery</Link></li> */}
                                  </div>

                                </div>
                              </ul>
                            </li>

                            <div className="header_img">
                              <Link to="/education/whymacquarie">
                                <img src="/img/home/whyMQmobile.jpg" alt="seminar" />
                              </Link>
                            </div>



                          </div>















                        </div>

                    </div>


                  </div>
                </ul>
              </div>








            </div>
          </nav>
        </div>
      </header>

      {show && <HotlineModal open={show} setShow={setShow} />}
    </>
  );
};

export default Header;
