import React,{Fragment} from "react";
import "./warrantHero.css"
import WarrantHero from "./WarrantHero"

const WarrantHeros = (props) => {
    return ( 
        <Fragment> 
        <WarrantHero/>
        </Fragment> 
     );
}


export default WarrantHeros;