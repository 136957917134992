import React, { useState, useEffect,Fragment } from 'react';
import WarrantHeatmaps from './WarrantHeatmaps';
import "./warrantHeatmap.css" 


const WarrantHeatmap = (props) => {
    return ( 
        <Fragment>
        <WarrantHeatmaps/>
        </Fragment> 
     );
}
 

export default WarrantHeatmap;