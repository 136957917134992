import React, { useState, useEffect } from 'react';
import JoinTelegramModal from '../../../../components/common/joinTelegramModal';
import GaEventsTracker from "../../../../components/common/GaEventsTracker";

const DesktopTab = ({Open,OpenModal,OnClickPopHandle}) => {
    
    const useGaEventsTracker = GaEventsTracker("External Links")
    const [openLive, setOpenLive] = useState(false)

    const TelegramWeb = () => {
        OnClickPopHandle("https://web.telegram.org/#/im?p=@Malaysiawarrants",true)
        useGaEventsTracker('event', 'visitWeb', {'event_category': 'Telegram'})
    }

    const ClickhereD = () => {
        OnClickPopHandle("https://web.telegram.org/#/im?p=@Malaysiawarrants",true)
        useGaEventsTracker('event', 'openOnWeb', {'event_category': 'Telegram'})
    }

    return (
        <>
        <div className="activeD">
            <div className="row step-row">
            <div className="col-md-1">
                <img src="/img/telegram/section1/no1.png" />
            </div>
            <div className="col-md-11">
                <p className="howJoinSubTitle">Click the link below</p>
                <div>
                <span
                    className="telegramWeb broker_img"
                    onClick={TelegramWeb}
                >
                    <img
                    className="forMDimg"
                    src="/img/telegram/section1/forDesktop/web-TelegramWeb.png"
                    />
                </span>
                </div>
            </div>
            </div> 
            <div className="row step-row">
                <div className="col-md-1">
                <img src="/img/telegram/section1/no2.png" />
                </div>
                <div className="col-md-11">
                <p className="howJoinSubTitle">
                    Sign in with your mobile number
                </p>
                <img
                    className="for-img forMDimg"
                    src="/img/telegram/section1/forDesktop/web-2signin.png"
                />
                </div>
            </div>
            <div className="row step-row">
                <div className="col-md-1">
                <img src="/img/telegram/section1/no3.png" />
                </div>
                <div className="col-md-11">
                <p className="howJoinSubTitle custom">
                    Search “Malaysia Warrants” on the left side of the page,
                    or{" "}
                    <span
                        onClick={()=>setOpenLive(true)}
                        className="clickhereD broker_img"
                        style={{ color: "#0675C2" }}
                        onClick={ClickhereD}
                    >
                    click here
                    </span>
                </p>
                <img
                    className="for-img forMDimg"
                    src="/img/telegram/section1/forDesktop/web-3search.png"
                />
                </div>
            </div>
            <div className="row step-row">
                <div className="col-md-1">
                <img src="/img/telegram/section1/no4.png" />
                </div>
                <div className="col-md-11">
                <p className="howJoinSubTitle">
                    Tap on the join button at the bottom of your screen
                </p>
                </div>
            </div>
            
        </div>
    </>
    )
};

export default DesktopTab;