import axios from "axios"
import { config } from './apiUrl';

    export  const getIndexWarrant = (ticker,qid) =>
    axios.get(`${config.warrantIssue}/IndexWarrantsJSON?ticker=${ticker}&qid=${qid}`)
    
    export  const getAlert = (ric,qid) =>
    axios.get(`${config.warrantIssue}/alert?TYPE=JSON&RIC=${ric}&PERIODICITY=Y&d=${qid}`)

    export  const getWarrantIssue = (qid) =>
    axios.get(`${config.warrantIssue}/MarketDataJSON?type=index_sortbyname&lmt=all&qid=${qid}`)

    export  const getLiveMatrix = (ric,mode) =>
    axios.get(`${config.warrantIssue}/LiveMatrixJSON?ric=${ric === '' || ric === undefined ? "" : ric}&mode=${mode}`)

    export  const getMarketData = (type,qid) =>
    axios.get(`${config.warrantIssue}/MarketDataJSON?type=${type}&qid=${qid}`)

    export  const getComparsionData = (type,ric) =>
    axios.get(`${config.warrantIssue}/ComparisonDataJSON?type=${type}&ric=${ric}`)

    export  const getSector = (sector,qid) =>
    axios.get(`${config.warrantIssue}/SectorDataJSON?sector=${sector}&qid=${qid}`)

    export  const getUnderlying = (ric,qid) =>
    axios.get(`${config.warrantIssue}/ComparisonDataJSON?type=l&ric=${ric}&qid=${qid}`)

    