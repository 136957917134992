import React, { useState, useEffect, useRef } from 'react';
import { moneyFlowTab, encodeQuery } from '../../../utils/constants'
import "./moneyFlows.css";
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import Select from 'react-select'
import { Carousel } from 'react-responsive-carousel';
import { getMoneyFlowData, getMoneyFlowDataIndex} from '../../../utils/moneyFlow.api' 
import { Spinner } from "reactstrap"
import moment from "moment"
import DonutChart from './DonutChart'
import BarChart from './BarChart';
import BarChartReverse from "./BarChartReverse"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MoneyFlows = (props) => {

  const newWindow = useRef(window);

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  const openNewWindow = (data) => {
    var baseUrl = window.location.origin
    newWindow.current = window.open(
      `${baseUrl}/marketdata/moneyflowchart?ssn=${data.name}&code=${data.code}`,
      "",
      "width=800,height=900"
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const api = new API()
  const [advImgVal, setAdvImgVal] = useState([])

  useEffect(() => {
    getHomePopup()
  }, [])

  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };

  const colourStyles = {
    option: (provided,state) => {
      
      return {
        backgroundColor: state.isFocused ? "#f5f5f5 !important" : ["1","2"].includes(state.innerProps.id.split("-")[4]) ? "#fff !important" : null ,
        color: "#333333",
        fontSize: "14px",
        padding: "3px 20px",
        cursor: "pointer",
      };
    },
  };

  const colourStyles2 = {
    option: (provided,state) => {
      return {
        backgroundColor: state.isFocused ? "#007fff !important" : ["1","2"].includes(state.innerProps.id.split("-")[4]) ? "#fff !important" : null ,
        color: "#333",
        color: state.isFocused ? "#ffffff" : null,
        fontSize: "14px",
        padding: "3px 5px",
        cursor: "pointer",
        borderTop: state.isFocused ? "1px solid #003eff" : null,
        borderBottom: state.isFocused ? "1px solid #003eff" : null,
      };
    },
  };
  
  const [activeTab, setActiveTab] = useState(moneyFlowTab['stock'])
  const [moneyFlow, setMoneyFlow] = useState([])
  const [moneyFlowStockList, setMoneyFlowStockList] = useState([])
  const [moneyFlowWeekEnding, setMoneyFlowWeekEnding] = useState([])
  const [underlying, setUnderlying] = useState("all")
  const [ranking, setRanking] = useState("top5")
  const [date, setDate] = useState()
  const [result, setResult] = useState()
  const [resultOut, setResultOut] = useState()
  const [load, setLoad] = useState(false)
  const [lastUpdate, setLastUpdate] = useState("")
  const [apiUnderlying, setApiUnderlying] = useState("")
  const TopFive = [
    { value: 'top5', label: 'Top 5' },
    { value: 'all', label: 'All' }
  ]
  
  useEffect(() => {
    getMoneyFlowResult();
  }, [activeTab])

  useEffect(() => {
    if (load && date) {
      activeTab === 'Stock' ? getMoneyFlow() : getMoneyFlowIndex()
    }
  }, [load])

  function getMoneyFlow() {
    getMoneyFlowData(underlying, date, ranking)
      .then((res) => {
        setLastUpdate(res.data.date)
        setApiUnderlying(res.data.underlying)
        ranking === "all" ? setResult(res.data.mfin) : setResult(res.data.mfin.slice(0, 5))
        ranking === "all" ? setResultOut(res.data.mfout) : setResultOut(res.data.mfout.slice(0, 5))
      })
  }

  function getMoneyFlowIndex() {
    getMoneyFlowDataIndex(underlying, date, ranking)
      .then((res) => {
        setLastUpdate(res.data.date)
        setApiUnderlying(res.data.underlying)
        ranking === "all" ? setResult(res.data.mfin) : setResult(res.data.mfin.slice(0, 5))
        ranking === "all" ? setResultOut(res.data.mfout) : setResultOut(res.data.mfout.slice(0, 5))
      })
  }

  const getMoneyFlowResult = async () => {
    let moneyFlowResult;
    if (activeTab === 'Stock') {
      moneyFlowResult = await api.get(config.moneyFlow, true, { getStockList: true, getDate: true, qid: Date.now() })
      let moneyFlowStockListRes = get(moneyFlowResult, 'stockList', [])
      let moneyFlowWeekEndingRes = get(moneyFlowResult, 'weekEnding', [])
      var tempStockList = moneyFlowStockListRes.map(({ stock_short_name : label, stock_code: value,underlying_ticker: ticker }) => ({ label:label+" ("+ticker+")", value }));
      var tempWeekEnding = moneyFlowWeekEndingRes.map(({ dateText: label, dateValue: value }) => ({ label, value }));
      var option = [{ label: 'ALL underLying', value: 'all' }]
      var newOption = [...option, ...tempStockList]
      setMoneyFlow(moneyFlowResult)
      setMoneyFlowStockList(newOption)
      setMoneyFlowWeekEnding(tempWeekEnding)
      !date && setDate(tempWeekEnding[0].value)
      setLoad(true)
    }
    else {
      moneyFlowResult = await api.get(config.moneyFlow, true, { getStockList: true, getDate: true,isIndices: true, qid: Date.now() })
      let topTradedUnderlyingsListRes = [
            {
              stock_code: "CHINA A50 ETF",
              stock_code1: "CHINA A50",
              stock_short_name: "CHINA A50",
              underlying_ticker: "CHINA A50 ETF",
              underlying_ric: "CHINA A50"
          },
          {
              stock_code: "DJIA",
              stock_code1: "DJIA",
              stock_short_name: "DOW JONES",
              underlying_ticker: "DJIA",
              underlying_ric: ".DJI"
          },
          {
            stock_code: "FTSE BURSA KLCI",
            stock_code1: "FTSE BURSA KLCI",
            stock_short_name: "FTSE BURSA KLCI",
            underlying_ticker: "FTSE BURSA KLCI",
            underlying_ric: ".KLCI"
          },
          {
            stock_code: "GLDHK",
            stock_code1: "GLDHK",
            stock_short_name: "SPDR GOLD TRUST",
            underlying_ticker: "GLDHK",
            underlying_ric: "2840.HK"
          },
          {
              stock_code: "HSI",
              stock_code1: "HSI",
              stock_short_name: "HANG SENG INDEX",
              underlying_ticker: "HANG SENG INDEX",
              underlying_ric: ".HSI"
          },
          {
            stock_code: "HSTECH",
            stock_code1: "HSTECH",
            stock_short_name: "HANG SENG TECH INDEX",
            underlying_ticker: "HANG SENG TECH INDEX",
            underlying_ric: ".HSTECH",
          },
          {
            stock_code: "NDX",
            stock_code1: "NDX",
            stock_short_name: "NASDAQ-100",
            underlying_ticker: "NDX",
            underlying_ric: ".NDX",
          },
          {
            stock_code: "NIKKEI",
            stock_code1: "NIKKEI",
            stock_short_name: "N225",
            underlying_ticker: "NIKKEI",
            underlying_ric: ".N225",
          },
          {
              stock_code: "S&P 500 INDEX",
              stock_code1: "S%26P 500 INDEX",
              stock_short_name: "S&P 500 INDEX",
              underlying_ticker: "S&P 500 INDEX",
              underlying_ric: "S&P 500 INDEX",
          },
          
      ]

      let topTradedUnderlyingsDateRes = get(moneyFlowResult, 'weekEnding', [])
      var tempStockList = topTradedUnderlyingsListRes.map(({ stock_code : label, stock_short_name: value,
        underlying_ticker: ticker }) => ({ label:label+" ("+ticker+")", value }));
      var tempWeekEnding = topTradedUnderlyingsDateRes.map(({ dateText: label, dateValue: value }) => ({ label, value }));
      var option = [{ label: 'ALL underLying', value: 'all' }]
      var newOption = [...option, ...tempStockList]
      setMoneyFlow(moneyFlowResult)
      setMoneyFlowStockList(newOption)
      setMoneyFlowWeekEnding(tempWeekEnding)
      setLoad(true)
      !date && setDate(tempWeekEnding[0].value)
    }
  }

  const isActive = (tab) => {
    if (activeTab === tab) {
      return "active";
    }
    return "";
  }

  return (
    <div id="moneyflow" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwstat">Warrant statistics</Link>
            </li>
            <li className="active">Money flow</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8">
            <div className="section">
              <div className="page-header">
                <h1>Money flow</h1>
              </div>
              <p>
                Find out which Macquarie warrants other investors are buying and
                selling.
              </p>
              <div id="assetTabs" className="tablist">
                <div className="tablist-left">
                  <table>
                    <tbody>
                      <tr>
                        <td
                          onClick={() => {
                            setResult();
                            setResultOut();
                            setLoad(false);
                            setActiveTab(moneyFlowTab.stock);
                          }}
                          className={`${isActive(moneyFlowTab.stock)}`}
                        >
                          <div
                            data-toggle="tab"
                            className="tablist-item"
                            value="stock"
                          >
                            <div className="tablist-item-txt">Stock</div>
                          </div>
                        </td>
                        <td
                          className={`${isActive(moneyFlowTab.index)} `}
                          onClick={() => {
                            setResult();
                            setResultOut();
                            setLoad(false);
                            setActiveTab(moneyFlowTab.index);
                          }}
                        >
                          <div
                            data-toggle="tab"
                            className="tablist-item"
                            value="index"
                          >
                            <div className="tablist-item-txt">Index</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="filter row">
                <div className="col-sm-3 col-xs-12">
                  <div className="filter-item underlying">
                    <Select
                      styles={colourStyles2}
                      defaultValue={"all"}
                      onChange={(moneyFlowStockList) =>
                        setUnderlying(moneyFlowStockList.value)
                      }
                      options={moneyFlowStockList}
                      placeholder="All underlying"
                    />
                  </div>
                </div>
                <div className="col-sm-3 col-xs-12">
                  <div className="filter-item">
                    <Select
                      defaultValue={
                        Object.keys(moneyFlowWeekEnding).length !== 0 &&
                        moneyFlowWeekEnding[0]?.value
                      }
                      onChange={(moneyFlowWeekEnding) =>
                        setDate(moneyFlowWeekEnding.value)
                      }
                      options={moneyFlowWeekEnding}
                      styles={colourStyles}
                      isSearchable={false}
                      placeholder={moment(date).format("DD MMM YY")}
                    />
                  </div>
                </div>
                <div className="col-sm-3 col-xs-12">
                  <div className="filter-item">
                    <div>
                      <Select
                        isSearchable={false}
                        styles={colourStyles}
                        defaultValue={
                          Object.keys(TopFive).length !== 0 && TopFive[0]?.value
                        }
                        onChange={(TopFive) => setRanking(TopFive.value)}
                        options={TopFive}
                        placeholder="Top 5"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 col-xs-12">
                  <div className="filter-item">
                    <button
                      type="button"
                      onClick={() => {
                        setResult();
                        setResultOut();
                        activeTab === "Stock"
                          ? getMoneyFlow()
                          : getMoneyFlowIndex();
                      }}
                      className="btn btn-block btn-primary"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div className="tblh">
                <div className="tblh-left">
                  <h2 className="small">Top 5 money flow in</h2>
                </div>
                <div className="tblh-right">
                  <span className="lastupdatebox hidden-xs">
                    Last update :{" "}
                    <span>
                      {lastUpdate && moment(lastUpdate).format("DD MMM YY")}
                    </span>
                  </span>
                </div>
              </div>
              <div
                id="topinTbl"
                className="topTbl"
                style={{ position: "relative" }}
              >
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>Underlying</th>
                      <th>Type</th>
                      <th>No. of warrants bought by investors</th>
                      <th>Top bought warrant</th>
                      <th className="hide-xs-portrait">Exercise price</th>
                      <th className="hide-xs-portrait">Last trading date</th>
                      <th>Money flow chart</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result?.length === 0 && (
                      <tr className="emptymsg">
                        <td colspan="8">No related information</td>
                      </tr>
                    )}
                    {result?.length >= 1 &&
                      apiUnderlying === "all" &&
                      result.map((d, i) => (
                        <tr className="rowtmpl" key={i}>
                          <td className="col-underlying">
                            <a>{d.name}</a>
                          </td>
                          <td className="col-type">
                            Call
                            <br />
                            Put
                          </td>
                          <td className="col-noofdwboughtbyinvestors">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.call.toLocaleString(),
                              }}
                            ></span>
                            <br />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.put.toLocaleString(),
                              }}
                            ></span>
                          </td>
                          <td className="col-topboughtdw">
                            <Link to={`/tools/livematrix/${d.ticker}`}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: d && d.call_topdw,
                                }}
                              ></span>
                            </Link>
                            <br />
                            <Link to={`/tools/livematrix/${d.ticker}`}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: d && d.put_topdw,
                                }}
                              ></span>
                            </Link>
                          </td>
                          <td className="col-exerciseprice hide-xs-portrait">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.call_ep,
                              }}
                            ></span>
                            <br />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.put_ep,
                              }}
                            ></span>
                          </td>
                          <td className="col-lasttradingdate hide-xs-portrait">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.call_ltd,
                              }}
                            ></span>
                            <br />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.put_ltd,
                              }}
                            ></span>
                          </td>
                          <td
                            className="col-moneyflowchart"
                            onClick={() => openNewWindow(d)}
                          >
                            <a>
                              <img
                                style={{ border: 0 }}
                                src="/img/bullet/moneyflow_chart_remake.png"
                              />
                            </a>
                          </td>
                        </tr>
                      ))}
                    {result?.length >= 1 &&
                      apiUnderlying !== "all" &&
                      result.map((d, i) => (
                        <tr className="rowtmpl" key={"first"+i}>
                          <td className="col-underlying">
                            <a>{d.name}</a>
                          </td>
                          <td className="col-type">{d.type}</td>
                          <td className="col-noofdwboughtbyinvestors">
                            <span
                              dangerouslySetInnerHTML={{ __html: d && d.dw }}
                            ></span>
                          </td>
                          <td className="col-topboughtdw">
                            <Link to={`/tools/livematrix/${d.ticker}`}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: d && d.code,
                                }}
                              ></span>
                            </Link>
                          </td>
                          <td className="col-exerciseprice hide-xs-portrait">
                            <span
                              dangerouslySetInnerHTML={{ __html: d && d.ep }}
                            ></span>
                          </td>
                          <td className="col-lasttradingdate hide-xs-portrait">
                            <span
                              dangerouslySetInnerHTML={{ __html: d && d.ltd }}
                            ></span>
                          </td>
                          <td
                            className="col-moneyflowchart"
                            onClick={() => openNewWindow(d)}
                          >
                            <a>
                              <img
                                style={{ border: 0 }}
                                src="/img/bullet/moneyflow_chart_remake.png"
                              />
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                  {!result && (
                      <div className="loading">
                        <Spinner />
                      </div>
                    )}
              </div>

              {result?.length >= 1 &&
                ranking === "top5" &&
                apiUnderlying === "all" && (
                  <div
                    id="topin_chartarea"
                    className="chartarea container-fluid"
                    style={{ display: "block" }}
                  >
                    <div className="chartbox col-xs-12 col-sm-6">
                      <h2 className="small">Top 5 money flow in</h2>
                      <BarChart result={result} />

                      <div className="chartbox-legend">
                        <div style={{ fontSize: "13px", float: "left" }}>
                          Unit '000
                        </div>
                        <table style={{ width: "130px", margin: "auto" }}>
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span
                                    className="chartbox-legend-square"
                                    style={{ backgroundColor: "#F7941E" }}
                                  />
                                  <span className="chartbox-legend-txt">
                                    CALL
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span
                                    className="chartbox-legend-square"
                                    style={{ backgroundColor: "#DD5C10" }}
                                  />
                                  <span className="chartbox-legend-txt">
                                    PUT
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="chartbox col-xs-12 col-sm-6">
                      <h2 className="small">Top 5 money flow in percentage</h2>

                      <div className="chartbox-legend">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  id="legendin_0"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg00" />
                                  <span className="chartbox-legend-txt">
                                    {result?.map((d) => d.name)[0]}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  id="legendin_1"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg01" />
                                  <span className="chartbox-legend-txt">
                                    {result?.map((d) => d.name)[1]}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  id="legendin_2"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg02" />
                                  <span className="chartbox-legend-txt">
                                    {result?.map((d) => d.name)[2]}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  id="legendin_3"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg03" />
                                  <span className="chartbox-legend-txt">
                                    {result?.map((d) => d.name)[3]}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  id="legendin_4"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg04" />
                                  <span className="chartbox-legend-txt">
                                    {result?.map((d) => d.name)[4]}
                                  </span>
                                </div>
                              </td>
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <DonutChart
                        result={result}
                        date={moment(date).format("DD MMM YY")}
                      />
                    </div>
                  </div>
                )}
              <div className="tblh">
                <div className="tblh-left">
                  <h2 className="small">Top 5 money flow out</h2>
                </div>
                <div className="tblh-right">
                  <span className="lastupdatebox hidden-xs">
                    Last update :{" "}
                    <span>
                      {lastUpdate && moment(lastUpdate).format("DD MMM YY")}
                    </span>
                  </span>
                </div>
              </div>
              <div
                id="topoutTbl"
                className="topTbl"
                style={{ position: "relative" }}
              >
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>Underlying</th>
                      <th>Type</th>
                      <th>No. of warrants sold by investors</th>
                      <th>Top sold warrant</th>
                      <th className="hide-xs-portrait">Exercise price</th>
                      <th className="hide-xs-portrait">Last trading date</th>
                      <th>Money flow chart</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultOut?.length === 0 && (
                      <tr className="emptymsg">
                        <td colspan="8">No related information</td>
                      </tr>
                    )}
                    {resultOut?.length >= 1 &&
                      apiUnderlying === "all" &&
                      resultOut.map((d, index) => (
                        <tr className="rowtmpl" key={"second"+index}>
                          <td className="col-underlying">
                            <a>{d.name}</a>
                          </td>
                          <td className="col-type">
                            Call
                            <br />
                            Put
                          </td>
                          <td className="col-noofdwsoldbyinvestors">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.call.toLocaleString(),
                              }}
                            ></span>
                            <br />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.put.toLocaleString(),
                              }}
                            ></span>
                          </td>
                          <td className="col-topsolddw">
                            <Link to={`/tools/livematrix/${d.ticker}`}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: d && d.call_topdw,
                                }}
                              ></span>
                            </Link>
                            <br />
                            <Link to={`/tools/livematrix/${d.ticker}`}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: d && d.put_topdw,
                                }}
                              ></span>
                            </Link>
                          </td>
                          <td className="col-exerciseprice hide-xs-portrait">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.call_ep,
                              }}
                            ></span>
                            <br />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.put_ep,
                              }}
                            ></span>
                          </td>
                          <td className="col-lasttradingdate hide-xs-portrait">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.call_ltd,
                              }}
                            ></span>
                            <br />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: d && d.put_ltd,
                              }}
                            ></span>
                          </td>
                          <td
                            className="col-moneyflowchart"
                            onClick={() => openNewWindow(d)}
                          >
                            <a>
                              <img
                                style={{ border: 0 }}
                                src="/img/bullet/moneyflow_chart_remake.png"
                              />
                            </a>
                          </td>
                        </tr>
                      ))}
                    {resultOut?.length >= 1 &&
                      apiUnderlying !== "all" &&
                      resultOut.map((d, i) => (
                        <tr className="rowtmpl" key={"third"+i}>
                          <td className="col-underlying">
                            <a>{d.name}</a>
                          </td>
                          <td className="col-type">{d.type}</td>
                          <td className="col-noofdwboughtbyinvestors">
                            <span
                              dangerouslySetInnerHTML={{ __html: d && d.dw }}
                            ></span>
                          </td>
                          <td className="col-topboughtdw">
                            <Link to={`/tools/livematrix/${d.ticker}`}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: d && d.code,
                                }}
                              ></span>
                            </Link>
                          </td>
                          <td className="col-exerciseprice hide-xs-portrait">
                            <span
                              dangerouslySetInnerHTML={{ __html: d && d.ep }}
                            ></span>
                          </td>
                          <td className="col-lasttradingdate hide-xs-portrait">
                            <span
                              dangerouslySetInnerHTML={{ __html: d && d.ltd }}
                            ></span>
                          </td>
                          <td
                            className="col-moneyflowchart"
                            onClick={() => openNewWindow(d)}
                          >
                            <a>
                              <img
                                style={{ border: 0 }}
                                src="/img/bullet/moneyflow_chart_remake.png"
                              />
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {!resultOut && (
                      <div className="loading">
                        <Spinner />
                      </div>
                    )}
              </div>

              {resultOut?.length >= 1 &&
                ranking === "top5" &&
                apiUnderlying === "all" && (
                  <div
                    id="topout_chartarea"
                    className="chartarea container-fluid"
                    style={{ display: "block" }}
                  >
                    <div className="chartbox col-xs-12 col-sm-6">
                      <h2 className="small">Top 5 money flow out</h2>
                      <BarChartReverse result1={resultOut} />

                      <div className="chartbox-legend">
                        <div style={{ fontSize: "13px", float: "left" }}>
                          Unit '000
                        </div>
                        <table style={{ width: "130px", margin: "auto" }}>
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span
                                    className="chartbox-legend-square"
                                    style={{ backgroundColor: "#F7941E" }}
                                  />
                                  <span className="chartbox-legend-txt">
                                    CALL
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span
                                    className="chartbox-legend-square"
                                    style={{ backgroundColor: "#DD5C10" }}
                                  />
                                  <span className="chartbox-legend-txt">
                                    PUT
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="chartbox col-xs-12 col-sm-6">
                      <h2 className="small">Top 5 money flow out percentage</h2>
                      <div className="chartbox-legend">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  id="legendin_0"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg00" />
                                  <span className="chartbox-legend-txt">
                                    {resultOut?.map((d) => d.name)[0]}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  id="legendin_1"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg01" />
                                  <span className="chartbox-legend-txt">
                                    {resultOut?.map((d) => d.name)[1]}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  id="legendin_2"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg02" />
                                  <span className="chartbox-legend-txt">
                                    {resultOut?.map((d) => d.name)[2]}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  id="legendin_3"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg03" />
                                  <span className="chartbox-legend-txt">
                                    {resultOut?.map((d) => d.name)[3]}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  id="legendin_4"
                                  className="chartbox-legend-item"
                                  style={{ display: "block" }}
                                >
                                  <span className="chartbox-legend-square cbg04" />
                                  <span className="chartbox-legend-txt">
                                    {resultOut?.map((d) => d.name)[4]}
                                  </span>
                                </div>
                              </td>
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <DonutChart
                        result={resultOut}
                        date={moment(date).format("DD MMM YY")}
                      />
                    </div>
                  </div>
                )}
              <hr />
              <p className="small">
                All market data is delayed 15 mins unless otherwise indicated by
                timestamp.
              </p>
            </div>
          </div>
          <div className="hidden-xs hidden-sm col-md-4">
            <Slider {...settings} className="ad-section custom">
              {advImgVal &&
                advImgVal.map((data, index) => {
                  return (
                    <a target="_blank" href={data.link} key={"four"+index}>
                      <div>
                        <img src={data.imgSrc} />
                      </div>
                    </a>
                  );
                })}
            </Slider>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant search</h2>
              </div>
              <p className="section-p">
                Find the right warrant according to your investment criteria.
                vikash
              </p>
              <div className="section-qlink-img section-qlink-dwsearch">
                <Link to="/tools/warrantsearch" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Live matrix</h2>
              </div>
              <p className="section-p">
                Makes trading warrant easy by showing investors where our bid
                prices will be throughout the day
              </p>
              <div className="section-qlink-img section-qlink-lmatrix">
                <Link to="/tools/livematrix" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">End of day insights</h2>
              </div>
              <p>
                What happened on the warrant market today? Our exclusive
                commentary is updated by 6pm each day.
              </p>
              <div className="section-qlink-img section-qlink-marketcommentary">
                <Link
                  to="/marketnews/eodinsights"
                  className="section-qlink-achor"
                >
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MoneyFlows;
