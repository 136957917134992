import React from 'react';
import {  Modal, ModalBody } from 'reactstrap';


const LiveMatrixPlusModal = (props) => {
    const {
        HandleModel,
        Open,
        data
      } = props;

      
    return ( 
    <div id="ppboxHTML" className="overlay" style={{ display: 'none' }}>
    <div className="overlay-inner">
        <Modal style={{ position: 'relative' }} centered isOpen={Open} toggle={HandleModel} className="modal-lg mt-5 homeLive-modal">  
        <img className="btn-close" onClick={HandleModel} style={{}} src="img/bullet/icon-close-black.png" />
        <ModalBody >
        <div className="popupbox-content">
            <div className="ppc" key="Why-trade-index-warrants">
              <h2 className="ppc-h">
                {data && data[0]?.en_title}
              </h2>
              <span dangerouslySetInnerHTML={{__html:data && data[0]?.en_full_content}}></span>
            </div>
          </div>
        </ModalBody>
        
      </Modal>
      </div>
    </div>
     );
}
 
export default LiveMatrixPlusModal;