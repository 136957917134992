import React, { useState, useEffect,Fragment } from 'react';
import Dividends from './Dividends';
import "../../common.css";
import "../../investorAcademy.css";


const Dividend = (props) => {
    return ( 
        <Fragment> 
            <Dividends/>
        </Fragment> 
     );
}


export default Dividend;