import React, { Fragment } from 'react';
import TopTradedWarrant from './TopTradedWarrant';
import "./tradedWarrant.css"

const TradedWarrant = (props) => {
    return ( 
        <Fragment>
        <TopTradedWarrant/>
        </Fragment>
     );
}
 

export default TradedWarrant;