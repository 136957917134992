import React, { useState, useEffect,Fragment } from 'react';
import Subscribes from './Subscribes';
import "./subscribe.css" 


const Subscribe = (props) => {
    return ( 
        <Fragment> 
        <Subscribes/>
        </Fragment> 
     );
}


export default Subscribe;