import React, { useState } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import HotlineModal from "./HotlineModal"; 
import { NavItem } from "reactstrap";
import GaEventsTracker from "../../../components/common/GaEventsTracker";

const Sharebtn = ({
  Location,
  Hist,
  eduVideo1,
  eduVideo2,
  eduVideo3,
  eduVideo4,
  active1,
  active2,
  active3,
  active4,
  active5,
  active6,
  gustVideo1,
  gustVideo2,
  gustVideo3,
  gustVideo4,
  gustVideo5,
  id,
}) => {
  const pathName = get(Location, "pathname", "");
  const [show, setShow] = useState(false);
  
  
  const useGaEventsTracker = GaEventsTracker("External Links")

  const FirstStep = () => {
    useGaEventsTracker('event', 'First Step', {'event_category': 'Animated Videos'})
  }

  const GetStarted = () => {
    useGaEventsTracker('event', 'Get Started', {'event_category': 'Animated Videos'})
  }
  

  return (
    <>
      {pathName === "/FirstStep" ? (
        <div className="sharebtnRes">
          <ul className="list-unstyled">
            <li>
              <a href="#section3" className="ctabtn btn"></a>
            </li>
          </ul>
        </div>
      ) : null}
      {pathName !== "/education/warrantvideosind" &&
      pathName !== "/FirstStep" &&
      pathName !== "/education/warrantvideosind/11" &&
      pathName !== "/education/warrantvideosind/12" &&
      pathName !== "/education/warrantvideosind/13" &&
      pathName !== "/education/warrantvideosind/14" &&
      pathName !== "/education/warrantvideosind/15" &&
      pathName !== "/education/warrantvideosind/16" &&
      pathName !== "/education/warrantvideosind/1" &&
      pathName !== "/education/warrantvideosind/2" &&
      pathName !== "/education/warrantvideosind/3" &&
      pathName !== "/education/warrantvideosind/4" &&
      pathName !== "/education/warrantvideosind/5" &&
      pathName !== "/education/warrantvideosind/6" &&
      pathName !==
        "/education/warrantvideosind/StructuredWarrants" &&
      pathName !==
        "/education/warrantvideosind/whyTradeWarrants" &&
      pathName !==
        "/education/warrantvideosind/howToSelectWarrants" &&
      pathName !==
        "/education/warrantvideosind/monitorWarrantsPrice" ? (
        <div className="sharebtn sm-none xs-none">
          <ul className="list-unstyled">
            <li>
              <Link
                target="_blank"
                to="/marketnews/subscribe"
                className="sharebtn-sb btn"
              />
            </li>
            <li>
              <a className="sharebtn-hl btn" onClick={() => setShow(true)} />
            </li>
            <li>
              <a
                target="_black"
                className="sharebtn-fb btn"
                type="button"
                href="https://www.facebook.com/MalaysiaWarrants/"
              />
            </li>
            <li>
              <Link
                target="_black"
                className="sharebtn-tl btn"
                type="button"
                to="/telegram"
              />
            </li>
          </ul>
        </div>
      ) : null}

      {gustVideo1 || gustVideo2 || gustVideo3 || gustVideo4 || gustVideo5 ? (
        <div className="sharebtn ">
          <ul className="list-unstyled">
            <li>
              <Link
                target="_blank"
                to="/marketnews/subscribe"
                className="sharebtn-sb btn"
              />
            </li>
            <li>
              <a className="sharebtn-hl btn" onClick={() => setShow(true)} />
            </li>
            <li>
              <a
                target="_black"
                className="sharebtn-fb btn"
                type="button"
                href="https://www.facebook.com/MalaysiaWarrants/"
              />
            </li>
            <li>
              <Link
                target="_black"
                className="sharebtn-tl btn"
                type="button"
                to="/telegram"
              />
            </li>
          </ul>
        </div>
      ) : null}

      {active1 || active2 || active3 || active4 || active5 || active6 ? (
        <div className="sharebtn ">
          <ul className="list-unstyled">
            <li>
              <Link
                target="_blank"
                to="/marketnews/subscribe"
                className="sharebtn-sb btn"
              />
            </li>
            <li>
              <a
                target="_black"
                className="sharebtn-fb btn"
                type="button"
                href="https://www.facebook.com/MalaysiaWarrants/"
              />
            </li>
            <li>
              <Link
                target="_black"
                className="sharebtn-tl btn"
                type="button"
                to="/telegram"
              />
            </li>
            <li>
              <Link
                target="_blank"
                className="sharebtn-fs btn"
                type="button"
                to="/FirstStep"
                onClick={FirstStep}
              />
            </li>
            <li>
              <Link
                target="_blank"
                className="sharebtn-stw btn"
                type="button"
                to="/education/getstarted"
                onClick={GetStarted}
              />
            </li>
          </ul>
        </div>
      ) : null}

      {eduVideo1 || eduVideo2 || eduVideo3 || eduVideo4 ? (
        <div className="sharebtn ">
          <ul className="list-unstyled">
            <li>
              <Link
                target="_blank"
                to="/marketnews/subscribe"
                className="sharebtn-sb btn"
              />
            </li>
            <li>
              <a className="sharebtn-hl btn" onClick={() => setShow(true)} />
            </li>
            <li>
              <a
                target="_black"
                className="sharebtn-fb btn"
                type="button"
                href="https://www.facebook.com/MalaysiaWarrants/"
              />
            </li>
            <li>
              <Link
                target="_black"
                className="sharebtn-tl btn"
                type="button"
                to="/telegram"
              />
            </li>
          </ul>
        </div>
      ) : null}

      {show && <HotlineModal open={show} setShow={setShow} />}
    </>
  );
};
export default Sharebtn;
