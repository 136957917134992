import React from "react"
import { Modal, ModalBody } from 'reactstrap';
function FirstStepModal({ open }) {
    return (
        <>
            <Modal style={{ position: 'relative' }} centered isOpen={open} className="modal-lg mt-5 success-speak first-step">
                <ModalBody >
                    <h4>Thank you for your interest. Your submission was successfully received. Please do not hesitate to contact us if you have any questions.</h4>
                </ModalBody>
            </Modal>
        </>
    )
}
export default FirstStepModal;
