import React from "react"
function WhyTarde(){
    return(
        <>
        <h2 className="ppc-h">
                            Why trade index warrants?
                        </h2>
                        <p>The key advantages of index warrants are:</p>
                        <ol>
                            <li>
                                <h5 className="text-bold">Allow you to take a view on the general market direction</h5>
                                <p>
                                    By gaining access to the index, you remove the need to select a share and instead take a view on the market as a whole.
                                </p>
                            </li>
                            <li>
                                <h5 className="text-bold">Able to profit from both rises and falls</h5>
                                <p>
                                   Index warrants allow you to take either a bullish (call warrant) or a bearish (put warrant) view on the market. So you have the potential to profit from both rises and falls in the market.
                                </p>
                            </li>
                            <li>
                                <h5 className="text-bold">Get exposure to different markets</h5>
                                <p>
                                   Index warrants allow you to get exposure to foreign markets like Hong Kong (e.g. the HSI Index) without having to leave the Bursa Malaysia. 
                                </p>
                            </li>
                            <li>
                                <h5 className="text-bold">Hedge your share portfolio</h5>
                                <p>
                                   Index put warrants allow you the potential to profit from downward movements in the market, making them a useful hedge for a diversified share portfolio.
                                </p>
                            </li>
                        </ol>
        </>
    )
}
export default WhyTarde