import React, { useState, useEffect,Fragment } from 'react';
import MoneyFlows from './MoneyFlows';
import "./moneyFlows.css" 


const MoneyFlow = (props) => {
    return ( 
        <Fragment>
        <MoneyFlows/>
        </Fragment> 
     );
}
 

export default MoneyFlow;