import React from "react";
import { Link } from "react-router-dom";

function TrendingWarrent({ data }) {
  return (
    <div className="holder-hotlist">
      <div id="hotlist" className="section" style={{ position: "relative" }}>
        <div
          id="TrendingWarrants"
          style={{
            marginTop: "-50px",
            position: "absolute",
          }}
        />
        <div className="page-header">
          <h1 className="small">Trending warrants</h1>
        </div>
        <div style={{ marginBottom: 20 }} className="hotlist-row row">
          <div className="hotlist-left col-md-8 p-0">
            <div className="hotlist-col-h right-border">
              Single stock warrant
            </div>

            <div className="hotlist-row right-border first row">
              {data && data.stock && 
                <>
                <div className="hotlist-col col-6">
                  <Link
                    to={"/tools/livematrix/"+(data.stock[0]?.ticker != null ? data.stock[0]?.ticker : "")}
                    style={{ display: "flex" }}
                  >
                      <div data-index={0} className="hotlist-item stock-item">
                        <div className="hotlist-item-nm" >{data.stock[0]?.dsply_nmll === null ? "-" : data.stock[0]?.dsply_nmll}</div>
                        <div className={Number(data.stock[0]?.BID_PCTCHNG) >= 0 ? "hotlist-item-trend" : "hotlist-item-trend down"}></div>
                        <div className="hotlist-item-params">
                          {data.stock[0] && <div className="hotlist-item-pc">
                            {data.stock[0]?.BID_PCTCHNG === null ? "-" : data.stock[0]?.BID_PCTCHNG > 0 ? "+"+data.stock[0]?.BID_PCTCHNG : data.stock[0]?.BID_PCTCHNG}%
                          </div>}
                        </div>
                        <div className="hotlist-item-eg">
                          Eff. gearing <span>{data.stock[0]?.effective_gearing === null ? "-" : data.stock[0]?.effective_gearing}</span>
                        </div>
                      </div>
                  </Link>
                </div> 
                <div className="hotlist-col col-6">
                  <Link
                    to={"/tools/livematrix/"+(data.stock[3]?.ticker != null ? data.stock[3]?.ticker : "")}
                    style={{ display: "flex" }}
                  >
                      <div data-index={0} className="hotlist-item stock-item">
                        <div className="hotlist-item-nm" >{data.stock[3]?.dsply_nmll === null ? "-" : data.stock[3]?.dsply_nmll}</div>
                        <div className={Number(data.stock[3]?.BID_PCTCHNG) >= 0 ? "hotlist-item-trend" : "hotlist-item-trend down"}></div>
                        <div className="hotlist-item-params">
                        {data.stock[3] && <div className="hotlist-item-pc">
                            {data.stock[3]?.BID_PCTCHNG === null ? "-" : data.stock[3]?.BID_PCTCHNG > 0 ? "+"+data.stock[3]?.BID_PCTCHNG : data.stock[3]?.BID_PCTCHNG}%
                          </div>}
                        </div>
                        <div className="hotlist-item-eg">
                          Eff. gearing <span>{data.stock[3]?.effective_gearing === null ? "-" : data.stock[3]?.effective_gearing}</span>
                        </div>
                      </div>
                  </Link>
                </div>
                <div className="hotlist-col col-6">
                  <Link
                    to={"/tools/livematrix/"+(data.stock[1]?.ticker != null ? data.stock[1]?.ticker : "")}
                    style={{ display: "flex" }}
                  >
                      <div data-index={0} className="hotlist-item stock-item">
                        <div className="hotlist-item-nm" >{data.stock[1]?.dsply_nmll === null ? "-" : data.stock[1]?.dsply_nmll}</div>
                        <div className={Number(data.stock[1]?.BID_PCTCHNG) >= 0 ? "hotlist-item-trend" : "hotlist-item-trend down"}></div>
                        <div className="hotlist-item-params">
                        {data.stock[1] && <div className="hotlist-item-pc">
                            {data.stock[1]?.BID_PCTCHNG === null ? "-" : data.stock[1]?.BID_PCTCHNG > 0 ? "+"+data.stock[1]?.BID_PCTCHNG : data.stock[1]?.BID_PCTCHNG}%
                          </div>}
                        </div>
                        <div className="hotlist-item-eg">
                          Eff. gearing <span>{data.stock[1]?.effective_gearing === null ? "-" : data.stock[1]?.effective_gearing}</span>
                        </div>
                      </div>
                  </Link>
                </div>
                <div className="hotlist-col col-6">
                  <Link
                    to={"/tools/livematrix/"+(data.stock[4]?.ticker != null ? data.stock[4]?.ticker : "")}
                    style={{ display: "flex" }}
                  >
                      <div data-index={0} className="hotlist-item stock-item">
                        <div className="hotlist-item-nm" >{data.stock[4]?.dsply_nmll === null ? "-" : data.stock[4]?.dsply_nmll}</div>
                        <div className={Number(data.stock[4]?.BID_PCTCHNG) >= 0 ? "hotlist-item-trend" : "hotlist-item-trend down"}></div>
                        <div className="hotlist-item-params">
                        {data.stock[4] && <div className="hotlist-item-pc">
                            {data.stock[4]?.BID_PCTCHNG === null ? "-" : data.stock[4]?.BID_PCTCHNG > 0 ? "+"+data.stock[4]?.BID_PCTCHNG : data.stock[4]?.BID_PCTCHNG}%
                          </div>}
                        </div>
                        <div className="hotlist-item-eg">
                          Eff. gearing <span>{data.stock[4]?.effective_gearing === null ? "-" : data.stock[4]?.effective_gearing}</span>
                        </div>
                      </div>
                  </Link>
                </div>
                <div className="hotlist-col col-6">
                  <Link
                    to={"/tools/livematrix/"+(data.stock[2]?.ticker != null ? data.stock[2]?.ticker : "")}
                    style={{ display: "flex" }}
                  >
                      <div data-index={0} className="hotlist-item stock-item">
                        <div className="hotlist-item-nm" >{data.stock[2]?.dsply_nmll === null ? "-" : data.stock[2]?.dsply_nmll}</div>
                        <div className={Number(data.stock[2]?.BID_PCTCHNG) >= 0 ? "hotlist-item-trend" : "hotlist-item-trend down"}></div>
                        <div className="hotlist-item-params">
                        {data.stock[2] && <div className="hotlist-item-pc">
                            {data.stock[2]?.BID_PCTCHNG === null ? "-" : data.stock[2]?.BID_PCTCHNG > 0 ? "+"+data.stock[2]?.BID_PCTCHNG : data.stock[2]?.BID_PCTCHNG}%
                          </div>}
                        </div>
                        <div className="hotlist-item-eg">
                          Eff. gearing <span>{data.stock[2]?.effective_gearing === null ? "-" : data.stock[2]?.effective_gearing}</span>
                        </div>
                      </div>
                  </Link>
                </div>
                <div className="hotlist-col col-6">
                  <Link
                    to={"/tools/livematrix/"+(data.stock[5]?.ticker != null ? data.stock[5]?.ticker : "")}
                    style={{ display: "flex" }}
                  >
                      <div data-index={0} className="hotlist-item stock-item">
                        <div className="hotlist-item-nm" >{data.stock[5]?.dsply_nmll === null ? "-" : data.stock[5]?.dsply_nmll}</div>
                        <div className={Number(data.stock[5]?.BID_PCTCHNG) >= 0 ? "hotlist-item-trend" : "hotlist-item-trend down"}></div>
                        <div className="hotlist-item-params">
                        {data.stock[5] && <div className="hotlist-item-pc">
                            {data.stock[5]?.BID_PCTCHNG === null ? "-" : data.stock[5]?.BID_PCTCHNG > 0 ? "+"+data.stock[5]?.BID_PCTCHNG : data.stock[5]?.BID_PCTCHNG}%
                          </div>}
                        </div>
                        <div className="hotlist-item-eg">
                          Eff. gearing <span>{data.stock[5]?.effective_gearing === null ? "-" : data.stock[5]?.effective_gearing}</span>
                        </div>
                      </div>
                  </Link>
                </div>
                </>
              }
            </div>
          </div>
          <div className="hotlist-right col-md-4 p-0">
            <div className="hotlist-col-h">Index warrants</div>
            <div className="visible-xs visible-sm hotlist-row row first">
            {data && data.index &&
              <>
              <div className="hotlist-col col-12">
                <Link
                  to={"/tools/livematrix/"+(data.index[0]?.ticker != null ? data.index[0]?.ticker : "")}
                  style={{ display: "flex" }}
                >
                    <div data-index={0} className="hotlist-item stock-item">
                      <div className="hotlist-item-nm" >{data.index[0]?.dsply_nmll === null ? "-" : data.index[0]?.dsply_nmll}</div>
                      <div className={Number(data.index[0]?.BID_PCTCHNG) >= 0 ? "hotlist-item-trend" : "hotlist-item-trend down"}></div>
                      <div className="hotlist-item-params">
                        {data.index[0] && <div className="hotlist-item-pc">
                            {data.index[0]?.BID_PCTCHNG === null ? "-" : data.index[0]?.BID_PCTCHNG > 0 ? "+"+data.index[0]?.BID_PCTCHNG : data.index[0]?.BID_PCTCHNG}%
                          </div>}
                      </div>
                      <div className="hotlist-item-eg">
                        Eff. gearing <span>{data.index[0]?.effective_gearing === null ? "-" : data.index[0]?.effective_gearing}</span>
                      </div>
                    </div>
                </Link>
              </div>
              <div className="hotlist-col col-12">
                <Link
                  to={"/tools/livematrix/"+(data.index[1]?.ticker != null ? data.index[1]?.ticker : "")}
                  style={{ display: "flex" }}
                >
                    <div data-index={0} className="hotlist-item stock-item">
                      <div className="hotlist-item-nm" >{data.index[1]?.dsply_nmll === null ? "-" : data.index[1]?.dsply_nmll}</div>
                      <div className={Number(data.index[1]?.BID_PCTCHNG) >= 0 ? "hotlist-item-trend" : "hotlist-item-trend down"}></div>
                      <div className="hotlist-item-params">
                      {data.index[1] && <div className="hotlist-item-pc">
                            {data.index[1]?.BID_PCTCHNG === null ? "-" : data.index[1]?.BID_PCTCHNG > 0 ? "+"+data.index[1]?.BID_PCTCHNG : data.index[1]?.BID_PCTCHNG}%
                          </div>}
                      </div>
                      <div className="hotlist-item-eg">
                        Eff. gearing <span>{data.index[1]?.effective_gearing === null ? "-" : data.index[1]?.effective_gearing}</span>
                      </div>
                    </div>
                </Link>
                </div>
                <div className="hotlist-col col-12">
                <Link
                  to={"/tools/livematrix/"+(data.index[2]?.ticker != null ? data.index[2]?.ticker : "")}
                  style={{ display: "flex" }}
                >
                    <div data-index={0} className="hotlist-item stock-item">
                      <div className="hotlist-item-nm" >{data.index[2]?.dsply_nmll === null ? "-" : data.index[2]?.dsply_nmll}</div>
                      <div className={Number(data.index[2]?.BID_PCTCHNG) >= 0 ? "hotlist-item-trend" : "hotlist-item-trend down"}></div>
                      <div className="hotlist-item-params">
                      {data.index[2] && <div className="hotlist-item-pc">
                            {data.index[2]?.BID_PCTCHNG === null ? "-" : data.index[2]?.BID_PCTCHNG > 0 ? "+"+data.index[2]?.BID_PCTCHNG : data.index[2]?.BID_PCTCHNG}%
                          </div>}
                      </div>
                      <div className="hotlist-item-eg">
                        Eff. gearing <span>{data.index[2]?.effective_gearing === null ? "-" : data.index[2]?.effective_gearing}</span>
                      </div>
                    </div>
                </Link>
                </div>
                </>
              }
            </div>
        </div>
        </div>
        
        <p className="section-p">
          <em>
            The market maker will aim to provide high liquidity via our quoted
            bid-offer volumes for these warrants and, if possible, will try (but
            is not obliged) to sell to investors queuing at the market maker's
            best bid price.
          </em>
        </p>
        {/* <div className="loadingindicator" /> */}
      </div>
    </div>
  );
}

export default TrendingWarrent;
