import React from "react"
import { Link } from 'react-router-dom';
function FaqHeader() {
  return (
    <>
      <ol className="breadcrumb">
        <li><Link to="/home?edu">Warrant education</Link></li>
        <li className="active">Frequently asked questions</li>
      </ol>
    </>
  )
}
export default FaqHeader
