import React from "react"
import { Link } from 'react-router-dom';
function Sidebar(){
    return(
        <>
        <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant videos</h2>
              </div>
              <p className="section-p">
                Check out our guest speakers and animated education videos.
              </p>
              <div className="section-qlink-img section-qlink-dwvideo">

                <Link className="section-qlink-achor" to="/education/warrantvideosind">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>

              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Investor academy</h2>
              </div>
              <p className="section-p">
                Everything you need to know about warrants.
              </p>
              <div className="section-qlink-img section-qlink-investoracademy">

                <Link className="section-qlink-achor" target="_blank" to="/edu">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>

              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Index warrants</h2>
              </div>
              <p className="section-p">
                What is an index warrant and why is it so popular?
              </p>
              <div className="section-qlink-img section-qlink-indexdw">

                <Link className="section-qlink-achor" to="/education/indexwarrants">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>

              </div>
            </div>

        </>
    )
}
export default Sidebar