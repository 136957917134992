import React, { useState, useEffect } from "react";
import "./unSubscribe.css";
import {postUnSubscribe} from "../../utils/moneyFlow.api"
import { faL } from "@fortawesome/free-solid-svg-icons";

const UnSubscribe = () => {
    const [email, setEmail] = useState('')
    const [popupOne, setPopupTwo] = useState(false)

    const unSubscribe = () => {
        if(email == "" || email === null || email === undefined){
            alert("Please enter email address")
        }else{
            subScribeEmail()
            setPopupTwo(true)
        }
    }

    const subScribeEmail = () => {
        postUnSubscribe(email)
        .then((res) => {
            console.log(res.data.Message)
          })
    }

    const closeCurrentPage = () => {
        if (window.navigator.userAgent.indexOf("Firefox") != -1 || window.navigator.userAgent.indexOf("Chrome") != -1) {
            window.open("about:blank", '_self').close();
        } else {
            window.opener = null;
            window.open('', '_self');
            window.close();
        }
    }

    const openWebPage = () => {
        
        var url = window.location.origin+'/home';
        window.location.href = url;

    }


  return (
    <>
        <div className="pageobj" id="unsubscribe"> 
            <div className={popupOne ? "container-fluid none" : "container-fluid block"} id="Popups-one">
                <div className="row">
                    <div className="unsubscribe_box col-md-12">
                        <img src="/img/unsubscribe/unsubscribe_1.png" alt="Unsubscribe" style={{marginBottom: '30px', height: '180px', width: '180px'}} />
                        <h3>Do you want to unsubscribe?</h3>
                        <p>If you unsubscribe, you will stop</p>
                        <p>receiving our daily newsletter</p>
                        <div style={{marginTop: '40px', paddingBottom: '100px'}}>
                            <input id="email" type="text" required name="Email" onChange={(e)=> setEmail(e.target.value)} placeholder="Email" style={{padding: '4px', color: '#000'}} />
                            <br />
                            <button className="btn btn-warning Unsubscribe" onClick={unSubscribe}>Unsubscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={popupOne ? "container-fluid block" : "container-fluid none"} id="Popups-two">
                <div className="row">
                    <div className="unsubscribe_box col-md-12">
                    <img src="/img/unsubscribe/unsubscribe_2.png" alt="Unsubscribe" style={{marginBottom: '8px', height: '300px', width: '300px'}} />
                    <h3>Unsubscribe successful</h3>
                    <p>You will no longer receive the daily</p>
                    <p>newsletter from us.</p>
                    <p>You may close this window now.</p>
                    <div style={{marginTop: '40px', paddingBottom: '100px'}}>
                        <button id="USClose" className="btn btn-light Close" style={{color: '#000'}} onClick={closeCurrentPage}>Close</button>
                    </div>
                    <div className="unsubscribe_box_img">
                        <span>
                        <img className="earth_img" src="/img/unsubscribe/earth.png" alt="Unsubscribe" onClick={openWebPage} />
                        <a target="_black" className="sharebtn-fb btn" type="button" href="https://www.facebook.com/MalaysiaWarrants/" style={{height: '50px', width: '50px'}} />
                        <a target="_black" className="sharebtn-tl btn" type="button" href="https://web.telegram.org/#/im?p=@Malaysiawarrants" style={{height: '50px', width: '50px', marginLeft: '10px'}} />
                        </span>
                    </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" id="Popups-three" style={{display: 'none'}}>
                <div className="row">
                    <div className="unsubscribe_box col-md-12" style={{textAlign: 'left'}}>
                        <h3 style={{fontSize: '60px'}}>Unsubscribe Successful</h3>
                        <p>You will no longer receive the daily newsletter from us</p>
                        <p style={{marginTop: '25px'}}>If you have a moment, do let us know why you unsubscribed:</p>
                        <p><input type="radio" />Receive too many emails</p>
                        <p><input type="radio" />Not interested in structured warrants</p>
                        <p><input type="radio" />Not interested in the content of these emails</p>
                        <p><input type="radio" />I already subscribe to your Telegram and/or Facebook channels</p>
                        <p><input type="radio" />Other</p>
                        <div style={{marginTop: '40px', paddingBottom: '100px'}}>
                            <button className="btn btn-primary Submit">Submit</button>
                        </div>
                        <div className="unsubscribe_box_img" style={{textAlign: 'center'}}>
                            <span>
                                <img className="earth_img" src="/img/unsubscribe/earth.png" alt="Unsubscribe" />
                                <a target="_black" className="sharebtn-fb btn" type="button" href="https://www.facebook.com/MalaysiaWarrants/" style={{height: '50px', width: '50px'}} />
                                <a target="_black" className="sharebtn-tl btn" type="button" href="https://web.telegram.org/#/im?p=@Malaysiawarrants" style={{height: '50px', width: '50px', marginLeft: '10px'}} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" id="Popups-four" style={{display: 'none'}}>
                <div className="row">
                    <div className="unsubscribe_box col-md-12">
                        <img src="/img/unsubscribe/unsubscribe_2.png" alt="Unsubscribe" style={{marginBottom: '8px', height: '300px', width: '300px'}} />
                        <h3>Thank you for your feedback</h3>
                        <p>You may close this window now.</p>
                        <div style={{marginTop: '40px', paddingBottom: '100px'}}>
                            <button className="btn btn-light" style={{color: '#000'}}>Close</button>
                        </div>
                        <div className="unsubscribe_box_img">
                            <span>
                            <img className="earth_img" src="/img/unsubscribe/earth.png" alt="Unsubscribe" />
                            <a target="_black" className="sharebtn-fb btn" type="button" href="https://www.facebook.com/MalaysiaWarrants/" style={{height: '50px', width: '50px'}} />
                            <a target="_black" className="sharebtn-tl btn" type="button" href="https://web.telegram.org/#/im?p=@Malaysiawarrants" style={{height: '50px', width: '50px', marginLeft: '10px'}} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </>
  );
};
export default UnSubscribe;
