import React,{useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Modal, ModalBody } from 'reactstrap';
import { createSubscription } from '../../utils/subscribeApi';

const SubscribeModel = (props) => {
  const {
    HandleModel,
    Open
  } = props;

  const [email, setemail] = useState("")
  const [errormsg, seterrormsg] = useState("")
  const [success, setsuccess] = useState(false)

  const handleSubscribe = async() =>{
    try {
      if(email === ""){
       seterrormsg("Invalid email")
       return
      }
     const res = await createSubscription({email})
     setsuccess(true)
    } catch (error) {
     console.log(error) 
    }
  }
  return (
    <div className="overlay " style={{ display: 'none' }}>
      <div className="overlay-inner">
        <Modal centered isOpen={Open} toggle={HandleModel}
          className="modal-lg mt-5 terms home-subscribe"
        >
          <ModalBody className="bg-black text-white">
          
            {success && <div id="popup-customized">
              <OutsideClickHandler onOutsideClick={()=>setsuccess(false)}>
                <div><h5>You have successfully registered for our daily newsletter!</h5></div>
              </OutsideClickHandler>
            </div>}
            <div className="popupbox-content">
              <div className="ppc" key="subscribe-newsletter">
                <div className="signup-layer">
                  <div className="page-header">
                    <h3>Sign up now</h3>
                    <img
                      className="btn-close"
                      onClick={HandleModel}
                      style={{}}
                      src="/img/bullet/icon-close-black.png"
                    />
                  </div>
                  <input type="text" id="name" placeholder="Name" />
                  <input type="text" id="email" placeholder="Email address" value={email} onChange={(e)=>{setemail(e.target.value);seterrormsg("")}}/>
                  <div className="warning" style={{paddingLeft:"8px",color:"red",marginBottom: "5px"}}>{errormsg}</div>
                  <p className="small">
                    By clicking “Submit”, you confirm that you would like to receive Macquarie’s daily newsletter and invitations for warrant seminars, and you acknowledge that Macquarie’s website administrators may have access to your personal data for the processing of
                    such data on behalf of Macquarie Capital Securities Malaysia Sdn Bhd and other companies of Macquarie group (“Macquarie”). You may view Macquarie’s privacy policy with regard to collection and management of personal data
                    via the links at the bottom of this page. You further confirm that you allow Macquarie to use your personal data for purposes of marketing and advertising of financial products (including using your data for market research
                    and analysis and sending focus group invitations to you).
                  </p>
                  <input className="btn btn-primary" type="button" id="submitBtn" defaultValue="Submit" onClick={handleSubscribe} />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
}
export default SubscribeModel;
