import React,{Fragment} from "react";
import "./warrantWarrior.css"
import WarrantWarrior from "./WarrantWarrior"

const WarrantWarriors = (props) => {
    return ( 
        <Fragment> 
        <WarrantWarrior/>
        </Fragment> 
     );
}


export default WarrantWarriors;