import React, { useState, useEffect } from "react";
import "./live-matrix.css";
import { Link, useLocation } from "react-router-dom";
import { config } from "../../../utils/apiUrl";
import API from "../../../utils/apiCalling";
import { get } from "lodash";
import JoinTelegramModal from "../../../components/common/joinTelegramModal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { getLiveMatrix, getMarketData, getComparsionData, getSector, getUnderlying } from "../../../utils/WarrantIssue.api"
import LiveMatrixCard from "./LiveMatrixCard";
import FooterData from './FooterData'
import LeftSideBar from './LeftSideBar'
import ToolTipData from './ToolTipData'
import SelectComp from "./SelectComp";
import LiveMatrixModal from "./LiveMatrixModal";
import OutsideClickHandler from "react-outside-click-handler";
import ReactTooltip from "react-tooltip";
import GaEventsTracker from "../../../components/common/GaEventsTracker";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LiveMatrix = () => {

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  const useGaEventsTracker = GaEventsTracker("External Links")

  const {pathname} = useLocation()
  const newPathname = pathname?.split("/")
  const hashArray = newPathname[3]?.split("&")
  const api = new API();
  const [activeTab, setActiveTab] = useState("liveMatrixPlus");
  const [result, setResult] = useState([])
  const [underlying, setUnderlying] = useState([]);
  const [rwList, setRwList] = useState([]);
  const [lvList, setLvList] = useState([]);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [localRic, setLocalRic] = useState(JSON.parse(window.localStorage.getItem("ric")) === null ? [] : JSON.parse(window.localStorage.getItem("ric")))
  const [resultName, setResultName] = useState([])
  const [symbol, setSymbol] = useState([])
  const [ric, setRic] = useState("")
  const [load, setLoad] = useState(false)
  const [page, setPage] = useState(1)
  // const [sector, setSector] = useState()
  const [lastUpdated, setlastUpdated] = useState("")
  const [total, setTotal] = useState(null)
  const [cardNo, setCardNo] = useState(0)
  const [incrementTooltip, setIncrementTooltip] = useState(false)
  const [decrementTooltip, setDecrementTooltip] = useState(false)
  const [clearTooltip, setClearTooltip] = useState(false)
  const [refreshTooltip, setRefreshTooltip] = useState(false)
  const [urlId, setUrlId] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [hotList, sethotList] = useState(null)
  const [cdata, setcdata] = useState(null)
  const [loading, setloading] = useState(false)
  const [inputValWarr, setInputValWarr] = useState(true)
  const [recogniaData, setRecogniaData] = useState([])
  const [dataSymbol, setDataSymbol] = useState([])
  const [refreshBtnDis, setRefreshBtnDis] = useState(false)
  const [symbolCheck, setSymbolCheck] = useState(false)
  const [newSymbol, setNewSymbol] = useState([])
  const [contractData, setContractData] = useState(false)
  const [sp500Contract, setSp500Contract] = useState(false)
  const [futureName, setFutureName] = useState('')
  const [clearName, setClearName] = useState(true)
  const [lastViewRic, setLastViewRic] = useState("")


  const List = [
    {
      id: 1,
      name: 1
    },
    {
      id: 2,
      name: 2
    },
    {
      id: 3,
      name: 3
    },
    {
      id: 4,
      name: 4
    },
    {
      id: 5,
      name: 5
    },
    {
      id: 6,
      name: 6
    },
    {
      id: 7,
      name: 7
    },
    {
      id: 8,
      name: 8
    },
    {
      id: 9,
      name: 9
    }

  ]

  const Sector = [
    {
      underlying_ric: '7',
      underlying_name: 'Custom group 1 test'
    },
    {
      underlying_ric: '11',
      underlying_name: 'Custom group 1 test'
    },
    {
      underlying_ric: '12',
      underlying_name: 'Custom group 1 test'
    },
    {
      underlying_ric: '13',
      underlying_name: 'dd'
    },
    {
      underlying_ric: '6',
      underlying_name: 'Gloves'
    },
    {
      underlying_ric: '1',
      underlying_name: 'Index warrants'
    },
    {
      underlying_ric: '9',
      underlying_name: 'Index warrants'
    },
    {
      underlying_ric: '10',
      underlying_name: 'Index warrants'
    },
    {
      underlying_ric: '3',
      underlying_name: 'test'
    }
  ]

  const isActive = (tab) => {
    if (activeTab === tab) {
      return "active";
    }
    return "";
  };


  const [advImgVal, setAdvImgVal] = useState([])


  const getSymbols =()=>{
    getLiveMatrix("",0).then((res)=>{
      setNewSymbol(res.data.symbols)
    })
  }

  useEffect(()=>{
    var pathname = "/tools/livematrix"
    if(pathname == window.location.pathname){
      window.location.pathname = "/tools/livematrix/"
    }
  },[])

  useEffect(()=>{
    getSymbols();
  },[])

  useEffect(() => {
    getHomePopup()
  }, [])

  useEffect(() => {
    symbolDataCHeck()
  }, [symbol])

  const symbolDataCHeck = () => {
    if(symbol == []){
      setSymbolCheck(false)
    }
    else{
      setSymbolCheck(true)
    }
  }

  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };
  useEffect(() => {
      if(ric === "" && hashArray.length > 1){
        return
      }
      var temp = localRic;
      if(localRic.includes(ric)){
        temp = localRic.filter(el=>{
          return el !== ric
        })
      }
      var lric = [ric, ...temp]
      setLocalRic(lric)
      window.localStorage.setItem("ric", JSON.stringify(lric));
      getLiveMatrixData(ric, 0)
      getWarrantToolsLiveMatrixUnderlying();
      getWarrantToolsLiveMatrixlastview();
      if(!total){
       setTotal([0])
      }
  }, [ric])


  useEffect(() => {
    var urls = []
    var hric = ''
    var hricNew = hashArray && hashArray.length && hashArray.length > 1 ? hashArray[hashArray.length-1] : hashArray.length === 1 ? hashArray[0] : null
    var ricToTickerHric = newSymbol.filter(function (el) {
        if(el.security_code == hricNew){
          hric = el.ric
        }
    });
    const ric_id = hric ? hric:JSON.parse(window.localStorage.getItem("ric")) !== null ? JSON.parse(window.localStorage.getItem("ric"))[0] : ""
    var size = hashArray.length
    if(size > 1){
      setPage(size)
      var newArray = [...Array(size).keys()]
      setTotal(newArray)
      for(var i=1;i<=size;i++){
        var tickerValue1 = ''
        var ricToTicker1 = newSymbol.filter(function (el) {
            if(el.security_code == hashArray[i - 1]){
              tickerValue1 = el.ric
            }
        });
        var ric = tickerValue1
        if(ric !== ""){
          setCardNo(i-1)
          var temp = localRic;
          if(localRic.includes(ric)){
            temp = localRic.filter(el=>{
              return el !== ric
            })
          }
          var lric = [ric, ...temp]
          setLocalRic(lric) 
          window.localStorage.setItem("ric", JSON.stringify(lric));
          if(i===size){
            getWarrantToolsLiveMatrixUnderlying();
            getWarrantToolsLiveMatrixlastview();
            setRic(ric_id)
            }
          else{
          getLiveMatrixData(ric, 0,i-1)
          urls.push(ric)
        }
        }
      }
    }
    else{
      setRic(ric_id)
      let filtered_symbol = newSymbol?.filter(function (el) {
        if(el.ric == ric_id){
          return el
        }
      });
      urls.push(filtered_symbol[0]?.security_code)
    }
    setUrlId(urls)
      return () => {
    }

  }, [newSymbol])
  
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const findMidPoint = (lmdata, lmprice, iscompress, isfiveday, type) => {
    var mp = {
      price: -1,
      diff: -1,
      index: -1
    };
    for (var i = 0; i < lmdata.length; i++) {
      if (iscompress && !(i % 2 == 0)) {
        var ubid;
        if (isfiveday && !iscompress) {
          ubid = Object.keys(lmdata[i])[0];
        } else {
          ubid = lmdata[i][(type == 'PUT') ? "underlying_ask" : "underlying_bid"];
        }
        var diff = Math.abs(lmprice - ubid);  
        if (mp.index === -1 || mp.diff > diff) {
          mp.diff = diff;
        }
        continue;
      }
      var ubid;
      if (isfiveday && !iscompress) {
        ubid = Object.keys(lmdata[i])[0];
      } else {
        ubid = lmdata[i][(type == 'PUT') ? "underlying_ask" : "underlying_bid"];
      }
      var diff = Math.abs(lmprice - ubid);  
      if (mp.index === -1 || mp.diff > diff) {
        mp.diff = diff;
        mp.price = ubid;
        mp.index = i;
      }
    }
    return mp;
  }


  const getTicketToRic = async (data) => {
    let ticketResult = await api.get(config.noRicApi, true, {ticker:data});
    if(ticketResult?.tickerResult){
      return ticketResult?.tickerResult[0]?.ric
    }
    
  };

  const getLiveMatrixData = async (ric, mode, cno) => {
    setRefreshBtnDis(true)
    setloading(true)
    // if(page === 1){
    //   setResult([])
    // }
    // else if(page === 2 && cardNo === 1){
      
    // }
    result[cardNo] = []
    var cardNum = cno?cno:cardNo
    getLiveMatrix(ric, mode)
      .then((res) => {
        setClearName(true)
        var symbolData = res.data.symbols
        var fName= ''
        for (var k = 0; k < symbolData.length; k++) {
          var futurename = symbolData[k]["future_dsply_name"];
          // if ((ric === symbolData[k]["ric"] && ((futurename && futurename !== "-") || ric.startsWith("CWSX")))) {
          //     if(ric.startsWith("CWSX")){
          //         setSp500Contract(true);
          //     }
          //     setContractData(true);

              if(ric === symbolData[k]["ric"]){
              setFutureName(futurename)
              fName =  futurename;
              }
          //     break;
          // }
          // else{
          //   setSp500Contract(false);
          //   setContractData(false);
          // }
        }
        if(res.data.ric_data){
        var ric_data = res.data.ric_data;
        setDataSymbol(res.data.symbols)
        setRecogniaData(res.data.recognia_data[0])
        var livematrix = res.data.livematrix;
        var ubidask = (ric_data?.type == 'PUT') ? "underlying_ask" : "underlying_bid";
        var isMacq = (ric_data?.issuer_code === "Macquarie") ? true : false;
        var fdata = [];
        // if (livematrix && livematrix.length > 4) {
        if (livematrix.length>0) {
          var midpoint;
          var sdata = [];
          
          if (livematrix[0].isCompressed == "true") {
            var ldata = []
            midpoint = findMidPoint(livematrix, ric_data.underlying_bid_lm, true, false, ric_data.type);
            var hrow, trow;
            var scount = 1, lcount = 1;
            for (var i = 0; i < livematrix.length; i++) {
              if (i + 1 === livematrix.length)
              break;
              hrow = livematrix[i];
              trow = livematrix[i + 1]
              if (!isNaN(hrow[ubidask])) {
                hrow[ubidask] = Number(hrow[ubidask]);
              }
              if (hrow.head_tail_id == trow.head_tail_id) {
                if (hrow[ubidask] == midpoint.price) {
                  hrow["classList"] = "midpoint"
                  trow["classList"] = "midpoint"
                } 
                else if (hrow[ubidask] < midpoint.price) {
                  hrow["classList"] = "srow srow" + scount;
                  trow["classList"] = "srow srow" + scount;
                  scount++;
                } 
                else if (hrow[ubidask] > midpoint.price) {
                  hrow["classList"] = "lrow lrow" + lcount;
                  trow["classList"] = "lrow lrow" + lcount;
                  lcount++;
                }
                ldata.push(hrow)
                ldata.push(trow)
                i++;
              }
            }
            if (ric_data.BID == 0.04) {
              for (var i = lcount; i >= lcount - 6; i--) {
                sdata.push("lrow lrow" + i)
              }
            } else {
              for (var i = lcount; i >= lcount - 6; i--) {
                sdata.push("lrow lrow" + i)
              }
              for (var i = 1; i <= 6; i++) {
                sdata.push("srow srow" + i)
              }
            }
            ldata = ldata.filter(el => {
              return sdata.includes(el.classList) || el.classList === "midpoint"
            })
            var isOdd = true;
            var cnt = 1;
            ldata.forEach((el, i) => {
              var cl = el.classList
              if (cnt > 2) {
                cnt = 1;
                isOdd = !isOdd;
              }
              cnt++;
              if (isOdd) {
                cl += " bgcolor-08";
                el["classList"] = cl
              }
              fdata.push(el)
            })
          }
          else {
            midpoint = findMidPoint(livematrix, ric_data.underlying_bid_lm, false, false, ric_data.type);
            var livematrixindex = 0;
            var livematrixlimit = 7;
            livematrixindex = midpoint.index;
            if (livematrixindex == 0) {
              livematrixindex = Math.ceil(livematrix.length / 2);
            }
            var hightlight = true;
            var bid = "";
            for (var i = 0; i < livematrix.length; i++) {
              if (i > livematrixindex - livematrixlimit && i < livematrixindex + livematrixlimit) {
                var livematrixdata = livematrix[i];
                if (bid != livematrixdata.bid) {
                  bid = livematrixdata.bid;
                  hightlight = !hightlight;
                }
                var isMidPoint = (livematrixdata.underlying_bid == midpoint.price) ? true : false;
                var trclass = (isMidPoint ? "midpoint" : "") + " " + (hightlight ? "bgcolor-08" : "");
                livematrixdata["classList"] = trclass
                fdata.push(livematrixdata)
              }
            }
          }
        }
        var fresult = result;
        var fresultName = resultName;
        if(ric_data?.BID==0.005){
          fdata=[]
        }
        if (cardNum == 0) {
            fresult[0] = fdata
            fresultName[0] = res.data.ric_data
            fresultName[0].future_dsply_name =fName;
            setResult(fresult)
            setResultName(fresultName)
        }
        else if (cardNum == 1) {
            fresult[1] = fdata
            fresultName[1] = res.data.ric_data
            fresultName[1].future_dsply_name =fName;
            setResult(fresult)
            setResultName(fresultName)
        }
        else if (cardNum == 2) {
            fresult[2] = fdata
            fresultName[2] = res.data.ric_data
            fresultName[2].future_dsply_name =fName;
            setResult(fresult)
            setResultName(fresultName)
        }
        else if (cardNum == 3) {
            fresult[3] = fdata
            fresultName[3] = res.data.ric_data
            fresultName[3].future_dsply_name =fName;
            setResult(fresult)
            setResultName(fresultName)
        }
        else if (cardNum == 4) {
            fresult[4] = fdata
            fresultName[4] = res.data.ric_data
            fresultName[4].future_dsply_name =fName;
            setResult(fresult)
            setResultName(fresultName)
        }
        else if (cardNum == 5) {
            fresult[5] = fdata
            fresultName[5] = res.data.ric_data
            fresultName[5].future_dsply_name =fName;
            setResult(fresult)
            setResultName(fresultName)
        }
        else if (cardNum == 6) {
            fresult[6] = fdata
            fresultName[6] = res.data.ric_data
            fresultName[6].future_dsply_name =fName;
            setResult(fresult)
            setResultName(fresultName)
        }
        else if (cardNum == 7) {
            fresult[7] = fdata
            fresultName[7] = res.data.ric_data
            fresultName[7].future_dsply_name =fName;
            setResult(fresult)
            setResultName(fresultName)
        }
        else if (cardNum == 8) {
            fresult[8] = fdata
            fresultName[8] = res.data.ric_data
            fresultName[8].future_dsply_name =fName;
            setResult(fresult)
            setResultName(fresultName)
        }
        var compRic = ric_data?.underlying_ric
        getComparsionDataList("u", compRic)
        setSymbol(res.data.symbols)
        setlastUpdated(res.data.last_update)
        setLoad(true)
        setloading(false)
        setRefreshBtnDis(false)
        }
        else{
          setDataSymbol(res.data.symbols)
          setSymbol(res.data.symbols)
          var locationData = window.location.pathname.split('/')
          if(locationData[3]){
            var ricData1 = ''
            var filtered_symbol1 = res.data.symbols?.filter(function (el) {
              if(el.security_code == locationData[3]){
                ricData1 = el.ric
              }
            });
            if(ricData1 == "" || ricData1 === null || ricData1 === undefined){
              (async () => {
                var getRicVal = await getTicketToRic(locationData[3])
                // getLiveMatrixData(getRicVal, 0)
                getLiveMatrixData(res.data.symbols[0].ric, 0)
              })();
            }else{
              getLiveMatrixData(ricData1, 0)
            }
          }
          else{
            getLiveMatrixData(res.data.symbols[0].ric, 0)
            getWarrantToolsLiveMatrixlastviewFun(res.data.symbols[0].ric)
          }
        }
      }
      )
  }

  useEffect(() => getMarketDataList("hotlist", Date.now()), [])

  function getMarketDataList(type, qid) {
    getMarketData(type, qid)
      .then((res) => {
        sethotList(res.data)
      })
  }

  // useEffect(() => {
  //   if (sector) {
  //     getSectorData(sector, Date.now())
  //   }
  // }, [sector])

  // function getSectorData(sector, qid) {
  //   getSector(sector, qid)
  //     .then((res) => {
  //     })
  // }

  function getComparsionDataList(type, ric) {
    getComparsionData(type, ric)
      .then((res) => {
        setcdata(res.data.comparison)
      })
  }

  const getWarrantToolsLiveMatrixUnderlying = async () => {
    let homeWarrantSearchResult = await api.get( 
      config.warrantUnderlying,
      true,
      { mode: 4, qid: Date.now() }
    );
    let underlyingRes = get(homeWarrantSearchResult, "list", []);
    var underlyingResData = []
    for(var k = 0; k < underlyingRes.length; k++){
      if(underlyingRes[k].underlying_ticker == "2823"){
        underlyingRes[k].underlying_ticker = "A50CHIN"
      }
      if(underlyingRes[k].underlying_ticker =="N225"){
        underlyingRes[k].underlying_name = "Nikkei Stock Average "
        underlyingRes[k].underlying_ticker = "Nikkei 225"
      }
      underlyingResData.push(underlyingRes[k])
    }
    setUnderlying(underlyingResData);
  };

  const getWarrantToolsLiveMatrixlastviewFun = async (lastViewRic) => {
    let data = {
      type: "lastview",
      ric: lastViewRic
    };
    let LiveMatrixlastviewResult = await api.get(
      config.marketTurnOverList,
      true,
      data
    );
    let rwlistRes = get(LiveMatrixlastviewResult, "dwList", []);
    let lvlistRes = get(LiveMatrixlastviewResult, "lvList", []);
    setLvList(lvlistRes);
    setRwList(rwlistRes);
  };

  const getWarrantToolsLiveMatrixlastview = async () => {
    let data = {
      type: "lastview",
      ric: JSON.parse(window.localStorage.getItem("ric")) ? JSON.parse(window.localStorage.getItem("ric")).slice(0, 6).toString() : ""
    };
    let LiveMatrixlastviewResult = await api.get(
      config.marketTurnOverList,
      true,
      data
    );
    let rwlistRes = get(LiveMatrixlastviewResult, "dwList", []);
    let lvlistRes = get(LiveMatrixlastviewResult, "lvList", []);
    setLvList(lvlistRes);
    setRwList(rwlistRes);
  };

  const onClickPopHandle = (url, value) => {
    setOpen(value);
    setUrl(url);
  };

  function handleChange(e) {
    setPage(parseInt(e.target.value))
    var size = parseInt(e.target.value)
    var newArray = [...Array(size).keys()]
    setTotal(newArray)
  }

  function increase() {
    setPage(page + 1)
    var size = page + 1
    var newArray = [...Array(size).keys()]
    setTotal(newArray)
  }

  function decrease() {
    setPage(page - 1)
    var size = page - 1
    var newArray = [...Array(size).keys()]
    if(urlId.length >= 1){
      urlId.length = urlId && urlId.length-1;
      setUrlId(urlId=>urlId)
    }
    setTotal(newArray)
  }

  function clearAll() {
    setPage(1)
    var newArray = [...Array(1).keys()]
    setTotal(newArray)
    setResult([])
    setResultName([])
    // setRic("")
    setClearName(false)
    setInputValWarr(false)
    if(urlId.length > 0){
      var newUrlId = urlId[0]
      if(newUrlId == "" || newUrlId == null || newUrlId == undefined){
        window.history.replaceState(
          "liveMatrix",
          "Live Matrix Plus | Malaysia Warrants | Macquarie",
          `/tools/livematrix/`
        );
      }else{
        window.history.replaceState(
          "liveMatrix",
          "Live Matrix Plus | Malaysia Warrants | Macquarie",
          `/tools/livematrix/${newUrlId}`
        );
      }
    }
    setUrlId([])
  }

  function getUnderlyingData(e) {
    getUnderlying(e, Date.now())
      .then((res) => {
        var dataCall = res?.data?.call
        var dataPut = res?.data?.put
        var finalDataRes = []
        if(dataCall && !dataPut){
          finalDataRes = dataCall
        }else if(!dataCall && dataPut){
          finalDataRes = dataPut
        }else if(dataCall && dataPut){
          finalDataRes = dataCall.concat(dataPut)
        }else{
          finalDataRes = []
        }
        var urlHref = window.location.href.split('/')
        var baseHref = urlHref[0]+'/'+urlHref[1]+'/'+urlHref[2]+'/'+urlHref[3]+'/'+urlHref[4]+'/'
        var tickerValue2 = []
        if(finalDataRes){
          for(var i=0; i < finalDataRes.length; i++){
            var ricToTicker2 = symbol.filter(function (el) {
              if(el.ric == finalDataRes[i].ric){
                tickerValue2.push(el.security_code)
              }
            });
          }
          var finalHrefValue = ''
          for(var j=0; j<tickerValue2.length; j++){
            if(j === tickerValue2.length - 1){
              finalHrefValue+=tickerValue2[j]
            }else{
              finalHrefValue+=tickerValue2[j]+'&'
            }
          }
          if(finalHrefValue){
            window.location.href = baseHref+finalHrefValue
          }
        }
      })
  }

  function showInrementData() {
    if (!incrementTooltip) {
      setIncrementTooltip(true)
    }
    else setIncrementTooltip(false)
  }

  function showDecrementData() {
    if (!decrementTooltip) {
      setDecrementTooltip(true)
    }
    else setDecrementTooltip(false)
  }

  function clearData() {
    if (!clearTooltip) {
      setClearTooltip(true)
    }
    else {
      setClearTooltip(false)
    }
  }

  function refreshData() {
    if (!refreshTooltip) {
      setRefreshTooltip(true)
    }
    else setRefreshTooltip(false)
  }

  function manageUrl(childData,index) {
    let filtered_symbol = symbol?.filter(function (el) {
      if(el.ric == childData){
        return el
      }
    });
    let id;
    // let newId = urlId.filter(function( element ) {
    //   return element !== undefined;
    // });
    let newId = urlId
    newId[index] = filtered_symbol[0]?.security_code;
    setUrlId(newId)
    id = newId.join("&");
    window.history.replaceState(
      "liveMatrix",
      "Live Matrix Plus | Malaysia Warrants | Macquarie",
      `/tools/livematrix/${id}`
    );
  }

  function AffinHwang() {
    useGaEventsTracker('event', 'Click', {'event_category': 'AHIB'})
    onClickPopHandle("https://einvest.affinhwang.com/",true) 
  }

  function HongLeong() {
    useGaEventsTracker('event', 'Click', {'event_category': 'HLIB'})
    onClickPopHandle("https://www.hlebroking.com/v3/",true) 
  }

  function MalaccaSecurities() {
    useGaEventsTracker('event', 'Click', {'event_category': 'MalaccaSec'})
    onClickPopHandle("https://www.mplusonline.com.my/macsecos/index.asp",true) 
  }

  function PublicBank() {
    useGaEventsTracker('event', 'Click', {'event_category': 'PublicBank'})
    onClickPopHandle("https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx",true) 
  }

  function UobKayHian() {
    useGaEventsTracker('event', 'Click', {'event_category': 'UOB'})
    onClickPopHandle("https://www.utrade.com.my/",true) 
  }



  return (
    <>
      <div
        id="livematrix"
        className="pageobj"
        style={{}}
      >
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">Warrant tools</Link>
              </li>
              <li className="active">Live Matrix Plus</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className={page === 1 ? "col-md-8 left-view" : "col-md-12 left-view"}>
              <div className="section">
                <div className="page-header">
                  <h1>Live Matrix Plus</h1>
                  <div style={{ display: "flex", position: "relative", }}>
                    {/* <div style={{ marginRight: 5 }} className="hide_mobile_xs">
                      
                      <Link
                        className="btn btn-primary"
                        to="/tools/classiclivematrix"
                      >
                        Back to the classic Live Matrix
                      </Link>
                    </div> */}
                    <input
                      className="btn btn-primary tradeEl tradeBtn"
                      defaultValue="Trade warrants here"
                      type="button"
                      data-bs-toggle="collapse"
                      href="#tradeEl3"
                      id="tradeButton3"
                      role="button"
                      aria-expanded="false"
                      aria-controls="tradeEl3"
                    />
                    <OutsideClickHandler 
                      onOutsideClick={()=>{
                        document.getElementById("tradeEl3").classList.remove("show")
                        document.getElementById("tradeButton3").setAttribute("aria-expanded","false")
                      }}
                    >
                    <div
                      className="tradeDropDown tradeEl collapse"
                      id="tradeEl3"
                    >
                      {" "}
                      <Link
                        className="btn btn-primary tradeEl"
                        to="/education/getstarted"
                        target="_blank"
                        onClick={useGaEventsTracker('event', 'Click', {'event_category': 'GetStarted'})}
                      >
                        How to get started
                      </Link>
                      <div className="dropdown-or tradeEl">
                        <hr className="tradeEl" />
                        <p className="tradeEl">or</p>
                        <hr className="tradeEl" />
                      </div>
                      <p className="tradeEl">Select your broker:</p>
                      <div className="row tradeEl">
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          defaultValue="Affin Hwang"
                          type="button"
                          onClick={AffinHwang}
                        />
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          link="https://www.hlebroking.com/v3/"
                          defaultValue="Hong Leong"
                          type="button"
                          onClick={HongLeong}
                        />
                      </div>
                      <div className="row tradeEl">
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          link="https://www.mplusonline.com.my/macsecos/"
                          defaultValue="Malacca Securities"
                          type="button"
                          onClick={MalaccaSecurities}
                        />
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          link="https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx"
                          defaultValue="Public Bank"
                          type="button"
                          onClick={PublicBank}
                        />
                      </div>
                      <div className="row tradeEl">
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          link="https://www.utrade.com.my/"
                          defaultValue="UOB Kay Hian"
                          type="button"
                          onClick={UobKayHian}
                        />
                        <input
                          className="btn btn-primary broker_link tradeEl"
                          style={{ visibility: "hidden" }}
                          link="https://www.utrade.com.my/"
                          defaultValue="Phillip "
                          type="button"
                        />
                      </div>
                    </div>
                    </OutsideClickHandler>
                  </div>
                </div>
                <p style={{marginBottom: "10px"}}>
                  The live matrix is directly connected to Macquarie's market
                  making system. It indicates where our bids and offer prices
                  would be in a warrant, based on various price points in the
                  stock or index.
                  <br />
                  <br />
                  {" "}
                  <span className="text-bold">Important notice:</span>&nbsp;The
                  live matrix may be updated during trading hours, and if so
                  they will be reflected when you refresh the live matrix. The
                  actual prices of warrants may differ from the prices displayed
                  below. Information contained in the live matrix may not be
                  accurate outside of our market making hours* and may
                  occasionally be delayed. Please call us at +603-2059 8840 if
                  you have any questions or require immediate assistance.
                </p>
                <div className="select-area">
                  <div className="showtable">
                    {" "}
                    <span>Show </span>
                    <select
                      className="input"
                      value={page}
                      onChange={(e) => {

                        handleChange(e)
                      }}
                    >
                      {List && List.map((d, optindex) =>
                        <option key={"0"+optindex} value={d.id}>{d.name}</option>
                      )}
                    </select>
                    {" "}
                    <span> live matrix table(s)</span>
                  </div>
                  <div
                    className="howtouse"
                    data-bs-toggle="collapse"
                    href="#ppboxHTML2"
                    role="button"
                    aria-expanded="false"
                    aria-controls="ppboxHTML2"
                    onClick={() => setOpenModal(true)}
                  >
                    What's this?
                  </div>
                  <div className="select_underlying">
                    <div className="select_underlying_text">
                      <span style={{ fontWeight: "bold" }}>OR</span> show warrants over
                    </div>
                    {underlying.length>0 &&
                      <div

                        style={{ textAlignLast: "left", width: "350px", }}>
                        <SelectComp
                          data={underlying}
                          placeholder="Select underlying"
                          getUnderlyingData={getUnderlyingData} 
                        />
                      </div>
                    }

                  </div>
                  {/* <div className="select_underlying">
                    <div className="select_underlying_text" >
                      <span style={{ fontWeight: "bold" }}>OR</span> show
                      warrants over
                    </div>

                    <div style={{ textAlignLast: "center", width: "350px", }}>
                      <SelectComp
                        data={Sector}
                        placeholder="Select sector"
                        getUnderlyingData={setSector}
                      />
                    </div>

                  </div> */}
                </div>
                <div id="lmTabs" className="tablist">
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td
                            onClick={() => setActiveTab("liveMatrixPlus")}
                            className={`${isActive("liveMatrixPlus")}`}
                          >
                            <div
                              data-toggle="tab"
                              className="tablist-item"
                              value="lmp"
                            >
                              <div className="tablist-item-txt">
                                Live Matrix+
                              </div>
                            </div>
                          </td>
                          <td style={{ width: 10 }} />
                          <td
                            onClick={() => setActiveTab("quickTerms")}
                            className={`${isActive("quickTerms")}`}
                          >
                            <div
                              data-toggle="tab"
                              className="tablist-item"
                              value="qt"
                            >
                              <div className="tablist-item-txt">
                                Quick Terms
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pbtnarea" style={{ margin: "auto 0" }}>
                    <div className="pmarea">
                      {page < 9 && <div
                        className="plusbtn"
                        onMouseEnter={() => showInrementData()}
                        onMouseLeave={() => showInrementData()}
                        data-tip
                        data-for={"tooltip_1img"}
                      >
                        {/* {incrementTooltip && <p style={{ backgroundColor: "#FECC0A", position: "absolute", marginTop: "-17px", width: "250px" }}>Quick add another live matrix table</p>} */}
                        <img
                          className="ttip"
                          data-target="plusbtn"
                          src="/img/bg/plusButtonBlue.png"
                          data-hasqtip={0}
                          onClick={() => page < 9 && increase()}
                        />
                      </div>}
                      <ReactTooltip
                          id={"tooltip_1img"}
                          delayHide={200}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                            Quick add another live matrix table
                            </p>
                          </div>
                        </ReactTooltip>
                      {page >= 2 &&
                      <>
                        <div
                          className="minusbtn"
                          onMouseEnter={() => showDecrementData()}
                          onMouseLeave={() => showDecrementData()}
                          data-tip
                          data-for={"tooltip_2img"}
                        >
                          {/* {decrementTooltip && <p style={{ backgroundColor: "#FECC0A", position: "absolute", marginTop: "-17px", width: "250px",zIndex:2  }}>Quick hide the last live matrix table</p>} */}
                          <img
                            className="ttip"
                            data-target="minusbtn"
                            src="/img/bg/minusButtonBlue.png"
                            data-hasqtip={1}
                            onClick={() => page >= 2 && decrease()}
                          />
                        </div>
                        <ReactTooltip
                          id={"tooltip_2img"}
                          delayHide={200}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                            <div className="bg-tooltip">
                              <p className="tooltip-content">
                                Quick hide the last live matrix table
                              </p>
                            </div>
                        </ReactTooltip>
                        </>
                        }
                        
                      <div
                        className="resetbtn"
                        onMouseEnter={() => clearData()}
                        onMouseLeave={() => clearData()}
                        data-tip
                        data-for={"tooltip_3img"} 
                      >
                        {/* {clearTooltip && <p style={{ backgroundColor: "#FECC0A", position: "absolute", marginTop: "-17px", width: "250px",zIndex:2  }}>Clear all live matrix tables</p>} */}
                        <img
                          className="ttip"
                          data-target="resetbtn"
                          src="/img/bg/resetButton.png"
                          data-hasqtip={2}
                          onClick={() => clearAll()}
                        />
                      </div>
                      <ReactTooltip
                          id={"tooltip_3img"}
                          delayHide={200}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              Clear all live matrix tables
                            </p>
                          </div>
                        </ReactTooltip>
                      <div
                        className={refreshBtnDis === true ? "refreshbtn disabled" : "refreshbtn"}
                        value="Refresh"
                        onMouseEnter={() => refreshData()}
                        onMouseLeave={() => refreshData()}
                        data-tip
                        data-for={"tooltip_4img"}
                      >
                        {/* {refreshTooltip && <p style={{ backgroundColor: "#FECC0A", position: "absolute", marginTop: "-17px", width: "250px",zIndex:2 }}>Refresh all live matrix tables</p>} */}
                        <img
                          className="ttip"
                          data-target="refreshbtn"
                          src="/img/bg/refreshButton.png"
                          data-hasqtip={3}
                          onClick={() => {
                            setResultName([])
                            setResult([])
                            getLiveMatrixData(resultName.map((d) => d.ric)[resultName.length - 1], 0)
                          }}
                        />
                      </div>
                      <ReactTooltip
                          id={"tooltip_4img"}
                          delayHide={200}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p className="tooltip-content">
                              Refresh all live matrix tables
                            </p>
                          </div>
                        </ReactTooltip>
                    </div>
                  </div>
                </div>
                <div className="row align-items-start">
                  {total && total.map((d, i) =>
                    <LiveMatrixCard
                      hashArray={hashArray[i]}   
                      page={page}
                      setRic={setRic}
                      setLocalRic={setLocalRic}
                      setResultName={setResultName}
                      setResult={setResult}
                      setLoad={setLoad}
                      symbol={symbol}
                      resultName={resultName}
                      activeTab={activeTab}
                      load={load}
                      result={result}
                      lastUpdated={lastUpdated}
                      hotList={hotList}
                      localRic={localRic}
                      index={i}
                      setCardNo={setCardNo}
                      manageUrl={manageUrl}
                      cdata={cdata}
                      cardNo={cardNo}
                      loading={loading}
                      inputValWarr={inputValWarr}
                      recogniaData={recogniaData}
                      dataSymbol={dataSymbol}
                      contractData={contractData}
                      sp500Contract={sp500Contract}
                      futureName={futureName}
                      clearName={clearName}
                    />
                  )}
                </div>
                <div style={{ clear: "both" }} />
                <div id="bottom_area" className="livematrix_valid">
                  <ToolTipData />
                  <FooterData />
                </div>
              </div>
            </div>
              <div className="col-md-4 right-view sm-none xs-none">
                <style dangerouslySetInnerHTML={{ __html: "\n\t\t\t\t\t" }} />

                <div className="pbtnarea right-side xs-none">
                  <div className="pmarea">
                    <ul className="list-unstyled">
                      {page < 9 && <li>
                        <div className="plusbtn">
                          <img
                            className="ttip"
                            data-target="plusbtn"
                            src="/img/bg/plusButtonBlue.png"
                            data-hasqtip={0}
                            onClick={() => page < 9 && increase()}
                          />
                        </div>
                      </li>}
                      {page >= 2 &&
                        <li>
                          <div className="minusbtn">
                            <img
                              className="ttip"
                              data-target="minusbtn"
                              src="/img/bg/minusButtonBlue.png"
                              data-hasqtip={1}
                              onClick={() => page >= 2 && decrease()}
                            />
                          </div>
                        </li>
                      }
                      <li>
                        <div 
                        className="resetbtn" 
                        onMouseEnter={() => clearData()}
                        onMouseLeave={() => clearData()}
                        >
                          <img
                            className="ttip"
                            data-target="resetbtn"
                            src="/img/bg/resetButton.png"
                            data-hasqtip={2}
                            onClick={() => clearAll()}
                          />
                        </div>
                      </li>
                      <li>
                        <div className={refreshBtnDis === true ? "refreshbtn disabled" : "refreshbtn"} value="Refresh">
                          <img
                            className="ttip"
                            data-target="refreshbtn"
                            src="/img/bg/refreshButton.png"
                            data-hasqtip={3}
                            onClick={() => {
                              setResultName([])
                              setResult([])
                              getLiveMatrixData(resultName.map((d) => d.ric)[resultName.length - 1], 0)
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
        {!total || total.length <2 && <>
        <div id="lastview" className="section qlink-section">
          <div className="page-header">
            <h2 className="small">Related warrants</h2>
          </div>
          <div id="relatedDWTbl">
            <table className="table table-closed">
              <thead>
                <tr>
                  <th>​​Warrant ​​n​ame</th>
                  <th>Bid (MYR)</th>
                  <th>Eff. gearing (x)</th>
                </tr>
              </thead>
              <tbody>
                {rwList &&
                  rwList.map((rwListData, rwListIdx) => (
                    <tr
                      className="rowtmpl striped"
                      style={{}}
                      key={"4"+rwListIdx}
                    >
                      <td className="col_tkr">
                        <Link
                          target="_blank"
                          to={`/tools/livematrix/${rwListData.ticker}`}
                        >
                          {rwListData.dsply_nmll}
                        </Link>
                      </td>
                      <td
                        className="col_bd"
                        style={{ textAlign: "center" }}
                      >
                        {rwListData.BID}
                      </td>
                      <td
                        className="col_eg"
                        style={{ textAlign: "center" }}
                      >
                        {rwListData.effective_gearing}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="page-header">
            <h2 className="small">Last viewed</h2>
          </div>
          <div id="lastViewTbl">
            <table className="table table-striped table-closed">
              <thead>
                <tr>
                  <th>​​Warrant ​​n​ame</th>
                  <th>Bid (MYR)</th>
                  <th>Eff. gearing (x)</th>
                </tr>
              </thead>
              <tbody>
               
                {lvList.length >= 1 ?
                  lvList.map((lvListData, lvListIdx) => (
                    <tr
                      className="rowtmpl"
                      style={{}}
                      key={"1"+lvListIdx}
                    >
                      <td className="col_tkr">
                        <Link
                          target="_blank"
                          to={`/tools/livematrix/${lvListData.ticker}`}
                        >
                          {lvListData.dsply_nmll}
                        </Link>
                      </td>
                      <td
                        className="col_bd"
                        style={{ textAlign: "center" }}
                      >
                        {lvListData.BID}
                      </td>
                      <td
                        className="col_eg"
                        style={{ textAlign: "center" }}
                      >
                        {lvListData.effective_gearing}
                      </td>
                    </tr>
                  )) :  <tr className="emptymsg"><td colSpan="4" style={{textAlign: 'center'}}>No last view</td></tr>}
                  
              </tbody>
            </table>
          </div>
        </div>

        <Slider {...settings} className="ad-section custom">
        {advImgVal && advImgVal.map((data, indx) => { return (
          <div key={"3"+indx}>
            <a
              className="homeimg-achor home_banner1"
              target="_blank"
              href={data.link}
            >
              <img
                className='ad-img'
                src={data.imgSrc}
              />
            </a>
          </div>)})}
          </Slider> 
        <LeftSideBar />
        </>}
              </div>
          </div>
        </div>
      </div>
      <LiveMatrixModal
        Open={openModal}
        setOpenModal={setOpenModal}
      />
      <JoinTelegramModal
        Url={url}
        Open={open}
        OpenModal={() => setOpen(!true)}
      />
    </>
  );
};
export default LiveMatrix;
