import React, { useState, useEffect } from 'react';
import "../warrantDocument.css";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import API from '../../../../utils/apiCalling';
import { config, Base_Url } from '../../../../utils/apiUrl';
import { get } from 'lodash';



const TermsheetTabs = ({ WarrantDocumentsTermsheet, SetWarrantDocumentsTermsheet }) => {
  const api = new API()

  const [searchListData, setSearchListData] = useState([]);
  const [serachKey, setSerachKey] = useState("");
  const [serachSection, setSerachSection] = useState(false);
  const [selectList, setSerachList] = useState([]);
  const [selectListKey, setSelectListKey] = useState("");
  const [dataList, setdataList] = useState(null);



  useEffect(() => {
    getSearchListData()
    setdataList(WarrantDocumentsTermsheet)
  }, [serachKey])


  const getSearchListData = async () => {
    let data = {
      key: serachKey,
      limit: 10,
      d: "1646208378443",
      type: "w"
    }
    let WarrantChartResult = await api.get(config.warrantPowerSearch, true, data)
    let warrantChartRes = get(WarrantChartResult, "powersearch", [])
    setSearchListData(warrantChartRes)
  }

  const getSerachSection = (e) => {
    const { value } = e;
    setSerachKey(value)
    setSerachSection(true)
  }



  const filterData = (selectSearchKey) => {
    let tempArr = WarrantDocumentsTermsheet.filter((data) => data.ticker === selectSearchKey);
    setSerachSection(false)
    // SetWarrantDocumentsTermsheet(tempArr)
    setdataList(tempArr)
  }

  const selectRowData = () => {
    return (
      searchListData && searchListData.map((data, idx) => (
        <tr className="rowtmpl_termsheet" onClick={() => filterData(data.ticker)} key={idx}>
          {/* <td>{`${highlight(data.name)} ${"("} ${data.ticker} ${")"}`}</td> */}
          <td>{highlight(data.name)} {"("}{data.ticker}{")"}</td>
        </tr>
      ))
    )
  }

  const highlight = (text) => {
    var a = text.split(serachKey.toUpperCase())
    if (a.length === 1) {
      var finalText = <span>{a[0]}<span style={{ color: "red", fontWeight: "600" }}>{serachKey.toUpperCase()}</span></span>
    }
    else {
      var finalText = <span>{a[0]}<span style={{ color: "red", fontWeight: "600" }}>{serachKey.toUpperCase()}</span>{a[1]}</span>
    }
    return finalText
  }

  return (

    <div id="termsheet" className="tabcontent" >
      <div className="input-group ">
        <input type="text" style={{ textAlign: "center", borderRight: "transparent" }}
          className="form-control input-text " name={"serachKey"} value={serachKey} onChange={(e) => getSerachSection(e.target)}
          placeholder="Search warrant symbol"
          aria-label="Recipient's username" aria-describedby="basic-addon2" />

        <div className="input-group-append">
          <button style={{
            border: "1px solid #aeaeae",
            borderLeftColor: "transparent"
          }} className="btn btn-lg" type="button">
            <i className="fa fa-search" />
          </button>
        </div>

      </div>

      {serachSection ?
        <div className='srch-div'>
          <table className="table table-striped">
            <tbody>
              {selectRowData()}
              {searchListData && searchListData.length === 0 &&
                <tr className='emptymsg'>
                  <td colspan="5">No related term sheets</td>
                </tr>
              }
            </tbody>
          </table>
        </div> : null}

      <table className="table table-striped">
        <thead>
          <tr>
            <th className="text-center" >Warrant code</th>
            <th className="text-center">Warrant name</th>
            <th className="text-center">Maturity date</th>
            <th className="text-center">Exercise price</th>
            <th className="text-center">Listing document</th>
          </tr>
        </thead>
        <tbody>
          {dataList && dataList.map((data, idx) => (
            <tr className="rowtmpl_termsheet" key={idx}>
              <td className="ticker">
                <Link to={`${"/tools/livematrix/"}${data.ticker}`}>{data.ticker}</Link>
              </td>
              <td className="dsply_nmll">
                <Link to={`${"/tools/livematrix/"}${data.ticker}`}>{data.dsply_nmll}</Link>
              </td>
              <td className="maturity">{(moment(data.maturity).format("DD MMM YY"))}</td>
              <td className="exercise_price">{data.exercise_price}</td>
              <td className="view">

                <a
                  target="_blank"
                  className="additional_pdf "
                  href={`${Base_Url}/apimqmy/pdf?type=termsheet&id=${data.ticker}`}
                  data-target="additional_pdf"
                >
                  <img src="/img/bullet/pdf.png" />
                </a>
              </td>
            </tr>
          ))}
          {dataList && dataList.length === 0 &&
            <tr className='emptymsg'>
              <td colspan="5">No related term sheets</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
}

export default TermsheetTabs;