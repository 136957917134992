import React from "react"
import { Modal, ModalBody } from 'reactstrap';
function SubmitModalGain({ open }) {
    return (
        <>
            <Modal style={{ position: 'relative' }} centered isOpen={open} className="modal-lg mt-5 success-speak">
                <ModalBody >
                    <h4>You have successfully registered for our daily newsletter!</h4>
                </ModalBody>
            </Modal>
        </>
    )
}
export default SubmitModalGain;
