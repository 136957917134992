import React, {Component} from "react";
import classNames from "classnames";
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import {config} from "../../../utils/apiUrl"
import indicators from 'highcharts/indicators/indicators';
import rsi from 'highcharts/indicators/rsi';
import macd from 'highcharts/indicators/macd'
import bb from 'highcharts/indicators/bollinger-bands'
import ema from 'highcharts/indicators/ema';
indicators(Highcharts);
rsi(Highcharts)
macd(Highcharts)
bb(Highcharts)
ema(Highcharts)
export default class Chart extends Component {
    constructor(ricValue) {
      super(ricValue);
      this.setTextInputRef = (element) => {
        this.textInput = element;
      };
      var ricValue1 = ricValue.ricValue
      this.state = {
        dateClicked: "D",
        timeClicked: "10",
        chartClicked: "ohlc",
        len1: 12,
        len2: 26,
        avg: 9,
        bolvalue: 20,
        rsiValue: 14,
        ric: ricValue1,
        first: '',
        isBol: false,
      };
    }


    componentDidMount() {
      this.getUnderlyingData();
    }
  
    dateClick = (val) => {
      this.setState(
        {
          dateClicked: val,
        },
        function () {
          this.getUnderlyingData();
        }
      );
    };
  
    timeClick = (val) => {
      this.setState(
        {
          timeClicked: val,
        },
        () => {
          this.getUnderlyingData();
        }
      );
    };
  
    chartClick = (val) => {
      this.setState(
        {
          chartClicked: val,
          first: 'val'
        },
        () => {
          this.getUnderlyingData();
        }
      );
    };
    isBolCheck = (e) => {
      this.setState({ isBol: e.target.checked }, () => {
        if (this.state.isBol) {
          this.getUnderlyingData();
        }
      });
    };
    changeBolValue = (e) => {
      this.setState({ bolvalue: e.target.value }, () => {
        if (this.state.isBol) {
          this.getUnderlyingData();
        }
      });
    };
    changeMacdValue = (type, e) => {
      this.setState({ [type]: e.target.value }, () => {
        this.getUnderlyingData();
      });
    };
    changeRsiValue = (e) => {
      this.setState({ rsiValue: e.target.value }, () => {
        this.getUnderlyingData();
      });
    };

    getUnderlyingData = (ric) => {
      var RIC;
      if (ric) {
        this.setState({ ric: ric });
        RIC = ric;
      } else {
        RIC = this.state.ric;
      }
      var that = this;
      const url = config.getUnderlying+"?period=" + this.state.dateClicked + "&ric=" + RIC;
      fetch(url)
        .then((res) => {
          return res.json()
        }
        )
        .then((result) => {
          var data = result.data,
            ohlc = [],
            line = [],
            volume = [],
            open = [],
            startVal = 100,
            endVal = 100;
          // + + 28800000
          for (var i = 0; i < data.length; i++) {
            if (this.state.dateClicked == 'D') {
              var timeVal =
                new Date(data[i]["time"]).getTime() + 28800000;
            } else {
              var timeVal =
                new Date(data[i]["time"]).getTime();
            }
  
            line.push([
              timeVal, // the date
              Number(data[i]["open"]), // open
              Number(data[i]["high"]), // high
              Number(data[i]["low"]), // low
              Number(data[i]["close"]), // close
            ]);
            volume.push([
              timeVal, // the date
              Number(data[i]["volume"]), // the volume
            ]);
            open.push(data[i]["open"]);
          }
          startVal = Math.min.apply(null, open) - startVal;
          endVal = endVal + Math.max.apply(null, open);
          var that = this;
          var types = '';
          if (that.state.first == '') {
            if (that.state.dateClicked == 'Y') {
              types = 'ohlc'
            } else {
              types = 'candlestick'
            }
          } else {
            types = that.state.chartClicked;
          }
          if (this.textInput) {
            Highcharts.stockChart(this.textInput, {
              rangeSelector: {
                enabled: false,
              },
              time: {
                //                        timezone:'Thailand',
                timezoneOffset: 0,
                useUTC: true,
              },
              chart: {
                marginRight: 30,
              },
              xAxis: {
                gridLineWidth: 1,
                lineWidth: 1,
                gridLineColor: "#A8A8A8",
                lineColor: "#A8A8A8",
                crosshair: {
                  color: "#000",
                },
                type: "datetime",
                labels: {
                  formatter: function () {
                    var date = '';
                    if (that.state.dateClicked == "D") {
                      date = Highcharts.dateFormat('%H:%M', this.value)
                    } else if (that.state.dateClicked == "W") {
                      date = Highcharts.dateFormat('%m/%d', this.value)
                    } else {
                      date = Highcharts.dateFormat('%y/%m', this.value)
                    }
                    return date
                  }
                },
                dateTimeLabelFormats: {
                  day: '%d/%m/%y',
                }
              },
              yAxis: [
                {
                  height: "59%",
                  resize: {
                    enabled: true,
                  },
                  lineWidth: 1,
                  // offset: 40,
                  gridLineColor: "#A8A8A8",
                  lineColor: "#A8A8A8",
                  labels: {
                    x: 30,
                  },
                },
                {
                  lineWidth: 1,
                  height: "9%",
                  top: "60%",
                  gridLineColor: "#A8A8A8",
                  lineColor: "#A8A8A8",
                  labels: {
                    x: 30,
                  },
                },
                {
                  lineWidth: 1,
                  height: "14%",
                  top: "70%",
                  gridLineColor: "#A8A8A8",
                  lineColor: "#A8A8A8",
                  labels: {
                    x: 30,
                  },
                },
                {
                  lineWidth: 1,
                  height: "15%",
                  top: "85%",
                  gridLineColor: "#A8A8A8",
                  lineColor: "#A8A8A8",
                  labels: {
                    x: 30,
                  },
                },
              ],
              plotOptions: {
                ohlc: {
                  tooltip: {
                    pointFormat: '<span style={{font-size:17px}}>O:{point.open},H:{point.high},L:{point.low},C:{point.close}</span>,Time:{point.key}',
                  }
                },
                area: {
                  tooltip: {
                    pointFormat: '<span style={{font-size:17px}}>O:{point.open},H:{point.high},L:{point.low},C:{point.close}</span>,Time:{point.key}',
                  },
                  fillOpacity: 0.2,
                  lineWidth: 0,
                  marker: {
                    enabled: false,
                    states: {
                      hover: {
                        enabled: true,
                        radius: 5,
                      },
                    },
                  },
                  shadow: false,
                  states: {
                    hover: {
                      lineWidth: 1,
                    },
                  },
                  threshold: null,
                },
                candlestick: {
                  tooltip: {
                    pointFormat: '<span style={{font-size:17px}}>O:{point.open},H:{point.high},L:{point.low},C:{point.close}</span>,Time:{point.key}',
                  }
                },
                series: {
                  showInLegend: false,
                  dataGrouping: {
                    units: [[
                      'millisecond', // unit name
                      [1, 2, 5, 10, 20, 25, 50, 100, 200, 500] // allowed multiples
                    ], [
                      'second',
                      [1, 2, 5, 10, 15, 30]
                    ], [
                      'minute',
                      [1]
                    ], [
                      'hour',
                      [1, 2, 3, 4, 6, 8, 12]
                    ], [
                      'day',
                      [1]
                    ], [
                      'week',
                      [1]
                    ], [
                      'month',
                      [1, 3, 6]
                    ], [
                      'year',
                      null
                    ]]
                  }
                },
                column: {
                  showInLegend: false,
                },
                scatter: {
                  marker: {
                    symbol: "triangle-down",
                  },
                },
              },
              tooltip: {
                // animation: true,
                // shape: "square",
                // headerShape: "callout",
                // borderWidth: 0,
                // backgroundColor: "#FECC0A",
                // shadow: false,
                // headerFormat: "",
                backgroundColor: '#fff',
                shape: 'square',
                headerShape: 'callout',
                borderWidth: 0,
                shadow: false,
                positioner: function (width, height, point) {
                  var chart = this.chart,
                    position;
                  if (point.isHeader) {
                    position = {
                      x: Math.max(
                        // Left side limit
                        chart.plotLeft,
                        Math.min(
                          point.plotX + chart.plotLeft - width / 2,
                          // Right side limit
                          chart.chartWidth - width - chart.marginRight
                        )
                      ),
                      y: point.plotY
                    };
                  } else {
                    position = {
                      x: point.series.chart.plotLeft,
                      y: point.series.yAxis.top - chart.plotTop
                    };
                  }
                  return position;
                }
              },
              credits: { enabled: false }, // Close copyright notice
              navigator: { enabled: false }, //Turn off navigator
              scrollbar: { enabled: false }, //Close scrollbar
              series: [
                {
                  // type: "ohlc",
                  type: types,
                  name: "AAPL",
                  id: "aapl",
                  color: 'red',
                  lineColor: 'red',
                  upColor: 'green',
                  upLineColor: 'green',
                  zIndex: 2,
                  data: line,
                  color: that.state.chartClicked == 'area' ? '#8B8CCB' : "#FF0909",
  
                },
                {
                  type: "ema",
                  zIndex: 1,
                  linkedTo: "aapl",
                  color: "#FECC69",
                  marker: {
                    enabled: false,
                  },
                  params: {
                    index: 0,
                    period: that.state.timeClicked,
                  },
                  tooltip: {
                    pointFormat: '<b>Moving Average(MA)</b>  <span style="color:{series.color}">{point.y}</span>',
                  }
                },
                {
                  type: "column",
                  name: "Volume",
                  id: "volume",
                  data: volume,
                  yAxis: 1,
                  color: "#8B8B8B",
                  tooltip: {
                    pointFormat: 'Vol:{point.y}',
                    // {series.name}: 
                  }
                },
                {
                  yAxis: 2,
                  type: "rsi",
                  linkedTo: "aapl",
                  color: "#FEDC56",
                  marker: {
                    enabled: false,
                  },
                  params: {
                    index: 0,
                    period: that.state.rsiValue,
                  },
                  tooltip: {
                    pointFormat: '<b>Relative Strength Index(RSI)</b>  <span style="color:{series.color}">{point.y}</span>',
                  }
                },
                {
                  yAxis: 3,
                  type: "macd",
                  linkedTo: "aapl",
                  color: "#F13232",
                  params: {
                    shortPeriod: that.state.len1,
                    longPeriod: that.state.len2,
                    signalPeriod: that.state.avg,
                  },
                  marker: {
                    enabled: false,
                  },
                  tooltip: {
                    // pointFormat: '<b>Moving Average Convergence Divergence(MACD)</b>  <span style="color:{series.color}">{point.y}</span>',
                    // pointFormatter() {
                    // }
                  }
                },
                {
                  type: "bb",
                  topLine: {
                    styles: {
                      lineColor: "yellow",
                    },
                  },
                  bottomLine: {
                    styles: {
                      lineColor: "purple",
                    },
                  },
                  color: "#FEDC56",
                  linkedTo: "aapl",
                  //                                        data: ohlc,
                  visible: that.state.isBol,
                  name: "BOL",
                  tooltip: {
                    pointFormat:
                      '<span style="color:{point.color}">\u25CF</span> <b> {series.name}</b><br/>' +
                      "Upper Band：{point.top}<br/>" +
                      "Middle Band：{point.middle}<br/>" +
                      "Lower Band：{point.bottom}<br/>",
                  },
                  params: {
                    period: that.state.bolvalue,
                  },
                },
              ],
              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: "100%",
                    },
                    chartOptions: {
                      rangeSelector: {
                        inputEnabled: false,
                      },
                    },
                  },
                ],
              },
            });
          }
        });
    };
    render() {
      return (
        <div>
          <table id="periodtab" className="ctab">
            <tbody>
              <tr>
                <td
                  className={classNames(
                    { active: this.state.dateClicked == "D" },
                    "ctab-item"
                  )}
                  onClick={() => this.dateClick("D")}
                >
                  1D
                </td>
                <td className="ctab-item-space">&nbsp;</td>
                <td
                  className={classNames(
                    { active: this.state.dateClicked == "W" },
                    "ctab-item"
                  )}
                  onClick={() => this.dateClick("W")}
                >
                  1W
                </td>
                <td className="ctab-item-space">&nbsp;</td>
                <td
                  className={classNames(
                    { active: this.state.dateClicked == "M" },
                    "ctab-item"
                  )}
                  onClick={() => this.dateClick("M")}
                >
                  1M
                </td>
                <td className="ctab-item-space">&nbsp;</td>
                <td
                  className={classNames(
                    { active: this.state.dateClicked == "Y" },
                    "ctab-item"
                  )}
                  onClick={() => this.dateClick("Y")}
                >
                  MAX
                </td>
              </tr>
            </tbody>
          </table>
          <div id="chartbox" style={{ width: "100%" }}>
            <div className="chartFrame" style={{ height: "750px" }}>
              <div
                id="chartID"
                ref={this.setTextInputRef("chartID")}
                style={{ width: "100%", height: "700px" }}
              ></div>
            </div>
            <div className="ta_panel">
              <div>
                <div className="row">
                  <div className="col-sm-4">Moving Average (MA)</div>
                  <div className="col-sm-8">
                    <table className="MAtab ctab">
                      <tbody>
                        <tr>
                          <td
                            className={classNames(
                              { active: this.state.timeClicked == "10" },
                              "ctab-item"
                            )}
                            onClick={() => this.timeClick("10")}
                          >
                            10D
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              { active: this.state.timeClicked == "20" },
                              "ctab-item"
                            )}
                            onClick={() => this.timeClick("20")}
                          >
                            20D
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              { active: this.state.timeClicked == "50" },
                              "ctab-item"
                            )}
                            onClick={() => this.timeClick("50")}
                          >
                            50D
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              { active: this.state.timeClicked == "100" },
                              "ctab-item"
                            )}
                            onClick={() => this.timeClick("100")}
                          >
                            100D
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              { active: this.state.timeClicked == "200" },
                              "ctab-item"
                            )}
                            onClick={() => this.timeClick("200")}
                          >
                            200D
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Chart type</div>
                  <div className="col-sm-8">
                    <table className="typetab ctab">
                      <tbody>
                        <tr>
                          <td
                            className={classNames(
                              { active: this.state.chartClicked === "ohlc" },
                              "ctab-item"
                            )}
                            onClick={() => this.chartClick("ohlc")}
                          >
                            <img src="/img/bullet/bar.png" alt="" />
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              { active: this.state.chartClicked === "area" },
                              "ctab-item"
                            )}
                            onClick={() => this.chartClick("area")}
                          >
                            <img src="/img/bullet/line.png" alt="" />
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              {
                                active: this.state.chartClicked === "candlestick",
                              },
                              "ctab-item"
                            )}
                            onClick={() => this.chartClick("candlestick")}
                          >
                            <img src="/img/bullet/candle.png" alt="" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-sm-4">Relative Strength Index (RSI)</div>
                  <div className="col-sm-8">
                    <input
                      id="rsiValue"
                      className="txtboxs ui-autocomplete-input"
                      autoComplete="off"
                      role="textbox"
                      aria-autocomplete="list"
                      aria-haspopup="true"
                      data-original-title=""
                      defaultValue={14}
                      onChange={this.changeRsiValue}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    Moving Average Convergence
                    <br />
                    Divergence (MACD)
                  </div>
                  <div className="col-sm-8">
                    <table className="txttbl">
                      <tbody>
                        <tr>
                          <td className="txttbl_lbl">Len 1</td>
                          <td className="txttbl_input">
                            <input
                              id="macdLen1"
                              title=""
                              placeholder=""
                              className="macdValue txtboxs ui-autocomplete-input"
                              autoComplete="off"
                              role="textbox"
                              aria-autocomplete="list"
                              aria-haspopup="true"
                              data-original-title=""
                              defaultValue={12}
                              onChange={(e) => this.changeMacdValue("len1", e)}
                            />
                          </td>
                          <td className="txttbl_lbl">Len 2</td>
                          <td className="txttbl_input">
                            <input
                              id="macdLen2"
                              title=""
                              placeholder=""
                              className="macdValue txtboxs ui-autocomplete-input"
                              autoComplete="off"
                              role="textbox"
                              aria-autocomplete="list"
                              aria-haspopup="true"
                              data-original-title=""
                              defaultValue={26}
                              onChange={(e) => this.changeMacdValue("len2", e)}
                            />
                          </td>
                          <td className="txttbl_lbl">Avg</td>
                          <td className="txttbl_input last">
                            <input
                              id="macdAvg"
                              title=""
                              placeholder=""
                              className="macdValue txtboxs ui-autocomplete-input "
                              autoComplete="off"
                              role="textbox"
                              aria-autocomplete="list"
                              aria-haspopup="true"
                              data-original-title=""
                              defaultValue={9}
                              onChange={(e) => this.changeMacdValue("avg", e)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Bollinger (BOL)</div>
                  <div className="col-sm-8">
                    <div className="input-group txtcheckbox">
                      <input
                        id="bollingerValue"
                        min="0"
                        max="100"
                        title=""
                        placeholder=""
                        className="txtboxs ui-autocomplete-input"
                        autoComplete="off"
                        role="textbox"
                        aria-autocomplete="list"
                        aria-haspopup="true"
                        data-original-title=""
                        defaultValue={20}
                        onChange={this.changeBolValue}
                      />
                      {/* readOnly */}
                      <input
                        id="isBollingerCheckBox"
                        className="checkbox"
                        type="checkbox"
                        onChange={this.isBolCheck}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }