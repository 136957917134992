import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
function Guest2() {
  const refreshPage = () => {
    window.location.reload(false);
  };
  return (
    <div className="videorack-body">
      <div className="videorack-content swiper-container swiper-container-horizontal">
        <div className="swiper-wrapper">
          <div
            className="swiper-slide swiper-slide-active"
            style={{
              paddingLeft: "30px",
              paddingRight: "35px",
              // width: "1090px",
            }}
          >
            <Carousel
              showArrows={true}
              emulateTouch
              showStatus={false}
              autoFocus
              infiniteLoop={false}
              showIndicators={false}
            >
            <div>
              <table className="videorack-page ">
                <tbody>
                  <tr>
                    <td>
                      <div
                        playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                        index={0}
                        className="videorack-item"
                        style={{ display: "inline-block" }}
                        onClick={refreshPage}
                      >
                        <Link  to="/education/warrantvideosind/Umkmj5NGOBo/12">
                        <img
                          style={{ width: "100%" }}
                          src="https://i.ytimg.com/vi/Umkmj5NGOBo/mqdefault.jpg"
                        />
                        <div className="videorack-item-txt">
                          <span className="text-bold">Success Speaks:</span>{" "}
                          Joey Ng turbulence
                        </div>
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div
                        playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                        index={1}
                        className="videorack-item"
                        style={{ display: "inline-block" }}
                      >
                        <Link  to="/education/warrantvideosind/1sI5l5-CsVs/51">
                        <img
                          style={{ width: "100%" }}
                          src="https://i.ytimg.com/vi/1sI5l5-CsVs/mqdefault.jpg"
                        />
                        <div className="videorack-item-txt">
                          <span className="text-bold">Success Speaks:</span>{" "}
                          Jason Wong
                        </div>
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div
                        playlistid="/img/speaker/TK.png"
                        index={2}
                        className="videorack-item"
                        style={{ display: "inline-block" }}
                      >
                        <Link  to="/education/warrantvideosind/Jnjt8d7XTKo/52">
                        <img
                          style={{ width: "100%" }}
                          src="/img/speaker/TK.png"
                        />
                        <div className="videorack-item-txt">
                          <span className="text-bold">Success Speaks:</span> TK
                          Tan Tan
                        </div>
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guest2;
