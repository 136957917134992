import React from "react"
import { Link } from "react-router-dom";

export default function LeftSideBar() {
  return (
    <>
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrant search</h2>
        </div>
        <p className="section-p">
          Find the right warrant according to your investment criteria.
        </p>
        <div className="section-qlink-img section-qlink-dwsearch">
          <Link
            to="/tools/warrantsearch"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" />
          </Link>
        </div>
      </div>
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrant terms</h2>
        </div>
        <p className="section-p">
          Everything you need to know about your selected warrant is
          found here!
        </p>
        <div className="section-qlink-img section-qlink-dwterm">
          <Link
            to="/tools/warrantterms"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" />
          </Link>
        </div>
      </div>
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrant calculator</h2>
        </div>
        <p className="section-p">
          Estimates your future warrant price given a change in
          underlying price, time or implied volatility
        </p>
        <div className="section-qlink-img section-qlink-dwcalculator">
          <Link
            to="/tools/warrantcalculator"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" />
          </Link>
        </div>
      </div>
    </>
  )
}
