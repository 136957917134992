import React, { useState, useEffect } from "react"
import { getWarrantIssue } from "../../../utils/WarrantIssue.api"
import LoaderSpin from "../../../components/common/LoaderSpin";
function WarrantIssue() {
    const [result, setResult] = useState()
    const [load, setLoad] = useState(false)
    const qid = Date.now()
    useEffect(() => getWarrant(), [])
    function getWarrant() {
        setLoad(true)
        getWarrantIssue(qid)
            .then((res) => {
                setResult(res.data.ranking)
                setLoad(false)
            })
    }
    return (
        <>
            <h2>Index warrants issued by Macquarie</h2>
            <table id="warranttbl">
                <thead>
                    <tr>
                        <th>Warrant Name</th>
                        <th>Underlying</th>
                        <th>Type</th>
                        <th className="xs-none">Last bid price</th>
                        <th className="xs-none">Change</th>
                        <th className="xs-none">Change (%)</th>
                        <th className="xs-none">Volume ('000)</th>
                        <th>Expiry</th>
                        <th className="xs-none">Last trading date</th>
                    </tr>
                </thead>
                <tbody>
                    {load && <div className="loader-div warrentComp_Loader xs-none">
                        <LoaderSpin isLoading={load} isVisible />
                    </div>}
                    {result && result.map((d) =>
                        <tr>
                            <td ><a target="_blank" href={`${"/tools/livematrix/"}${d.ticker}`}>{d.dsply_nmll}</a></td>
                            <td ><a target="_blank" href={`/tools/underlying/${d.underlying_ric}`}>{d.underlying_ticker}</a></td>
                            <td >{d.type}</td>
                            <td className="xs-none">{d.BID}</td>
                            <td className={d.BID_NETCHNG < 0 ? "hidden-xs col_BID_NETCHNG down xs-none" : d.BID_NETCHNG == "-" ? "hidden-xs col_BID_NETCHNG xs-none" : "hidden-xs col_BID_NETCHNG up xs-none"}>{d.BID_NETCHNG}</td>
                            <td className={d.BID_PCTCHNG < 0 ? "hidden-xs col_BID_PCTCHNG down xs-none" : d.BID_PCTCHNG == "-" ? "hidden-xs col_BID_PCTCHNG xs-none" : "hidden-xs col_BID_PCTCHNG up xs-none"}>{d.BID_PCTCHNG}</td>
                            <td className="xs-none">{d.ACVOL_1_SCALED}</td>
                            <td >{d.maturity}</td>
                            <td className="xs-none">{d.last_trading_date}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    )
}
export default WarrantIssue
