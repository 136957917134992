import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const LiveMatrixModal = (props) => {
    const {
        Open,
        setOpenModal
      } = props;
      
    return (     
        <Modal style={{ position: 'relative' }} 
        centered 
        isOpen={Open}
        toggle={()=>setOpenModal(false)}
        className="modal-lg mt-5 terms live-how">     
        <ModalBody >     
             <div className="popupbox-content"
              style={{ backgroundColor: "white" }}
            >
                  <div className="page-header" style={{ marginTop: 0 }}>
                    <h3 style={{ color: "black" }}>How to use the Live Matrix Plus</h3>
                  </div>
                  <div className="content">
                    We’ve added the following features to the live matrix for
                    your convenience:
                    <ul>
                      <li>
                        <span className="text-bold">Quick Terms tab</span> –
                        quickly view the warrant’s important terms
                      </li>
                      <li>
                        <span className="text-bold">
                          Indicators (new indicators added!)
                        </span>{" "}
                        – immediately know if a warrant is trending, new or
                        responsive, has high liquidity, effective gearing or
                        time decay, or if it is near expiry or sold-out
                      </li>
                      {/* <li>
                        <span className="text-bold">Technical chart</span> –
                        visit the Technical Event Lookup® chart for the
                        warrant’s underlying share or index
                      </li> */}
                      <li>
                        <span className="text-bold">Print button</span> – print
                        the live matrix table
                      </li>
                    </ul>
                    View{" "}
                    <span className="text-bold">
                      up to 9 live matrix tables
                    </span>{" "}
                    now with the new Live Matrix Plus! Simply select the number
                    of tables you would like displayed using the dropdown.
                    <br />
                    <img src="/img/livematrix/dropdown1.JPG" />
                    <br />
                    You can also <strong>select an underlying</strong> and the
                    Live Matrix Plus will automatically populate with Macquarie
                    warrants over that underlying (by highest liquidity and
                    excluding warrants which are worth less than RM0.020).
                    <br />
                    <br />
                    <img src="/img/livematrix/dropdown2.JPG" />
                    <br />
                    Alternatively, you can also quickly add or hide live matrix
                    tables with the plus (+) and minus (-) buttons, or you can
                    clear or refresh all live matrix tables with the reset and
                    refresh buttons. All these buttons are above the tables and
                    in the floating bar on your right hand side.
                    <br />
                    <img src="/img/livematrix/4buttons.png" />
                    <br />
                    Feel free to contact us for more information. <br />
                    <br />
                    Prefer to use the original Live Matrix? Click on the ‘Back
                    to the classic Live Matrix’ button at the top (for desktop)
                    or the link (for mobile).
                  </div>
                  <div style={{ textAlign: "center", marginTop: "10px", }}>
                    <input
                      className="btn btn-primary closebtn"
                      defaultValue="Close"
                      type="button"                    
                    onClick={()=>setOpenModal(false)}
                  />
                  </div>             
             </div>      
        </ModalBody>       
      </Modal> 
     );
}
export default LiveMatrixModal;
