import React, { useState, useEffect,Fragment } from 'react';
import TopTradedStock from './TopTradedStock';
import './tradedStock.css';


const TradedStock = (props) => {
    return ( 
        <Fragment>
        <TopTradedStock/>
        </Fragment>
     );
}
 

export default TradedStock;