import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"
import Nodata from "highcharts/modules/no-data-to-display";
import moment from "moment"

Nodata(Highcharts);
Timeline(Highcharts);

const ChartWarrant = (
  { period, dataArr, marketRic }
) => {
 useEffect(()=> [])
  const temp = dataArr.data
  var array = temp
  const array1 = [];
  const array2 = [];
  const currName = marketRic
  for (var i = 0; i < array.length; i++) {
    array1.push(array[i].time);
    array2.push(parseInt(array[i].close));
  }

  const array3= array1

  const style = {
    width: "300px",
    height: "470px",
  };
  const colors = {
    fill: "#777",
  };
//   useEffect(() => {
//     funtio()
//   }, [])
//   const funtio = () => {
//     Highcharts.setOptions({
//       lang: {
//         thousandsSep: ','
//       }
//     });
// }

  const options = {

    lang: {
      thousandsSep: ','
    },
    chart: {
     type: "area",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    credits: {
      text: "",
    },
    graphs:{
      fillAlphas: 0.2,
      fillColors: "#097CCF",
      lineColor: "#097CCF",
      negativeFillAlphas: 0,
    },
    tooltip: {
      useHTML: true,
      borderColor: '#097CCF',
      borderWidth: 2,
      style: {
        fontWeight: 800,
        fontSize: 13,
        zIndex: 100,
      },
      //pointFormat: `<b>Underlying Price (${currName}) :</b> {point.y:.f}`,
      formatter: function () {
        // The first returned item is the header, subsequent items are the
        // points
        return ['<b>' + this.x + '</b>'].concat(
            this.points ?
                this.points.map(function (point) {
                    return '<b>Underlying Price (' + currName + '): ' + point.y.toLocaleString();
                }) : []
        );
      },
      split: true,
      xDateFormat: '%d/%m/%y'
    },
    xAxis: {
      categories:array3.map((d)=>moment(d).format("DD/MM")),
      tickInterval: 33,
      title: {
        text: "",
      },
      crosshair: {
        width: 1,
        color: "red",
        zIndex: "10", 
      },
      gridLineColor: "#FAFAFA",
      gridLineWidth: 1,
      
    },
    yAxis: {
      lineWidth: 1,
      tickWidth: 1,
      gridLineColor: "#FAFAFA",
      gridLineWidth: 1,
      floor:Math.min(...array2),
      categories:array2.map((d)=>d),
      tickInterval: Math.min(...array2) >= 4000 ? 1000 : (Math.min(...array2) < 4000 && Math.min(...array2) > 3400) ? 400 : (Math.min(...array2) < 3399 && Math.min(...array2) > 1300) ? 30 : 2,
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    },
    plotOptions: {
      area: {
        lineWidth: 1,
        lineColor: '#097CCF',
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 0,
          states: {
            hover: {
              enabled: false,
              lineWidth: 0,
              lineWidthPlus: 0,
              radius: 0,
              radiusPlus: 0
            },
          },
        },
        series: {
          stacking: "normal",
        },
      },
    },
    series: [{
      showInLegend: false,
      name: "", 
      animation: false,
      fillColor: "#cce3f3",
      data: array2,
    }]
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        style={style}
      />
    </>
  );
}
export default ChartWarrant;
