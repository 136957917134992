import React, { useState, useEffect } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import Modal from '@material-ui/core/Modal';
import { Modal } from 'reactstrap';
import validator from 'validator'
import SubmitModalGain from "./SubmitModalGain"






const GainExpo = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const api = new API()

  // HK_CALL & PUI //
  const [gainExposureHKCall, setGainExposureHKCall] = useState([])
  const [gainExposureHKPut, setGainExposureHKPut] = useState([])
  // HSI_CALL & PUI //
  const [gainExposureHsiCall, setGainExposureHsiCall] = useState([])
  const [gainExposureHsiPut, setGainExposureHsiPut] = useState([])
  // HSTECH_CALL & PUI //
  const [gainExposureHsTechCall, setGainExposureHsTechCall] = useState([])
  const [gainExposureHsTechPut, setGainExposureHsTechPut] = useState([])

  const [email, setEmail] = useState()
  const [name, setName] = useState()
  const [emailError, setEmailError] = useState(false)
  const [openModal, setopenModal] = useState(false);
  const [openModal1, setopenModal1] = useState(false);
  const [openModal2, setopenModal2] = useState(false);
  const [openModal3, setopenModal3] = useState(false)
  const [openModal4, setopenModal4] = useState(false)



  useEffect(() => {
    getGainExposure()
  }, [])


  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  const validateEmail = (e) => {
    var email = e.target.value
    if (validator.isEmail(email)) {
      setEmail(email)
    } else {
      setEmailError(true)
      setEmail()
    }
  }

  function valueSubmit(e) {
    if (!email) {
      setEmailError(true)
    }
    else {
      onAgreeHandle(e)
    }
  }

  const onAgreeHandle = async (e) => {
    e.preventDefault();
    let data = {
      email: email,
      fname: name
    }
    let res = await api.post(`${config.getRegistration}${"?p=register"}`, data)
      .then((res) => {
        setopenModal3(false)
        setopenModal4(true)
        setTimeout(() => setopenModal4(false), [2500])
      })
      .catch((error) => console.log(error))
  }

  const getGainExposure = async () => {
    let gainExposureResult = await api.get(config.gainExposure, true, { isIndices: true, qid: Date.now() })

    // HK_CALL & PUI //
    let gainExposureHKCallRes = get(gainExposureResult, "HK_CALL", [])
    let gainExposureHKPutRes = get(gainExposureResult, "HK_PUI", [])
    setGainExposureHKCall(gainExposureHKCallRes)
    setGainExposureHKPut(gainExposureHKPutRes)

    // HSI_CALL & PUI //
    let gainExposureHsiCallRes = get(gainExposureResult, "HSI_CALL", [])
    let gainExposureHsiPutRes = get(gainExposureResult, "HSI_PUI", [])
    setGainExposureHsiCall(gainExposureHsiCallRes)
    setGainExposureHsiPut(gainExposureHsiPutRes)

    // HSTECH_CALL & PUI //
    let gainExposureHsTechCallRes = get(gainExposureResult, "HSTECH_CALL", [])
    let gainExposureHsTechPutRes = get(gainExposureResult, "HSTECH_PUT", [])
    setGainExposureHsTechCall(gainExposureHsTechCallRes)
    setGainExposureHsTechPut(gainExposureHsTechPutRes)

  }
  const redirect1 = (e) => {
    e.preventDefault()
    const section = document.querySelector("#section1");
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const redirect2 = (e) => {
    e.preventDefault()
    const section = document.querySelector("#section2");
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const redirect3 = (e) => {
    e.preventDefault()
    const section = document.querySelector("#section3");
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const isSticky = (e) => {
    const header = document.querySelector('#hshnav-bar');
    const scrollTop = window.scrollY;
    scrollTop >= 90 ? header.classList.add('hshnav-bar1') : header.classList.remove('hshnav-bar1');
  };


  const modalFirst = () => {
    setopenModal(true)
    document.body.classList.add('noscroll')
  }
  const modalFirstClose = () => {
    setopenModal(false)
    document.body.classList.remove('noscroll')
  }

  const modalSecond = () => {
    setopenModal1(true)
    document.body.classList.add('noscroll')
  }
  const modalSecondClose = () => {
    setopenModal1(false)
    document.body.classList.remove('noscroll')
  }

  const modalThird = () => {
    setopenModal2(true)
    document.body.classList.add('noscroll')
  }
  const modalThirdClose = () => {
    setopenModal2(false)
    document.body.classList.remove('noscroll')
  }

  


  



  return (
    <>
      <div id="hstech" className="pageobj">
        <div className="hsh-welcometop">
          <div className="hsh-video-wrapper">
            <div className="hsh-welcomeimgtop">
              <img className='hstech_desk_img' src="/img/hkunderlyings/banner.png" />
              <img className='hstech_mob_img' src="/img/hkunderlyings/banner_m.png" />
            </div>
          </div>
        </div>
        {/* xs-nav */}
        <div id="hshnav-bar" className="container-fluid hsh-xsnav hshnav-bar1">
          <div>
            <div style={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}>
              <div className="col-xs-5ths hsh-xsnav-box hsh-xsnav-box-what p-0" align="center" data-value="what">

                <img className="nav-img hstech_desk_img" src="/img/hkunderlyings/HSI_button.png" />
                <img className='hstech_mob_img' src="/img/hkunderlyings/HSI_button_mobile.png" />
                <a href="#section1" onClick={redirect1} >
                  <div className="hsh-navtxt">Hang Seng<br />Index</div>
                </a>
              </div>
              <div className="col-xs-5ths hsh-xsnav-box hsh-xsnav-box-grow p-0" data-value="grow">

                <img className="nav-img hstech_desk_img" src="/img/hkunderlyings/HSTECH_button.png" />
                <img className='hstech_mob_img' src="/img/hkunderlyings/HSTECH_button_mobile.png" />
                <a href="#section2" onClick={redirect2}>
                  <div className="hsh-navtxt">Hang Seng<br />TECH Index</div>
                </a>
              </div>
              <div className="col-xs-5ths hsh-xsnav-box hsh-xsnav-box-why p-0" data-value="why">

                <img className="nav-img hstech_desk_img" src="/img/hkunderlyings/HKSS_button.png" />
                <img className='hstech_mob_img' src="/img/hkunderlyings/HKSS_button_mobile.png" />
                <a href="#section3" onClick={redirect3}>
                  <div className="hsh-navtxt">Hong Kong<br />Shares</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*内容*/}
        <div className="container-fluid">
          <div className="row hestch-container">
            <div className="col-md-12 htc-main-left p-0">
              {/* left-one-blove-add */}
              {/* <div id="section1" style="position: relative;top: -165px;"></div>*/}
              <div className="mlarea-index" style={{ marginBottom: '20px' }}>
                <div>
                  Malaysian investors may now gain exposure to the <strong>broader Hong Kong market</strong> with structured warrants over the Hang Seng Index (HSI), to the <strong>Chinese technology sector</strong> with structured warrants over the Hang Seng TECH Index (HSTECH) and to <strong>specific Hong Kong listed companies</strong> with structured warrants over Hong Kong shares! Learn more about each underlying and their warrants below.
                  {/*                               <br><br>
                      Join our <strong>upcoming webinars</strong> for a chance to <strong>win a Mi TV, Mi Watch Lite and more!</strong> Each free half-day webinar provides fresh market insights and includes a session on structured warrants.*/}
                </div>
              </div>
              {/* left-one */}
              <div className="row" style={{ margin: 0 }}>
                <div id="carousel-example-generic" className="mrarea-one carousel slide center-block col-md-12 col-sm-12">
                  <div id="registration" style={{ position: 'absolute', top: '-52px' }} />
                  {/* Wrapper for slides */}
                  <div className="carousel-inner" role="listbox">
                    
                    <div className="item carousel-box active" style={{ minHeight: 'initial' }}>
                      <div className="carousel-caption">
                        <div style={{ marginTop: '20px', color: '#FECC09', fontSize: '36px', fontWeight: 600, letterSpacing: '0.1em' }}>Stay tuned for more webinars available soon!</div>
                        <div style={{ marginTop: '10px', fontSize: '24px' }}>Sign up for our newsletter to receive invites!</div>
                        {/* learn more */}
                        <div className="hstechbtn-area" style={{ marginTop: '50px' }}>
                          <div className="subscribebtn bgcolor-06" onClick={()=>setopenModal3(true)}>Subscribe</div>
                        </div>
                        <div id="hshSubscribe_area" className="hsh-overlay" style={{display : openModal3 ? "block": "none" }}>
                          <div className="hsh-overlay-inner">
                            <div style={{ position: 'relative' }}>
                              <div className="popupbox-content">
                                <img className="btn-close" style={{width:'auto', top: '40px', right: '40px' }} src="/img/bullet/icon-close-white.png"  onClick={() => setopenModal3(false)}/>
                                <div className="page-header">
                                  <h1>Subscribe to our newsletter</h1>
                                  <hr className="hstech-hr" style={{ marginTop: '10px' }} />
                                </div>
                                <input type="text" name="Name" placeholder="Name" value={name} onChange={(e) => {setName(e.target.value) }} />
                                
                                <input type="text" name="Email" placeholder="Email" value={email} onChange={(e) => {
                                    validateEmail(e)
                                    setEmailError(false)
                                  }} />
                                <div className="warning">
                                  {emailError && <span>Email is required. Please fill in.</span>}
                                </div>
                                <p className="small">
                                  By clicking “Submit”, you confirm that you would like to receive Macquarie’s daily newsletter and invitations for warrant seminars, and you acknowledge that Macquarie’s website administrators may have access to your personal data for the processing of such data on behalf of Macquarie Capital Securities Malaysia Sdn Bhd and other companies of Macquarie group (“Macquarie”). You may view Macquarie’s privacy policy with regard to collection and management of personal data via the links at the bottom of this page.
                                </p>
                                
                                <input type="button" id="submitBtn" onClick={(e) => { valueSubmit(e) }} defaultValue="Agree and submit" key="label.agreesubmit" />
                              </div>
                            </div>
                          </div>
                          <div id="text-content" style={{ display: 'none' }}>
                            <div className="text_subscribe_registration_success">You have successfully registered for our daily newsletter!</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
               
              </div>
              <div className="section">
                <div className="section_title">
                  <h2 style={{ marginTop: 0 }}>Why trade warrants over Hong Kong shares and indices?</h2>
                </div>
                <div className="hk_share_index_list">
                  <div className="hk_share_index">
                    <div className="hk_share_index_title">Hang Seng Index</div>
                    <div className="hk_share_index_point"><img className="left" src="/img/hkunderlyings/1-HSIicon-a.png" />Trade moves in the broader Hong Kong market</div>
                    <div className="hk_share_index_point"><img className="left" src="/img/hkunderlyings/1-HSIicon-b.png" />Serves as a proxy for the wider Asian markets</div>
                    <div className="hk_share_index_point"><img className="left" src="/img/hkunderlyings/1-HSIicon-c.png" />Reflects price movements of major sectors</div>
                  </div>
                  <div className="hk_share_index">
                    <div className="hk_share_index_title">Hang Seng TECH Index</div>
                    <div className="hk_share_index_point"><img className="left" src="/img/hkunderlyings/2-HSTECHicon-a.png" />Gain leverage on the volatile HSTECH index</div>
                    <div className="hk_share_index_point"><img className="left" src="/img/hkunderlyings/2-HSTECHicon-b.png" />Access to the leading tech giants</div>
                    <div className="hk_share_index_point"><img className="left" src="/img/hkunderlyings/2-HSTECHicon-c.png" />Broad coverage of technology sector</div>
                  </div>
                  <div className="hk_share_index">
                    <div className="hk_share_index_title">Hong Kong Shares</div>
                    <div className="hk_share_index_point"><img className="left" src="/img/hkunderlyings/3-HKSSicon-a.png" />Gain exposure to specific Hong Kong listed companies</div>
                    <div className="hk_share_index_point"><img className="left" src="/img/hkunderlyings/3-HKSSicon-b.png" />Can be traded on Bursa with existing CDS accounts</div>
                    <div className="hk_share_index_point"><img className="left" src="/img/hkunderlyings/3-HKSSicon-c.png" />Coverage includes well-known names such as Alibaba, Geely and Xiaomi</div>
                  </div>
                </div>
                <div>Macquarie’s <strong>call and put warrants listed on Bursa Malaysia</strong> allow investors to trade on a bullish or bearish
                  view, providing increased trading opportunities with investors’ existing trading accounts. Trade with confidence
                  using the <Link target="_blank" to="/tools/livematrix/"> Live Matrix Plus</Link> to see how the warrant price will move for every change in the underlying and use it
                  to determine entry and exit points.
                </div>
              </div>
              <div id="section1" style={{ position: 'relative', top: '-120px' }} />
              <div className="share_index_detail hsi_data">
                <div className="share_index_detail_col">
                  <img className="share_index_detail-img  hstech_desk_img" src="/img/hkunderlyings/HSI_square.png" />
                  <img className="hstech_mob_img" src="/img/hkunderlyings/HSI_mobile.png" />
                  <div className="share_index_detail_title">
                    <div><span>Hang Seng Index</span></div>
                  </div>
                </div>
                <div className="share_index_detail_col">
                  <div className="share_index_detail_des">The HSI, launched on 24 November 1969, serves as a market benchmark that reflects the overall performance of the Hong Kong share market, measuring the performance of the largest and most liquid companies listed in Hong Kong. </div>
                  
                  <div className="des_learnbtn" onClick={modalFirst}>Learn More</div>


                  <div id="ppboxHTML" className="overlay" style={{ display: openModal ? "block" : "none" }}>
                    <div className="overlay-inner">
                      <div style={{ position: 'relative' }}>
                        <img className="btn-close" style={{}} src="/img/hkunderlyings/icon-close-black.png" onClick={modalFirstClose} />
                        <div className="popupbox-content">
                          <div className="content_title">
                            What is HSI?<br /><br />
                          </div>
                          <div>
                            The Hang Seng Index ("HSI") is one of the most watched indexes in the region and a widely quoted performance indicator of the Hong Kong share market. Comprising the largest companies listed on the Hong Kong Stock Exchange, including HSBC, Alibaba, Geely, Haidilao, Tencent and more, it tracks the movement in the Hong Kong market.
                            <br /><br />
                            More information can be found <a href="https://www.hsi.com.hk/eng/indexes/all-indexes/hsi" target="_blank">here</a>.
                            <br /><br />
                          </div>
                          <div className="content_title">
                            Why trade the HSI with warrants?<br /><br />
                          </div>
                          <div>
                            <img className="left" src="/img/hkunderlyings/1-HSIicon-a-blue.png" />
                            Trade moves in the broader Hong Kong market
                            <br /><br />
                            As the HSI serves as a market benchmark that reflects the performance of the overall Hong Kong share market, warrants over the HSI allow investors to gain exposure to the Hong Kong market while minimizing any single stock specific risk. The warrants also allow investors who do have exposure to Hong Kong shares to hedge their portfolio.
                            <br /><br />
                          </div>
                          <div>
                            <img className="left" src="/img/hkunderlyings/1-HSIicon-b-blue.png" />
                            Serves as a proxy for the wider Asian markets
                            <br /><br />
                            The Hong Kong share market has mostly institutional investors from Hong Kong and overseas, resulting in it being one of the most-watched Asian indices globally. Its constituents also include many companies incorporated in mainland China and listed in Hong Kong so investors can diversify their investment portfolio and gain leveraged exposure to one of the most active markets in the world.
                            <br /><br />
                          </div>
                          <div>
                            <img className="left" src="/img/hkunderlyings/1-HSIicon-c-blue.png" />
                            Reflects price movements of major sectors
                            <br /><br />
                            HSI constituent securities are grouped into four sectors including Finance, Utilities, Properties, and Commerce and Industry Sub-indexes, allowing it to better reflect the price movements of major industry sectors of the Hong Kong share market.
                            <br /><br />
                          </div>
                          <div>
                            Macquarie’s call and put warrants over the HSI allow investors to trade on a bullish or bearish view, providing increased trading opportunities. Trade with confidence using the <Link target="_blank" to="/tools/livematrix/">Live Matrix Plus</Link> to see how the warrant price will move for every change in the underlying futures level and use it to determine entry and exit points.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="share_index_detail_col share_index_detail_tw">
                  <div className="row tw_table">
                    <div className="call_table col-xs-6 col-md-6 col-lg-6">
                      <table className="tw_call_table" style={{ width: '100%' }}>
                        <tbody>
                          {gainExposureHsiCall && gainExposureHsiCall.map((HsiCallData, HsiCallIndex) => (
                            <tr className="call_tmp" key={HsiCallIndex} idx={HsiCallIndex}>
                              <td>
                                <div className="tw_warrant">
                                  <div colSpan={2} className="tw_title">
                                    <div className="tw_type">{HsiCallData.type}</div>
                                    <div className="tw_ticker">{HsiCallData.dsply_nmll}</div>
                                  </div>
                                  <table style={{ width: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Price:
                                        </td>
                                        <td style={{ width: '60%' }}>
                                          RM <span className="tw_BID">{HsiCallData.BID}</span> <br />(<span className={HsiCallData.BID_PCTCHNG > 0 ? "tw_BID_PCTCHNG up" : "tw_BID_PCTCHNG down"}>{HsiCallData.BID_PCTCHNG}%</span>)
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Strike:
                                        </td>
                                        <td className="tw_strike" style={{ width: '60%' }}>{HsiCallData.exercise_price}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Expiry:
                                        </td>
                                        <td className="tw_expiry" style={{ width: '60%' }}>{(moment(new Date(HsiCallData.maturity)).format("DD MMM YY"))}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Eff gearing:
                                        </td>
                                        <td className="tw_eff" style={{ width: '60%' }}>{HsiCallData.effective_gearing}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="put_table col-xs-6 col-md-6 col-lg-6">
                      <table className="tw_put_table" style={{ width: '100%' }}>
                        <tbody>
                          {gainExposureHsiPut && gainExposureHsiPut.map((HsiPutData, HsiPutIndex) => (
                            <tr className="put_tmp" key={HsiPutIndex} idx={HsiPutIndex}>
                              <td>
                                <div className="tw_warrant">
                                  <div colSpan={2} className="tw_title">
                                    <div className="tw_type">{HsiPutData.type}</div>
                                    <div className="tw_ticker">{HsiPutData.dsply_nmll}</div>
                                  </div>
                                  <table style={{ width: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Price:
                                        </td>
                                        <td style={{ width: '60%' }}>
                                          RM <span className="tw_BID">{HsiPutData.BID}</span> <br />(<span className={HsiPutData.BID_PCTCHNG > 0 ? "tw_BID_PCTCHNG up" : "tw_BID_PCTCHNG down"}>{HsiPutData.BID_PCTCHNG}%</span>)
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Strike:
                                        </td>
                                        <td className="tw_strike" style={{ width: '60%' }}>{HsiPutData.exercise_price}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Expiry:
                                        </td>
                                        <td className="tw_expiry" style={{ width: '60%' }}>{(moment(new Date(HsiPutData.maturity)).format("DD MMM YY"))}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Eff gearing:
                                        </td>
                                        <td className="tw_eff" style={{ width: '60%' }}>{HsiPutData.effective_gearing}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Link className="viewLM" to={`/tools/livematrix/${gainExposureHsiCall[0] ? gainExposureHsiCall[0].ticker : ''}${gainExposureHsiCall[1] ? "&"+gainExposureHsiCall[1].ticker : ''}${gainExposureHsiPut[0] ? "&"+gainExposureHsiPut[0].ticker : ''}${gainExposureHsiPut[1] ? "&"+gainExposureHsiPut[1].ticker : ''}`} target="_blank">
                    <div className="vlmbtn bgcolor-06">View Live Matrix</div>
                  </Link>
                </div>
              </div>
              <div id="section2" style={{ position: 'relative', top: '-120px' }} />
              <div className="share_index_detail hstech_data">
                <div className="share_index_detail_col">
                  <img className="share_index_detail-img hstech_desk_img" src="/img/hkunderlyings/HSTECH_square.png" />
                  <img className="share_index_detail-img hstech_mob_img" src="/img/hkunderlyings/HSTECH_mobile.png" />
                  <div className="share_index_detail_title">
                    <div><span>Hang Seng TECH Index</span></div>
                  </div>
                </div>
                <div className="share_index_detail_col">
                  <div className="share_index_detail_des">Launched on 27 July 2020, the HSTECH comprises the largest companies listed on the Hong Kong Stock Exchange which have high exposure in selected technology themes. Alibaba, Tencent, Meituan and Xiaomi (commonly referred to as ‘ATMX’) are heavyweights in this index. </div>
                  
                  <div className="des_learnbtn" onClick={modalSecond}>Learn More</div>

                  <div id="ppboxHTML" className="overlay" style={{ display: openModal1 ? "block" : "none" }}>
                    <div className="overlay-inner">
                      <div style={{ position: 'relative' }}>
                        <img className="btn-close" style={{}} src="/img/hkunderlyings/icon-close-black.png" onClick={modalSecondClose} />
                        <div className="popupbox-content">
                          <div className="content_title">
                            What is HSTECH?<br /><br />
                          </div>
                          <div>
                            The Hang Seng TECH Index (“HSTECH”) comprises the largest companies listed on the Hong Kong Stock Exchange which have high exposure in selected technology themes.
                            <br /><br />
                            Notable constituents include well-known names such as Alibaba, Tencent, Meituan and Xiaomi (commonly referred to as ‘ATMX’) which have a combined weight of more than 33% and contribute an average of 20.1% to the HSI’s daily turnover. More information on the HSTECH index constituents can be found <a href="https://www.hsi.com.hk/eng/indexes/all-indexes/hstech" target="_blank">here</a>.
                            <br /><br />
                          </div>
                          <div className="content_title">
                            What differentiates the HSTECH from other indexes?<br /><br />
                          </div>
                          <div>
                            Technology companies have been top performers globally in recent years, with many technology giants continuing to see unprecedented growth and resilience to economic cycles. The HSTECH, which has ATMX as heavyweights, gained 47.5% in a year, outperforming the HSI by 24.6% and the FBM KLCI by 41.2% in the past 12 months, despite the recent dip in mid-February.
                            <br /><br />
                          </div>
                          <div>
                            <img src="/img/hkunderlyings/hstechvshsi.png" />
                            <br /><br />
                          </div>
                          <div>
                            The HSTECH is also a lot <strong>more volatile</strong> than the HSI and FBM KLCI. This means that the HSTECH tends to move in a larger range, and the short and medium term trends are likely to be larger and potentially present more trading opportunities for investors.
                          </div>
                          <div>
                            <img src="/img/hkunderlyings/12month.png" />
                            <br /><br />
                          </div>
                          <div className="content_title">
                            Why trade the HSTECH with warrants?<br /><br />
                          </div>
                          <div>
                            <img className="left" src="/img/hkunderlyings/2-HSTECHicon-a-blue.png" />
                            Gain leverage on the volatile HSTECH index
                            <br /><br />
                            Macquarie has listed a suite of warrants that allow investors to select the amount of leverage that best suits their view of short to medium term outlook. Investors can achieve magnified potential returns on market moves in the HSTECH index that leads major benchmark indices with an average volatility of 38% and a 47.5% growth over the past 12 months.
                            <br /><br />
                          </div>
                          <div>
                            <img className="left" src="/img/hkunderlyings/2-HSTECHicon-b-blue.png" />
                            Access to the leading tech giants
                            <br /><br />
                            Investors can trade the HSTECH using warrants listed on Bursa Malaysia with their existing CDS and trading account, without the hassle of creating an account to trade on the Hong Kong exchange.
                            <br /><br />
                          </div>
                          <div>
                            <img className="left" src="/img/hkunderlyings/2-HSTECHicon-c-blue.png" />
                            Broad coverage of technology sector
                            <br /><br />
                            Of the HSTECH index constituents, the popular ATMX stocks account for over 33% of the index. Investors can gain exposure to these heavyweights as well as a diverse spectrum of themes within the tech sector including internet, fintech, cloud, e-commerce and digital while minimizing any single stock specific risk.
                            <br /><br />
                          </div>
                          <div>
                            Macquarie’s call and put warrants over the HSTECH allow investors to trade on a bullish or bearish view, providing increased trading opportunities especially when coupled with the HSTECH’s higher volatility. Trade with confidence using the <Link to="/tools/livematrix/" target="_blank">Live Matrix Plus</Link> to see how the warrant price will move for every change in the underlying futures level and use it to determine entry and exit points.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="share_index_detail_col share_index_detail_tw">
                  <div className="row tw_table">
                    <div className="call_table col-xs-6 col-md-6 col-lg-6">
                      <table className="tw_call_table" style={{ width: '100%' }}>
                        <tbody>
                          {gainExposureHsTechCall && gainExposureHsTechCall.map((HsTechCallData, HsTechCallIndex) => (
                            <tr className="call_tmp" key={HsTechCallIndex} idx={HsTechCallIndex}>
                              <td>
                                <div className="tw_warrant">
                                  <div colSpan={2} className="tw_title">
                                    <div className="tw_type">{HsTechCallData.type}</div>
                                    <div className="tw_ticker">{HsTechCallData.dsply_nmll}</div>
                                  </div>
                                  <table style={{ width: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Price:
                                        </td>
                                        <td style={{ width: '60%' }}>
                                          RM <span className="tw_BID">{HsTechCallData.BID}</span> <br />(<span className={HsTechCallData.BID_PCTCHNG > 0 ? "tw_BID_PCTCHNG up" : "tw_BID_PCTCHNG down"}>{HsTechCallData.BID_PCTCHNG}%</span>)
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Strike:
                                        </td>
                                        <td className="tw_strike" style={{ width: '60%' }}>{HsTechCallData.exercise_price}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Expiry:
                                        </td>
                                        <td className="tw_expiry" style={{ width: '60%' }}>{(moment(new Date(HsTechCallData.maturity)).format("DD MMM YY"))}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Eff gearing:
                                        </td>
                                        <td className="tw_eff" style={{ width: '60%' }}>{HsTechCallData.effective_gearing}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="put_table col-xs-6 col-md-6 col-lg-6">
                      <table className="tw_put_table" style={{ width: '100%' }}>
                        <tbody>
                          {gainExposureHsTechPut && gainExposureHsTechPut.map((HsTechPutData, HsTechPutIndex) => (
                            <tr className="put_tmp" key={HsTechPutIndex} idx={HsTechPutIndex}>
                              <td>
                                <div className="tw_warrant">
                                  <div colSpan={2} className="tw_title">
                                    <div className="tw_type">{HsTechPutData.type}</div>
                                    <div className="tw_ticker">{HsTechPutData.dsply_nmll}</div>
                                  </div>
                                  <table style={{ width: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Price:
                                        </td>
                                        <td style={{ width: '60%' }}>
                                          RM <span className="tw_BID">{HsTechPutData.BID}</span> <br />(<span className={HsTechPutData.BID_PCTCHNG > 0 ? "tw_BID_PCTCHNG up" : "tw_BID_PCTCHNG down"}>{HsTechPutData.BID_PCTCHNG}%</span>)
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Strike:
                                        </td>
                                        <td className="tw_strike" style={{ width: '60%' }}>{HsTechPutData.exercise_price}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Expiry:
                                        </td>
                                        <td className="tw_expiry" style={{ width: '60%' }}>{(moment(new Date(HsTechPutData.maturity)).format("DD MMM YY"))}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Eff gearing:
                                        </td>
                                        <td className="tw_eff" style={{ width: '60%' }}>{HsTechPutData.effective_gearing}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Link className="viewLM" to={`/tools/livematrix/${gainExposureHsTechCall[0] ? gainExposureHsTechCall[0].ticker : ''}${gainExposureHsTechCall[1] ? "&"+gainExposureHsTechCall[1].ticker : ''}${gainExposureHsTechPut[0] ? "&"+gainExposureHsTechPut[0].ticker : ''}${gainExposureHsTechPut[1] ? "&"+gainExposureHsTechPut[1].ticker : ''}`} target="_blank">
                    <div className="vlmbtn bgcolor-06">View Live Matrix</div>
                  </Link>
                </div>
              </div>
              <div id="section3" style={{ position: 'relative', top: '-120px' }} />
              <div className="share_index_detail hkshares_data">
                <div className="share_index_detail_col">
                  <img className="share_index_detail-img  hstech_desk_img" src="/img/hkunderlyings/HKSS_square.png" />
                  <img className="share_index_detail-img hstech_mob_img" src="/img/hkunderlyings/HKSS_mobile.png" />
                  <div className="share_index_detail_title">
                    <div><span>Hong Kong Shares</span></div>
                  </div>
                </div>
                <div className="share_index_detail_col">
                  <div className="share_index_detail_des">Hong Kong shares refer to the ordinary shares of a company that are listed on the Hong Kong stock exchange, one of the world’s largest bourse in terms of market capitalization.
                    <br /><br />Macquarie has issued new warrants over Alibaba, Geely, Xiaomi and more, and look to add more Hong Kong share underlyings to its coverage over time.
                  </div>
                 
                  <div className="des_learnbtn" onClick={modalThird}>Learn More</div>

                  <div id="ppboxHTML" className="overlay" style={{ display: openModal2 ? "block" : "none" }}>
                    <div className="overlay-inner">
                      <div style={{ position: 'relative' }}>
                        <img className="btn-close" style={{}} src="/img/hkunderlyings/icon-close-black.png" onClick={modalThirdClose} />
                        <div className="popupbox-content">
                          <div className="content_title">
                            What are Hong Kong Shares?<br /><br />
                          </div>
                          <div>
                            Hong Kong shares refer to the ordinary shares of a company that are listed on the Hong Kong stock exchange, one of the world’s largest bourse in terms of market capitalization.
                            <br /><br />
                            Macquarie has issued warrants over the following companies at the moment:
                            <ul>
                              <li>
                                <strong>Alibaba</strong> is engaged in the e-commerce business. Its electronic marketplaces include Taobao, Tmall and AliExpress, where merchants on these marketplaces range from individuals and small businesses to large Chinese and international brands and retailers. (Visit <a href="https://www.alibabagroup.com/en/global/home" target="_blank">website</a>)
                              </li>
                              {/*                                            <li>
                                                  <strong>BYD</strong> produces passenger and commercial new energy vehicles such as cars, buses, coaches and taxis, and have built a complete monorail system called the SkyRail. They also provide electronic and new energy solutions. (Visit <a href="https://www.byd.com/en/index.html" target="_blank">website</a>)
                                              </li>*/}
                              <li>
                                <strong>Geely</strong> manufactures passenger vehicles, selling 17 major vehicle models including Volvo, Proton and Lotus cars, exporting its products to 24 countries worldwide. In 2021, Geely launched its first electric car and plans to expand further in the electric vehicle segment. (Visit <a href="http://geelyauto.com.hk/en/" target="_blank">website</a>)
                              </li>
                              {/*                                            <li>
                                                  <strong>Meituan</strong> is China’s leading e-commerce platform for services. Its apps such as Meituan, Dianping and Meituan Waimai offer services such as catering, on-demand delivery, car-hailing, and hotel and travel booking, among others, covering over 2,800 cities and counties across China. (Visit <a href="https://about.meituan.com/en" target="_blank">website</a>)
                                              </li>*/}
                              <li>
                                <strong>Tencent</strong> specializes in digital content and solutions. They developed social platforms and e-payment services such as WeChat and QQ, and video games such as PUBG and League of Legends. They also have online video platforms, music platforms and provide digital products and services to businesses. (Visit <a href="https://www.tencent.com/en-us/index.html" target="_blank">website</a>)
                              </li>
                              <li>
                                <strong>Xiaomi</strong> is a well-known producer of smartphones and smart TVs, and other lifestyle electronic gadgets such as smart watches, power banks, vacuum cleaners, electric scooters and others. (Visit <a href="https://www.mi.com/global/" target="_blank">website</a>)
                              </li>
                            </ul>
                            <br />
                            We will increase our coverage on Hong Kong shares over time so that you can trade your selected underlying with warrants listed on Bursa. A full list of Hong Kong listed equities can be found <a href="https://www.hkex.com.hk/Market-Data/Securities-Prices/Equities?sc_lang=en" target="_blank">here</a>.
                            <br /><br />
                          </div>
                          <div className="content_title">
                            Why trade Hong Kong Shares with warrants?<br /><br />
                          </div>
                          <div>
                            <img className="left" src="/img/hkunderlyings/3-HKSSicon-a-blue.png" />
                            Gain exposure to specific Hong Kong listed companies
                            <br /><br />
                            While warrants over the HSI and HSTECH allow you to gain a broader exposure to the Hong Kong market, the call and put warrants over Hong Kong shares allow you to gain exposure to your selected Hong Kong company, allowing potential gains from stock-specific news.
                            <br /><br />
                          </div>
                          <div>
                            <img className="left" src="/img/hkunderlyings/3-HKSSicon-b-blue.png" />
                            Can be traded on Bursa with existing CDS accounts
                            <br /><br />
                            As the warrants are listed and traded on Bursa, there is no need to go through the hassle of opening a new trading account – use your existing CDS and trading account that you use to trade shares in Malaysia to buy and sell warrants, including warrants over the Hong Kong shares!
                            <br /><br />
                          </div>
                          <div>
                            <img className="left" src="/img/hkunderlyings/3-HKSSicon-c-blue.png" />
                            Coverage includes well-known names such as Alibaba, Geely and Xiaomi
                            <br /><br />
                            Often seeing Hong Kong listed companies making the trading day’s headlines? Macquarie has commenced its warrants coverage on some of the larger and more well-known Hong Kong listed companies! Stay updated as we add more Hong Kong shares to our coverage.
                            <br /><br />
                          </div>
                          <div>
                            Macquarie’s call and put warrants over the Hong Kong shares allow investors to trade on a bullish or bearish view, providing increased trading opportunities. Trade with confidence using the <Link to="/tools/livematrix/" target="_blank">Live Matrix Plus</Link> to see how the warrant price will move for every change in the underlying futures level and use it to determine entry and exit points.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="share_index_detail_col share_index_detail_tw">
                  <div className="row tw_table">
                    <div className="call_table col-xs-6 col-md-6 col-lg-6">
                      <table className="tw_call_table" style={{ width: '100%' }}>
                        <tbody>
                          {gainExposureHKCall && gainExposureHKCall.map((HkCallData, HkCallIndex) => (
                            <tr className="call_tmp" key={HkCallIndex} idx={HkCallIndex}>
                              <td>
                                <div className="tw_warrant">
                                  <div colSpan={2} className="tw_title">
                                    <div className="tw_type">{HkCallData.type}</div>
                                    <div className="tw_ticker">{HkCallData.dsply_nmll}</div>
                                  </div>
                                  <table style={{ width: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Price:
                                        </td>
                                        <td style={{ width: '60%' }}>
                                          RM <span className="tw_BID">{HkCallData.BID}</span> <br />(<span className={HkCallData.BID_PCTCHNG > 0 ? "tw_BID_PCTCHNG up" : "tw_BID_PCTCHNG down"}>{HkCallData.BID_PCTCHNG}%</span>)
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Strike:
                                        </td>
                                        <td className="tw_strike" style={{ width: '60%' }}>{HkCallData.exercise_price}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Expiry:
                                        </td>
                                        <td className="tw_expiry" style={{ width: '60%' }}>{(moment(new Date(HkCallData.maturity)).format("DD MMM YY"))}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Eff gearing:
                                        </td>
                                        <td className="tw_eff" style={{ width: '60%' }}>{HkCallData.effective_gearing}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="put_table col-xs-6 col-xs-6 col-md-6 col-lg-6">
                      <table className="tw_put_table" style={{ width: '100%' }}>
                        <tbody>
                          {gainExposureHKPut && gainExposureHKPut.map((HkPutData, HkPutIndex) => (
                            <tr className="put_tmp" key={HkPutIndex} idx={HkPutIndex}>
                              <td>
                                <div className="tw_warrant">
                                  <div colSpan={2} className="tw_title">
                                    <div className="tw_type">{HkPutData.type}</div>
                                    <div className="tw_ticker">{HkPutData.dsply_nmll}</div>
                                  </div>
                                  <table style={{ width: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Price:
                                        </td>
                                        <td style={{ width: '60%' }}>
                                          RM <span className="tw_BID">{HkPutData.BID}</span> <br />(<span className={HkPutData.BID_PCTCHNG > 0 ? "tw_BID_PCTCHNG up" : "tw_BID_PCTCHNG down"}>{HkPutData.BID_PCTCHNG}%</span>)
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Strike:
                                        </td>
                                        <td className="tw_strike" style={{ width: '60%' }}>{HkPutData.exercise_price}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Expiry:
                                        </td>
                                        <td className="tw_expiry" style={{ width: '60%' }}>{(moment(new Date(HkPutData.maturity)).format("DD MMM YY"))}</td>
                                      </tr>
                                      <tr>
                                        <td style={{ width: '40%' }}>
                                          Eff gearing:
                                        </td>
                                        <td className="tw_eff" style={{ width: '60%' }}>{HkPutData.effective_gearing}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Link className="viewLM" to={`/tools/livematrix/${gainExposureHKCall[0] ? gainExposureHKCall[0].ticker : ''}${gainExposureHKCall[1] ? "&"+gainExposureHKCall[1].ticker : ''}${gainExposureHKPut[0] ? "&"+gainExposureHKPut[0].ticker : ''}${gainExposureHKPut[1] ? "&"+gainExposureHKPut[1].ticker : ''}`} target="_blank">
                    <div className="vlmbtn bgcolor-06">View Live Matrix</div>
                  </Link>
                </div>
              </div>

              <div className="mrarea-two">
                <h2 style={{ fontWeight: 600 }}>How to get started?</h2>
                <hr className="hstech-hr" />
                <div className="hestch-text" style={{ fontSize: '18px' }}>
                  Trade Macquarie’s warrants on Bursa Malaysia just like how you trade shares! Simply login to your local brokerage account to start trading. You may view the live HSI and HSTECH futures level on our <Link to="/home" target="_blank">homepage</Link> and can also view the live underlying prices for the Hong Kong shares with the <Link to="/tools/underlying" target="_blank">Underlying Charts</Link> tool.
                </div>
                <div className="hestch-text">
                  The market-making hours for warrants over the <strong>HSI and HSTECH</strong> will be <strong>from 9:15am to 11:58am, then 2:30pm to 4:28pm</strong>, while for warrants over the <strong>Hong Kong shares</strong> will be <strong>from 9:30am to 11:58am, then 2:30pm to 3:58pm</strong>.
                </div>




                <div className="area_top" style={{ paddingTop: '20px' }}>
                  <div className="row hidden-xs">
                    <div className="col-sm-1" />
                    <div className="col-sm-2 top-pointer">
                      <div><a>1</a></div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div><a>2</a></div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div><a>3</a></div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div><a>4</a></div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div><a>5</a></div>
                      <div className="top_line" />
                    </div>
                   
                  </div>
                  <div className="row  ">
                    <div className="col-sm-1" />
                    <div className="col-sm-2 top-img">
                      <a>
                        <div><img src="/img/hkunderlyings/1.png" /></div>
                        <div className="top_img_text">Knowledge</div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a>
                        <div><img src="/img/hkunderlyings/2.png" /></div>
                        <div className="top_img_text">CDS account</div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a>
                        <div><img src="/img/hkunderlyings/3.png" /></div>
                        <div className="top_img_text">Selecting a warrant</div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a>
                        <div><img src="/img/hkunderlyings/4.png" /></div>
                        <div className="top_img_text">How to buy or sell warrants</div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a>
                        <div><img src="/img/hkunderlyings/5.png" /></div>
                        <div className="top_img_text">Constant support</div>
                      </a>
                    </div>
                    
                  </div>
                </div>


                <div className='area_top_mob'>
                  <div className="row">
                    <div className="col-xs-4 top-pointer"><div><a>1</a></div><div className="top_line"></div></div>
                    <div className="col-xs-4 top-pointer"><div><a>2</a></div><div className="top_line"></div></div>
                    <div className="col-xs-4 top-pointer"><div><a>3</a></div><div className="top_line"></div></div>
                  </div>

                  <div className="row">
                    <div className="col-xs-4 top-img"><a><div><img src="/img/hkunderlyings/1.png" /></div><div className="top_img_text">Knowledge</div></a></div>
                    <div className="col-xs-4 top-img"><a><div><img src="/img/hkunderlyings/2.png" /></div><div className="top_img_text">CDS account</div></a></div>
                    <div className="col-xs-4 top-img"><a><div><img src="/img/hkunderlyings/3.png" /></div><div className="top_img_text">Selecting a warrant</div></a></div>
                  </div>
                  <div className="row">
                    <div className="col-xs-4 top-pointer"><div><a>4</a></div><div className="top_line"></div></div>
                    <div className="col-xs-4 top-pointer"><div><a>5</a></div><div className="top_line"></div></div>
                    
                  </div>
                  <div className="row">
                    <div className="col-xs-4 top-img"><a><div><img src="/img/hkunderlyings/4.png" /></div><div className="top_img_text">How to buy or sell warrants</div></a></div>
                    <div className="col-xs-4 top-img"><a><div><img src="/img/hkunderlyings/5.png" /></div><div className="top_img_text">Constant support</div></a></div>
                   
                  </div>
                </div>

























                {/* learn more */}
                <div className="hstechbtn-area" style={{ width: '120px', height: '50px' }}>
                  <div className="learnbtn bgcolor-06"><a href="../education/getstarted" target="_blank">Learn More</a></div>
                </div>
              </div>
            </div>
          </div>
          <div className="mlarea-index" style={{ marginTop: '20px' }}>
            <h2 style={{ fontWeight: 600 }}>Risk factors of warrants</h2>
            <hr className="hstech-hr" />
            <br />
            Leverage, also known as gearing works in both ways, meaning one can gain or lose more in trading warrants than investing directly in the underlying asset.
            <br />
            <br />
            As with all investment products, warrants are subject to price movements in the underlying asset and other prevailing market fluctuations or circumstances. An investor may loss the full amount invested in the warrant.
            <br />
            <br />
            If held overnight, warrants are subjected to holding costs which increases the longer the holding period is. Manage holding costs by using the warrant calculator.
            <br />
            <br />
            Warrants with foreign underlying assets are subject to foreign exchange risk. The warrants trade and settle in MYR, and the value of the warrants will change in response to movements in the FX rate, as well as the underlying asset.
            <br />
            <br />
            Given the HSTECH index has exposure only to technology companies, the performance of the index may not correlate with the performance of other indices that track the market generally or track other sectors or asset classes. This may affect the performance of the Warrants as compared to other warrants that are linked to other indices. The HSTECH warrants may experience increased price volatility of technology stocks and may be more susceptible to economic, market, political or regulatory occurrences affecting technology stocks than other warrants over indices that do not concentrate in the technology sector.
          </div>
          <div className="hestch-tips" style={{ paddingLeft: '20px', paddingTop: '20px' }}>The content on this page has not been reviewed by the Securities Commission Malaysia. Please refer to the full disclaimer through the links at the bottom of this page.</div>
          <div className="hestch-tips" style={{ paddingLeft: '20px' }}><em>
            * All information on this page is based on data sourced from Bloomberg, and the websites of Hang Seng Indexes Company Limited and the Hong Kong exchange, as of 31 May 2021, which includes back-tested data for the period before the HSTECH index’s launch date of 27 July 2020.
          </em>
          </div>
        </div>
      </div>
      {openModal4 && <SubmitModalGain open={openModal4} />}
    </>
  );
}

export default GainExpo;