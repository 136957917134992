import React, { useState, useEffect,Fragment } from 'react';
import SuccessSpeaks from './SuccessSpeaks';
import "./successSpeaks.css" 


const SuccessSpeak = (props) => {
    return ( 
        <Fragment> 
        <SuccessSpeaks/>
        </Fragment> 
     );
}


export default SuccessSpeak;