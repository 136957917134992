import React, { useState, useEffect,useRef } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import NewsComp from '../../../components/common/NewsComp';


const EndDayInsights = () => {
    const api = new API();
    const [endDayInsight, setEndDayInsight] = useState([]);
    const [selectDate,setSelectDate] = useState("")
    const param = "dailycommentary"
    const dataslider = useRef()
    const getDayInsight = async () => {
        let dayinsight = await api.get(config.marketDailyHighLight, true, { sp: param, start: "0",... selectDate && {startdate: selectDate} , qid: Date.now() })
        let endDayInsightt = get(dayinsight, 'newsList', [])
        setEndDayInsight(endDayInsightt)
        dataslider.current.slickGoTo(0)
    };
    useEffect(() => {
        getDayInsight()
    }, [])

    useEffect(()=>{
        if(selectDate !== "")
        {
        getDayInsight()
        }
    }
        ,[selectDate])

    return (
     <NewsComp 
     param={param}
     data={endDayInsight}
     section="Market news"
     breadcrumb="End of day insights"
     title="End of day insights"
     subtitle="Our exclusive commentary is updated by 6pm each day."
     dataIndex={3}
     setSelectDate={setSelectDate}
     dataslider={dataslider}
     />   
    );
}
export default EndDayInsights;
