import React, { useState, useEffect } from "react";
import Select from "react-select";
import { get } from "lodash";
import { config } from "../../../utils/apiUrl";
import { Link } from "react-router-dom";
import "./warrantCalc.css";
import API from "../../../utils/apiCalling";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RangeSliderUnderlyingPriceComponent from "../../../components/common/RangeSliderUnderlyingPriceComponent";
import RangeSliderDateSelectionComponent from "../../../components/common/RangeSliderDateSelectionComponent";
import RangeSliderImpliedVolatilityComponent from "../../../components/common/RangeSliderImpliedVolatilityComponent";
import WarrantTermsModal from "../../../components/common/warrantTermsModal";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";
import GaEventsTracker from "../../../components/common/GaEventsTracker";
import { getLiveMatrix } from "../../../utils/WarrantIssue.api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WarrantCalculators = (props) => {
  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const api = new API();

  const useGaEventsTracker = GaEventsTracker("External Links");

  const [valueLiveIndexSymbolRes, SetValueLiveIndexSymbolRes] = useState();
  const [valueUnderlyingPrice, setValueUnderlyingPrice] = useState(0);
  const [valueImpliedVolatility, setValueImpliedVolatility] = useState(0);
  const [symbolList, setSymbolList] = useState([]);
  const [ric, setRic] = useState("");
  const [uRic, setuRic] = useState("");
  const [liveMatrixSelectedName, setLiveMatrixSelectedName] = useState("");
  const [liveIndexSymbolObj, setLiveIndexSymbolObj] = useState({});
  const [type, setType] = useState("warrantdata");
  const [qid, setQid] = useState(Date.now());
  const [startDate, setStartDate] = useState(0);
  const [finalValue, setFinalValue] = React.useState(null);
  const [maxUn, setmaxUn] = useState(0);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [localRic, setLocalRic] = useState("");
  const [fxRate, setfxRate] = useState(1);
  const [ivMatrix, setivMatrix] = useState(null);
  const [unpricenum, setunpricenum] = useState(6);
  const [ivnum, setivnum] = useState(6);
  const [unPriceProps, setunPriceProps] = useState({ spread: 0.01, udp: 2 });
  const [ivMatrixDisp, setivMatrixDisp] = useState(null);
  const [uname, setuname] = useState("");
  const [udp, setudp] = useState(null);
  const [wdp, setwdp] = useState(null);
  const [bid, setbid] = useState(null);
  const [riskFreeRate, setriskFreeRate] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [delta, setdelta] = useState("");
  const [efgearing, setefgearing] = useState("");
  const [dwpchg, setdwpchg] = useState("");
  const [initial, setinitial] = useState(true);
  const [advImgVal, setAdvImgVal] = useState([]);

  useEffect(() => {
    getHomePopup();
    getSymbolsWarrant();
  }, []);

  const getHomePopup = async () => {
    let homePopupResult = await api.get(config.advImgPopup, true);
    let items = [];
    if (homePopupResult.length > 0) {
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {
          adv_id: homePopupResult[i].id,
        });
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link,
        });
      }
      setAdvImgVal(items);
    }
  };

  useEffect(() => {
    getWarrantTermsSelect();
  }, []);

  useEffect(() => {
    if (ivMatrix !== null) {
      calIVMatrix();
    }
    return () => {};
  }, [ivMatrix]);

  useEffect(() => {
    var locRic = JSON.parse(window.localStorage.getItem("ric"));
    if (locRic && symbolList.length > 0) {
      setLocalRic(locRic);
      setRic(locRic[0]);
      var narray = symbolList.filter((el) => {
        return el.value === locRic[0];
      });
      setLiveMatrixSelectedName(narray && narray[0] && narray[0].label);
    }
    return () => {};
  }, [symbolList]);

  useEffect(() => {
    if (ric !== "") {
      getWarrantToolsLiveMatrix();
    }
  }, [liveMatrixSelectedName]);

  useEffect(() => {
    if (
      valueUnderlyingPrice >= 0 &&
      valueImpliedVolatility >= 0 &&
      startDate &&
      liveIndexSymbolObj &&
      valueUnderlyingPrice !== null &&
      valueImpliedVolatility !== null &&
      riskFreeRate &&
      !initial
    ) {
      startMatrix();
    }
    return () => {};
  }, [valueUnderlyingPrice, valueImpliedVolatility, startDate, riskFreeRate]);

  const getSelectedValue = (e) => {
    const { value, label, security_code } = e;

    setLiveMatrixSelectedName(label);
    setRic(value);
    var temp = [value, ...localRic];

    setLocalRic(temp);
    window.localStorage.setItem("ric", JSON.stringify(temp));
    window.history.replaceState(
      "wrrantcalculator",
      "Warrant Calculator | Malaysia Warrants | Macquarie",
      `/tools/warrantcalculator/${security_code}`
    );
  };

  const getSymbolsWarrant = async () => {
    getLiveMatrix("", 0).then((res) => {
      var resSymbols = res.data.symbols;
      var finalResSymbol = [];
      for (var j = 0; j < resSymbols.length; j++) {
        var temp = {};
        temp["label"] =
          resSymbols[j].dsply_nmll + " (" + resSymbols[j].security_code + ")";
        temp["value"] = resSymbols[j].ric;
        temp["security_code"] = resSymbols[j].security_code;
        finalResSymbol.push(temp);
      }
      setSymbolList(finalResSymbol);
    });
  };

  const getWarrantTermsSelect = async () => {
    let LiveIndexSymbolResult = await api.get(
      config.homeLiveIndexFuturesSymbol,
      true,
      { qid: Date.now() }
    );
    let liveIndexSymbolRes = get(LiveIndexSymbolResult, "symbols", []);
    const liveIndexSymbolData = liveIndexSymbolRes.map(
      ({ name, security_code, ric: value }) => ({
        label: `${name} ${"("}${security_code}${")"}`,
        value,
        security_code,
      })
    );
    let selectedOneName =
      liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].name;
    let selectedOneCode =
      liveIndexSymbolRes &&
      liveIndexSymbolRes[0] &&
      liveIndexSymbolRes[0].security_code;
    let tempSelectedName = selectedOneName + " (" + selectedOneCode + ") ";
    setRic(
      liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].ric
    );
    // setSymbolList(liveIndexSymbolData);
    setLiveMatrixSelectedName(tempSelectedName);
  };

  const getWarrantToolsLiveMatrix = async () => {
    let data = {
      qid,
      type,
      ric,
    };
    let LiveIndexSymbolResult = await api.get(
      config.marketTurnOver,
      true,
      data
    );
    const liveIndexSymbolRes = get(LiveIndexSymbolResult, ric, {});
    var maxValue =
      3 *
      Number(liveIndexSymbolRes?.underlying_price?.replace(/,/g, "")).toFixed(
        2
      );
    setmaxUn(maxValue);
    setLiveIndexSymbolObj(liveIndexSymbolRes);
    setValueImpliedVolatility(
      !isNaN(liveIndexSymbolRes.implied_volatility)
        ? liveIndexSymbolRes.implied_volatility
        : "N/A"
    );
    setuRic(liveIndexSymbolRes.underlying_ric);
    SetValueLiveIndexSymbolRes(liveIndexSymbolRes.underlying_price);
    setValueUnderlyingPrice(liveIndexSymbolRes.underlying_price_cal);
    setStartDate(new Date(LiveIndexSymbolResult.systemtime));
    setuname(liveIndexSymbolRes.underlying_name);
    setfxRate(liveIndexSymbolRes.r_hst_close);
    setbid(liveIndexSymbolRes.BID);
    setCurrency(liveIndexSymbolRes.underlying_curr);
    setdelta(liveIndexSymbolRes.delta);
    setefgearing(liveIndexSymbolRes.effective_gearing);
    setdwpchg(
      (
        ((Number(liveIndexSymbolRes.underlying_price_cal) -
          liveIndexSymbolRes.underlying_price) /
          Number(liveIndexSymbolRes.underlying_price_cal)) *
        100
      ).toFixed(2)
    );
    setriskRate();
    setupDecimalPlace();
  };

  const onClickPopHandle = (url, value) => {
    setOpen(value);
    setUrl(url);
  };

  // const Reset = () => {
  //   window.location.reload();
  // };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#007fff" : null,
        color: "#333333",
        color: isFocused ? "#fff" : null,
        textAlign: "center",
        fontSize: "14px",
        padding: "3px 10px",
        cursor: "pointer",
      };
    },
  };

  const initIVMatrix = () => {
    const initIV =
      valueImpliedVolatility === "N/A" ||
      valueImpliedVolatility === "" ||
      valueImpliedVolatility === "-" ||
      valueImpliedVolatility === null
        ? 0
        : Number(valueImpliedVolatility);
    const initUP =
      valueUnderlyingPrice === "N/A" ||
      valueUnderlyingPrice === "" ||
      valueUnderlyingPrice === "-" ||
      valueUnderlyingPrice === null
        ? 0
        : Number(valueUnderlyingPrice);
    var nivMatrix = ivMatrix;
    if (!nivMatrix) {
      nivMatrix = [];
      for (var i = 0; i < unpricenum; i++) {
        nivMatrix[i] = [];
        for (var j = 0; j < ivnum; j++) {
          nivMatrix[i][j] = "";
        }
      }
    }

    for (let i = 1; i < ivnum; i++) {
      let iv = (i - ivnum / 2) * 1 + initIV;
      if (iv < 0) iv = 0;
      nivMatrix[0][i] = iv.toFixed(1);
    }
    for (let i = 1; i < unpricenum; i++) {
      var p = initUP + (-unpricenum / 2 + i) * unPriceProps.spread;
      nivMatrix[i][0] = p.toFixed(unPriceProps.udp);
    }
    setivMatrix(nivMatrix);
    initIVMatrixDisp();
  };

  const initIVMatrixDisp = () => {
    const initIV =
      valueImpliedVolatility === "N/A" ||
      valueImpliedVolatility === "" ||
      valueImpliedVolatility === "-" ||
      valueImpliedVolatility === null
        ? 0
        : Number(valueImpliedVolatility);
    const initUP =
      valueUnderlyingPrice === "N/A" ||
      valueUnderlyingPrice === "" ||
      valueUnderlyingPrice === "-" ||
      valueUnderlyingPrice === null
        ? 0
        : Number(valueUnderlyingPrice);
    var nivMatrixDisp = ivMatrixDisp;
    if (nivMatrixDisp === null) {
      nivMatrixDisp = [];
      for (let i = 0; i < unpricenum; i++) {
        nivMatrixDisp[i] = [];
        for (let j = 0; j < ivnum; j++) {
          nivMatrixDisp[i][j] = "";
        }
      }
    }

    for (let i = 1; i < ivnum; i++) {
      let iv = (i - ivnum / 2) * 1 + initIV;
      if (iv < 0) iv = 0;
      nivMatrixDisp[0][i] = iv.toFixed(1);
    }
    for (var i = 1; i < unpricenum; i++) {
      var p = initUP + (-unpricenum / 2 + i) * unPriceProps.spread;
      nivMatrixDisp[i][0] = p.toFixed(unPriceProps.dp);
    }
    setivMatrixDisp(nivMatrixDisp);
  };
  useEffect(() => {
    if (uRic?.length > 0) {
      setUnpricePropertiesByUname();
    }
    return () => {};
  }, [uRic]);

  const setUnpricePropertiesByUname = () => {
    var data = {};
    if (uRic.match(/^HSI/) || uRic.match(/^SSI/)) {
      data.spread = 10;
      data.udp = 2;
    } else if (uRic.match(/^SFC/) || uRic.indexOf("FTSTI") > 0) {
      data.spread = 5;
      data.udp = 2;
    } else {
      if (valueUnderlyingPrice < 2) {
        data.spread = 0.005;
        data.udp = 3;
      } else {
        data.spread = 0.01;
        data.udp = 2;
      }
    }
    setunPriceProps(data);
  };
  const setupDecimalPlace = () => {
    setudp(
      valueUnderlyingPrice !== null
        ? getDecimalPlace(valueLiveIndexSymbolRes)
        : 0
    );
    setwdp(bid !== null ? getDecimalPlace(bid) : 0);
  };

  const getDecimalPlace = (value = "") => {
    const decimalIndex = value.indexOf(".");
    if (decimalIndex > -1) {
      return value.length - (decimalIndex + 1);
    }
    return 0;
  };
  useEffect(() => {
    setriskRate();
    return () => {};
  }, [currency]);

  const setriskRate = () => {
    if (!currency) return;
    if (currency == "HKD") {
      setriskFreeRate(0.004);
    } else if (currency == "USD") {
      setriskFreeRate(0.0035);
    } else if (currency == "MYR") {
      setriskFreeRate(0.038);
    } else if (currency == "SGD") {
      setriskFreeRate(0.015);
    } else if (currency == "JPY") {
      setriskFreeRate(-0.005);
    } else {
      setriskFreeRate(0.016);
    }
  };
  const calIVMatrix = async () => {
    let unprice = valueUnderlyingPrice;
    let exercisePrice = liveIndexSymbolObj.exercise_price_cal;
    var warrantsPerShare = liveIndexSymbolObj.conv_ratio_cal;
    var lasttradingdate = liveIndexSymbolObj.last_trading_date;
    var maturity = liveIndexSymbolObj.maturity;
    var type = liveIndexSymbolObj.type;
    var result;
    const TDays = await _caldateDiffInDays(startDate, maturity);
    for (var i = 1; i < unpricenum; i++) {
      for (var j = 1; j < ivnum; j++) {
        unprice = Number(ivMatrix[i][0].replace(",", ""));
        if (TDays < 0) {
          ivMatrix[i][j] = "-";
        } else {
          result = _calDWPriceWithDividen(
            unprice,
            exercisePrice,
            riskFreeRate,
            ivMatrix[0][j] / 100,
            TDays,
            warrantsPerShare,
            type
          );
          ivMatrix[i][j] = result.toFixed(3);
        }
      }
    }
    recalculateMatrix();
  };

  const _caldateDiffInDays = async (sdate, maturity) => {
    if (sdate && maturity) {
      const fDate = moment(new Date(sdate)).format("DD MMMM YYYY");
      const ndate = moment.utc(new Date(fDate));
      const eDate = moment.utc(new Date(maturity));
      return eDate.diff(ndate, "days");
    }
    return 0;
  };

  const _calDWPriceWithDividen = (
    unprice,
    exercisePrice,
    riskFreeRate,
    sigma,
    TDays,
    warrantsPerShare,
    type
  ) => {
    var divYield = parseFloat(liveIndexSymbolObj.div_yield / 100);
    if (
      unprice !== null &&
      exercisePrice !== null &&
      warrantsPerShare !== null &&
      type !== null &&
      divYield !== null
    ) {
      const S = Number(unprice * fxRate).toPrecision(10); //underlying price
      const X = Number(exercisePrice * fxRate).toPrecision(10); //exercise price
      let c = 0;
      const T = TDays / 365;
      const F = S * Math.exp((riskFreeRate - divYield) * T);
      let d1 =
        (Math.log(F / X) + ((sigma * sigma) / 2) * T) / (sigma * Math.sqrt(T));
      d1 = Number(d1).toPrecision(10);
      let d2 = d1 - sigma * Math.sqrt(T);
      d2 = Number(d2).toPrecision(10);

      if (type === "CALL") {
        c = Math.exp(-riskFreeRate * T) * (F * _calCND(d1) - X * _calCND(d2));
      } else if (type === "PUT") {
        c = Math.exp(-riskFreeRate * T) * (X * _calCND(-d2) - F * _calCND(-d1));
      }
      c = Number(c).toPrecision(10);
      return c / warrantsPerShare;
    }
    return 0;
  };

  const _calCND = (x) => {
    var a1 = 0.254829592;
    var a2 = 0.284496736;
    var a3 = 1.421413741;
    var a4 = 1.453152027;
    var a5 = 1.061405429;
    var p = 0.3275911;
    var t = 1 / (1 + (p * Math.abs(x)) / Math.sqrt(2));
    var value =
      (1 / 2) *
      (1 +
        _sign(x) *
          (1 -
            (a1 * t -
              a2 * Math.pow(t, 2) +
              a3 * Math.pow(t, 3) -
              a4 * Math.pow(t, 4) +
              a5 * Math.pow(t, 5)) *
              Math.exp((-x * x) / 2)));
    return value;
  };

  const _sign = (x) => {
    return x ? (x < 0 ? -1 : 1) : 0;
  };

  const calDelta = (
    unprice,
    exercisePrice,
    riskFreeRate,
    sigma,
    TDays,
    type
  ) => {
    var divYield = parseFloat(liveIndexSymbolObj.div_yield / 100);
    const S = unprice;
    const X = exercisePrice;
    const r = riskFreeRate;
    const T = Number(TDays) / 365;
    const F = S * Math.exp((r - divYield) * T);
    const d1 =
      (Math.log(F / X) + ((sigma * sigma) / 2) * T) / (sigma * Math.sqrt(T));
    let result = 0;
    if (type === "CALL") {
      result = _calCND(d1) * Math.exp(-divYield * T);
    } else if (type === "PUT") {
      result = -1 * _calCND(-d1) * Math.exp(-divYield * T);
    }
    return result;
  };
  const calEffGearing = (unprice, dwprice, wps, delta) => {
    const S = unprice;
    const wp = dwprice;
    var ef = Math.abs(delta) * (S / (wp / wps));
    return ef;
  };
  const startMatrix = () => {
    initIVMatrix();
  };
  const recalculateMatrix = async () => {
    // warrant bid price
    const calculatedWarrantBidPrice = getWarrantBidPrice(); // return string
    if (!isNaN(Number(calculatedWarrantBidPrice))) {
      const warrantBidPrice = calculatedWarrantBidPrice;
      setbid(warrantBidPrice);
    }
    // delta, effgearing
    const deltaAndEffgearing = await getDeltaAndEffGearingAndDwpChange();
    setdelta(deltaAndEffgearing.delta);
    setefgearing(deltaAndEffgearing.effgearing);
    setdwpchg(deltaAndEffgearing.upchg);
    setivMatrix(null);
    setivMatrixDisp(null);
  };
  const getDeltaAndEffGearingAndDwpChange = async () => {
    var uprice = +valueUnderlyingPrice;
    var dwprice = +ivMatrix[unpricenum / 2][ivnum / 2];
    var realUprice =
      liveIndexSymbolObj.underlying_price !== null
        ? Number(liveIndexSymbolObj.underlying_price.replace(",", ""))
        : 0;
    var realDWprice =
      liveIndexSymbolObj.BID !== null
        ? Number(liveIndexSymbolObj.BID.replace(",", ""))
        : 0;
    var udp =
      liveIndexSymbolObj.underlying_price !== null
        ? liveIndexSymbolObj.underlying_price.length -
          liveIndexSymbolObj.underlying_price.indexOf(".") -
          1
        : 0;
    var wdp =
      liveIndexSymbolObj.BID !== null
        ? liveIndexSymbolObj.BID.length -
          liveIndexSymbolObj.BID.indexOf(".") -
          1
        : 0;
    var type = liveIndexSymbolObj.type;
    var exercisePrice = liveIndexSymbolObj.exercise_price_cal;
    var conv_ratio = liveIndexSymbolObj.conv_ratio_cal;
    var upchg = (100 * (uprice - realUprice)) / realUprice;
    var dwpchg = (100 * (dwprice - realDWprice)) / realDWprice;

    var TDays = await _caldateDiffInDays(
      startDate,
      liveIndexSymbolObj.maturity
    );
    var delta, effgearing;
    var result = {};
    result.uprice = uprice.toFixed(udp);
    result.dwprice = dwprice.toFixed(wdp);
    result.upchg = upchg.toFixed(2);
    result.dwpchg = dwpchg.toFixed(1);

    if (TDays < 0) {
      result.delta = "";
      result.effgearing = "";
    } else {
      delta = calDelta(
        uprice,
        exercisePrice,
        riskFreeRate,
        ivMatrix[0][ivnum / 2] / 100,
        TDays,
        type
      );
      effgearing = calEffGearing(uprice, dwprice, 1 / conv_ratio, delta);
      result.delta = (delta * 100).toFixed(1);
      result.effgearing = effgearing.toFixed(1);
    }
    return result;
  };

  const getWarrantBidPrice = (matrixIndex = 3) => {
    return ivMatrix[matrixIndex][ivnum / 2];
  };
  const generateDateString = (noOfDay) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + noOfDay);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear().toString().slice(2);
    const selectedDate = day + " " + month + " " + year;
    return selectedDate;
  };
  const getDate = () => {
    return generateDateString(startDate);
  };

  function AffinHwang() {
    useGaEventsTracker("event", "Click", { event_category: "AHIB" });
    onClickPopHandle("https://einvest.affinhwang.com/", true);
  }

  function HongLeong() {
    useGaEventsTracker("event", "Click", { event_category: "HLIB" });
    onClickPopHandle("https://www.hlebroking.com/v3/", true);
  }

  function MalaccaSecurities() {
    useGaEventsTracker("event", "Click", { event_category: "MalaccaSec" });
    onClickPopHandle("https://www.mplusonline.com.my/macsecos/index.asp", true);
  }

  function PublicBank() {
    useGaEventsTracker("event", "Click", { event_category: "PublicBank" });
    onClickPopHandle(
      "https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx",
      true
    );
  }

  function UobKayHian() {
    useGaEventsTracker("event", "Click", { event_category: "UOB" });
    onClickPopHandle("https://www.utrade.com.my/", true);
  }

  return (
    <>
      <div id="calculator" className="pageobj" style={{}}>
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">Warrant tools</Link>
              </li>
              <li className="active">Warrant calculator</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 sm-w-100">
              <div className="section">
                <div className="page-header">
                  <h1>Warrant calculator</h1>
                  <div>
                    <input
                      className="btn btn-primary tradeEl tradeBtn"
                      defaultValue="Trade warrants here"
                      type="button"
                      data-bs-toggle="collapse"
                      href="#tradeEl5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="tradeEl5"
                      id="tradeButton31"
                    />
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        document
                          .getElementById("tradeEl5")
                          .classList.remove("show");
                        document
                          .getElementById("tradeButton31")
                          .setAttribute("aria-expanded", "false");
                      }}
                    >
                      <div
                        className="tradeDropDown tradeEl collapse"
                        id="tradeEl5"
                      >
                        <Link
                          className="btn btn-primary tradeEl"
                          to="/education/getstarted"
                          type="button"
                          target="_blank"
                          onClick={useGaEventsTracker("event", "Click", {
                            event_category: "GetStarted",
                          })}
                        >
                          How to get started
                        </Link>
                        <div className="dropdown-or tradeEl">
                          <hr className="tradeEl" />
                          <p className="tradeEl">or</p>
                          <hr className="tradeEl" />
                        </div>
                        <p className="tradeEl">Select your broker:</p>
                        <div className="row tradeEl">
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            defaultValue="Affin Hwang"
                            type="button"
                            onClick={AffinHwang}
                          />
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            link="https://www.hlebroking.com/v3/"
                            defaultValue="Hong Leong"
                            type="button"
                            onClick={HongLeong}
                          />
                        </div>
                        <div className="row tradeEl">
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            link="https://www.mplusonline.com.my/macsecos/"
                            defaultValue="Malacca Securities"
                            type="button"
                            onClick={MalaccaSecurities}
                          />
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            link="https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx"
                            defaultValue="Public Bank"
                            type="button"
                            onClick={PublicBank}
                          />
                        </div>
                        <div className="row tradeEl">
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            link="https://www.utrade.com.my/"
                            defaultValue="UOB Kay Hian"
                            type="button"
                            onClick={UobKayHian}
                          />
                          <input
                            className="btn btn-primary broker_link tradeEl"
                            style={{ visibility: "hidden" }}
                            link="https://www.utrade.com.my/"
                            defaultValue="Phillip "
                            type="button"
                          />
                        </div>
                      </div>
                    </OutsideClickHandler>
                  </div>
                </div>
                <p style={{ marginBottom: "10px" }}>
                  Estimates the theoretical price of a warrant based on your
                  input of the underlying price, date or implied volatility.
                </p>
                <div className="searchbox searchbox-toppage input-group">
                  <div id="powersearch">
                    <Select
                      placeholder={"Select warrant"}
                      onChange={(e) => getSelectedValue(e)}
                      options={symbolList}
                      styles={colourStyles}
                      value={"Select warrant"}
                    />
                  </div>
                </div>

                <div id="initloadingbox" style={{ position: "relative" }}>
                  <div className="ricbar">
                    <div id="ticker" className="ricbar-lbl">
                      {liveMatrixSelectedName}
                    </div>
                    <div>
                      <Link
                        to="/tools/livematrix/"
                        type="button"
                        className="ricbar-btn btn btn-block btn-primary"
                      >
                        View live matrix
                      </Link>
                    </div>
                  </div>
                  <div id="pstable">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <th>
                            <span className="underlyingprice">
                              Underlying{" "}
                              {liveIndexSymbolObj.underlying_ticker == "HSI" ||
                              liveIndexSymbolObj.underlying_ticker == "KLSE" ||
                              liveIndexSymbolObj.underlying_ticker == "S&P500"
                                ? "level"
                                : "price"}
                            </span>{" "}
                            (
                            <span className="uccy">
                              {liveIndexSymbolObj.underlying_curr}
                            </span>
                            )
                          </th>

                          <td className="left underlying_bid_price">
                            {liveIndexSymbolObj.underlying_price === undefined
                              ? ""
                              : liveIndexSymbolObj.underlying_price.toString()}
                          </td>
                        </tr>

                        <tr>
                          <th>
                            <span className="exerciseprice">
                              Exercise{" "}
                              {liveIndexSymbolObj.underlying_ticker == "HSI" ||
                              liveIndexSymbolObj.underlying_ticker == "KLSE" ||
                              liveIndexSymbolObj.underlying_ticker == "S&P500"
                                ? "level"
                                : "price"}
                            </span>{" "}
                            (
                            <span className="uccy">
                              {liveIndexSymbolObj.underlying_curr}
                            </span>
                            )
                          </th>
                          <td className="left exercise_price">
                            {liveIndexSymbolObj.exercise_price}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Warrant bid (
                            <span className="uccy">
                              {liveIndexSymbolObj.currency}
                            </span>
                            )
                          </th>
                          <td className="left BID">{liveIndexSymbolObj.BID}</td>
                        </tr>
                        <tr>
                          <th>
                            Warrant ask (
                            <span className="uccy">
                              {liveIndexSymbolObj.currency}
                            </span>
                            )
                          </th>
                          <td className="left ASK">{liveIndexSymbolObj.ASK}</td>
                        </tr>

                        <tr>
                          <th>Exercise ratio</th>
                          <td className="left conv_ratio">
                            {liveIndexSymbolObj.conv_ratio}
                          </td>
                        </tr>
                        <tr>
                          <th>Expiry</th>
                          <td className="left maturity">
                            {liveIndexSymbolObj.maturity}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h3 className="bold_title">Input field</h3>
                  <p style={{ marginBottom: 10 }}>
                    Move the sliders to estimate the price of the warrant (shown
                    under “Simulated results”) based on:
                  </p>
                  <p style={{ marginBottom: 5 }}>
                    1. your view of where the underlying will move to,
                  </p>
                  <p style={{ marginBottom: 5 }}>
                    2. the date until which you intend to hold the warrant, and
                  </p>
                  <p style={{ marginBottom: 5 }}>
                    3. your view of the warrant’s implied volatility.
                  </p>
                  <p className="tip_box">
                    Tip: Change only the date, and keep the underlying price and
                    implied volatility unchanged to estimate time decay.
                  </p>
                  <div id="wcalbox" className="sm-none">
                    <table>
                      <tbody>
                        <tr className="wcalbox-row-lbl">
                          <td className="wcalbox-col-lbl">
                            <span className="underlyingprice">
                              Underlying{" "}
                              {liveIndexSymbolObj.underlying_ticker == "HSI" ||
                              liveIndexSymbolObj.underlying_ticker == "KLSE" ||
                              liveIndexSymbolObj.underlying_ticker == "S&P500"
                                ? "level"
                                : "price"}
                            </span>{" "}
                            (
                            <span className="uccy">
                              {liveIndexSymbolObj.underlying_curr}
                            </span>
                            )
                          </td>
                          <td className="wcol-col-empty" />
                        </tr>
                        <tr className="wcalbox-row-ctr">
                          <td className="wcalbox-col-input">
                            <input
                              className="wcalbox-input underlying_bid_price"
                              type="text"
                              maxLength={10}
                              name="bdval"
                              value={
                                valueUnderlyingPrice ? valueUnderlyingPrice : 0
                              }
                              onBlur={(e) => {
                                setValueUnderlyingPrice(
                                  Number(e.target.value).toFixed(2)
                                );
                              }}
                              onChange={(e) => {
                                setinitial(false);
                                setValueUnderlyingPrice(e.target.value);
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1");
                              }}
                            />
                          </td>
                          <td className="wcalbox-col-slider">
                            <RangeSliderUnderlyingPriceComponent
                              Value={
                                valueUnderlyingPrice ? valueUnderlyingPrice : 0
                              }
                              SetValue={(val) => {
                                setinitial(false);
                                setValueUnderlyingPrice(Number(val).toFixed(2));
                              }}
                              max={maxUn}
                              step={unPriceProps.spread}
                              min={0}
                            />
                          </td>
                        </tr>
                        <tr className="wcalbox-row-lbl">
                          <td className="wcalbox-col-lbl">Date</td>
                          <td></td>
                        </tr>
                        <tr className="wcalbox-row-ctr">
                          <td className="wcalbox-col-input">
                            <DatePicker
                              selected={startDate}
                              dateFormat="dd MMM yy"
                              minDate={moment().toDate()}
                              onChange={(date) => {
                                setinitial(false);
                                setStartDate(date);
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              maxTime={
                                new Date(
                                  liveIndexSymbolObj.last_trading_date
                                ).getTime() + 86400000
                              }
                              minTime={+new Date().getTime()}
                              className="wcalbox-input"
                            />
                          </td>
                          <td className="wcalbox-col-slider">
                            <div
                              className="slider slider-horizontal"
                              id="dt-slider"
                            >
                              <RangeSliderDateSelectionComponent
                                value={startDate}
                                setValue={(val) => {
                                  setinitial(false);
                                  setStartDate(val);
                                }}
                                startMatrix={startMatrix}
                                max={
                                  new Date(
                                    liveIndexSymbolObj.last_trading_date ==
                                    undefined
                                      ? new Date()
                                      : liveIndexSymbolObj.last_trading_date
                                  ).getTime() + 86400000
                                }
                                min={+new Date().getTime()}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr className="wcalbox-row-lbl iv-slider-top">
                          <td className="wcalbox-col-lbl">
                            Implied volatility (%)
                          </td>
                          <td />
                        </tr>
                        <tr className="wcalbox-row-ctr iv-slider-bottom">
                          <td className="wcalbox-col-input">
                            <input
                              className="wcalbox-input underlying_bid_price"
                              type="text"
                              maxLength={6}
                              name="bdval"
                              value={
                                valueImpliedVolatility == "N/A" ||
                                valueImpliedVolatility == undefined
                                  ? 0
                                  : valueImpliedVolatility
                              }
                              onChange={(e) => {
                                setinitial(false);
                                setValueImpliedVolatility(e.target.value);
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1");
                              }}
                            />
                          </td>
                          <td className="wcalbox-col-slider">
                            <RangeSliderImpliedVolatilityComponent
                              Value={
                                valueImpliedVolatility == "N/A" ||
                                valueImpliedVolatility == undefined
                                  ? 0
                                  : valueImpliedVolatility
                              }
                              SetValue={(val) => {
                                setinitial(false);
                                setValueImpliedVolatility(val);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p style={{ margin: "10px 0" }}>
                      Note that the warrant’s{" "}
                      <Link
                        to="/edu/useful-warrant-terms/implied-volatility"
                        target="_blank"
                      >
                        implied volatility
                      </Link>{" "}
                      is affected by market related events and/or demand for the
                      warrant.
                    </p>
                    <div className="resetbtn">
                      <Link
                        type="button"
                        className="btn btn-primary"
                        to="/tools/warrantcalculator"
                      >
                        Reset
                      </Link>
                    </div>
                  </div>
                  <div id="wcalbox" className="sm-view">
                    <table>
                      <tbody>
                        <tr className="wcalbox-row-lbl">
                          <td className="wcalbox-col-lbl">
                            <span className="underlyingprice">
                              Underlying{" "}
                              {liveIndexSymbolObj.underlying_ticker == "HSI" ||
                              liveIndexSymbolObj.underlying_ticker == "KLSE" ||
                              liveIndexSymbolObj.underlying_ticker == "S&P500"
                                ? "level"
                                : "price"}
                            </span>{" "}
                            (
                            <span className="uccy">
                              {liveIndexSymbolObj.underlying_curr}
                            </span>
                            )
                          </td>
                          <td className="wcalbox-col-lbl">Date</td>
                          <td className="wcalbox-col-lbl border-das">
                            Implied volatility (%)
                          </td>
                        </tr>
                        <tr className="wcalbox-row-ctr">
                          <td className="wcalbox-col-input">
                            <input
                              className="wcalbox-input underlying_bid_price"
                              type="text"
                              maxLength={10}
                              name="bdval"
                              value={
                                valueUnderlyingPrice ? valueUnderlyingPrice : 0
                              }
                              onBlur={(e) => {
                                setValueUnderlyingPrice(
                                  Number(e.target.value).toFixed(2)
                                );
                              }}
                              onChange={(e) => {
                                setinitial(false);
                                setValueUnderlyingPrice(e.target.value);
                              }}
                            />
                          </td>
                          <td className="wcalbox-col-input">
                            <DatePicker
                              selected={startDate}
                              dateFormat="dd MMM yy"
                              minDate={moment().toDate()}
                              onChange={(date) => {
                                setinitial(false);
                                setStartDate(date);
                              }}
                              maxTime={
                                new Date(
                                  liveIndexSymbolObj.last_trading_date
                                ).getTime() + 86400000
                              }
                              minTime={+new Date().getTime()}
                              className="wcalbox-input"
                            />
                          </td>
                          <td className="wcalbox-col-input border-das">
                            <input
                              className="wcalbox-input underlying_bid_price"
                              type="text"
                              maxLength={6}
                              name="bdval"
                              value={valueImpliedVolatility}
                              onChange={(e) => {
                                setinitial(false);
                                setValueImpliedVolatility(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p style={{ margin: "10px 0" }}>
                      Note that the warrant’s{" "}
                      <Link
                        to="/edu/useful-warrant-terms/implied-volatility"
                        target="_blank"
                      >
                        implied volatility
                      </Link>{" "}
                      is affected by market related events and/or demand for the
                      warrant.
                    </p>
                    <div className="resetbtn">
                      <Link
                        type="button"
                        className="btn btn-primary"
                        to="/tools/warrantcalculator"
                      >
                        Reset
                      </Link>
                    </div>
                  </div>
                  <h3 className="bold_title">Simulated results</h3>
                  <div id="srtable">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th className="srtable-leftlbl bgcolor-01">
                            <span className="underlyingprice">
                              Underlying{" "}
                              {liveIndexSymbolObj.underlying_ticker == "HSI" ||
                              liveIndexSymbolObj.underlying_ticker == "KLSE" ||
                              liveIndexSymbolObj.underlying_ticker == "S&P500"
                                ? "level"
                                : "price"}
                            </span>{" "}
                            (
                            <span className="uccy">
                              {liveIndexSymbolObj.underlying_curr}
                            </span>
                            )
                          </th>
                          <td className="srtable-leftval bgcolor-01 underlying_price">
                            {valueUnderlyingPrice === undefined
                              ? ""
                              : valueUnderlyingPrice.toString()}
                          </td>

                          <th className="srtable-rightlbl bgcolor-02">
                            Delta (%)
                          </th>

                          <td className="sttable-rightlbl bgcolor-02 delta">
                            {delta == "-0.0" ? "0.0" : delta}
                          </td>
                        </tr>
                        <tr>
                          <th className="srtable-leftlbl">
                            Underlying change (%)
                          </th>
                          <td className="srtable-leftval upchg">{dwpchg}</td>

                          <th className="srtable-rightlbl">
                            Effective gearing (x)
                          </th>

                          <td className="srtable-rightval effective_gearing">
                            {efgearing == "NaN"
                              ? "0.0"
                              : efgearing == "Infinity"
                              ? "0.0"
                              : efgearing == "-Infinity"
                              ? "0.0"
                              : efgearing}
                          </td>
                        </tr>
                        <tr>
                          <th className="srtable-leftlbl bgcolor-01">
                            Warrant bid price (MYR)
                          </th>

                          <td className="srtable-leftval bgcolor-01 BID">
                            {bid}
                          </td>
                        </tr>
                        <tr>
                          <th className="srtable-leftlbl">Change (%)</th>
                          <td className="srtable-leftval dwpchg">
                            {(
                              (100 * (bid - liveIndexSymbolObj.BID)) /
                              liveIndexSymbolObj.BID
                            ).toFixed(1) != "NaN"
                              ? (
                                  (100 * (bid - liveIndexSymbolObj.BID)) /
                                  liveIndexSymbolObj.BID
                                )
                                  .toFixed(1)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : "0.0"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="small">
                    The simulated results above are indicative only.
                  </p>
                  <hr className="hr-hmgbm" />
                  <p className="small">
                    All market data is delayed 15 mins unless otherwise
                    indicated by timestamp.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 sm-none xs-none">
              <Slider {...settings} className="ad-section custom">
                {advImgVal &&
                  advImgVal.map((data, index) => {
                    return (
                      <a target="_blank" href={data.link} key={index}>
                        <div>
                          <img src={data.imgSrc} />
                        </div>
                      </a>
                    );
                  })}
              </Slider>

              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Live matrix</h2>
                </div>
                <p>
                  Makes trading warrant easy by showing investors where our bid
                  prices will be throughout the day
                </p>
                <div className="section-qlink-img section-qlink-lmatrix">
                  <Link to="/tools/livematrix/" className="section-qlink-achor">
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Warrant search</h2>
                </div>
                <p>
                  Find the right warrant according to your investment criteria.
                </p>
                <div className="section-qlink-img section-qlink-dwsearch">
                  <Link
                    to="/tools/warrantsearch"
                    className="section-qlink-achor"
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WarrantTermsModal
        Url={url}
        Open={open}
        OpenModal={() => setOpen(!true)}
      />
    </>
  );
};

export default WarrantCalculators;
