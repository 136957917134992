import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link , useParams, useLocation} from "react-router-dom";
import "./indexWarrant.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IndexModal from "./IndexModal";
import DataModal from "./DataModal";
import IndexWarrantModal from "../../../components/common/IndexWarrant"
import OutsideClickHandler from "react-outside-click-handler";
import { Base_Url } from "../../../utils/apiUrl";

const IndexWarrants = (props) => {
  const {underlying} = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [show, setShow] = useState(false);
  const [keyPara, setKeyPara] = useState();
  const [value, setValue] = useState();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false)
  const [swiping, setswiping] = useState(false)
  const [termPopupCss, setTermPopupCss] = useState("none")

  const onClickPopHandle = (value) => { 
    setOpen1(value)
  }
  useEffect(() => {
    if(underlying){
      var val = null;
      var ric= underlying.toUpperCase()
      val= ric=='NDX'?1:ric=='DJIA'?2:ric=='HSI'?3:ric=='HSTECH'?4:ric=='KLSE'?5:ric=='2823'?6:ric=='N225'?8:ric=='2840.HK'?9:7
      setValue(val)
      setOpen(true)
    }
  }, []);
  const settings = {
    infinite: true,
    slidesToShow: 5,
    speed:500,
    slidesToScroll: 1,
    swipeToSlide: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '10%',
        }
  },      {
    breakpoint: 900,
    settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0%',
    }
  },    {
    breakpoint: 700,
    settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '15%',
    }
  },   {
    breakpoint: 550,
    settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '15%',
    }
  },  {
    breakpoint: 400,
    settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0%',
    }
  }],
  
  
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          {" "}
          <i className="fa fa-angle-right"></i>{" "}
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          {" "}
          <i className="fa fa-angle-left"></i>{" "}
        </div>
      </div>
    ),
  };

  const keyData = {
    key1: "Why-trade-index-warrants",
    key2: "How-to-trade-index-warrants",
    key3: "How-to-calculate",
    key4: "Index-warrants-issued-mq",
  };

  const Image = [
    {
      value: 8,
      src: "/img/bg/Index_warrants_SG_Nikkei.png",
      text: "Nikkei Stock Average (Nikkei 225) Index",
    },
    {
      value: 9,
      src: "/img/bg/2840HK_tile.png",
      text: "SPDR® Gold Trust",
    },
    {
      value: 3,
      src: "/img/bg/hsti_tile.png",
      text: "Hang Seng Index",
    },
    {
      value: 4,
      src: "/img/bg/hktech_tile.png",
      text: "Hang Seng TECH Index",
    },
    {
      value: 5,
      src: "/img/bg/malaysia_tile.png",
      text: "FTSE Bursa Malaysia KLCI",
    },
    {
      value: 6,
      src: "/img/bg/china_tile.png",
      text: "iShares China A50 ETF",
    },
    {
      value: 7,
      src: "/img/bg/us_tile.png",
      text: "The Standard & Poor's 500 Index",
    },
    {
      value: 1,
      src: "/img/bg/nasdq_tile.png",
      text: "The NASDAQ-100 Index",
    },
    {
      value: 2,
      src: "/img/bg/dow_tile.png",
      text: "The Dow Jones Industrial Average Index",
    },
  ];


  

  return (
    <>
    <div id="indexdw" className="pageobj" style={{}}>
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?edu">Warrant education</Link>
            </li>
            <li className="active">Index warrants</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />


      {/* <div className="container-fluid">
        <div className="row title_bg">
          <div className="col-md-6" style={{padding: 0, backgroundSize: 'cover', backgroundImage: 'url(/img/education/USIW_image.jpg)', width: '50%', height: '460px'}}>
          </div>
          <div className="col-md-6 title_right">
            <div className="grab_vouchers">Stand a chance to win <span className="color-yellow">RM50</span> worth of <span className="color-yellow">Grab vouchers</span></div>
            <div className="banner_400"><span className="color-yellow">400</span> vouchers to be won!</div>
            <div className="banner-right"><b>Contest Period</b>: 1 January 2023 to 28 February 2023</div>
            <div className="joinTitle"><b>How to join?</b></div>
            <ol className="joinSize">
              <li> Subscribe to our newsletter, and follow us on Facebook and Telegram </li>
              <li> A link to the contest question will be sent on ANY of these platforms during the contest period </li>
              <li> Be the <b>first 100 participants</b> to correctly answer the contest question on US Index warrants and win a Grab voucher worth RM50!</li>
            </ol>
            <div className="row banner-right">
              <div className="col-md-6 one"> 
                <span>
                  <a target="_black" className="sharebtn-fb btn" type="button" href="https://www.facebook.com/MalaysiaWarrants/" style={{height: '50px', width: '50px', lineHeight: '1.42857143'}} />
                  <a target="_black" className="sharebtn-tl btn" type="button" href="https://web.telegram.org/#/im?p=@Malaysiawarrants" style={{height: '50px', width: '50px', marginLeft: '14px', lineHeight: '1.42857143'}} />
                </span>
              </div>
              <div className="col-md-6 one" style={{textAlign: 'right', paddingLeft: '15%'}}>
                <div className="subscribe_btn" onClick={() => onClickPopHandle(true)}>
                  <p style={{fontWeight: 'bold'}}>
                    Subscribe to our Daily
                    <br />Newsletter
                  </p>
                </div>
                <button to="" style={{color: 'white', fontSize: '10px', backgroundColor: 'transparent'}} onClick={() => setTermPopupCss("block")}><u>*Terms and conditions apply</u></button>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>What is an index warrant? </h1>
          </div>
          <p>
            An index warrant is a structured warrant (“SW”) that is issued over
            an underlying index e.g. the Hang Seng Index or the FTSE Bursa
            Malaysia KLCI. The index call warrant tracks the performance of the
            underlying index i.e. it will tend to increase in value when the
            underlying index increases, while index put warrants move in an
            opposite direction to the underlying index, meaning it tends to
            increase in value when the underlying index decreases.
            <br />
            <br />
            Click on a tile below to learn more about each underlying index.
          </p>
            <div id="topimggrip" className="imggrip row">
              <Slider {...settings} afterChange={ () => setswiping(false) } onSwipe={ () => setswiping(true) }>
                {Image.map((d, index) => (
                  <div
                    className="imggrip-col col-xs-4 col-sm-5th"
                    key={index}
                    onMouseOver={() => setValue(d.value)}
                    onClick={() => {
                      if(swiping){
                        return
                      }
                      setOpen(true)
                    }}
                  >
                    <img src={d.src} />
                    <div className="imggrip-col-txt">
                      <h3 className="imggrip-col-h">{d.text}</h3>
                      <span className="imggrip-col-a">More info</span>
                    </div>
                    {(d.value === 9) && (
                      <div className="new-icon bgcolor-06">NEW!</div>
                    )}
                  </div>
                ))}
              </Slider>
            </div>
          <div className="livematrix-row row-fullw row">
            <div className="livematrix-col col-xs-12 col-sm-6">
              <table className="col-txtarea">
                <tbody>
                  <tr>
                    <td>
                      <h2 className="col-h">Live matrix</h2>
                      <p className="col-p">
                        Visit the Live Matrix page to see how you can utilize
                        this powerful tool to track the movements of your index
                        warrants.
                      </p>
                      <a
                        className="moreinfobtn"
                        target="_blank"
                        href="/tools/livematrix/"
                      >
                        View Live Matrix
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="livematrix-col ipad-col col-xs-12 col-sm-6">
              <div className="imggrip-col">
                <img src="/img/bg/livematrix_iPad-only_bg.png" />
              </div>
            </div>
          </div>
          <div className="page-header">
            <h1>Want to know more?</h1>
          </div>
          <p>
            Click on a section below to get more in depth information on
            warrants.
          </p>
          {/* <OutsideClickHandler onOutsideClick={() => setShow(false)}> */}
        <div id="wtkm">
          
              <div className="row">
                <div
                  className="imggrip-col col-sm-6"
                  onMouseOver={() => setKeyPara(keyData.key1)}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <img
                    className="ppbtn"
                    key={keyData.key1}
                    src="/img/bg/whytradeindexwarrant.png"
                  />
                </div>
                <div
                  className="imggrip-col col-sm-6"
                  onMouseOver={() => setKeyPara(keyData.key2)}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <img
                    className="ppbtn"
                    key={keyData.key2}
                    src="/img/bg/howtotradeindexwarrants.png"
                  />
                </div>
              </div>
             
            <div className="row">
              <div
                className="imggrip-col col-sm-6"
                onMouseOver={() => setKeyPara(keyData.key3)}
                onClick={() => {
                  setShow(true);
                }}
              >
                <img
                  className="ppbtn"
                  key={keyData.key3}
                  src="/img/bg/bot_left.jpg"
                />
              </div>
              <div
                className="imggrip-col col-sm-6"
                onMouseOver={() => setKeyPara(keyData.key4)}
                onClick={() => {
                  setShow(true);
                }}
              >
                <img
                  className="ppbtn"
                  key={keyData.key4}
                  src="/img/bg/bot_right.jpg"
                />
              </div>
            </div>
        </div>
        {/* </OutsideClickHandler> */}
         
          <hr className="hr-hmgbm" />
          <p className="small">
            All data sourced from Bloomberg and the official websites of the
            underlying index sponsor or ETF manager (as applicable) as of
            December 2021.
          </p>
          <p className="small">*Nikkei Stock Average (Nikkei 225) statement of rights and disclaimer - August 2023:</p>
            <p className="small">Nikkei Inc. is the sole exclusive owner of the copyright and other intellectual property rights in the Nikkei Stock Average (Nikkei 225) itself and the methodology to calculate thereof, The intellectual property and any other rights in the marks to indicate Nikkei and the Nikkei 225 shall be vested in Nikkei Inc.;</p>
            <p className="small">Nikkei Inc. does not sponsor, support, sell or market the “Warrants”. The “Warrants” are managed exclusively at the risk of the “Licensee”, and Nikkei Inc. shall assume no obligation or responsibility for management of and transactions involving the “Warrants”.</p>
            <p className="small">Nikkei Inc. shall not have the obligation to continuously announce the Nikkei 225 and shall not be liable for any error, delay, interruption, suspension or cessation of announcement thereof. Nikkei Inc. shall have the right to change the description of the stocks included in the Nikkei 225, the calculation methodology of the Nikkei 225 or any other details of the Nikkei 225</p>
        </div>
      </div>
      {show && <IndexModal open={show} setShow={setShow} keyValue={keyPara} />}
      {open && <DataModal open={open} setOpen={setOpen} keyValue={value} />}
    </div>
      <IndexWarrantModal
        Open={open1} 
        OpenModal={() => setOpen1(!true)}
         
      />

      <div className="main-disclaimer-overlay main-p index" id="disclaimer2" style={{display: termPopupCss, height: '100%'}}>
        <div className="disclaimer-overlay-inner"><div style={{padding: '10px'}}>
            <div className="disclaimer-page-header" style={{textAlign: 'left'}}>
              
              <div style={{position: 'relative'}}>
                <img className="btn-close" src="/img/bullet/icon-close-black.png"  onClick={() => setTermPopupCss("none")} />
                
                <div className="bold" style={{textAlign: 'center', fontSize: '22px', padding: '10px 0px'}}>TERMS AND CONDITIONS – MALAYSIA WARRANTS ANSWER AND WIN CONTEST</div>
                
                <div className="popupbox-content">
                  <p>1. The Malaysia Warrants Answer and Win Contest (“Contest”) organized by Macquarie Capital Securities (Malaysia) Sdn Bhd (“Organizer”) will be held on a webpage within its website https://malaysiawarrants.com.my (“Contest Page”) anytime in January 2023 up to February 2023 (or as updated otherwise) as announced by the Organizer ("Contest Period"), with the link to the Contest Page made available via the Macquarie Warrants Malaysia Facebook page (available on https://www.facebook.com/MalaysiaWarrants), Telegram channel (available on https://t.me/malaysiawarrants) and/or the daily newsletter (subscription via the link {Base_Url}/marketnews/subscribe; the “Newsletter”) anytime within the Contest Period.</p>
                  <div className="bold">ELIGIBILITY</div><br />
                  <p>2. The Contest is open to all citizens and permanent residents of Malaysia above the age of 18, except for permanent and/or contract employees of the Organizer, as well as their parents, siblings, spouses and children. The Organizer reserves the right but does not have the obligation to verify the eligibility of the participants. </p>
                  <p>3. Participants must ensure that their email addresses provided on the Contest Page are accurate to be eligible for the Contest. Please note that winners of the Contest will be awarded via the email address provided.</p>
                  <div className="bold">CONTEST MECHANISM AND PRIZES</div><br />
                  <p>4. There will be four contest sessions (each a “Contest Session”) within the Contest Period. </p>
                  <p>5. At anytime during the Contest Period, the link to the Contest Page for each Contest Session shall be made available on any one (1), any two (2), or all of these platforms:</p>
                  <p>         a) Macquarie Warrants Malaysia Facebook page (available on https://www.facebook.com/MalaysiaWarrants),</p>
                  <p>         b) Telegram channel (available on https://t.me/malaysiawarrants) and, </p>
                  <p>         c) the daily newsletter (subscription via the link {Base_Url}/marketnews/subscribe; the “Newsletter”)</p>
                  <p>The first one hundred (100) fastest participants to correctly answer all the questions posted on the Contest Page (based on the time of receipt of the submission on the Contest Page) for each Contest Session will win one (1) Grab voucher each valued at fifty Malaysian Ringgit only (MYR 50.00). </p>
                  <p>For the avoidance of doubt, each Contest Session will award the first one hundred (100) fastest participants, meaning there can be a maximum of four hundred (400) winners across the four Contest Sessions. </p>
                  <p>6. With respect to prizes, if there are two (2) or more participants contesting for the one hundredth (100th) place having submitted their answers at the same time (i.e. a tie), the Organizer shall contact the said participants via email, who will be required to reply and answer a specific question within three (3) calendar days from the send date of the email/notification. The participant with the best answer, as determined by the Organizer, shall be the winner. </p>
                  <p>7. Participants will have until 11:59pm Malaysia time on the seventh (7th) calendar day from the date the link to the Contest Page is made available for a particular Contest Session (the “Contest Session Period”) to participate in the Contest. Submission of the answers to the questions posted on the Contest Page must be received by the Organizer during the Contest Session Period. At the end of the Contest Session Period, if there are less than one hundred (100) participants who have correctly answered all the question(s) on the Contest Page, the remaining prizes that have not been awarded shall be forfeited. For avoidance of doubt, each participant can only win one (1) prize throughout the whole Contest Period, regardless of the number of email accounts they may have used to participate in the Contest. Participants found cheating will be disqualified immediately.</p>
                  <p>8. The Organizer will not be held responsible for any late, lost or misdirected submission of answers, including submissions not received or delay in receipt of the submissions due to the breakdown or failure of transmission and communication facilities, technical issue or for any other reason.</p>
                  <div className="bold"> NOTIFICATION OF REWARDS</div><br />
                  <p>9. The Organizer shall send the winners the prize of Grab vouchers worth MYR 50.00 each via the email addresses submitted on the Contest Page within five (5) business days after the end of the relevant Contest Session Period. </p>
                  <div className="bold">GENERAL TERMS</div><br />
                  <p>10. By participating in the Contest, you confirm that you have read, understand and agree to be bound by the Terms and Conditions herein and further agree that any decision by the Organizer in relation to every aspect of the Contest, including the awarding of the rewards, shall be final, binding and conclusive. No appeals, demands or protests (whether verbal or written) will be entertained.</p>
                  <p>11.	In the event there is reasonable suspicion that you have tampered with or benefited from tampering the entry process or the operation of the Contest or have acted in violation of the Terms and Conditions stated herein, the Organizer reserves the sole and absolute right and discretion to disqualify all entries and prohibit you from further participation in this Contest.</p>
                  <p>12.	The prizes are non-transferable to any third party, non-negotiable, non-exchangeable and cannot be used in conjunction with any other offers or promotions.</p>
                  <p>13.	The Organizer has the right and discretion to add and/or remove and/or substitute the reward(s) with any other item(s) or service(s) of similar value.</p>
                  <p>14.	The winners shall personally bear and be responsible for ALL incidental costs relating to the collection and/or usage of the prize(s) including but not limited to any applicable registration fees, processing fees, administrative fees, fuel charges, courier and/or delivery fees and all other personal expenses.</p>
                  <p>15.	Picture(s) of the reward(s) shown in any advertising, promotional, publicity and other materials relating to or in connection with the Contest are solely for illustration purposes only and may not depict the actual colour, brand or specifications of the reward(s).</p>
                  <p>16.	The Organizer gives no representation or warranty with respect to the quality or suitability of the prizes. The winners shall deal directly with the supplier/manufacturer of the prizes for all warranty information and any dispute with respect to the quality, merchantability, fitness of purposes of the prizes.</p>
                  <p>17.	The prizes may carry the supplier(s)/manufacturer(s) own terms and conditions and those terms and conditions shall be applicable, in addition to these Terms and Conditions contained herein.</p>
                  <p>18.	The Organizer reserves the sole and absolute right and exclusive discretion to change, vary and/or amend any aspect of the Contest (including cancelling or discontinuing the Contest inclusive of rewards which shall be awarded) or any part of these Terms and Conditions without prior notice and/or reference to any party.</p>
                  <div className="bold">USE OF PERSONAL INFORMATION</div><br />
                  <p>19.	By participating in the Contest, you shall be deemed to have given consent to the Organizer to collect, store, process and use your personal data in accordance with the Organizer’s Privacy Policy (which is available at {Base_Url}/about/privacypolicy), and for the purposes of organizing, administering, advertising and promoting the Contest at its sole and absolute discretion (“Approved Purpose”). You will also automatically receive the daily Macquarie warrants newsletter and invitations for warrant seminars and other warrant related initiatives. The Organizer’s website administrators may have access to your personal data for the processing of such data to be used only for the Approved Purpose on behalf of the Organizer and other companies of Macquarie Group. </p>
                  <p>20.	You may gain access to any personal information that the Organizer holds about you by contacting the Organizer and accessing the privacy policy available on the Organizer’s website or requested directly from the Organizer.</p>
                  <p>21.	By providing the personal data of any third party (“Third Party Data Subject”) in connection with this Contest or otherwise, you shall be deemed to have obtained the consent and authorization of each such Third Party Data Subject for the Organizer to collect, store, process, use, or disclose the Third Party Data Subject’s personal data as set out in paragraphs 19 to 20 above.</p>
                  <div className="bold">DISCLAIMER</div><br />
                  <p>22.	You agree to and acknowledge the Disclaimer ({Base_Url}/about/disclaimer). </p>
                  <p>23.	The Organizer shall not be held liable and/or responsible for any loss, damage or any other form of liability in whatsoever nature and howsoever arising or suffered by any participants resulting directly or indirectly from participating in the Contest or resulting from the acceptance, possession, use/misuse of the prize(s) in this Contest.</p>
                  <p>24.	The information provided in the Contest is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to laws or regulations or which would subject the Organizer to any registration requirement within such jurisdiction or country. Neither the information, nor any opinion distributed on social media and/or contained in the Contest constitutes a solicitation or offer by the Organizer to buy or sell any securities, futures, options or other financial instruments or provide any investment advice or service whatsoever and cannot under any circumstances be relied upon for investment dealings of whatever nature. You are advised to obtain separate independent professional advice before making any decision to trade/invest in securities or invest in any financial instruments.</p>
                  <p>25.	Whilst every care has been taken in preparing the information materials contained in the Contest, such information is provided on an “as is” basis without any warranty or indemnity of any kind either expressly or implied, including but not limited to any implied warranties or indemnities or implied terms of satisfactory quality, fitness for a particular purpose or non-infringement. All such implied terms, warranties and indemnities are hereby excluded.</p>
                  <p>26.	The Organizer will not be held responsible for any loss or damage that could result from interception by any third parties of any information made available to you during the Contest. Although the information provided to you during the Contest is obtained or compiled from sources we believe to be reliable, the Organizer cannot and does not guarantee the accuracy, validity, timeliness or completeness of any information or data made available to you for any particular purpose. </p>
                  <p>27.	In no event will the Organizer or any such parties be liable to you for any other damages of any direct, special, indirect, consequential, incidental damages or any other damages of any kind even if the Organizer or any other party have been advised of the possibility thereof.</p>
                  <p>28.	Nothing contained in the Contest constitutes an offer to sell any securities and other goods or services of whatever nature and cannot under any circumstances be relied upon for investment dealings of whatever nature.</p>
                  <p>29. If any provision of these disclaimers and exclusions shall be unlawful, void or any reason unenforceable then that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions.</p>
                  <div className="bold">LIABILITY</div><br />
                  <p>30.	By participating in the Contest, you are deemed to have agreed and accepted that the Organizer will not be held liable under any circumstances, including negligence, for any direct, indirect or consequential loss arising from your use of the information and material distributed during the Contest. The Organizer shall also not be liable for any material provided by third parties with their own respective copyright and shall not under any circumstances, be liable for any loss, damages or injury arising from these materials.</p>
                  <p>31. You hereby undertake to fully indemnify the Organizer for any damages, losses, expenses and cost that may arise as a direct or indirect consequence of a breach on your part of any provisions of these Terms and Conditions, in addition and without prejudice, to any other right that the Organizer may have as contained herein.</p>
                  <p>32. In the event that the Organizer failed and/or neglected to enforce any provision or remedy as provided in these Terms and Conditions, we shall not be construed as having waived our rights to enforce the same.</p>
                  <div className="bold">EXCLUSIONS</div><br />
                  <p>33. This Contest shall be governed by the Laws of Malaysia and subject to the exclusive jurisdiction of the Courts of Malaysia.</p>
                  <p>34. The exclusions and limitations described herein shall apply only to the extent permitted by law, without prejudice to our rights to seek legal redress in accordance with the applicable laws.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
      </>
  );
};
export default IndexWarrants;
