import React, { useState, useEffect } from "react";
import "./underlyingcharts.css";
import { get } from "lodash";
import Select from "react-select";
import { config } from "../../../utils/apiUrl";
import API from "../../../utils/apiCalling";
import { Link, useParams, useHistory} from "react-router-dom";
import { faL, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { googleCollect, googleCollectData } from "../../../utils/googleAPi";
import { getUnderLing } from "../../../utils/underLying.api";
// import StockChart from "./StockChart";
//import HighChartData from './HighChartData'
import Chart from "./classComponent"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const UnderLyingCharts = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  const { id } = useParams();
  let history = useHistory()
  const api = new API();
  const [symbolList, setSymbolList] = useState([]);
  const [comapareList, setCompareList] = useState([]);
  const [type, setType] = useState("u");
  const [ric, setRic] = useState(id ? id : "");
  const [liveMatrixSelectedName, setLiveMatrixSelectedName] = useState();
  const [liveIndexSymbolFirstIdx, setLiveIndexSymbolFirstIdx] = useState({});
  const [ricValue, setRicValue] = useState(id ? id : 'AEOM.KL');
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [chartData, setChartData] = useState();
  const [period, setPeriod] = useState("D");
  const [status, setStatus] = useState();
  const [day, setDay] = useState("10");
  const [chartType, setChartType] = useState("bar");
  const [rsi, setrsi] = useState(14)
  const [macd1, setmacd1] = useState(12)
  const [macd2, setmacd2] = useState(26)
  const [macd3, setmacd3] = useState(9)
  const [bol, setbol] = useState(20)
  const [showbol, setshowbol] = useState(false)
  const [displayValue, setDisplayValue] = useState(true)
  const [babaBanner, setBabaBanner] = useState("d-none")
  const [bydBanner, setBydBanner] = useState("d-none")
  const [xiaomiBanner, setXiaomiBanner] = useState("d-none")
  const [tencentBanner, setTencentBanner] = useState("d-none")
  const [geelyBanner, setGeelyBanner] = useState("d-none")
  const [meituanBanner, setMeituanBanner] = useState("d-none")
  const [sunnyBanner, setSunnyBanner] = useState("d-none")
  const [pingAnBanner, setPingAnBanner] = useState("d-none")
  const [ganfengBanner, setGanfengBanner] = useState("d-none")
  const [kuaishouBanner, setKuaishouBanner] = useState("d-none")
  const [jdBanner, setJdBanner] = useState("d-none")

  const [advImgVal, setAdvImgVal] = useState([])


  useEffect(() => {
    getHomePopup()
  }, [])
  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };

  useEffect(() => {
    getWarrantTermsSelect();
    googleCollectResponse();
    googleCollectResponseData();
  }, []);

  useEffect(() => getUnderlyingData(), [period, day, chartType]);

  useEffect(() => {
    if (ricValue) {
      if(ricValue == "1211.HK"){
        setDisplayValue(false)
        setBydBanner("")
      }else if(ricValue == "9988.HK"){
        setDisplayValue(false)
        setBabaBanner("")
      }else if(ricValue == "1810.HK"){
        setDisplayValue(false)
        setXiaomiBanner("")
      }else if(ricValue == "0700.HK"){
        setDisplayValue(false)
        setTencentBanner("")
      }else if(ricValue == "0175.HK"){
        setDisplayValue(false)
        setGeelyBanner("")
      }else if(ricValue == "3690.HK"){
        setDisplayValue(false)
        setMeituanBanner("")
      }else if(ricValue == "2382.HK"){
        setDisplayValue(false)
        setSunnyBanner("")
      }else if(ricValue == "2318.HK"){
        setDisplayValue(false)
        setPingAnBanner("")
      }else if(ricValue == "1772.HK"){
        setDisplayValue(false)
        setGanfengBanner("")
      }else if(ricValue == "1024.HK"){
        setDisplayValue(false)
        setKuaishouBanner("")
      }else if(ricValue == "9618.HK"){
        setDisplayValue(false)
        setJdBanner("")
      }else{
        setDisplayValue(true)
        setBabaBanner("d-none")
        setBydBanner("d-none")
        setXiaomiBanner("d-none")
        setTencentBanner("d-none")
        setGeelyBanner("d-none")
        setMeituanBanner("d-none")
        setSunnyBanner("d-none")
        setPingAnBanner("d-none")
        setGanfengBanner("d-none")
        setKuaishouBanner("d-none")
        setJdBanner("d-none")
      }
      getWarrantToolsLiveMatrix();
    }
  }, [ricValue]);
  const getUnderlyingTickerDisplayName = (val,name,type)=>{
    let UnderlyingtickerdisplaynameMap = JSON.parse(window.localStorage.getItem('UnderlyingtickerdisplaynameMap'))
    let value = UnderlyingtickerdisplaynameMap.filter((v)=>{
      return v.underlying_ric == val
    })
    if(value.length>0){
      if(type==1){
        return value[0].underlying_name
      }else{
        return value[0].underlying_ticker
      }
    }else{
      return name
    }
  }
  const googleCollectResponse = () => {
    googleCollect().then((res) => {

    });
  }; 

  const googleCollectResponseData = () => {
    googleCollectData().then((res) => {
      
    });
  }; 

  const getUnderlyingData = () => {
    getUnderLing(period,ricValue).then((res) => {
      
      var fdata = []
      res.data.data.forEach(el => {
        var eld = {
          date: el.time,
          open: +el.open,
          low: +el.low,
          high: +el.high,
          close: +el.close,
          volume: +el.volume
        }
        fdata.push(eld)
      });
      setChartData(fdata)
    });
  };
  const getWarrantTermsSelect = async () => {
    let LiveIndexSymbolResult = await api.get(
      config.homeLiveIndexFuturesSymbol,
      true,
      { type: "u", qid: Date.now() }
    );
    let liveIndexSymbolRes = get(LiveIndexSymbolResult, "symbols", []);
    const liveIndexSymbolData = liveIndexSymbolRes.map(
      ({ name, symbol, ric: value }) => {
        // if(symbol=='N225'){
        //   return {
        //     label: `Nikkei Stock Average (Nikkei 225)`,
        //     value,
        //     symbol,
        //   }
        // }else{
        //   return  {
        //     label: `${name} ${"("}${symbol}${")"}`,
        //     value,
        //     symbol,
        //   }
        // }
          return  {
            label: `${getUnderlyingTickerDisplayName(value,name,1)} ${"("}${getUnderlyingTickerDisplayName(value,symbol,2)}${")"}`,
            value,
            symbol,
          }
      }
    );
    let selectedOneName =
      liveIndexSymbolRes && liveIndexSymbolRes[0] && liveIndexSymbolRes[0].name;
    let selectedOneCode =
      liveIndexSymbolRes &&
      liveIndexSymbolRes[0] &&
      liveIndexSymbolRes[0].symbol;
    let selectedOneRic =
      liveIndexSymbolRes &&
      liveIndexSymbolRes[0] &&
      liveIndexSymbolRes[0].symbol;
    let tempSelectedName = selectedOneName + " (" + selectedOneCode + ") ";
    setRic(liveIndexSymbolRes[0].symbol);
    setSymbolList(liveIndexSymbolData);
    setLiveMatrixSelectedName(tempSelectedName);
    id === null && setRicValue(liveIndexSymbolRes.map((d) => d.ric)[0]);
  };

  const getWarrantToolsLiveMatrix = async (by, order) => {
    let formData = {
      type: "u",
      ...(by && { sortBy: by }),
      ...(order && { sortOrder: order }),
      ric: ricValue,
      qid: Date.now(),
    };
    let LiveIndexSymbolResult = await api.get(
      config.warrantComparison,
      true,
      formData
    );
    let liveIndexSymbolRes = get(LiveIndexSymbolResult, "comparison", []);
    let tempArr =
      liveIndexSymbolRes.length > 0 &&
      liveIndexSymbolRes.splice(1, liveIndexSymbolRes.length);
      if(liveIndexSymbolRes[0].OFFCL_NAME=="N225"){
        liveIndexSymbolRes[0].OFFCL_NAME='Nikkei Stock Average';
        liveIndexSymbolRes[0].ticker='Nikkei 225'
      }
    setLiveIndexSymbolFirstIdx(liveIndexSymbolRes[0]);
    setStatus(liveIndexSymbolRes[0].PCTCHNG);
    setCompareList(tempArr);
    
  };

  const getSelectedValue = (e) => {
    document.getElementById("warrant").value = "";
    const { value, label, symbol } = e;
    setLiveMatrixSelectedName(label);
    setRic(value);
    setRicValue(value);
    history.push(`/tools/underlying/${value}`)
  };

  const sortTable = async (by, order) => {
    getWarrantToolsLiveMatrix(by, order);
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#4a87c0" : null,
        color: "#333333",
        color: isFocused ? "#fff" : null,
        textAlign: "center",
        fontSize: "14px",
        padding: "3px 10px",
        zIndex: "100",
      };
    },
  };

  return (
    <>
      <div id="underlying" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <a href="#">Warrant tools</a>
              </li>
              <li className="active">Underlying charts</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 md-w-100">
              <div className="section">
                <div className="page-header">
                  <h1>Underlying charts</h1>
                </div>
                <p style={{ marginBottom: "10px" }}>
                  Our charts allow investors to perform technical analysis on
                  the warrants' underlying.
                </p>
                <Select
                  id="warrant"
                  placeholder={"Select underlying"}
                  onChange={(e) => getSelectedValue(e)}
                  options={symbolList}
                  styles={colourStyles}
                  value=""
                />

                <div id="dwsummary" style={{ position: "relative" }}>
                  <div id="ticker" className="ricbar bgcolor-03">
                    {/* {id ? comapareList[0]?.dsply_nmll : liveMatrixSelectedName} */}
                    {/* {console.log(liveIndexSymbolFirstIdx)}
                    {liveIndexSymbolFirstIdx.OFFCL_NAME
                      ? liveIndexSymbolFirstIdx.OFFCL_NAME +
                        " (" +
                        liveIndexSymbolFirstIdx.OFFCL_CODE +
                        ")"
                      : "-"} */}
                    {liveIndexSymbolFirstIdx.OFFCL_NAME
                      ? getUnderlyingTickerDisplayName(liveIndexSymbolFirstIdx.ric,liveIndexSymbolFirstIdx.OFFCL_NAME,1) +
                        " (" +
                        getUnderlyingTickerDisplayName(liveIndexSymbolFirstIdx.ric,liveIndexSymbolFirstIdx.OFFCL_CODE,2) +
                        ")"
                      : "-"}
                  </div>
                  {displayValue && (
                    <table id="pricetbl" className="uppertbl">
                      <colgroup>
                        <col
                          style={{ backgroundColor: "#E7E8E8", padding: "3px" }}
                        />
                        <col
                          style={{
                            backgroundColor: "#F5F6F7",
                            padding: "3px",
                            textAlign: "left",
                          }}
                        />
                        <col
                          style={{
                            backgroundColor: "#F5F6F7",
                            padding: "3px",
                            textAlign: "right",
                          }}
                        />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td className="uppertbl-col-bid" rowSpan={3}>
                            <div className="bidprice lbl">Last price</div>
                            <div
                              id="trfprcval"
                              className="bidprice val"
                              style={{ paddingBottom: "15px" }}
                            >
                              {liveIndexSymbolFirstIdx.TRDPRC_1
                                ? liveIndexSymbolFirstIdx.TRDPRC_1
                                : "-"}
                            </div>
                            <div
                              className="bidprice lbl"
                              style={{ paddingTop: "15px" }}
                            >
                              Last bid price
                            </div>
                            <div id="bidval" className="bidprice val">
                              {liveIndexSymbolFirstIdx.BID
                                ? liveIndexSymbolFirstIdx.BID
                                : "-"}
                            </div>
                          </td>
                          <td className="uppertbl-col-chglbl">Change</td>
                          <td
                            id="bidchg"
                            className={status < 0 ? "uppertbl-col-chgval val down" : status > 0 ? "uppertbl-col-chgval val up" : "uppertbl-col-trdvol val"}
                          >
                            {liveIndexSymbolFirstIdx &&
                            liveIndexSymbolFirstIdx.NETCHNG_1 === null
                              ? "-"
                              : liveIndexSymbolFirstIdx.NETCHNG_1}
                          </td>
                        </tr>
                        <tr>
                          <td className="uppertbl-col-pchglbl">%Change</td>

                          <td
                            id="bidpchg"
                            className={status < 0 ? "uppertbl-col-chgval val down" : status > 0 ? "uppertbl-col-chgval val up" : "uppertbl-col-trdvol val"}
                          >
                            {liveIndexSymbolFirstIdx && status < 0
                              ? liveIndexSymbolFirstIdx.PCTCHNG
                              : status > 0
                              ? "+" + liveIndexSymbolFirstIdx.PCTCHNG
                              : liveIndexSymbolFirstIdx.PCTCHNG === null
                              ? "-"
                              : liveIndexSymbolFirstIdx.PCTCHNG}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td className="uppertbl-col-pchglbl">
                            Traded volume ('000)
                          </td>
                          <td id="trdvol" className="uppertbl-col-trdvol val">
                            {liveIndexSymbolFirstIdx.ACVOL_1_SCALED
                              ? liveIndexSymbolFirstIdx.ACVOL_1_SCALED
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  <div
                    className={`xiaomiBanner ${xiaomiBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1810"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/xiaomiBanner.jpg"
                      />
                    </a>
                  </div>
                  <div
                    className={`tencentBanner ${tencentBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/0700"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/tencentBanner.jpg"
                      />
                    </a>
                  </div>
                  <div
                    className={`babaBanner ${babaBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/9988"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/BABA.png"
                      />
                    </a>
                  </div>
                  <div
                    className={`GeelyBanner ${geelyBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/0175"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/Geely.jpg"
                      />
                    </a>
                  </div>
                  <div
                    className={`BYDBanner ${bydBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1211"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/BYD.jpg"
                      />
                    </a>
                  </div>
                  <div
                    className={`MeituanBanner ${meituanBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/3690"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/Meituan.jpg"
                      />
                    </a>
                  </div>
                  <div
                    className={`SunnyBanner ${sunnyBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/2382"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/sunny.png"
                      />
                    </a>
                  </div>
                  <div
                    className={`PingAnBanner ${pingAnBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/2318"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/ping_an.png"
                      />
                    </a>
                  </div>
                  <div
                    className={`GanfengBanner ${ganfengBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1772"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/ganfeng.png"
                      />
                    </a>
                  </div>
                  <div
                    className={`KuaishouBanner ${kuaishouBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/1024"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/Kuaishou.png"
                      />
                    </a>
                  </div>
                  <div
                    className={`jdBanner ${jdBanner}`}
                    style={{ paddingBottom: "20px" }}
                  >
                    <a
                      href="https://www.warrants.com.hk/en/market/underlying-tech-sg/code/9618"
                      target="_blank"
                    >
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src="/img/underlying/JD.png"
                      />
                    </a>
                  </div>
                </div>
                <Chart ricValue={ricValue} />
                <div id="dwtable">
                  <h3>Related warrants</h3>
                  <table className="table table-striped table-noHeaderborder">
                    <thead>
                      <tr className="htxt">
                        <th sortby="sl">Warrant name</th>
                        <th sortby="type">Type</th>
                        <th sortby="isr">Issuer</th>
                        <th sortby="ep" className="warrant_strike">
                          Strike (MYR)
                        </th>
                        <th sortby="ltd">Last trading date</th>
                        <th sortby="eg">
                          Effective gearing
                          <br />
                          (x)
                        </th>
                      </tr>
                      <tr className="sort">
                        <th>
                          <FontAwesomeIcon
                            icon={faSortDown}
                            onClick={() => {
                              sortTable("sl", "desc");
                            }}
                            className="result-sort-down"
                          />
                          <FontAwesomeIcon
                            icon={faSortUp}
                            onClick={() => sortTable("sl", "asc")}
                            className="result-sort-up"
                          />
                        </th>
                        <th>
                          <FontAwesomeIcon
                            icon={faSortDown}
                            onClick={() => sortTable("type", "desc")}
                            className="result-sort-down"
                          />
                          <FontAwesomeIcon
                            icon={faSortUp}
                            onClick={() => sortTable("type", "asc")}
                            className="result-sort-up"
                          />
                        </th>
                        <th>
                          <FontAwesomeIcon
                            icon={faSortDown}
                            onClick={() => sortTable("isr", "desc")}
                            className="result-sort-down"
                          />
                          <FontAwesomeIcon
                            icon={faSortUp}
                            onClick={() => sortTable("isr", "asc")}
                            className="result-sort-up"
                          />
                        </th>
                        <th>
                          <FontAwesomeIcon
                            icon={faSortDown}
                            onClick={() => sortTable("ep", "desc")}
                            className="result-sort-down"
                          />
                          <FontAwesomeIcon
                            icon={faSortUp}
                            onClick={() => sortTable("ep", "asc")}
                            className="result-sort-up"
                          />
                        </th>
                        <th>
                          <FontAwesomeIcon
                            icon={faSortDown}
                            onClick={() => sortTable("ltd", "dsc")}
                            className="result-sort-down"
                          />
                          <FontAwesomeIcon
                            icon={faSortUp}
                            onClick={() => sortTable("ltd", "asc")}
                            className="result-sort-up"
                          />
                        </th>
                        <th>
                          <FontAwesomeIcon
                            icon={faSortDown}
                            onClick={() => sortTable("eg", "desc")}
                            className="result-sort-down"
                          />
                          <FontAwesomeIcon
                            icon={faSortUp}
                            onClick={() => sortTable("eg", "asc")}
                            className="result-sort-up"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {comapareList &&
                        comapareList.map((compareData, compareIdx) => (
                          <tr
                            className={`rowtmpl ${
                              compareData.issuer_code == "Macquarie"
                                ? "text-bold"
                                : ""
                            }`}
                            key={compareIdx}
                          >
                            <td className="ticker">
                              <Link
                                to={`${"/tools/livematrix/"}${
                                  compareData.ticker
                                }`}
                              >
                                {compareData.dsply_nmll}
                              </Link>
                            </td>
                            <td className="type">{compareData.type}</td>
                            <td className="issuer_code">
                              {compareData.issuer_code}
                            </td>
                            <td className="exercise_price">
                              {compareData.exercise_price}
                            </td>
                            <td className="last_trading_date">
                              {compareData.last_trading_date}
                            </td>
                            <td className="effective_gearing">
                              {compareData.effective_gearing}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="index_disclaimer">
                  <p className="small">
                    Note that the underlying prices in the charts refer to the{" "}
                    <span className="disclaimer_ticker" /> futures
                  </p>
                </div>
                <hr className="hr-hmgbm" />
                <p className="small">
                  All market data is delayed 15 mins unless otherwise indicated
                  by timestamp.
                </p>
              </div>
            </div>
            <div className="col-md-4 sm-none xs-none">
              
              <Slider {...settings} className="ad-section custom">
                {advImgVal &&
                  advImgVal.map((data, index) => {
                    return (
                      <a target="_blank" href={data.link} key={index}>
                        <div>
                          <img src={data.imgSrc} />
                        </div>
                      </a>
                    );
                  })}
              </Slider>
              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Warrant search</h2>
                </div>
                <p>
                  Find the right warrant according to your investment criteria.
                </p>
                <div className="section-qlink-img section-qlink-dwsearch">
                  <Link
                    to="/tools/warrantsearch"
                    className="section-qlink-achor"
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Warrant comparison</h2>
                </div>
                <p>
                  Compare warrants and their key factors before making your
                  decision.
                </p>
                <div className="section-qlink-img section-qlink-dwcomparison">
                  <Link
                    to="/tools/warrantcomparison"
                    className="section-qlink-achor"
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UnderLyingCharts;
