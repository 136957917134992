import React from "react";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import loadingSpiner from "../../assets/images/loadingSpinner/loading.gif";

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;

// `;

const LoaderSpin = ({ isVisible, isLoading }) => {
  if (isVisible) {
    return (
      <img
        src={loadingSpiner}
        alt="loading spinner"
        style={{
          height: "40px",
          width: "40px",
          radius: "2px",
          marginTop: "25px",
          marginBottom: "25px",
        }}
      />
      // <FadeLoader  height={15}	width={5}	radius={2}	margin={2}
      // color={"rgb(32, 46, 18)"}
      //  loading={isLoading}
      // //  css={override}
      //  />
    );
  } else {
    return null;
  }
};
export default LoaderSpin;
