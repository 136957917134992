import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/global.css';
import reportWebVitals from './reportWebVitals';
import GaEventsTracker from "../src/components/common/GaEventsTracker"
import API from "./utils/apiCalling";
import { config } from "./utils/apiUrl";

// get UnderlyingtickerdisplaynameMap
const api = new API();
const getScreenerJSONServlet = async()=>{
  let ScreenerJSONServlet = await api.get(
    config.homeWarrantSearch+'/ric'
  )
  window.localStorage.setItem('UnderlyingtickerdisplaynameMap',JSON.stringify(ScreenerJSONServlet))
}
getScreenerJSONServlet()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


