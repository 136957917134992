import React from "react"
import Chart from 'react-apexcharts'

export default function DonutChart({ result, date }) {
  const sectorName = result.map((d) => d.name)
  const sectorCall = result.map((d) => (parseInt(d.call.replace(/,/g, ''))+d.put))
  const series = sectorCall
  const options = {
    series: sectorCall,
    labels: sectorName,
    stroke:{
      width: "0"
     },
    colors: ["#0675C2", "#00A1E0", "#6CC263", "#F7941E", "#FECC0A"],
    "borderWidth":"0",
    legend: {
      show: false
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%"
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "40px",
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: 10,
              fontSize: '14px',
              color: "#333333",
              formatter: function (val) {
                return date
              }
            },
            value: {
              show: false,
              color: "#fffff",
              formatter: function (val) {
                return val
              }
            },
            total: {
              show: true,
              showAlways: true,
            }
          }
        }
      }
    },
    chart: {
      type: 'donut',
    },
  }

  return (
    <>
      <Chart
        options={options}
        series={series}
        type="donut"
        width="317"
        height="340"
      />
    </>
  )
}
