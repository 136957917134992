import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {useHistory} from 'react-router-dom';
import "../../../src/pages/WarrantEducation/HowToStart/howToStarted.css"
import { config } from '../../utils/apiUrl';
import API from '../../utils/apiCalling';


const IndexWarrantModal = (props) => {

  const {
    OpenModal,
    Open,
    // showFormVal,
    // showSuccessVal,
  } = props;

  const api = new API()
  const [email, setemail] = useState("")
  const [showForm, setShowForm] = useState(true)
  const [showSuccessForm, setShowSuccessForm] = useState(false)

      const handleSubscribe = async() =>{
        let data = {
          email
        }
        let res = await api.post(`${config.subscription}${"?p=register"}`, data)
          .then((res) => {
            setShowForm(false)
            setShowSuccessForm(true)
            setTimeout(() => setShowSuccessForm(false), [2500],)
            setTimeout(() => OpenModal(), [2500],)
            setTimeout(() => setShowForm(true), [2500],)
          })
          .catch((error) => console.log(error))
      }
      
    return ( 
    <div id="hshSubscribe_area" className="hsh-overlay" style={{ display: 'none' }}>
      <div className="hsh-overlay-inner">
        <Modal style={{ position: 'relative' }} centered isOpen={Open} className="modal-lg mt-5 index-warrant">
        
        <ModalBody >
          <div style={{position: 'relative'}}>
            <div className="popupbox-content">
              <img onClick={OpenModal} className="btn-close" style={{top: '40px', right: '40px'}} src="/img/bullet/icon-close-white.png" />
              { showForm === true && showSuccessForm === false ? <div className="page-header">
              <div>
                <h1>Sign up now</h1>
                <hr className="hstech-hr" style={{marginTop: '10px'}} />
              </div>
              <input type="text" name="Email" placeholder="Email address" value={email} onChange={(e)=>{setemail(e.target.value)}} />
              <div className="warning" />
              <p className="small">
                By clicking “Submit”, you confirm that you would like to receive Macquarie’s daily newsletter and invitations for warrant seminars, and you acknowledge that Macquarie’s website administrators may have access to your personal data for the processing of such data on behalf of Macquarie Capital Securities Malaysia Sdn Bhd and other companies of Macquarie group (“Macquarie”). You may view Macquarie’s privacy policy with regard to collection and management of personal data via the links at the bottom of this page.
              </p>
              <input type="button" id="submitBtn" defaultValue="Submit" onClick={handleSubscribe} />
              </div> : 
              <div className="page-header">
                <h1>You have successfully registered for our daily newsletter!</h1>
              </div>
              }
            </div>
          </div>
        </ModalBody>
        
      </Modal>
      </div>
    </div>
     );
}
 
export default IndexWarrantModal;