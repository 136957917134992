import React, { useState, useEffect,Fragment } from 'react';
import JoinTelegrams from './JoinTelegrams';
import "./joinTelegram.css" 


const JoinTelegram = (props) => {
    return ( 
        <Fragment> 
        <JoinTelegrams/>
        </Fragment> 
     );
}


export default JoinTelegram;