import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"

Timeline(Highcharts);
const BarChart = ({ result }) => {
    const sectorName = result.map((d) => d.name)
    const sectorCall = result.map((d) => (parseInt(d.call.replace(/,/g, '')))/1000)
    const sectorPut = result.map((d) => (d.put)/1000)
    var UNDEFINED;
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: sectorName,
            crosshair: true,
            labels: {
                // enabled: true,
                // show: false,
                // rotation: 0,
                // rotateAlways: true,
                // trim : true,
                // align: "center",
                // allowOverlap: true,
                style: {
                    fontSize: '10px'
                }
           }
        },
        yAxis: {
            // labels: {
            //     format: '{value:,.0f}'
            // },
            labels: {
                formatter: function () {
                    return Highcharts.numberFormat(this.value, -1, UNDEFINED, ',');
                }
            },
            title: {
                text: ''
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: false,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.0,
                borderWidth: 0,
                stacking: 'normal'
            },
            series: {
                groupPadding: 0.1
            }
        },
        credits: false,
        series: [{
            showInLegend: false,
            name: 'PUT',
            color: '#DD5C10',
            data: sectorPut
        },
        {
            showInLegend: false,
            name: 'CALL',
            color: '#F7941E',
            data: sectorCall,
        }]
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
}
export default BarChart;
