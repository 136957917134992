import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "../../../src/pages/WarrantEducation/YourFirstStep/yourFirstSteps.css"


const YourFirstStepsModal = (props) => {
    const {
      OpenModal, Url,
        Open,
      } = props;

      const toggle = (index) => { 

      }
      
    return ( 
    <div id="ppboxHTML" className="overlay" style={{ display: 'none' }}>
      <div className="overlay-inner">
        <Modal style={{ position: 'relative' }} centered isOpen={Open} toggle={OpenModal} className="modal-lg mt-5 your-first-step">
        <img className="btn-close" onClick={OpenModal} src="/img/bullet/icon-close-white.png" />
        <ModalBody >
          <div style={{position: 'relative'}}>
            <div className="popupbox-content">
              <iframe src={Url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </ModalBody>
        
      </Modal>
      </div>
    </div>
     );
}
 
export default YourFirstStepsModal;