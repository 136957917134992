import React, { useEffect } from "react";
import "./FooterInfo.css";
import { Link } from 'react-router-dom';

const DisclaimerInfo =()=> {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (

        <div id="disclaimerinfo" className="pageobj">
            <div className="breadcrumbbar">
                <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li>
                            Macquarie
                        </li>
                        <li className="active">Disclaimer</li>
                    </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="section">
                    <div className="page-header">
                        <h1>Disclaimer</h1>
                    </div>

                    <p>This internet site is produced by Macquarie Capital Securities (Malaysia) Sdn. Bhd. (Company No
                        463469-W) ("<strong>MCSM</strong>"). No part of this internet site may be copied, either in whole or in part, or distributed to any other person.</p>

                    <p>The information on this internet site is directed and available for general circulation to residents of
                        Malaysia only and is not provided to any person who is a resident of the United States or any other country.
                        Any material provided herein, including any indicative terms are provided for information purposes only and
                        do not constitute an offer, a solicitation of an offer, or any advice or recommendation to conclude any
                        transaction. The Warrants will not be offered to any US persons. While MCSM provides the information in
                        good faith and derived from sources believed to be reliable, MCSM does not represent or warrant the
                        completeness, reliability, accuracy, timeliness or fitness for any purpose of any of the material and it
                        accepts no responsibility for the accuracy, completeness or timeliness of the information. The content on
                        this internet site has not been reviewed by the Securities Commission Malaysia.</p>

                    <p>MCSM is the issuer of the Macquarie Warrants ("<strong>Warrants</strong>") while Macquarie Financial Holdings Limited is the
                        guarantor for the Warrants. The Warrants constitute general unsecured contractual obligations of the issuer
                        and of no other person and the guarantee constitutes the general unsecured contractual obligations of the
                        guarantor and of no other person. If you purchase the Warrants, you are relying on the creditworthiness of
                        the issuer and the guarantor and have no recourse/ rights against the underlying corporation, the underlying
                        exchange traded funds and the manager or trustee, the underlying index sponsor that compiles and publishes
                        the underlying index and the constituents companies of the index.</p>

                    <p>You are warned that the price or level of the underlying financial instruments and Warrants may fall in
                        value as rapidly as it may rise and you may sustain a total loss of your investment. You should therefore make
                        sure you understand the terms and conditions of the Warrants offered, the risk factors involved, and where
                        necessary, seek professional advice before investing in the Warrants. Do not invest in them unless you fully
                        understand and are willing to assume the risks associated with them. You should read the full details of the
                        Warrants, including the risk factors, which are set out in the most recent Base Prospectus and the relevant
                        termsheet.</p>

                    <p>MCSM is the market maker for the Warrants (acting through Macquarie Capital Securities (Singapore) Pte.
                        Limited). The market maker may be the only persons quoting prices for the Warrants on Bursa Malaysia
                        Securities Berhad. Where past performance is referred to, it is not indicative of future performance.</p>

                    <p>Other than Macquarie Bank Limited <strong>MBL</strong>"), any Macquarie Group entity noted on this page is not an authorised
                        deposit-taking institution for the purposes of the Banking Act 1959 (Commonwealth of Australia). That entity's
                        obligations do not represent deposits or other liabilities of MBL. MBL does not guarantee or otherwise provide
                        assurance in respect of the obligations of that entity, unless noted otherwise.</p>

                    <p>Disclosures with respect to the listed companies, if any, mentioned in this site are available at <Link to={{ pathname: "https://www.macquarie.com/in/en/disclosures.html"}} target="_top"> www.macquarie.com/disclosures</Link>.</p>

                    <p>Copyright © 2018 Refinitiv. Refinitiv content is the intellectual property of Refinitv. Any copying,
                        republication or redistribution of Refinitv content, including by caching, framing or similar means, is
                        expressly prohibited without the prior written consent of Refinitiv. Data is provided for information purposes
                        only and is not intended for trading purposes. Neither Refinitiv nor its Third Party Providers shall be liable
                        for any any loss, damage or cost, including without limitation, direct or indirect, special, incidental, or
                        consequential, arising in connection with the information, content or materials provided or as a result of
                        actions taken in reliance thereon. You expressly agree that your use of Refinitiv's information, content or
                        materials is at your sole risk. Refinitiv and the Refinitiv Logo are trademarks and registered trademarks of
                        the Refinitiv companies around the world.</p>

                </div>


            </div>
        </div>

    );
}

export default DisclaimerInfo;