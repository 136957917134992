import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import "./howUseLiveMatrix.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {Base_Url} from "../../../utils/apiUrl"

import DataLayout from "./DataLayout";

import Guest1 from "./Guest/Guest1";
import Guest2 from "./Guest/Guest2";
import Guest3 from "./Guest/Guest3";
import Guest4 from "./Guest/Guest4";
import Guest5 from "./Guest/Guest5";
import Guest6 from "./Guest/Guest6";
import Sharebtn from "../../../components/common/layout/ShareBtn";

const HowUseLiveMatrixs = (props) => {
  const { id ,index} = useParams();


  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);

  const [eduVideo1, SetEduVideo1] = useState(false);
  const [eduVideo2, SetEduVideo2] = useState(false);
  const [eduVideo3, SetEduVideo3] = useState(false);
  const [eduVideo4, SetEduVideo4] = useState(false);

  const [gustVideo1, SetGuesVideo1] = useState(false);
  const [gustVideo2, SetGuesVideo2] = useState(false);
  const [gustVideo3, SetGuesVideo3] = useState(false);
  const [gustVideo4, SetGuesVideo4] = useState(false);
  const [gustVideo5, SetGuesVideo5] = useState(false);
  const [gustVideo6, SetGuesVideo6] = useState(false);
  const [gustVideo7, SetGuesVideo7] = useState(false);

  const textChangeHandler = () => {
    setActive1(true);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };
  const textChangeHandler1 = () => {
    setActive1(false);
    setActive2(true);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };
  const textChangeHandler2 = () => {
    setActive3(true);
    setActive1(false);
    setActive2(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };
  const textChangeHandler3 = () => {
    setActive4(true);
    setActive3(false);
    setActive1(false);
    setActive2(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };
  const textChangeHandler4 = () => {
    setActive5(true);
    setActive4(false);
    setActive3(false);
    setActive1(false);
    setActive2(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };
  const textChangeHandler5 = () => {
    setActive5(false);
    setActive4(false);
    setActive3(false);
    setActive1(false);
    setActive2(false);
    setActive6(true);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };

  const moreEduHandler = () => {
    SetEduVideo1(true);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };
  const moreEduHandler1 = () => {
    SetEduVideo1(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
    SetEduVideo2(true);
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };
  const moreEduHandler2 = () => {
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(true);
    SetEduVideo4(false);
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };
  const moreEduHandler3 = () => {
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(true);
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
  };

  const gustVideohandler1 = () => {
    SetGuesVideo1(true);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
  };
  const gustVideohandler2 = () => {
    SetGuesVideo1(false);
    SetGuesVideo2(true);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
  };
  const gustVideohandler3 = () => {
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(true);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
  };
  const gustVideohandler4 = () => {
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(true);
    SetGuesVideo5(false);
    SetGuesVideo6(false)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
  };
  const gustVideohandler5 = () => {
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(true);
    SetGuesVideo6(false)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
  };

  const gustVideohandler6 = () => {
    SetGuesVideo1(false);
    SetGuesVideo2(false);
    SetGuesVideo3(false);
    SetGuesVideo4(false);
    SetGuesVideo5(false);
    SetGuesVideo6(true)
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    SetEduVideo1(false);
    SetEduVideo2(false);
    SetEduVideo3(false);
    SetEduVideo4(false);
  };

  useEffect(() => {
    id === "1"
      ? setActive1(true)
      : id === "2" || id === 'StructuredWarrants'
        ? setActive2(true)
        : id === "3" || id === 'whyTradeWarrants'
          ? setActive3(true)
          : id === "4" || id === 'howToSelectWarrants'
            ? setActive4(true)
            : id === "5" || id === 'monitorWarrantsPrice'
              ? setActive5(true)
              : id === "6"
                ? setActive6(true)
                : id === "7"
                  ? SetEduVideo1(true)
                  : id === "8"
                    ? SetEduVideo2(true)
                    : id === "9"
                      ? SetEduVideo3(true)
                      : id === "10"
                        ? SetEduVideo4(true)
                        : id === "11" || id === "41"|| id === "42" || id === "43" || id === "44" || id === "45" || id === "46"
                          ? SetGuesVideo1(true)
                          : id === "12" || id === "51"  || id === "52"
                            ? SetGuesVideo2(true)
                            : id === "13"  || id === "61"  || id === "62"   || id === "63"   || id === "64"   || id === "65"   || id === "66"   || id === "67"  || id === "68"   || id === "69"   || id === "70"  || id === "71" 
                              ? SetGuesVideo3(true)
                              : id === "14"
                                ? SetGuesVideo4(true)
                                : id === "15"
                                  ? SetGuesVideo5(true)
                                  : id === "16"  
                                    ? SetGuesVideo6(true)
                                    : id === "17"
                                    ? SetGuesVideo7(true)
                                    : setActive6(true);
  }, []);



  const location = useLocation();

  const srcLink = "https://www.youtube.com/embed/" + index + "?autoplay=" + 0 + "&index=" + id + "&rel=0&wmode=opaque";
  console.log(srcLink)
  return (
    <div id="dwvideoind" className="pageobj educational_warrent" style={{}}>
      <Sharebtn
        Location={location}
        eduVideo1={eduVideo1}
        eduVideo2={eduVideo2}
        eduVideo3={eduVideo3}
        eduVideo4={eduVideo4}
        active1={active1}
        active2={active2}
        active3={active3}
        active4={active4}
        active5={active5}
        active6={active6}
        gustVideo1={gustVideo1}
        gustVideo2={gustVideo2}
        gustVideo3={gustVideo3}
        gustVideo4={gustVideo4}
        gustVideo5={gustVideo5}
        gustVideo6={gustVideo6}
        gustVideo7={gustVideo7}
        id={id}
      />
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?edu">Warrant education</Link>
            </li>
            <li>
              <Link to="/education/warrantvideosind">Warrant videos</Link>
            </li>
            <li className="active">
              {gustVideo1 ||
                gustVideo2 ||
                gustVideo3 ||
                gustVideo4 ||
                gustVideo5 ||
                gustVideo6 ||
                gustVideo7
                ? "Guest speakers"
                : "Education"}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>
              {active1 ||
                active2 ||
                active3 ||
                active4 ||
                active5 ||
                active6 ||
                eduVideo1 ||
                eduVideo2 ||
                eduVideo3 ||
                eduVideo4
                ? "Educational warrant videos"
                : gustVideo1
                  ? <h1><b>Daryl Guppy</b> - Overcome your fear of market highs (2018)</h1>
                  : gustVideo2
                    ? <h1><b>Success Speaks</b> - Warrant investors share their trading experience</h1>
                    : gustVideo3
                      ? <h1><b>Craig Lazzara</b> - What impact will the new US Government have on markets in 2017?</h1>
                      : gustVideo4
                        ? <h1>Quick market talk with Experts</h1>
                        : gustVideo5
                          ? <h1>Quick market talk with Experts</h1>
                          : gustVideo6
                            ? <h1>Quick market talk with Experts</h1>
                            : gustVideo7
                            ? <h1>Quick market talk with Experts</h1>
                            : ""}
            </h1>
          </div>

          {active1 ||
            active2 ||
            active3 ||
            active4 ||
            active5 ||
            active6 ||
            eduVideo1 ||
            eduVideo2 ||
            eduVideo3 ||
            eduVideo4 ? (
            <p className="section-p">
              Learn about structured warrants with these bite-sized animated
              videos! In these videos, we will provide an introduction on
              structured warrants and the things to know when trading or
              investing in warrants.
            </p>
          ) : (
            ""
          )}

          <div id="video-anchor" className="video-anchor" />
          <div className="videoplayer">
            <div className="videoplayer-container">
              <iframe
                frameBorder={0}
                allowFullScreen
                allow="autoplay"
                src={srcLink}
                // src={
                //   active1
                //   ?"https://www.youtube.com/embed/x4nSIsrlnsM?list=PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                //   :active2
                //   ?"https://www.youtube.com/embed/O8aeqAFfTqw?list=PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                //   :active3
                //   ?"https://www.youtube.com/embed/WKEglUZgOs4?list=PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                //   :active4
                //   ?"https://www.youtube.com/embed/IByMqRq5Wdk?list=PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                //   :active5
                //   ?"https://www.youtube.com/embed/Jy-YbCmXv7g?list=PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                //   :active6
                //   ?"https://www.youtube.com/embed/y7Zkk7yuoDs?list=PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                //   :eduVideo1
                //   ?"https://www.youtube.com/embed/2itgroD4DGw?list=PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                //   :eduVideo2
                //   ?"https://www.youtube.com/embed/meI6OjbjkKM?list=PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                //   :eduVideo3
                //   ?"https://www.youtube.com/embed/0D4C5tp8wCc?list=PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                //   :eduVideo4
                //   ?"https://www.youtube.com/embed/t9J1GLrOMTk?list=PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                //   : gustVideo1
                //         ? "https://www.youtube.com/embed/qZfdSJNVk6s?list=PLOkzCdnS95xQ4zrzbOPfp0Cdds8do26Wg"
                //         : gustVideo2
                //           ? "https://www.youtube.com/embed/xiq4HijE5YE?list=PLOkzCdnS95xSpQik9dYWNZB2V-GsF6Eus"
                //           : gustVideo3
                //             ? "https://www.youtube.com/embed/RehMF8yfkNM?list=PLOkzCdnS95xQ7l5AXwvU4djoMXpSaLbyl"
                //             : gustVideo4
                //               ? "https://www.youtube.com/embed/l1abz3e-J3Q?list=PLVy-KcVix8Qf0fgqEpUiBigAG4eZ2IL3X&index=0"
                //               : gustVideo5
                //                 ? "https://www.youtube.com/embed/5ZZ-mHqOOf4?list=PLVy-KcVix8Qf0fgqEpUiBigAG4eZ2IL3X&index=0"
                //                 : gustVideo6
                //                   ? "https://www.youtube.com/embed/0-oAB7TNvmY?list=PLVy-KcVix8Qf0fgqEpUiBigAG4eZ2IL3X&index=0"
                //                   :""

                // }
              />
            </div>
          </div>
          {active1 ? (
            <DataLayout
              title="Trading structured warrants has never been easier (0:38)"
              para="Learn about structured warrants and Macquarie’s Live Matrix with our bite-sized animated video series."
            />
          ) : (
            ""
          )}
          {active2 ? (
            <DataLayout
              title="Episode 1: What are Structured Warrants? (2:22)"
              para="Learn the basics of structured warrants, one of the most powerful trading tools available to investors in Malaysia."
              para1="In this episode, we will also talk about the two types of structured warrants: call warrants and put warrants."
              para2="You can also learn more at our"
              link1={<Link to="/edu" target='_blank'> investor academy.</Link>}
              link={<Link to='/education/getstarted' target='_blank'>How do I get started with trading structured warrants?</Link>}
            />
          ) : (
            ""
          )}
          {active3 ? (
            <DataLayout
              title="Episode 2: Why trade Warrants? (2:54)"
              para="Find out why structured warrants are popular with investors, and why you should consider including structured warrants in your investment portfolio."
              para1="
                <p>
                  We also introduce 
                  <a href='/education/indexwarrants' target='_blank' style={{ color: '#0675c2' }}>
                    index warrants
                  </a> 
                  in this episode. Find out how you can gain a broad exposure to
                  movements in the foreign stock markets without leaving Bursa
                  Malaysia.
                </p>
              "
              link={<Link to='/education/getstarted' target='_blank'>How do I get started with trading structured warrants?</Link>}
            />
          ) : (
            ""
          )}
          {active4 ? (
            <DataLayout
              title="Episode 3: How to select a Warrant? (3:02)"
              para="After taking a view on the underlying share, how do you decide which structured warrant is right for you?"
              para1="See four easy steps you can take in selecting which warrants to buy. The main factors to consider are:"
              ul1="Your timeframe"
              ul2="Gearing level"
              ul3="Implied volatility"
              ul4="Warrant spreads"
              link={<Link to='/education/getstarted' target='_blank'>How do I get started with trading structured warrants?</Link>}
            />
          ) : (
            ""
          )}
          {active5 ? (
            <DataLayout
              title="Episode 4: How to monitor Warrant price changes? (2:50)"
              para="After you've selected the structured warrant you want to buy, how do you monitor its price changes?"
              para1="Although the daily price change of listed shares are typically calculated based on their last traded price, this method is often inaccurate with structured warrants. Instead, investors should look at the change in a warrant's bid price as that is the price you can exit your investment. Find out more in this episode."
              para2={
                <p>
                  <Link to="/tools/warrantsearch" target='_blank' style={{ color: "#0675c2" }}>
                    {" "}
                    Use the warrant search tool
                  </Link>{" "}
                  to view the bid and ask prices of all structured warrants
                  listed on Bursa Malaysia.
                </p>
              }
              link={<Link to='/education/getstarted' target='_blank'>How do I get started with trading structured warrants?</Link>}
            />
          ) : (
            ""
          )}
          {active6 ? (
            <DataLayout
              Maintitle="How to use the Live Matrix for real time warrant prices"
              para="Macquarie’s Live Matrix shows you where Macquarie warrant’s bid and offer prices will be, in real time!"
              divData={true}
            />
          ) : (
            ""
          )}
          {eduVideo1 ? (
            <DataLayout
              title="First Step: What are Structured Warrants? (3:02)"
              link={<Link to="/FirstStep">Take your first step now.</Link>}
            />
          ) : (
            ""
          )}
          {eduVideo2 ? (
            <DataLayout
              title="Structured Warrants and Cryptocurrencies: why they are different (2:29)"
              link={<Link to="/FirstStep">Take your first step now.</Link>}
            />
          ) : (
            ""
          )}
          {eduVideo3 ? (
            <DataLayout
              title="Structured Warrants and Shares: how do they fit into your portfolio? (2:40)"
              link={<Link to="/FirstStep">Take your first step now.</Link>}
            />
          ) : (
            ""
          )}
          {eduVideo4 ? (
            <DataLayout
              title="Structured Warrants and Fixed Deposits: how do they fit into your portfolio? (2:42)"
              link={<Link to="/FirstStep">Take your first step now.</Link>}
            />
          ) : (
            ""
          )}
         
          {gustVideo2 ? (
            <DataLayout
              title={<h3><b>Success Speaks</b> - Warrant investors share their trading experience</h3>}
              para="Hear what these investors have to say about their warrant trading experience. Introducing Joey Ng, Jason Wong and TK Tan, who agreed to share their warrant trading insights!"
              btnShow={true}
              authorImg=""
              authorTitle=""
              authorDes=""
            />
          ) : (
            ""
          )}
          {gustVideo3 ? (
            <DataLayout
              title={<h3><b>Craig Lazzara</b> - What impact will the new US Government have on markets in 2017?</h3>}
              para="2017 promises to be a year of change in the US and financial market commentators are focused on what this transitional period will mean for US markets and where they are heading. In a seminar held on 25 February 2017, Craig Lazzara – Global head of Index Investment Strategy of S&P Dow Jones Indices, shared his insights into what 2017 has in store for US markets, the potential impact on Asian markets and what this means for investors.<br /><br />
              Separately, Macquarie Warrants explains how investors can trade the moves in the S&P500 via warrants."
              btnShow={true}
              authorImg="/img/speaker/craiglaz.jpg"
              authorTitle="About Craig Lazzara"
              authorDes="Craig Lazzara is the global head of index investment strategy for S&P Dow Jones Indices. The index investment strategy team provides research and commentary on S&P Dow Jones Indices' product set, including US and global equities, commodities, fixed income, and economic indices. Prior to S&P, Craig was a managing director of Abacus Analytics. He had also directed marketing and client services for ETF Advisors and Salomon Smith Barney's Global Equity Index Group, as well as the Equity Portfolio Analysis Group at Salomon Brothers. Craig is a certified Chartered Financial Analyst, and an alumni of Princeton University and Harvard Business School.<br /><br />
              <i class='small'>The opinions, ideas and strategies expressed by the speakers in these videos represent the views of the Craig Lazzara and not of Macquarie or its affiliates. Neither Macquarie nor any of its affiliates shall be liable for the content of information provided by the Craig Lazzara in the videos</i>"
            />
          ) : (
            ""
          )}
          {gustVideo4 ? (
            <DataLayout
            title={<h3>Quick market talk with Experts</h3>}
            para="Welcome to Macquarie Warrants’ “Quick market talk with Experts” series! <br />This series features market experts to share their quick thoughts and most up-to-date views on the stock market. <br />Grab your cup of coffee and listen in on our chat!"
            para1={`For the latest market and warrants news join us on: </br> <ul><li>Newsletter: <a href="${Base_Url}/marketnews/subscribe">${Base_Url}/marketnews/subscribe</a></li><li>Facebook: <a href="https://facebook.com/MalaysiaWarrants">https://facebook.com/MalaysiaWarrants</a></li><li>Telegram: <a href="https://t.me/MalaysiaWarrants">https://t.me/MalaysiaWarrants</a></li></ul>`}
            btnShow={true}
            authorTitle=""
            authorImg=""
            authorDes=""
          />
          ) : (
            ""
          )}
          {gustVideo5 ? (
            <DataLayout
              title={<h3>Quick market talk with Experts</h3>}
              para="Welcome to Macquarie Warrants’ “Quick market talk with Experts” series! <br />This series features market experts to share their quick thoughts and most up-to-date views on the stock market. <br />Grab your cup of coffee and listen in on our chat!"
              para1={`For the latest market and warrants news join us on: </br> <ul><li>Newsletter: <a href="${Base_Url}/marketnews/subscribe">${Base_Url}/marketnews/subscribe</a></li><li>Facebook: <a href="https://facebook.com/MalaysiaWarrants">https://facebook.com/MalaysiaWarrants</a></li><li>Telegram: <a href="https://t.me/MalaysiaWarrants">https://t.me/MalaysiaWarrants</a></li></ul>`}
              btnShow={true}
              authorTitle=""
              authorImg=""
              authorDes=""
            />
          ) : (
            ""
          )}
          {gustVideo1 ? (
            <DataLayout
              title={<h3><b>Daryl Guppy</b> - Overcome your fear of market highs (2018)</h3>}
              para="Want to navigate the Malaysian equity market with confidence? Learn how to use technical indicators to identify market tops and how to trade this current bull trend. Discover how the General Elections may affect the overall market landscape and identify the right strategies to weather through it."
              para1="In the “Navigating the Malaysian Market” seminar held on 3 March 2018, Daryl explores the market outlook for the Malaysian equity market, the U.S. and a range of commodities which impact Malaysian equities in his presentation titled “Overcome your fear of market highs”. He explains which technical indicators work best to identify market trends and shows how you can trade the market effectively"
              btnShow={true}
              authorTitle="About Daryl Guppy"
              authorImg="/img/speaker/daryl_guppy.jpg"
              authorDes="Daryl Guppy is a regular CNBC Asia Squawk Box technical commentator. Known as “The Chart Man”, he is an active position trader in equities and associated derivatives markets, and has developed several leading technical indicators used by traders in many markets.<br /><br />
                        Apart from being the author of “Guppy Trading, Trend Trading” and other books, Guppy is also a contributor for several leading financial magazines and media in Singapore, Malaysia, China, Australia and the US, overseeing the production of weekly analysis and trading newsletters for the Singapore/Malaysia markets and the Australian market. He is also widely recognised as a leading expert on China markets"
            />
          ) : (
            ""
          )}
          {gustVideo6 ? (
            <DataLayout
              title={<h3>Quick market talk with Experts</h3>}
              para="Welcome to Macquarie Warrants’ “Quick market talk with Experts” series! <br />This series features market experts to share their quick thoughts and most up-to-date views on the stock market. <br />Grab your cup of coffee and listen in on our chat!"
              para1={`For the latest market and warrants news join us on: </br> <ul><li>Newsletter: <a href="${Base_Url}/marketnews/subscribe">${Base_Url}/marketnews/subscribe</a></li><li>Facebook: <a href="https://facebook.com/MalaysiaWarrants">https://facebook.com/MalaysiaWarrants</a></li><li>Telegram: <a href="https://t.me/MalaysiaWarrants">https://t.me/MalaysiaWarrants</a></li></ul>`}
              btnShow={true}
              authorTitle=""
              authorImg=""
              authorDes=""
            />
          ) : (
            ""
          )}
          {gustVideo7 ? (
            <DataLayout
              title={<h3>Quick market talk with Experts</h3>}
              para="Welcome to Macquarie Warrants’ “Quick market talk with Experts” series! <br />This series features market experts to share their quick thoughts and most up-to-date views on the stock market. <br />Grab your cup of coffee and listen in on our chat!"
              para1={`For the latest market and warrants news join us on: </br> <ul><li>Newsletter: <a href="${Base_Url}/marketnews/subscribe">${Base_Url}/marketnews/subscribe</a></li><li>Facebook: <a href="https://facebook.com/MalaysiaWarrants">https://facebook.com/MalaysiaWarrants</a></li><li>Telegram: <a href="https://t.me/MalaysiaWarrants">https://t.me/MalaysiaWarrants</a></li></ul>`}
              btnShow={true}
              authorTitle=""
              authorImg=""
              authorDes=""
            />
          ) : (
            ""
          )}

          <div className="content ">
            <div id="edurack" className="videorack">
              <div className="videorack-title">More videos in this series </div>
              {active1 ||
                active2 ||
                active3 ||
                active4 ||
                active5 ||
                active6 ? (
                <div className="videorack-body">
                  <div className="videorack-content swiper-container swiper-container-horizontal">
                    <div className="swiper-wrapper">
                      <div
                        className="swiper-slide swiper-slide-active"
                        style={{
                          paddingLeft: "30px",
                          paddingRight: "35px",
                          // width: "1090px",
                        }}
                      >
                        <Carousel
                          showArrows={true}
                          showThumbs={true}
                          emulateTouch
                          showStatus={false}
                          autoFocus
                          infiniteLoop={false}
                          showIndicators={false}
                        >
                          <div>
                            <table className="videorack-page  hidden_on_mobile">
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={0}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler}
                                      active={active1.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/x4nSIsrlnsM/1">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/x4nSIsrlnsM/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Trading structured warrants has never
                                          been easier
                                        </span>{" "}
                                        (0:38)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>

                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={1}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler1}
                                      active={active2.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/DupqIvOz4LU/2">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/DupqIvOz4LU/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 1:
                                        </span>{" "}
                                        What are Structured Warrants? (2:22)
                                      </div>
                                      </Link>
                                    </div>

                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={2}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler2}
                                      active={active3.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/Lg2QZSi18rw/3">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/Lg2QZSi18rw/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 2:
                                        </span>{" "}
                                        Why trade Warrants? (2:54)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={3}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler3}
                                      active={active4.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/VCzKXItjqbc/4">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/VCzKXItjqbc/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 3:
                                        </span>{" "}
                                        How to select a Warrant? (3:02)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                            <table className="videorack-page  show_on_mobile">
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={0}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler}
                                      active={active1.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/x4nSIsrlnsM/1">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/x4nSIsrlnsM/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Trading structured warrants has never
                                          been easier
                                        </span>{" "}
                                        (0:38)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>

                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={1}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler1}
                                      active={active2.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/DupqIvOz4LU/2">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/DupqIvOz4LU/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 1:
                                        </span>{" "}
                                        What are Structured Warrants? (2:22)
                                      </div>
                                      </Link>
                                    </div>

                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={2}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler2}
                                      active={active3.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/Lg2QZSi18rw/3">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/Lg2QZSi18rw/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 2:
                                        </span>{" "}
                                        Why trade Warrants? (2:54)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={3}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler3}
                                      active={active4.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/VCzKXItjqbc/4">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/VCzKXItjqbc/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 3:
                                        </span>{" "}
                                        How to select a Warrant? (3:02)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={4}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler4}
                                      active={active5.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/JhTPi51KsOs/5">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/JhTPi51KsOs/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 4:
                                        </span>{" "}
                                        How to monitor Warrant price changes?
                                        (2:50)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={5}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler5}
                                      active={active6.toString()}
                                    >
                                       <Link  to="/education/warrantvideosind/CthPrSrisGk/6">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/CthPrSrisGk/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 5:
                                        </span>{" "}
                                        How to use the Live Matrix for real time warrant prices (3:17)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>

                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={6}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={7}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                    </div>
                                  </td>

                                </tr>
                              </tbody>
                            </table>

                          </div>
                          <div>
                            <table className="videorack-page hidden_on_mobile">
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={4}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler4}
                                      active={active5.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/JhTPi51KsOs/5">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/JhTPi51KsOs/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 4:
                                        </span>{" "}
                                        How to monitor Warrant price changes?
                                        (2:50)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={5}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={textChangeHandler5}
                                      active={active6.toString()}
                                    >
                                      <Link  to="/education/warrantvideosind/CthPrSrisGk/6">
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/CthPrSrisGk/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 5:
                                        </span>{" "}
                                        How to use the Live Matrix for real time warrant prices (3:17)
                                      </div>
                                      </Link>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={6}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={7}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {eduVideo1 || eduVideo2 || eduVideo3 || eduVideo4 ? (
                <div className="videorack-body">
                  <div className="swiper-wrapper">
                    <div
                      className="swiper-slide swiper-slide-active"
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "35px",
                        // width: "1090px",
                      }}
                    >
                      <Carousel
                        showArrows={true}
                              showThumbs={true}
                              emulateTouch
                              showStatus={false}
                              autoFocus
                              infiniteLoop={false}
                              showIndicators={false}
                      >
                        <div>
                          <table className="videorack-page ">
                            <tbody>
                              <tr>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={6}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={moreEduHandler}
                                  >
                                    <Link  to="/education/warrantvideosind/2itgroD4DGw/7">
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/2itgroD4DGw/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">First Step:</span>{" "}
                                      What are Structured Warrants? (3:02)
                                    </div>
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={7}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={moreEduHandler1}
                                  >
                                    <Link  to="/education/warrantvideosind/meI6OjbjkKM/8">
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/meI6OjbjkKM/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Structured Warrants and Cryptocurrencies:
                                      </span>{" "}
                                      why they are different (2:29)
                                    </div>
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={8}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={moreEduHandler2}
                                  >
                                    <Link  to="/education/warrantvideosind/0D4C5tp8wCc/9">
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/0D4C5tp8wCc/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Structured Warrants and Shares:
                                      </span>{" "}
                                      how do they fit into your portfolio? (2:40)
                                    </div>
                                    </Link>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={9}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={moreEduHandler3}
                                  >
                                    <Link  to="/education/warrantvideosind/t9J1GLrOMTk/10">
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/t9J1GLrOMTk/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Structured Warrants and Fixed Deposits:
                                      </span>{" "}
                                      how do they fit into your portfolio? (2:42)
                                    </div>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {gustVideo1 ? <Guest1 /> : ""}
              {gustVideo2 ? <Guest2 /> : ""}
              {gustVideo3 ? <Guest3 /> : ""}
              {gustVideo4 ? <Guest6  fn6={gustVideohandler6} fn4={gustVideohandler4}  fn5={gustVideohandler5}/> : ""}
              {gustVideo5 ? <Guest6 fn6={gustVideohandler6} fn4={gustVideohandler4}  fn5={gustVideohandler5}/> : ""}
              {gustVideo6 ? <Guest6 fn6={gustVideohandler6} fn4={gustVideohandler4}  fn5={gustVideohandler5}/> : ""}
              {gustVideo7 ? <Guest6 fn6={gustVideohandler6} fn4={gustVideohandler4}  fn5={gustVideohandler5}/> : ""}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div id="edurack2" className="videorack">
                  <div className="videorack-title main_videos">More educational videos</div>
                  {eduVideo1 || eduVideo2 || eduVideo3 || eduVideo4 ? (
                    <div className="videorack-body">
                      <div className="videorack-content swiper-container swiper-container-horizontal">
                        <div className="swiper-wrapper hello">
                          <div
                            className="swiper-slide swiper-slide-active"
                            style={{
                              paddingLeft: "30px",
                              paddingRight: "35px",
                              width: "535px",
                              marginRight: "20px",
                            }}
                          >
                            <Carousel
                              showArrows={true}
                              showThumbs={true}
                              emulateTouch
                              showStatus={false}
                              autoFocus
                              infiniteLoop={false}
                              showIndicators={false}
                            >
                              <div>
                                <table className="videorack-page ">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                          index={0}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={textChangeHandler}
                                          active={active1.toString()}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/x4nSIsrlnsM/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Trading structured warrants has
                                              never been easier
                                            </span>{" "}
                                            (0:38)
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                          index={1}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={textChangeHandler1}
                                          active={active2.toString()}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/O8aeqAFfTqw/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Episode 1:
                                            </span>{" "}
                                            What are Structured Warrants? (2:22)
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                          index={2}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={textChangeHandler2}
                                          active={active3.toString()}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/WKEglUZgOs4/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Episode 2:
                                            </span>{" "}
                                            Why trade Warrants? (2:54)
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div>
                                <table className="videorack-page ">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                          index={3}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={textChangeHandler3}
                                          active={active4.toString()}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/IByMqRq5Wdk/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Episode 3:
                                            </span>{" "}
                                            How to select a Warrant? (3:02)
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                          index={0}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={textChangeHandler4}
                                          active={active5.toString()}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/Jy-YbCmXv7g/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Episode 4:
                                            </span>{" "}
                                            How to monitor Warrant price
                                            changes? (2:50)
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                          index={1}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={textChangeHandler5}
                                          active={active6.toString()}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/y7Zkk7yuoDs/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Episode 5:
                                            </span>{" "}
                                            How to use the Live Matrix for real
                                            time warrant prices? (2:22)
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : gustVideo1 ||
                    gustVideo2 ||
                    gustVideo3 ||
                    gustVideo4 ||
                    gustVideo5 ||
                    gustVideo6 ||
                    gustVideo7 ? (
                    <div
                      className="swiper-slide swiper-slide-active"
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "35px",
                        width: "535px",
                        marginRight: "20px",
                      }}
                    >
                      <Carousel
                        showArrows={true}
                        showThumbs={true}
                        emulateTouch
                        showStatus={false}
                        autoFocus
                        infiniteLoop={false}
                        showIndicators={false}
                      >
                        <div>
                          <table className="videorack-page">
                            <tbody>
                              <tr>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                    index={0}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={textChangeHandler}
                                    active={active1.toString()}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/x4nSIsrlnsM/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Trading structured warrants has never
                                        been easier
                                      </span>{" "}
                                      (0:38)
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                    index={1}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={textChangeHandler1}
                                    active={active2.toString()}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/O8aeqAFfTqw/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Episode 1:
                                      </span>{" "}
                                      What are Structured Warrants? (2:22)
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                    index={2}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={textChangeHandler2}
                                    active={active3.toString()}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/WKEglUZgOs4/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Episode 2:
                                      </span>{" "}
                                      Why trade Warrants? (2:54)
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <table className="videorack-page ">
                            <tbody>
                              <tr>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                    index={3}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={textChangeHandler3}
                                    active={active4.toString()}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/IByMqRq5Wdk/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Episode 3:
                                      </span>{" "}
                                      How to select a Warrant? (3:02)
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                    index={0}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={textChangeHandler4}
                                    active={active5.toString()}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/Jy-YbCmXv7g/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Episode 4:
                                      </span>{" "}
                                      How to monitor Warrant price changes?
                                      (2:50)
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                    index={1}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={textChangeHandler5}
                                    active={active6.toString()}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/y7Zkk7yuoDs/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Episode 5:
                                      </span>{" "}
                                      How to use the Live Matrix for real time
                                      warrant prices? (2:22)
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <table className="videorack-page ">
                            <tbody>
                              <tr>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={6}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={moreEduHandler}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/2itgroD4DGw/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        First Step:
                                      </span>{" "}
                                      What are Structured Warrants? (3:02)
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={7}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={moreEduHandler1}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/meI6OjbjkKM/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Structured Warrants and
                                        Cryptocurrencies:
                                      </span>{" "}
                                      why they are different (2:29)
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={8}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={moreEduHandler2}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/0D4C5tp8wCc/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Structured Warrants and Shares:
                                      </span>{" "}
                                      how do they fit into your portfolio?
                                      (2:40)
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <table className="videorack-page ">
                            <tbody>
                              <tr>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={9}
                                    className="videorack-item"
                                    style={{ display: "inline-block" }}
                                    onClick={moreEduHandler3}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/t9J1GLrOMTk/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Structured Warrants and Fixed Deposits:
                                      </span>{" "}
                                      how do they fit into your portfolio?
                                      (2:42)
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={6}
                                    className="videorack-item"
                                    onClick={moreEduHandler2}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/2itgroD4DGw/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        First Step:
                                      </span>{" "}
                                      What are Structured Warrants? (3:02)
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={7}
                                    className="videorack-item"
                                    onClick={moreEduHandler3}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/meI6OjbjkKM/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Structured Warrants and
                                        Cryptocurrencies:
                                      </span>{" "}
                                      why they are different (2:29)
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Carousel>
                    </div>
                  ) : (
                    <div className="videorack-body">
                      <div className="videorack-content swiper-container swiper-container-horizontal">
                        <div className="swiper-wrapper">
                          <div
                            className="swiper-slide swiper-slide-active"
                            style={{
                              paddingLeft: "30px",
                              paddingRight: "35px",
                              width: "535px",
                              marginRight: "20px",
                            }}
                          >
                            <Carousel
                              showArrows={true}
                              emulateTouch
                              showStatus={false}
                              autoFocus
                              infiniteLoop={false}
                              showIndicators={false}
                              showThumbs={true}
                            >
                              <div>
                                <table className="videorack-page ">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                          index={6}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={moreEduHandler}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/2itgroD4DGw/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              First Step:
                                            </span>{" "}
                                            What are Structured Warrants? (3:02)
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                          index={7}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={moreEduHandler1}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/meI6OjbjkKM/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Structured Warrants and
                                              Cryptocurrencies:
                                            </span>{" "}
                                            why they are different (2:29)
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                          index={8}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={moreEduHandler2}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/0D4C5tp8wCc/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Structured Warrants and Shares:
                                            </span>{" "}
                                            how do they fit into your portfolio?
                                            (2:40)
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div>
                                <table className="videorack-page ">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                          index={9}
                                          className="videorack-item"
                                          style={{ display: "inline-block" }}
                                          onClick={moreEduHandler3}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/t9J1GLrOMTk/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Structured Warrants and Fixed
                                              Deposits:
                                            </span>{" "}
                                            how do they fit into your portfolio?
                                            (2:42)
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                          index={6}
                                          className="videorack-item"
                                          style={{
                                            display: "inline-block",
                                            visibility: "hidden",
                                          }}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/2itgroD4DGw/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              First Step:
                                            </span>{" "}
                                            What are Structured Warrants? (3:02)
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                          index={7}
                                          className="videorack-item"
                                          style={{
                                            display: "inline-block",
                                            visibility: "hidden",
                                          }}
                                        >
                                          <img
                                            style={{ width: "100%" }}
                                            src="https://i.ytimg.com/vi/meI6OjbjkKM/mqdefault.jpg"
                                          />
                                          <div className="videorack-item-txt">
                                            <span className="text-bold">
                                              Structured Warrants and
                                              Cryptocurrencies:
                                            </span>{" "}
                                            why they are different (2:29)
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Carousel>
                          </div>
                          <div
                            className="swiper-slide swiper-slide-next"
                            style={{
                              paddingLeft: "30px",
                              paddingRight: "35px",
                              width: "535px",
                              marginRight: "20px",
                            }}
                          >
                            <table className="videorack-page ">
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                      index={9}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/t9J1GLrOMTk/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Structured Warrants and Fixed
                                          Deposits:
                                        </span>{" "}
                                        how do they fit into your portfolio?
                                        (2:42)
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                      index={6}
                                      className="videorack-item"
                                      style={{
                                        display: "inline-block",
                                        visibility: "hidden",
                                      }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/2itgroD4DGw/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          First Step:
                                        </span>{" "}
                                        What are Structured Warrants? (3:02)
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                      index={7}
                                      className="videorack-item"
                                      style={{
                                        display: "inline-block",
                                        visibility: "hidden",
                                      }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/meI6OjbjkKM/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Structured Warrants and
                                          Cryptocurrencies:
                                        </span>{" "}
                                        why they are different (2:29)
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div id="gsrack" className="videorack">
                  <div className="videorack-title">
                    More guest speaker videos
                  </div>
                  <div className="videorack-body">
                    {/* <div className="swiper-button-prev videorack-nav backward swiper-button-disabled">
                      <div className="videorack-nav-icon" />
                    </div>
                    <div className="swiper-button-next videorack-nav forward">
                      <div className="videorack-nav-icon" />
                    </div> */}
                    <div className="videorack-content swiper-container swiper-container-horizontal">
                      <div className="swiper-wrapper">
                        <div
                          className="swiper-slide swiper-slide-active"
                          style={{
                            paddingLeft: "30px",
                            paddingRight: "35px",
                            width: "535px",
                            marginRight: "20px",
                          }}
                        >
                          <Carousel
                            showArrows={true}
                            emulateTouch
                            showStatus={false}
                            autoFocus
                            infiniteLoop={false}
                            showIndicators={false}
                            showThumbs={true}
                          >
                            <div>
                              <table className="videorack-page ">
                                <tbody>
                                  <tr>
                                    {gustVideo1 ? (
                                      ""
                                    ) : (
                                      <td>
                                        <Link
                                          to="/education/warrantvideosind/qZfdSJNVk6s/11"
                                          // target={id ? "" : "_blank"}
                                          rel="noopener noreferrer"
                                        >
                                          <div
                                            playlistid="PLOkzCdnS95xQ4zrzbOPfp0Cdds8do26Wg"
                                            className="videorack-item"
                                            style={{ display: "inline-block" }}
                                            onClick={gustVideohandler1}
                                          >
                                            <img
                                              style={{ width: "100%" }}
                                              src="https://i.ytimg.com/vi/-ZgC2fZcNnc/mqdefault.jpg"
                                            />
                                            <div className="videorack-item-txt">
                                              <span className="text-bold">
                                                Daryl Guppy 2018:
                                              </span>{" "}
                                              Overcome your fear of market highs
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                    )}

                                    {gustVideo2 ? (
                                      ""
                                    ) : (
                                      <td>
                                        <Link
                                          to="/education/warrantvideosind/Umkmj5NGOBo/12"
                                          // target={id ? "" : "_blank"}
                                          rel="noopener noreferrer"
                                        >
                                          <div
                                            playlistid="PLOkzCdnS95xSpQik9dYWNZB2V-GsF6Eus"
                                            index={1}
                                            className="videorack-item"
                                            style={{ display: "inline-block" }}
                                            onClick={gustVideohandler2}
                                          >
                                            <img
                                              style={{ width: "100%" }}
                                              src="https://i.ytimg.com/vi/Umkmj5NGOBo/mqdefault.jpg"
                                            />
                                            <div className="videorack-item-txt">
                                              <span className="text-bold">
                                                Success Speaks:
                                              </span>{" "}
                                              Warrant investors share their
                                              trading experience
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                    )}
                                    {gustVideo3 ? (
                                      ""
                                    ) : (
                                      <td>
                                        <Link
                                          to="/education/warrantvideosind/RehMF8yfkNM/13"
                                          // target={id ? "" : "_blank"}
                                          rel="noopener noreferrer"
                                        >
                                          <div
                                            playlistid="PLOkzCdnS95xQ7l5AXwvU4djoMXpSaLbyl"
                                            className="videorack-item"
                                            style={{ display: "inline-block" }}
                                            onClick={gustVideohandler3}
                                          >
                                            <img
                                              style={{ width: "100%" }}
                                              src="https://i.ytimg.com/vi/RehMF8yfkNM/mqdefault.jpg"
                                            />
                                            <div className="videorack-item-txt">
                                              <span className="text-bold">
                                                Craig Lazzara:
                                              </span>{" "}
                                              What impact will the new US
                                              Government have on markets in
                                              2017?
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                    )}
                                    {/* {gustVideo1 || gustVideo2 || gustVideo3 ? (
                                      <td>
                                        <Link
                                          to="/education/warrantvideosind/14"
                                          target={id ? "" : "_blank"}
                                          rel="noopener noreferrer"
                                        >
                                          <div
                                            playlistid="PLOkzCdnS95xSU4efroi-l2J2bCoHos3sf"
                                            className="videorack-item"
                                            style={{ display: "inline-block" }}
                                            onClick={gustVideohandler4}
                                          >
                                            <img
                                              style={{ width: "100%" }}
                                              src="https://i.ytimg.com/vi/TN4Z3sTRA1U/mqdefault.jpg"
                                            />
                                            <div className="videorack-item-txt">
                                              <span className="text-bold">
                                                Daryl Guppy 2016:
                                              </span>{" "}
                                              Star Stock Picks in Malaysia
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                    ) : (
                                      ""
                                    )} */}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            {/* <div>
                              <table className="videorack-page hidden-xs">
                                <tbody>
                                  <tr>
                                    {gustVideo1 || gustVideo2 || gustVideo3 || gustVideo4 ? '' : (
                                      <td>
                                        <Link
                                          to="/education/warrantvideosind/14"
                                          target={id ? "" : "_blank"}
                                          rel="noopener noreferrer"
                                        >
                                          <div
                                            playlistid="PLOkzCdnS95xSU4efroi-l2J2bCoHos3sf"
                                            className="videorack-item"
                                            style={{ display: "inline-block" }}
                                            onClick={gustVideohandler4}
                                          >
                                            <img
                                              style={{ width: "100%" }}
                                              src="https://i.ytimg.com/vi/TN4Z3sTRA1U/mqdefault.jpg"
                                            />
                                            <div className="videorack-item-txt">
                                              <span className="text-bold">
                                                Daryl Guppy 2016:
                                              </span>{" "}
                                              Star Stock Picks in Malaysia
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                    )}
                                    {gustVideo5 ? null : (
                                      <td>
                                        <Link
                                          to="/education/warrantvideosind/15"
                                          target={id ? "" : "_blank"}
                                          rel="noopener noreferrer"
                                        >
                                          <div
                                            playlistid="PLOkzCdnS95xTaSD0Zi0LDxYcLcEDCwXzz"
                                            className="videorack-item"
                                            style={{ display: "inline-block" }}
                                            onClick={gustVideohandler5}
                                          >
                                            <img
                                              style={{ width: "100%" }}
                                              src="https://i.ytimg.com/vi/7U5xXkp006I/mqdefault.jpg"
                                            />
                                            <div className="videorack-item-txt">
                                              <span className="text-bold">
                                                Daryl Guppy 2015:
                                              </span>{" "}
                                              Trading the tired bull
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                    )}
                                    <td>
                                      <div
                                        className="videorack-item"
                                        style={{
                                          display: "inline-block",
                                          visibility: "hidden",
                                        }}
                                        playlistid="PLOkzCdnS95xTaSD0Zi0LDxYcLcEDCwXzz"
                                      >
                                        
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="videorack-item"
                                        style={{
                                          display: "inline-block",
                                          visibility: "hidden",
                                        }}
                                        playlistid="PLOkzCdnS95xTaSD0Zi0LDxYcLcEDCwXzz"
                                      >
                                        
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div> */}
                          </Carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowUseLiveMatrixs;
