import React, { Fragment } from 'react';
import TurnOverDist from './TurnOverDist';
import "./turnOverDistribution.css"

const TurnOverDistribution = (props) => {
    return ( 
        <Fragment>
        <TurnOverDist/>
        </Fragment>
     );
}
 

export default TurnOverDistribution;