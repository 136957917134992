
import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import ReactTooltip from "react-tooltip"; 


const  ChildInvestorAcademyCommon = ({Location,Hist}) => {

    const pathName = get(Location,"pathname","")
    
    return ( 
        <Fragment>
        <div className="homebtn dbtn">
            <Link to="/edu"></Link>
        </div>

           {/* Why Trade Warrant ALL Pages */}

        <div className={pathName === "/edu/why-trade-warrant/gearing-effect" || pathName === "/edu/why-trade-warrant/limited-loss" 
        || pathName === "/edu/why-trade-warrant/profit-bull-bear" || pathName ===  "/edu/why-trade-warrant/ease-trade"
        || pathName === "/edu/why-trade-warrant/lower-capital-outlay" || pathName ===  "/edu/why-trade-warrant/no-margin-call"
        || pathName === "/edu/why-trade-warrant/releasing-share-capital"
        ? 
        "dotbtn d1 dbtn selected" 
        :
        "dotbtn d1 dbtn"}
            data-tip
            data-for={"why-trade-warrants"}
        >

            <Link to="/edu/why-trade-warrant"></Link>
        </div>
        <ReactTooltip
            id={"why-trade-warrants"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>Why Trade Warrants?</p>
            </div>
        </ReactTooltip>


 {/* What Are Warrant ALL Pages */}


        <div className={pathName === "/edu/what-are-warrant/call-vs-put"  || pathName ===  "/edu/what-are-warrant/call-warrant-work"
            || pathName ===  "/edu/what-are-warrant/put-warrant-work" || pathName ===  "/edu/what-are-warrant/warrant-named"
            ? 
            "dotbtn d2 dbtn selected" 
            :
            "dotbtn d2 dbtn"}
            data-tip
            data-for={"what-are-warrants"} 
        >

            <Link to="/edu/what-are-warrant"></Link>
        </div>
        <ReactTooltip
            id={"what-are-warrants"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>What are Warrants?</p>
            </div>
        </ReactTooltip>

            {/* How are Warrants priced ALL Pages */}

        <div className={pathName === "/edu/how-are-warrant-priced/share-price" || pathName ===  "/edu/how-are-warrant-priced/exercise"
            || pathName ===  "/edu/how-are-warrant-priced/expiry-date" || pathName ===  "/edu/how-are-warrant-priced/volatility"
            || pathName === "/edu/how-are-warrant-priced/dividend" || pathName ===  "/edu/how-are-warrant-priced/interest-rates" 
            ?
            "dotbtn d3 dbtn selected" 
            :
            "dotbtn d3 dbtn"}
            data-tip
            data-for={"How-are-Warrants-priced"}
        >

            
            <Link to="/edu/how-are-warrant-priced"></Link>
        </div>
        <ReactTooltip
            id={"How-are-Warrants-priced"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>How are Warrants priced?</p>
            </div>
        </ReactTooltip>

 {/* Understanding Warrant Structure ALL Pages */}

        <div className={ pathName ==="/edu/understanding-warrant-structure/time-decay"
            || pathName ==="/edu/understanding-warrant-structure/intrinsic-value-time-value" || pathName ==="/edu/understanding-warrant-structure/moneyness"
            ?
            "dotbtn d4 dbtn selected" 
            :
            "dotbtn d4 dbtn"}
            data-tip
            data-for={"Understanding-the-Warrant-Structure"}
        >
             <Link to="/edu/understanding-warrant-structure"></Link>
        </div>
        <ReactTooltip
            id={"Understanding-the-Warrant-Structure"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>Understanding the Warrant Structure</p>
            </div>
        </ReactTooltip>

{/* Monitoring price changes ALL Pages */}

        <div className={pathName ==="/edu/monitoring-price-changes/bid-price"
            || pathName === "/edu/monitoring-price-changes/last-traded-price" || pathName ==="/edu/monitoring-price-changes/closing-times"
            ?
            "dotbtn d5 dbtn selected" 
            :
            "dotbtn d5 dbtn"}
            data-tip
            data-for={"Monitoring-price-changes"}
        >
            <Link to="/edu/monitoring-price-changes"></Link>
        </div>
        <ReactTooltip
            id={"Monitoring-price-changes"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>Monitoring price changes</p>
            </div>
        </ReactTooltip>

        {/* Useful Warrant terms ALL Pages */}

        <div className={ pathName ===  "/edu/useful-warrant-terms/premium"
            || pathName === "/edu/useful-warrant-terms/warrant-sensitivity" || pathName === "/edu/useful-warrant-terms/break-even-price"
            || pathName === "/edu/useful-warrant-terms/delta" || pathName === "/edu/useful-warrant-terms/excercise-ratio"
            || pathName === "/edu/useful-warrant-terms/implied-volatility" || pathName === "/edu/useful-warrant-terms/gearing"
            || pathName === "/edu/useful-warrant-terms/effective-gearing"
            ? 
            "dotbtn d6 dbtn selected" 
            :
            "dotbtn d6 dbtn"} 
            data-tip
            data-for={"useful-warrant-terms"}
        >
            <Link to="/edu/useful-warrant-terms"></Link>
        </div>
        <ReactTooltip
            id={"useful-warrant-terms"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>Useful Warrant terms</p>
            </div>
        </ReactTooltip>

        {/* How to select a Warrant ALL Pages */}
        
        <div className={
               pathName === "/edu/select-warrant/selecting-warrant"
            || pathName ===  "/edu/select-warrant/selecting-warrant/time-frame" 
            || pathName === "/edu/select-warrant/selecting-warrant/gearing-level"
            || pathName === "/edu/select-warrant/selecting-warrant/select-implied-volatility" 
            || pathName === "/edu/select-warrant/selecting-warrant/warrant-spread"
            || pathName === "/edu/select-warrant/market-maker" 
            || pathName === "/edu/select-warrant/risk-matrix"
            || pathName === "/edu/select-warrant/tips-and-tool"
            ?
            "dotbtn d7 dbtn selected" 
            :
            "dotbtn d7 dbtn"} 
            data-tip
            data-for={"select-warrant"}
        >
            <Link to="/edu/select-warrant"></Link>
        </div>
        <ReactTooltip
            id={"select-warrant"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>How to select a Warrant?</p>
            </div>
        </ReactTooltip>

        {/* Single share calls and puts at expiry ALL Pages */}

        <div className={pathName === "/edu/single-share-calls/last-trading-day" || pathName ==="/edu/single-share-calls/call-example"
            || pathName ==="/edu/single-share-calls/put-expiry-formula" || pathName ==="/edu/single-share-calls/put-example"
            || pathName ==="/edu/single-share-calls/call-expiry-formula"
            ?
            "dotbtn d8 dbtn selected" 
            :
            "dotbtn d8 dbtn"} 
            data-tip
            data-for={"single-share-call"}
        >
            <Link to="/edu/single-share-calls"></Link>
        </div>
        <ReactTooltip
            id={"single-share-call"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>Single share calls and puts at expiry</p>
            </div>
        </ReactTooltip>

{/* Index calls and puts at expiry ALL Pages */}

        <div className={pathName === "/edu/index-calls/put-example" || pathName === "/edu/index-calls/call-example" 
            || pathName === "/edu/index-calls/index-expiry" 
            ?
            "dotbtn d9 dbtn selected" 
            :
            "dotbtn d9 dbtn"} 
            data-tip
            data-for={"index-calls"}
        >
            <Link to="/edu/index-calls"></Link>
        </div>
        <ReactTooltip
            id={"index-calls"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>Index calls and puts at expiry</p>
            </div>
        </ReactTooltip>

             {/* Risk ALL Pages */}
             
        <div className={pathName === "/edu/risks/market-risk" || pathName === "/edu/risks/gearing"
            || pathName === "/edu/risks/limited-life" || pathName === "/edu/risks/credit-risk"
            || pathName === "/edu/risks/takeovers" 
            ?
            "dotbtn d10 dbtn selected" 
            :
            "dotbtn d10 dbtn"} 
            data-tip
            data-for={"risks"}
        >
            <Link to="/edu/risks"></Link>
        </div>
        <ReactTooltip
            id={"risks"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>Risks</p>
            </div>
        </ReactTooltip>

              {/* Market Marker ALL Pages */}

        <div className={pathName === "/edu/market-marker/liquidity-vs-volume" || pathName === "/edu/market-marker/how-we-hedge" 
            ?
            "dotbtn d11 dbtn selected" 
            :
            "dotbtn d11 dbtn"} 
            data-tip
            data-for={"market-marker"}
        >
            <Link to="/edu/market-marker"></Link>
        </div>
        <ReactTooltip
            id={"market-marker"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>Market Makers</p>
            </div>
        </ReactTooltip>
        </Fragment>
     );
}
 
export default ChildInvestorAcademyCommon;