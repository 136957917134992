import React, { useState, useEffect, useRef } from "react";
import "./warrantGuestSpeakerVideo.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link, useParams, useLocation } from "react-router-dom";
import { get } from "lodash";

const playlistID = [
  {
    edu1: 1,
    edu2: 2,
    edu3: 3,
    edu4: 4,
    edu5: 5,
    edu6: 6,
    edu7: 7,
    edu8: 8,
    edu9: 9,
    edu10: 10,
    guest1: 11,
    guest2: 12,
    guest3: 13,
    guest4: 14,
    guest5: 15,
    guest6: 16,
    guest7: 17,
  },
];

const WarrantGuestSpeakerVideo = (props) => {

  const location = useLocation()
  const myRef = useRef(null);
  const myRef1 = useRef(null);
  const pathName = get(Location, "pathname", "");
  const [guestPage, setGuestPage] = useState(false)

  useEffect(() => {
    var path = location.hash
    if (path == "#GuestSpeakerVideo") {
      setGuestPage(true)
    } else {
      setGuestPage(false)
    }
  }, [location])
  
  const { id } = useParams();
  const executeScroll = () => myRef.current.scrollIntoView();
  const executeScroll1 = () => myRef1.current.scrollIntoView();

  useEffect(() => {
    if (guestPage) {
      executeScroll();
    }
  }, [guestPage]);

  useEffect(() => {
    if (!guestPage) {
      executeScroll1();
    }
  }, [guestPage]);

  return (
    <div id="dwvideo" className="pageobj" ref={myRef1}>
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?edu">Warrant education</Link>
            </li>
            <li className="active">Warrant videos</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>Educational warrant videos</h1>
          </div>
          <p className="section-p">
            Learn about structured warrants with these bite-sized animated
            videos! In these videos, we will provide an introduction on
            structured warrants and the things to know when trading or investing
            in warrants.
          </p>
          <div id="videogallery">
            <div className="gallery swiper-container swiper-container-horizontal">
              <div className="swiper-wrapper">
                <div
                  className="swiper-slide swiper-slide-active"
                  style={{ width: "1170px" }}
                >
                  <div className="gallery-item">
                    <Link
                      target="_blank"
                      to="/education/warrantvideosind"
                    >
                      <img
                        style={{ width: "100%" }}
                        src="/img/home/VideoHeaderAd.png"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div id="edurack" className="videorack">
              <div className="videorack-title">More educational videos </div>
              <div className="videorack-body">
                <div className="videorack-content swiper-container swiper-container-horizontal">
                  <div className="swiper-wrapper">
                    <div
                      // className="swiper-slide swiper-slide-active"
                      style={{ maxWidth: "1030px", width: "100%" }}
                    >
                      <Carousel
                        showArrows={true}
                        emulateTouch
                        showStatus={false}
                        autoFocus
                        infiniteLoop={false}
                        showIndicators={false}
                      >
                        <div>
                          <table
                            className="videorack-page"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/x4nSIsrlnsM/${playlistID[0].edu1}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={0}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/x4nSIsrlnsM/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Trading structured warrants has never
                                          been easier
                                        </span>{" "}
                                        (0:38)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/DupqIvOz4LU/${playlistID[0].edu2}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={1}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/DupqIvOz4LU/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 1:
                                        </span>{" "}
                                        What are Structured Warrants? (2:22)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/Lg2QZSi18rw/${playlistID[0].edu3}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={2}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/Lg2QZSi18rw/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 2:
                                        </span>{" "}
                                        Why trade Warrants? (2:54)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/VCzKXItjqbc/${playlistID[0].edu4}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={3}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/VCzKXItjqbc/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 3:
                                        </span>{" "}
                                        How to select a Warrant? (3:02)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/JhTPi51KsOs/${playlistID[0].edu5}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={4}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/JhTPi51KsOs/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 4:
                                        </span>{" "}
                                        How to monitor Warrant price changes?
                                        (2:50)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/CthPrSrisGk/${playlistID[0].edu6}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                                      index={5}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/CthPrSrisGk/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Episode 5:
                                        </span>{" "}
                                        How to use the Live Matrix for real time
                                        warrant prices (3:17)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <table
                            className="videorack-page"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/2itgroD4DGw/${playlistID[0].edu7}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                      index={6}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/2itgroD4DGw/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          First Step:
                                        </span>{" "}
                                        What are Structured Warrants? (3:02)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/meI6OjbjkKM/${playlistID[0].edu8}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                      index={7}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/meI6OjbjkKM/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Structured Warrants and
                                          Cryptocurrencies:
                                        </span>{" "}
                                        why they are different (2:29)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/0D4C5tp8wCc/${playlistID[0].edu9}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                      index={8}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/0D4C5tp8wCc/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Structured Warrants and Shares:
                                        </span>{" "}
                                        how do they fit into your portfolio?
                                        (2:40)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/t9J1GLrOMTk/${playlistID[0].edu10}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                      index={9}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/t9J1GLrOMTk/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Structured Warrants and Fixed
                                          Deposits:
                                        </span>{" "}
                                        how do they fit into your portfolio?
                                        (2:42)
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={7}
                                    className="videorack-item"
                                    style={{
                                      display: "inline-block",
                                      visibility: "hidden",
                                    }}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/meI6OjbjkKM/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Structured Warrants and
                                        Cryptocurrencies:
                                      </span>{" "}
                                      why they are different (2:29)
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    playlistid="PLOkzCdnS95xTRdy0HRkuSzB4i06s7CHeS"
                                    index={8}
                                    className="videorack-item"
                                    style={{
                                      display: "inline-block",
                                      visibility: "hidden",
                                    }}
                                  >
                                    <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/0D4C5tp8wCc/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Structured Warrants and Shares:
                                      </span>{" "}
                                      how do they fit into your portfolio?
                                      (2:40)
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-hmgbm" />
        </div>
      </div>
      <div id="demo" ref={myRef} />
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section" id="GusetSpeakerVideo">
          <div className="page-header">
            <h1>Guest speaker videos</h1>
          </div>
          <p className="section-p">
            We regularly invite guest speakers to share their market expertise
            with you. Watch videos of previous talks and join us at any upcoming
            seminars. Also, hear what our investors have to say about their
            warrant trading experience!
          </p>
          <div id="videogallery">
            <div className="gallery swiper-container swiper-container-horizontal">
              <div className="swiper-wrapper">
                <div
                  className="swiper-slide swiper-slide-active"
                  style={{ width: "1170px" }}
                >
                  <div className="gallery-item">
                    <Link
                      to={`/education/warrantvideosind/LD9tX8U2yeA/${playlistID[0].guest7}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ width: "100%" }}
                        src="/img/education/successspeaks_slide.jpg"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div id="gsrack" className="videorack">
              <div className="videorack-title">More guest speaker videos</div>
              <div className="videorack-body">
                <div className="videorack-content swiper-container swiper-container-horizontal">
                  <div className="swiper-wrapper">
                    <div
                      // className="swiper-slide swiper-slide-active"
                      style={{ maxWidth: "1030px", width: "100%" }}
                    >
                      <Carousel
                        showArrows={true}
                        emulateTouch
                        showStatus={false}
                        autoFocus
                        infiniteLoop={false}
                        showIndicators={false}
                      >
                        <div>
                          <table
                            className="videorack-page"
                            style={{ with: "100%" }}
                          >
                            <tbody>
                              <tr>
                                  
                              <td>
                                  <Link
                                    to={`/education/warrantvideosind/LD9tX8U2yeA/${playlistID[0].guest7}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLVy-KcVix8Qf0fgqEpUiBigAG4eZ2IL3X"
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/LD9tX8U2yeA/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                        Quick market talk with Experts | Daryl Guppy Sep 2023 
                                        </span>{" "}
                                        
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                              
                              <td>
                                  <Link
                                    to={`/education/warrantvideosind/0-oAB7TNvmY/${playlistID[0].guest6}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLVy-KcVix8Qf0fgqEpUiBigAG4eZ2IL3X"
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/0-oAB7TNvmY/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                        Quick market talk with Experts | Daryl Guppy June 2023 
                                        </span>{" "}
                                        
                                      </div>
                                    </div>
                                  </Link>
                                </td>

                              <td>
                                  <Link
                                    to={`/education/warrantvideosind/5ZZ-mHqOOf4/${playlistID[0].guest5}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLVy-KcVix8Qf0fgqEpUiBigAG4eZ2IL3X"
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/5ZZ-mHqOOf4/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                        Quick market talk with Experts | Daryl Guppy May 2023 
                                        </span>{" "}
                                        
                                      </div>
                                    </div>
                                  </Link>
                                </td>

                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/l1abz3e-J3Q/${playlistID[0].guest4}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLVy-KcVix8Qf0fgqEpUiBigAG4eZ2IL3X"
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/l1abz3e-J3Q/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                        Quick market talk with Experts | Daryl Guppy Mar 2023
                                        </span>{" "}
                                        
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/qZfdSJNVk6s/${playlistID[0].guest1}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xQ4zrzbOPfp0Cdds8do26Wg"
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/qZfdSJNVk6s/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Daryl Guppy 2018:
                                        </span>{" "}
                                        Overcome your fear of market highs
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/xiq4HijE5YE/${playlistID[0].guest2}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xSpQik9dYWNZB2V-GsF6Eus"
                                      index={1}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/xiq4HijE5YE/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Success Speaks:
                                        </span>{" "}
                                        Warrant investors share their trading
                                        experience
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/RehMF8yfkNM/${playlistID[0].guest3}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xQ7l5AXwvU4djoMXpSaLbyl"
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/RehMF8yfkNM/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Craig Lazzara:
                                        </span>{" "}
                                        What impact will the new US Government
                                        have on markets in 2017?
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                              </tr>
                              {/* <tr>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/${playlistID[0].guest4}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xSU4efroi-l2J2bCoHos3sf"
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/TN4Z3sTRA1U/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Daryl Guppy 2016:
                                        </span>{" "}
                                        Star Stock Picks in Malaysia
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={`/education/warrantvideosind/${playlistID[0].guest5}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div
                                      playlistid="PLOkzCdnS95xTaSD0Zi0LDxYcLcEDCwXzz"
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                    >
                                      <img
                                        style={{ width: "100%" }}
                                        src="https://i.ytimg.com/vi/7U5xXkp006I/mqdefault.jpg"
                                      />
                                      <div className="videorack-item-txt">
                                        <span className="text-bold">
                                          Daryl Guppy 2015:
                                        </span>{" "}
                                        Trading the tired bull
                                      </div>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  <div
                                    className="videorack-item"
                                    style={{ visibility: "hidden" }}
                                  >
                                    <img style={{ width: "100%" }} src />
                                    <div className="videorack-item-txt" />
                                  </div>
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-hmgbm" />
        </div>
      </div>
    </div>
  );
};
export default WarrantGuestSpeakerVideo;
