import React, { useState, useEffect,Fragment } from 'react';
import SelectImpliedVolatilities from './SelectImpliedVolatilities';
import "../../../common.css";
import "../../../investorAcademy.css";


const SelectImpliedVolatility = (props) => {
    return ( 
        <Fragment> 
            <SelectImpliedVolatilities/>
        </Fragment> 
     );
}


export default SelectImpliedVolatility;