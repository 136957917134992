import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import "../../../common.css";
import "../../../investorAcademy.css";
import ChildInvestorAcademyCommon from '../../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon';
import SelectingWarrantCommonTabs from '../../../../../../components/common/SelectingWarrantCommonTabs';
import SelectingWarrentImg from '../../../../../../components/common/SelectingWarrentImg'


const WarrantSpreads = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const location = useLocation();
    const history = useHistory();
    const [openLive, setOpenLive] = useState(false);
    const [imageUrl, setImageUrl] = useState("")

    return (
        <div id="investor_academy">
            <div className="imgbg" />
            <div className="mainbg"></div>

            <div id="main">
                <div id="maintable" className='share_capital Common_warrent' style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                    <div style={{ display: 'table-row' }}>
                        <div id="leftbar" className="hidden-xs" style={{ display: 'table-cell', width: '80px' }} />
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                                <table style={{ width: '100%', tableLayout: 'fixed', borderBottom: '1px solid #353223' }}>
                                    <tbody><tr>
                                        <td width="20%" style={{ textAlign: 'left', visibility: 'hidden' }}>
                                            <table className="hasprev hidden-xs" style={{ width: '100%', height: '100%', tableLayout: 'fixed' }}>
                                                <tbody><tr>
                                                    <td width="33px"><div className="prev left" /></td>
                                                    <td>
                                                        <div className="prevlbl left">
                                                            <div className="prevtitle " style={{ textAlign: 'left' }} />
                                                            <div className="prevname " style={{ textAlign: 'left' }}>Implied Volatility</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody></table>
                                        </td>
                                        <td width="60%" style={{ textAlign: 'center' }}>
                                            <div id="pagetitlediv" style={{ width: '365px', height: '100%', borderBottom: '1px solid #FFD843', margin: 'auto' }}>
                                                <div id="ptitle" style={{ fontFamily: 'HelveticaNeueThinCond', fontSize: '25px', color: 'white', letterSpacing: '1px' }}>How to select a Warrant?</div>
                                                <div id="pname" style={{ fontFamily: 'HelveticaNeueThinCond', fontSize: '30px', color: '#FFD843', letterSpacing: '1px', paddingTop: '5px', paddingBottom: '5px' }}>Warrant Spreads</div>
                                            </div>
                                        </td>
                                        <td width="20%" style={{ textAlign: 'right', visibility: 'hidden' }}>
                                            <table className="hasnext  hidden-xs" style={{ width: '100%', height: '100%', tableLayout: 'fixed' }}>
                                                <tbody><tr>
                                                    <td>
                                                        <div className="nextlbl right">
                                                            <div className="nexttitle " style={{ textAlign: 'right' }} />
                                                            <div className="nextname " style={{ textAlign: 'right' }}>Market Maker</div>
                                                        </div>
                                                    </td>
                                                    <td width="33px"><div className="next right" /></td>
                                                </tr>
                                                </tbody></table>
                                        </td>
                                    </tr>
                                    </tbody></table>
                            </div>
                            <div id="maincontent" style={{ marginLeft: '10px', marginRight: '10px', paddingTop: '40px', marginBottom: '60px' }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', paddingLeft: '90px', paddingRight: '90px' }}>
                                    <div id="mainscrollcontent">
                                        <style dangerouslySetInnerHTML={{ __html: "\n            .contentbox table{\n                margin: 0 auto;\n            }\n            .contentbox table thead th{\n                background-color: #FFD400;\n            }\n            .contentbox table tbody td,\n            .contentbox table thead th{\n                padding:0.5em 1em;\n                color:#000000;\n                text-align: center;\n            }\n            .contentbox table tbody td.bgc0{\n                background-color: #FFF4D2;\n            }\n            .contentbox table thead th.bgc1 {\n                background-color: #9E9FA1;\n            }\n            .contentbox table tbody td.bgc2{\n                background-color: #C0C1C2;\n            }\n            .contentbox table tbody tr.active td{\n                background-color: #FD8C8F;\n            }\n            .ms .contentbox table.ctable\n            .xs .contentbox table.ctable,\n            .xxs .contentbox table.ctable{\n                font-size:11px;\n            }\n            .ms .contentbox table  td,\n            .ms .contentbox table  th,\n            .xs .contentbox table  td,\n            .xs .contentbox table  th,\n            .xxs .contentbox table td,\n            .xxs .contentbox table th{\n                padding:0.5em;\n            }\n            .imgholder img{\n                max-width: 895px;\n            }\n            .imgholder .imgmask{\n                position:relative;\n                margin:auto;\n            }\n            .imgholder .area{\n                position:absolute;\n                display:block;\n            }\n        " }} />
                                        <div className="contentbox">
                                            <p>When choosing a warrant, we suggest that investors consider the following 4 steps:</p>

                                            <div className="imgholder">
                                                <SelectingWarrantCommonTabs Location={location} Hist={history} />
                                            </div>

                                            <p>
                                                Finally, avoid investing in a warrant that is not on a tight spread. You can use the <Link to="/tools/livematrix/" target="_blank">Live Matrix</Link> to see when the issuer is on a wide spread for a warrant.
                                            </p>
                                            <div className="imgholder">
                                                <img src='/img/education/tightspread.png' style={{ maxWidth: '321px' }} />
                                                <p className="showtile">
                                                    Example of a warrant on a tight spread
                                                </p>
                                            </div>
                                            <p>
                                                The minimum spread is RM0.005 for warrants priced below RM1.00, and RM0.010 for warrants priced above RM1.00.
                                            </p>
                                            <p>
                                                If the bid and offer price shown on the Live Matrix is more than the minimum spread, it means that the market maker is unable to provide a tight spread on this warrant.
                                            </p>
                                            <div className="imgholder">
                                                <img src='/img/education/widespread.png' style={{ maxWidth: '321px' }} />
                                                <p className="showtile">
                                                    Example of a warrant on a wide spread
                                                </p>
                                            </div>
                                            <p>
                                                Investing in a warrant on a wide spread means you would incur an additional cost to trade, as you would purchase the warrant at a higher price and may end up selling the warrant back at a lower price.
                                            </p>

                                            <h2>
                                                Is the warrant on wide spread?
                                            </h2>
                                            <p>
                                                Warrant E is trading on a tight spread, whereas Warrant F has a wide spread of RM0.015, or three ticks. Investors will incur an additional cost to trade if they choose to trade Warrant F.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: '888px' }} src='/img/education/table4.png' />
                                                <div className="map_pop">
                                                    <div
                                                        className="how_put"
                                                        data-bs-toggle="collapse"
                                                        href="#popup-image"
                                                        role="text"
                                                        aria-expanded="false"
                                                        aria-controls="popup-image"
                                                        onClick={() => { return setOpenLive(true), setImageUrl("/img/education/tableMob4.png") }}
                                                    >View table
                                                    </div>
                                                </div>

                                            </div>
                                            <p>
                                                In the example provided, with our suggested selection process, the investor is likely to select Warrant E to trade.
                                            </p>
                                            <p>
                                                Please do not hesitate to contact us if you require further clarification on the process of selecting a warrant. You can also <Link to="/education/seminar" target="_blank">sign up for a seminar</Link> near you to learn more about warrants.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div id="rightbar" className="hidden-xs" style={{ display: 'table-cell', width: '80px' }}>
                                </div>
                            </div>
                        </div>
                        <div id="rightbar" className="hidden-xs" style={{ display: 'table-cell', width: '80px' }}>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="bottom"
                style={{
                    position: "fixed",
                    width: "100%",
                    bottom: "0px",
                    height: "40px",
                    backgroundColor: "#101010",
                    zIndex: 10000,
                }}
            >
                <div
                    className="hidden-xs hidden-xxs"
                    style={{
                        display: "table",
                        width: "100%",
                        textAlign: "center",
                        color: "#8F8F8F",
                        fontSize: "11px",
                        lineHeight: "40px",
                    }}
                >
                    <div></div>
                </div>
                <div
                    style={{
                        position: "absolute",
                        width: "46%",
                        height: "35px",
                        right: "10px",
                        bottom: "5px",
                    }}
                >
                    <table
                        className="hasnext"
                        style={{ width: "100%", height: "100%", tableLayout: "fixed" }}
                    >
                        <tbody>
                            <tr>
                                <td>
                                    <div className="nextlbl right">
                                        <div
                                            className="nextname "
                                            style={{ textAlign: "right", fontSize: "15px" }}
                                        >
                                            <Link to="/edu/select-warrant/market-maker">
                                                <span className="prevname ">  Market Maker</span>
                                            </Link>
                                        </div>
                                    </div>
                                </td>
                                <td width="33px">
                                    <Link to="/edu/select-warrant/market-maker">
                                        <div className="next right" />
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    style={{
                        position: "absolute",
                        width: "46%",
                        height: "35px",
                        left: "10px",
                        bottom: "5px",
                    }}
                >
                    <table
                        className="hasprev"
                        style={{ width: "100%", height: "100%", tableLayout: "fixed" }}
                    >
                        <tbody>
                            <tr>
                                <td width="33px">
                                    <Link to="/edu/select-warrant/selecting-warrant/select-implied-volatility">
                                        <div className="prev left" />
                                    </Link>
                                </td>
                                <td>
                                    <div className="prevlbl left">
                                        <div
                                            className="prevname "
                                            style={{ textAlign: "left", fontSize: "15px" }}
                                        >
                                            <Link to="/edu/select-warrant/selecting-warrant/select-implied-volatility">
                                                <span className="prevname "> Implied Volatility</span>
                                            </Link>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div id="dotbar" className="hidden-sm hidden-xs" style={{}}>
                {/* <div className="dbtn dbtn-seminarbtn">
                    <Link
                        className="seminarbtn"
                        to="/education/seminar"
                    >
                        <img src='/img/education/button-static.png' alt="image" />
                    </Link>
                </div> */}
                <SelectingWarrentImg Open={openLive} OpenModel={() => setOpenLive(!true)} Data={imageUrl}/>
                <ChildInvestorAcademyCommon Location={location} Hist={history} />
            </div>

        </div>
    );
}
export default WarrantSpreads;