import React, { useState, useEffect, useRef, Component } from "react";
import "../MoneyFlow/moneyFlows.css";
import $ from "jquery";
import { getMoneyFlowChart, getMoneyFlowChartCall, getMoneyFlowChartPut } from '../../../utils/moneyFlow.api' 
import Highcharts from 'highcharts/highstock'
import Callchart from "./CallChart"
import Putchart from "./PutChart"


const MoneyFlowChart = (props) => {



    const [chartData, setChartData] = useState()
    const [callData, setCallData] = useState()
    const [putData, setPutData] = useState()

    

    useEffect(() => {
        var windowUrl = window.location
        var urlArr = windowUrl.search.split("=")
        if (windowUrl.pathname.includes("moneyflowchart")) {
            $("header").hide();
            $(".footer").hide();
            var codeVal = urlArr[2]
            var nameVal1 = urlArr[1].replace("&code", "")
            var nameVal = nameVal1.replace("&", "%26")
        }
        getMoneyFlowChartData(codeVal)
        getMoneyFlowChartPutData(nameVal, codeVal)
        getMoneyFlowChartCallData(nameVal, codeVal)
    },[]);

    const getMoneyFlowChartData = (code) => {
        getMoneyFlowChart(code)
        .then((res) => {
            setChartData(res.data[code])
        })
    }

    const getMoneyFlowChartPutData = (name, code) => {
        getMoneyFlowChartPut(name, code)
        .then((res) => {
            setPutData(res.data.moneyflow)
        })
    }

    const getMoneyFlowChartCallData = (name, code) => {
        getMoneyFlowChartCall(name, code)
        .then((res) => {
            setCallData(res.data.moneyflow)
        })
    }


  return (
    <>
      <div id="moneyflowchart" className="pageobj">
        <div className="chartbox">
        <div
                id="callChart"
                className="chartbox-bars"
                style={{ position: "relative"}}
            >
            {callData == [] || callData == "" ? <div class="nodata"><table><tbody><tr><td>No data is available.</td></tr></tbody></table></div> : ""}
            {callData && chartData && <Callchart callData={callData} chartData={chartData} />}
            </div>
        </div>
        <div className="chartbox" style={{ marginTop: "20px" }}>
            <div
                id="putChart"
                className="chartbox-bars"
                style={{ position: "relative"}}
            >                
                {putData == [] || putData == "" ? <div class="nodata"><table><tbody><tr><td>No data is available.</td></tr></tbody></table></div> : ""}
                {putData && chartData && <Putchart putData={putData} chartData={chartData} />}
            </div>
        </div>
      </div>
    </>
  );
};
export default MoneyFlowChart;
