import React, { useState, useEffect } from 'react';
import moment from 'moment';
import "../warrantDocument.css";
import { Base_Url } from "../../../../utils/apiUrl"



const NoticeTabs = ({WarrantDocumentsNotice}) => {


  
  
    return ( 
        
        <div  className="tabcontent" >
        <table className="table table-striped" style={{display: "none"}}>
          <thead>
            <tr>
              <th className="text-center view">Id</th>
              <th className="text-center view">Date</th>
              <th className="text-center view">Underlying</th>
              <th className="text-center view">Headline</th>
              <th className="text-center view">Related Warrant</th>
              <th className="text-center view">View</th>
            </tr>
          </thead>
          <tbody>
            {!WarrantDocumentsNotice && 
              <tr className="emptymsg">
              <td colSpan={5}>No data</td>
            </tr>}
            {WarrantDocumentsNotice && WarrantDocumentsNotice.map((data,idx)=>(
              <tr key={idx} className="emptymsg">
              <td>{data.id}</td>
              <td>{(moment(data.date).format("DD MMM YY"))}</td>
              <td>{data.underlying}</td>
              <td>{data.headline}</td>
              <td>{data.related_warrant}</td>
              <td className="text-center view">
              <a target="_blank" href={`${Base_Url}/apimqmy/pdf?type=prospectus&id=${data.id}`}>
                <img src="/img/bullet/pdf.png" />
              </a>
            </td>
            </tr>
            ))}
          </tbody>
        </table>
        <p className="notice-p">
          For announcements addressed to holders of Macquarie warrants,
          including adjustments for corporate actions and expiry notices,
          you'll be able to find it on{" "}
          <a href="https://www.bursamalaysia.com/" target="_blank">
            Bursa Malaysia's website
          </a>
          , under{" "}
          <a
            href="https://www.bursamalaysia.com/market_information/announcements/structure_warrants"
            target="_blank"
          >
            Structured Warrants announcements
          </a>
          .
        </p>
      </div>
     );
}
 
export default NoticeTabs;