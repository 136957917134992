import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "../../../src/pages/WarrantMarket/JoinTelegram/joinTelegram.css"


const WarrantHeatmapModal = (props) => {
    const {
      OpenModal, Url,
        Open,
      } = props;

      const toggle = (index) => {

      }
      
    return ( 
    <div id="ppboxHTML" className="overlay" style={{ display: 'none' }}>
      <div className="overlay-inner">
        <Modal style={{ position: 'relative' }} centered isOpen={Open} toggle={OpenModal} className="modal-lg mt-5 terms">
        
        <ModalBody >
          <div style={{position: 'relative'}}>
            <div className="popupbox-content warrant-terms"> 
              <div className="ppc" key="subscribe-newsletter">
                  <div className="signup-layer">
                    <div className="page-header">
                        <h3>Important notice</h3>
                    </div>                                    
                    <p className="small">
                        You are now entering a third party website which is neither operated nor sponsored by Macquarie. 
                        Macquarie is not responsible for the content, cookie collection, personal data collection, trading and services 
                        provided on such website. You should review the terms and conditions of third party websites and contact the 
                        operators of such websites if you have any queries.
                        <br/>
                        <br/>
                        
                    </p>
                    <div style={{textAlign: 'center'}}>
                      <a href={Url} target="_blank">
                        <input style={{marginRight: '30px'}} onClick={OpenModal} className="btn btn-primary continue" defaultValue="Continue" type="button" />
                        </a>
                        <input onClick={OpenModal} className="btn btn-primary cancel" defaultValue="Cancel" type="button" />
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </ModalBody>
        
      </Modal>
      </div>
    </div>
     );
}
 
export default WarrantHeatmapModal;