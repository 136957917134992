
import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import ReactTooltip from "react-tooltip"; 


const  InvestorAcademyCommon = ({Location,Hist}) => {

    const pathName = get(Location,"pathname","")
    return ( 
        <Fragment>
            <div className="homebtn dbtn">
                <Link to="/edu"></Link>
            </div>
            <div 
                className={pathName === "/edu/why-trade-warrant" ? "dotbtn d1 dbtn selected" :"dotbtn d1 dbtn"}
                data-tip
                data-for={"why-trade-warrants"}
            >
                <Link to="/edu/why-trade-warrant"></Link>
            </div>
            <ReactTooltip
                id={"why-trade-warrants"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>Why Trade Warrants?</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/what-are-warrant" ? "dotbtn d2 dbtn selected" :"dotbtn d2 dbtn"} 
                data-tip
                data-for={"what-are-warrants"}
            >
                <Link to="/edu/what-are-warrant"></Link>
            </div>
            <ReactTooltip
                id={"what-are-warrants"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>What are Warrants?</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/how-are-warrant-priced" ? "dotbtn d4 dbtn selected" :"dotbtn d4 dbtn"}
                data-tip
                data-for={"How-are-Warrants-priced"}
            >
                <Link to="/edu/how-are-warrant-priced"></Link>
            </div>
            <ReactTooltip
                id={"How-are-Warrants-priced"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>How are Warrants priced?</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/understanding-warrant-structure" ? "dotbtn d10 dbtn selected" :"dotbtn d10 dbtn"}
                data-tip
                data-for={"Understanding-the-Warrant-Structure"}
            >
                <Link to="/edu/understanding-warrant-structure"></Link>
            </div>
            <ReactTooltip
                id={"Understanding-the-Warrant-Structure"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>Understanding the Warrant Structure</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/monitoring-price-changes" ? "dotbtn d7 dbtn selected" :"dotbtn d7 dbtn"} 
                data-tip
                data-for={"Monitoring-price-changes"}
            >
                <Link to="/edu/monitoring-price-changes"></Link>
            </div>
            <ReactTooltip
                id={"Monitoring-price-changes"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>Monitoring price changes</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/useful-warrant-terms" ? "dotbtn d11 dbtn selected" :"dotbtn d11 dbtn"} 
                data-tip
                data-for={"useful-warrant-terms"}
            >
                <Link to="/edu/useful-warrant-terms"></Link>
            </div>
            <ReactTooltip
                id={"useful-warrant-terms"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>Useful Warrant terms</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/select-warrant" ? "dotbtn d3 dbtn selected" :"dotbtn d3 dbtn"} 
                data-tip
                data-for={"select-warrant"}
            >
                <Link to="/edu/select-warrant"></Link>
            </div>
            <ReactTooltip
                id={"select-warrant"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>How to select a Warrant?</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/single-share-calls" ? "dotbtn d9 dbtn selected" :"dotbtn d9 dbtn"} 
                data-tip
                data-for={"single-share-call"}
            >
                <Link to="/edu/single-share-calls"></Link>
            </div>
            <ReactTooltip
                id={"single-share-call"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>Single share calls and puts at expiry</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/index-calls" ? "dotbtn d5 dbtn selected" :"dotbtn d5 dbtn"} 
                data-tip
                data-for={"index-calls"}
            >
                <Link to="/edu/index-calls"></Link>
            </div>
            <ReactTooltip
                id={"index-calls"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>Index calls and puts at expiry</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/risks" ? "dotbtn d8 dbtn selected" :"dotbtn d8 dbtn"} 
                data-tip
                data-for={"risks"}
            >
                <Link to="/edu/risks"></Link>
            </div>
            <ReactTooltip
                id={"risks"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>Risks</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/edu/market-marker" ? "dotbtn d6 dbtn selected" :"dotbtn d6 dbtn"} 
                data-tip
                data-for={"market-marker"}
            >
                <Link to="/edu/market-marker"></Link>
            </div>
            <ReactTooltip
                id={"market-marker"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>Market Makers</p>
                </div>
            </ReactTooltip>
        </Fragment>
     );
}
 
export default InvestorAcademyCommon;