import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import "./search.css";
import {useLocation,useHistory} from "react-router-dom"
import { searchApi } from "../../utils/searchApi";

const SearchCom = () => {
const location = useLocation()
const history = useHistory()
const {search} = location
const [searchKey, setsearchKey] = useState(search.split("?")[1])
const [key, setkey] = useState(search.split("?")[1])
const [loading, setloading] = useState(false)
const [data, setdata] = useState([])

useEffect(() => {
if(searchKey !== ""){
  searchNow()
}
  return () => {
  }
}, [])

const searchNow = async() => {
    try {
        setloading(true)
        const res =  await searchApi(searchKey) 
        if(res && res.data){
            setdata(res.data)
        }
        else{
            setdata([])
        }
        setloading(false)
    }
    catch (error) {
        console.log(error)   
    }
}

const goSearch = () =>{
    history.push("/search?"+searchKey)
}

const highlight = (text) => {  
  var reg = new RegExp(key, 'gi');
  var result = text.replace(reg, function(str) {return str.bold()});
  return result; 
}

  return (
    <div id="pagesearch">
        <div className="pagesearch-top">
            <div className="container-fluid">
                <div className="psearchbox input-group">
                    <input 
                    name="keywords" 
                    type="text" 
                    className="form-control" 
                    placeholder="Search Macquarie" 
                    value={searchKey}
                    onChange={(e)=>{
                        setsearchKey(e.target.value)
                    }}/>
                    <div className="input-group-btn">
                        <button className="btn btn-default" type="submit" onClick={goSearch}>
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="pagesearch-body">
            {loading && <div className="loading">loading ...</div>}
            <div className="container-fluid">
            {data.length > 0 ?data.map((item,index)=>{
                return (<div className="row" key={index}>
                    <div className="col-sm-12">
                        <div className="search-resultbox" style={{position: 'relative'}}>
                            <div className="search-result resulttmpl" index={index}>
                                <a href={item.page_url} className="search-result-title" dangerouslySetInnerHTML={{__html:highlight(item.page_title)}}></a>
                                <div className="search-result-dsply" dangerouslySetInnerHTML={{__html:highlight(item.page_dsply)}}></div>
                                <a className="search-result-achor" href={item.page_url}>{item.page_url}</a>
                            </div>
                        </div>
                    </div>
                </div>)}):loading?null:<div className="emptySearch">No search Results for this query</div>}
            </div>
        </div>
    </div>
  );
};
export default SearchCom;
