import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
function Guest1() {

  const  refreshPage=()=> {
    window.location.reload(false);
  }


  return (
    <div className="videorack-body">
      <div className="videorack-content swiper-container swiper-container-horizontal">
        <div className="swiper-wrapper">
          <div
            className="swiper-slide swiper-slide-active"
            style={{
              paddingLeft: "30px",
              paddingRight: "35px",
              // width: "1090px",
            }}
          >
            <Carousel
              showArrows={true}
              emulateTouch
              showStatus={false}
              autoFocus
              infiniteLoop={false}
              showIndicators={false}
            >
              <div>
                <table className="videorack-page hidden-xs hidden-sm">
                  <tbody>
                    <tr>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/qZfdSJNVk6s/11">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/qZfdSJNVk6s/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span> Episode 1 <br />{" "}
                            Trading turbulence
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={1}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/HolYObvfNUc/41">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/HolYObvfNUc/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span> Episode 2 <br />{" "}
                            Market trends and environment
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={2}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/HNn4qeSctC4/42">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/HNn4qeSctC4/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span> Episode 3 <br />{" "}
                            Reading Bubbles, Head and
                            <br />
                            Shoulders, Parabolics and
                            <br />
                            Rounding Tops
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={3}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/-ZgC2fZcNnc/43">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/-ZgC2fZcNnc/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Episode 4
                            <br />
                            Simple trade solutions
                          </div>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <table className="videorack-page ">
                  <tbody>
                    <tr>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={0}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/kjfW2BaeldU/44">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/kjfW2BaeldU/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Episode 5
                            <br />
                            identifying trading opportunities
                            <br />
                            with GMMA
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={1}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/xYSZEFXOcVQ/45">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/xYSZEFXOcVQ/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Episode 6 <br />
                            Applying GMMA on commodities
                            <br />
                            and global markets charts
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={2}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          <Link  to="/education/warrantvideosind/3KvYkAwc19A/46">
                          <img
                            style={{ width: "100%" }}
                            src="https://i.ytimg.com/vi/3KvYkAwc19A/mqdefault.jpg"
                          />
                          <div className="videorack-item-txt">
                            <span className="text-bold"></span>Episode 7
                            <br />
                            Trading Malaysian stocks
                          </div>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div
                          playlistid="PLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3"
                          index={3}
                          className="videorack-item"
                          style={{ display: "inline-block" }}
                        >
                          {/* <img
                                      style={{ width: "100%" }}
                                      src="https://i.ytimg.com/vi/IByMqRq5Wdk/mqdefault.jpg"
                                    />
                                    <div className="videorack-item-txt">
                                      <span className="text-bold">
                                        Episode 3:
                                      </span>{" "}
                                      How to select a Warrant? (3:02)
                                    </div> */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guest1;
