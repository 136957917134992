import axios from "axios"



export  const googleCollect = () =>
    axios.post("https://www.google-analytics.com/j/collect?v=1&_v=j96&a=1199763515&t=pageview&_s=1&dl=https%3A%2F%2Ft1www5.trkd-hs.com%2Fmqmy_aws%2Ftools%2Funderlying%2FAEON&ul=en-us&de=UTF-8&dt=Underlying%20charts%20%7C%20Malaysia%20Warrants%20%7C%20Macquarie&sd=24-bit&sr=1366x768&vp=1349x431&je=0&_u=QACAAUABAAAAAC~&jid=1592972438&gjid=1254908583&cid=2083827218.1647866660&tid=UA-52496905-5&_gid=1774745445.1647866660&_r=1&gtm=2ou3e0&z=485402964")

export  const googleCollectData = () =>
    axios.post("https://stats.g.doubleclick.net/j/collect?t=dc&aip=1&_r=3&v=1&_v=j96&tid=UA-52496905-5&cid=2083827218.1647866660&jid=1592972438&gjid=1254908583&_gid=1774745445.1647866660&_u=QACAAUAAAAAAAC~&z=886970433")

        