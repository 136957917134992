import React, { useState, useEffect } from 'react';
import { XLg } from 'react-bootstrap-icons';
import { Link } from "react-router-dom"
import "./whyMacquarie.css";
import HotlineModal from "../../../components/common/layout/HotlineModal";
import { Carousel } from 'react-responsive-carousel';
const WhyMacquaries = (props) => {
    const [show, setShow] = useState(false);
    const [policyShow, setPolicyShow] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // const [displayBlock, setDisplayBlock] = useState("none");
    // const [xs,setXs]=useState(true);

    // const listenScrollEvent = () => {
    //     window.scrollY > '700' && xs==true ? setDisplayBlock("block") : setDisplayBlock("none");
    // };

    // const handleClose=()=>{
    //     setDisplayBlock("none")
    //     setXs(true)
    // }
    // useEffect(() => {
    //     window.addEventListener("scroll", listenScrollEvent);
    // },[displayBlock]);

    return (
        <div id="whydw28" className="pageobj">
            <div className="breadcrumbbar">
                <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/home?edu">Warrant education</Link>
                        </li>
                        <li className="active">Why Macquarie?</li>
                    </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div style={{ backgroundColor: "#fff" }}>
                    <div id="area_1" className='banner_01'>
                        {/* <img src="/img/bullet/Header_image.png" /> */}
                        <div className="inner_title " style={{ margin: '0 auto', position: 'static', padding: '20px 0px' }}>
                            <p className='color-primary'>Why Macquarie?</p>
                            <p style={{ color: '#fff', fontSize: '18px' }}>Asia’s leading warrant issuer</p>
                            <p style={{ color: '#A6A5A0', fontSize: '16px' }}>Macquarie is the leading warrant issuer in Asia, and the only issuer active in four warrant markets in the region.</p>
                        </div>
                    </div>
                    <div className='banner_02' style={{ paddingTop: '30px',position:'relative' }}>
                        <Carousel
                            showArrows={true}
                            emulateTouch
                            showStatus={false}
                            autoFocus
                            infiniteLoop={false}
                            showIndicators={false}
                            showThumbs={false}
                            className="xs-none  none-slide" 
                        >
                            <div className="slide-tab">
                                <table style={{}}>
                                    <tbody>
                                        <tr>
                                            <td  >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2023</div>
                                                    <p style={{marginTop: '15px'}}>Best Structured Warrants Issuer (Index Warrants)</p>
                                                    <p className='banner_tab_f'>Bursa Excellence Awards 2023</p>
                                                </div>
                                            </td>
                                            <td  >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2022</div>
                                                    <p style={{marginTop: '15px'}}>Best Structured Warrants Issuer (Index Warrants & Equity Warrants)</p>
                                                    <p className='banner_tab_f'>Bursa Excellence Awards 2022</p>
                                                </div>
                                            </td>
                                            <td >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2021</div>
                                                    <p style={{marginTop: '15px'}}>Best Structured Warrants Issuer</p>
                                                    <p className='banner_tab_f'>Bursa Excellence Awards 2021</p>
                                                </div>
                                            </td>
                                            <td >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2018</div>
                                                    <p style={{marginTop: '15px'}}>Best Participating Organisation Structured Warrant</p>
                                                    <p className='banner_tab_f'>Bursa Excellence Awards 2018</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="slide-tab ">
                                <table style={{}}>
                                    <tbody>
                                        <tr>
                                            <td >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2015</div>
                                                    <p style={{marginTop: '15px'}}>Best Participating Organisation Structured Warrant</p>
                                                    <p className='banner_tab_f'>Bursa Broker Awards 2015</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Carousel>
                        <Carousel
                            showArrows={true}
                            emulateTouch
                            showStatus={false}
                            autoFocus
                            infiniteLoop={false}
                            showIndicators={false}
                            showThumbs={false}
                            className="md-up-none sm-none"
                        >
                            <div className="slide-tab ">
                                <table style={{ margin: '0 auto' }}>
                                    <tbody>
                                        <tr>
                                            <td  >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2023</div>
                                                    <p style={{marginTop: '15px',fontSize: '16px'}}>Best Structured Warrants Issuer (Index Warrants)</p>
                                                    <p className='banner_tab_f'>Bursa Excellence Awards 2023</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="slide-tab ">
                                <table style={{ margin: '0 auto' }}>
                                    <tbody>
                                        <tr>
                                            <td  >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2022</div>
                                                    <p style={{marginTop: '15px',fontSize: '16px'}}>Best Structured Warrants Issuer (Index Warrants & Equity Warrants)</p>
                                                    <p className='banner_tab_f'>Bursa Excellence Awards 2022</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="slide-tab ">
                                <table style={{ margin: '0 auto' }}>
                                    <tbody>
                                        <tr>
                                            <td >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2021</div>
                                                    <p style={{marginTop: '15px',fontSize: '16px'}}>Best Structured Warrants Issuer</p>
                                                    <p className='banner_tab_f'>Bursa Excellence Awards 2021</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="slide-tab ">
                                <table style={{ margin: '0 auto' }}>
                                    <tbody>
                                        <tr>
                                            <td >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2018</div>
                                                    <p style={{marginTop: '15px',fontSize: '16px'}}>Best Participating Organisation Structured Warrant</p>
                                                    <p className='banner_tab_f'>Bursa Excellence Awards 2018</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="slide-tab ">
                                <table style={{ margin: '0 auto' }}>
                                    <tbody>
                                        <tr>
                                            <td >
                                                <div className='banner_tab'>
                                                    <div><img src="/img/bullet/AwardIcon.png" alt="" />2015</div>
                                                    <p style={{marginTop: '15px',fontSize: '16px'}}>Best Participating Organisation Structured Warrant</p>
                                                    <p className='banner_tab_f'>Bursa Broker Awards 2015</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Carousel>
                        <p  style={{ position:"absolute",bottom:'10px',left:'15px',fontWeight:'bold',fontSize:'13px',margin:'0px' }}>*Macquarie Capital Securities (Malaysia) Sdn. Bhd. is a Participating Organisation of Bursa Malaysia</p>
                    </div>
                    <table className="tablearea tablearea_mob">
                        <tbody>
                            <tr>
                                <td className="td-text" style={{ padding: '50px 50px' }}>
                                    <div className="inner_title color-primary">
                                        State-of-the-art{" "}
                                        <span style={{ whiteSpace: "nowrap" }}>market making</span>
                                    </div>
                                    <div className="inner_content" style={{ lineHeight: '20px' }}>
                                        Our consistent pricing is one of the reasons that makes Macquarie the leading warrant issuer in Asia. The <a href="/tools/livematrix/" style={{textDecoration: 'revert'}}>Live Matrix</a> is available for all warrants issued by Macquarie and can be accessed for free. The Live Matrix is directly connected to Macquarie’s market making system and indicates where Macquarie’s bid and offer prices would be in a warrant, based on various price points in the underlying security, allowing you to make more informed decisions.
                                    </div>
                                    <div className="inner_content" style={{ lineHeight: '20px', marginTop: '15px' }}>
                                        Tight spreads and liquidity make it easy to enter and exit your trades. We provide high liquidity for warrants issued by us, with volumes of up to 12 million units placed on each of the bid and offer price for our Hang Seng Index warrants, up to 5 million units each for Nikkei 225 Index warrants, up to 4 million units each for warrants over the Hang Seng TECH Index and U.S. indices, up to 3 million units each for Hong Kong stock warrants, and up to 1 million units each for local underlying warrants*. This allows investors to enter and exit their positions with relative ease, and for warrants on tight spreads, this means investors incur a lower cost, as compared to buying and selling warrants on wide spreads.
                                    </div>
                                    <div style={{ color: '#FBCD03', background: '#666666', display: 'inline-block', cursor: 'pointer', padding: '5px 20px', borderRadius: '5px', marginTop: '15px' }} onClick={() => setPolicyShow(true)}>Read more</div>
                                    <div style={{ marginTop: '10px',fontSize: '12px',fontWeight: 'bold' }}>* Maximum volumes placed as of May 2024</div>
                                </td>
                                <td>
                                    <img src="/img/education/Stateoftheartmm.png" style={{maxHeight:'706px'}}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="tablearea">
                        <tbody>
                            <tr>
                                <td>
                                    <img src="/img/education/u22323.png" id="LearnMore_img"/>
                                </td>
                                <td className="td-text">
                                    <div className="inner_title color-primary">
                                        Learn more with Macquarie
                                    </div>
                                    <div className="inner_content">
                                        We invest a lot of time and effort into educating investors to improve their trading skills. Use the warrant tools available on our website and check out the educational resources available.
                                    </div>
                                    <div style={{marginTop:'15px'}}>
                                        <Link target="_blank" to="/education/seminar">
                                            <div id="btn-get-started" className="color-primary" style={{ display: 'inline-block', marginRight: '15px', width:'180px',height: '37px',lineHeight: '37px',fontSize: '17px'}}>
                                                Seminar
                                            </div>
                                        </Link>
                                        <Link target="_blank" to="/edu">
                                            <div id="btn-get-started" className="color-primary" style={{ display: 'inline-block', width:'180px',height: '37px',lineHeight: '37px',fontSize: '17px'  }}>
                                                Investor academy
                                            </div>
                                        </Link>
                                    </div>
                                    <div>
                                    <Link target="_blank" to="/education/getstarted">
                                            <div id="btn-get-started" className="color-primary" style={{ display: 'inline-block', marginRight: '15px', width:'180px' ,height: '37px',lineHeight: '37px',fontSize: '17px'}}>
                                                Get started
                                            </div>
                                        </Link>
                                        <Link target="_blank" to="/education/warrantvideos">
                                            <div id="btn-get-started" className="color-primary" style={{ display: 'inline-block', width:'180px',height: '37px',lineHeight: '37px',fontSize: '17px' }}>
                                                Educational videos
                                            </div>
                                        </Link>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="tablearea tablearea_mob">
                        <tbody>
                            <tr>
                                <td className="td-text">
                                    <div className="inner_title color-primary">Constant support</div>
                                    <div className="inner_content">
                                        Our local team is available throughout trading hours to help with your needs. Call or email us with your questions, we would love to hear from you. Access the latest updates on warrants via our website and social channels.
                                    </div>
                                    <div>
                                        <Link target="_blank" to="/marketnews/subscribe">
                                            <img src="/img/bullet/SubscribeIcon.png" alt="" style={{ width: '42px', height: '42px', marginRight: '5px' }} />
                                        </Link>
                                        <a style={{position:'relative',display:'inline-block'}} id="imgHover"  href="tel:+60 3 2059 8840">
                                            <img src="/img/bullet/ContactIcon.png"  alt="" style={{ width: '42px', height: '42px', cursor: 'pointer', marginRight: '5px' }} />
                                            <div
                                                style={{ display: "none", top: '56px', left: "0px" }}
                                                className="dropmenu-content nav-icon-tooltip"
                                            >
                                                <div className="icon-tooltip-bg" />
                                                <div className="icon-tooltip-content">
                                                    <i>
                                                        <a href="tel:+60 3 2059 8840">+60 3 2059 8840</a>
                                                        <br />
                                                        <a href="mailto:info@malaysiawarrants.com.my">
                                                            info@malaysiawarrants.com.my
                                                        </a>
                                                    </i>
                                                </div>
                                            </div>
                                        </a>
                                        <a target="_blank" href="https://www.facebook.com/MalaysiaWarrants/">
                                            <img src="/img/bullet/Facebook-logo-blue-circle-large-white-f.png" alt="" style={{ width: '42px', height: '42px', marginRight: '5px' }} />
                                        </a>
                                        <Link target="_blank" to="/telegram">
                                            <img src="/img/bullet/t_logo.png" alt="" style={{ width: '42px', height: '42px', marginRight: '5px' }} />
                                        </Link>
                                    </div>
                                </td>
                                <td>
                                    <img src="/img/education/u22327.png" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ backgroundColor: "#fff", padding: "20px 10px" }}>
                        <hr className="hr-hmgbm" />

                    </div>
                </div>
            </div>
            {/* {show && <HotlineModal open={show} setShow={setShow} />} */}
            <div className="main-disclaimer-overlays" id="disclaimers" style={{ display: policyShow ? 'block' : 'none' }}>
                <div className="disclaimer-overlay-inners">
                    <p style={{ textAlign: 'right', margin: '10px 8px 0px 0px', cursor: 'pointer', fontSize: '21px', fontWeight: 'bold' }} onClick={() => setPolicyShow(false)}  >X</p>
                    <div style={{ padding: '15px' }}>
                        <div className='popupbox-contents'>
                            <p>A market maker provides liquidity in warrants by providing continuous buy and sell quotes for investors to enter and exit their positions. When trading with warrants, liquidity corresponds more on the bid and offer volumes, as well as the price spreads provided by the market maker. This means that even a warrant with zero traded volume can be liquid if the market maker is providing high liquidity.</p>
                            <p>As investors may buy or sell warrants in larger quantities, high liquidity is optimal for you to enter and exit your positions with ease. If a warrant has low liquidity, you may not be able to transact at your desired price and time. You may have to wait for other investors’ orders to be executed if you are not the first in queue. The warrant’s price may move in the interim, causing you to incur a loss or lower profits.</p>
                            <p>Further, it is important to choose a warrant on tight spreads, which for warrants priced below RM1.00 would mean a RM0.005 difference between the bid and offer price. For example, a warrant priced at a bid/offer price of RM0.070/RM0.075 in on tight spreads, whereas a warrant priced at a bid/offer price of RM0.070/RM0.085 is on wide spreads. You would incur a higher cost when you buy a warrant on wide spreads as you would need the warrant’s bid price to move by a larger percentage before it reaches your initial purchase price. </p>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
}
export default WhyMacquaries;