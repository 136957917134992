import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./successSpeaks.css";
import SuccessSpeaksModal from "../../../../src/components/common/SuccessSpeaksModal"
import SpeakModal from './SpeakModal';
import API from '../../../utils/apiCalling';
import { config, Base_Url } from '../../../utils/apiUrl';
import validator from 'validator'

const SuccessSpeaks = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const api = new API()
    const [openMsg, setOpenMsg] = useState(false)
    const [open, setOpen] = useState(false)
    const [url, setUrl] = useState("");
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [nameMsg, setNameMsg] = useState(false)
    const [emailMsg, setEmailMsg] = useState(false)

    const onClickPopHandle = (url, value) => {
        setOpen(value)
        setUrl(url)
    }

    const validateEmail = (e) => {
        var email = e.target.value
        if (validator.isEmail(email)) {
            setEmail(email)
        } else {
            setEmailMsg(true)
            setEmail()
        }
    }

    function valueSubmit(e) {

        if (!name && !email) {
            setNameMsg(true)
            setEmailMsg(true)
        }
        else if (!email) {
            setEmailMsg(true)
        }
        else {
            submit(e)
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        let data = {
            email: email,
            name: name
        }
        let res = await api.post(`${config.subscription}${"?p=register"}`, data)
            .then((res) => {
                setOpenMsg(true)
                setTimeout(() => setOpenMsg(false), [2500])
            })
            .catch((error) => console.log(error))
    }
    return (
        <>
            <div id="campaign" className="pageobj">
                <div className="top-background">
                    <div className="top-txtarea container-fluid">
                        <h1>Success Speaks</h1>
                        <h3>What does it take to be a warrant trader?</h3>
                    </div>
                </div>
                <div className="videobox container-fluid">
                    <div className="row is-table-row">
                        <div className="col-sm-4">
                            <div className="videobox-item">
                                <div className="videobox-item-imgarea">
                                    <img

                                        onClick={() => onClickPopHandle("https://www.youtube.com/embed/Umkmj5NGOBo", true)}
                                        className="videolink"
                                        src="/img/education/campaign_screen1.png"
                                    />
                                </div>
                                <div className="videobox-item-txtarea">
                                    <h3 className="videobox-h3-clickable">Joey Ng</h3>
                                    <hr />
                                    <p >
                                        Joey has been a dealer for six years. During the day, she monitors
                                        her clients' investment portfolio and provides financial advice on
                                        the potential risk and return of their investments. At night, she
                                        helps her husband manage a cafe
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="videobox-item">
                                <div className="videobox-item-imgarea">
                                    <img

                                        onClick={() => onClickPopHandle("https://www.youtube.com/embed/1sI5l5-CsVs", true)}
                                        className="videolink"
                                        src="/img/education/jasonwong.jpg?v=1"
                                    />

                                </div>
                                <div className="videobox-item-txtarea">
                                    <h3 className="videobox-h3-clickable">Jason Wong</h3>
                                    <hr />
                                    <p>
                                        Jason is a professional trader that focuses on technical analysis
                                        on stocks and commodities. He has approximately 20,000 followers
                                        on his blog, website and telegram channel. He started including
                                        structured warrants in his daily trading and his favourite tool is
                                        the Live Matrix.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="videobox-item">
                                <div className="videobox-item-imgarea">
                                    <img
                                    
                                        onClick={() => onClickPopHandle("https://www.youtube.com/embed/Jnjt8d7XTKo", true)}
                                        className="videolink"
                                        src="/img/education/TanTimKiat.jpg?v=1"
                                    />

                                </div>
                                <div className="videobox-item-txtarea">
                                    <h3 className="videobox-h3-clickable">Tan Tim Kiat</h3>
                                    <hr />
                                    <p>
                                        Tim Kiat has been very successful in the warrants market, taking
                                        home the Warrant Hero 2015 grand prize in 2016. He continues to
                                        trade warrants actively, focusing on index warrants during
                                        uncertain market conditions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hrbar">
                    <div className="container-fluid">
                        Subscribe to our newsletter and like us on Facebook for updates
                    </div>
                </div>
                <div className="bttm">
                    <div className="container-fluid">
                        <div className="bttm-row row">
                            <div className="col-sm-6">
                                <div className="bttm-item-left">
                                    <p>
                                        Hear what these investors have to say about their warrant trading
                                        experience. Introducing Joey, Jason and TK, who have generously
                                        agreed to be interviewed and to share their secrets with you! At
                                        Macquarie Warrants, we believe that you too can be a warrant
                                        trader if you are willing to put in some effort to improve your
                                        skills. We hope that these stories will inspire you to start
                                        learning today.
                                    </p>
                                    <Link
                                        to="/edu"
                                        target="_blank"
                                        className="btn btn-primary btn-lm"
                                    >
                                        Learn more
                                    </Link>

                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="bttm-item-right">
                                    <div className="notiform">
                                        <p>
                                            Receive notifications on new videos and daily market highlights
                                        </p>

                                        <input
                                            className={nameMsg ? "input-warning" : ""}
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Name"
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value)
                                                setNameMsg(false)
                                                setEmailMsg(false)
                                            }} />

                                        {nameMsg && <div className="warning">* Fill in name</div>}
                                        <input
                                            className={emailMsg ? "input-warning" : ""}
                                            type="text"
                                            id="email"
                                            name="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => {
                                                validateEmail(e)
                                                setNameMsg(false)
                                                setEmailMsg(false)
                                            }} />

                                        {emailMsg && <div className="warning">* Fill in valid email</div>}


                                        <button
                                            className="btn btn-primary"
                                            onClick={(e) => valueSubmit(e)}
                                        >Submit</button>


                                        <div className="fbarea">
                                            <div className="fbarea-left">
                                                <a
                                                    target="_blank"
                                                    href="https://www.facebook.com/MalaysiaWarrants/"
                                                >
                                                    <img src="/img/bullet/FBlogo.png" />
                                                </a>
                                            </div>
                                            <div className="fbarea-right">
                                                <table className="table-fb">
                                                    <tbody>
                                                        <tr>
                                                            <td className="td-fb-like">
                                                                <div
                                                                    className="fb-like fb_iframe_widget"
                                                                    data-href="https://www.facebook.com/MalaysiaWarrants/"
                                                                    data-layout="button"
                                                                    data-action="like"
                                                                    data-size="small"
                                                                    data-show-faces="false"
                                                                    data-share="true"
                                                                    fb-xfbml-state="rendered"
                                                                    fb-iframe-plugin-query="action=like&app_id=1598647610447450&container_width=0&href=https%3A%2F%2Fwww.facebook.com%2FMalaysiaWarrants%2F&layout=button&locale=en_US&sdk=joey&share=true&show_faces=false&size=small"
                                                                >
                                                                    <span
                                                                        style={{
                                                                            verticalAlign: "bottom",
                                                                            width: "120px",
                                                                            height: "28px",
                                                                        }}
                                                                    >
                                                                        <iframe
                                                                            name="f2d7896fd7f7364"
                                                                            width="1000px"
                                                                            height="1000px"
                                                                            data-testid="fb:like Facebook Social Plugin"
                                                                            title="fb:like Facebook Social Plugin"
                                                                            frameBorder={0}
                                                                            // allowTransparency="true"
                                                                            allowFullScreen={true}
                                                                            scrolling="no"
                                                                            allow="encrypted-media"
                                                                            src="https://www.facebook.com/v2.10/plugins/like.php?action=like&app_id=1598647610447450&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df385ffccb6562dc%26domain%3Dwww.malaysiawarrants.com.my%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.malaysiawarrants.com.my%252Ff33019b6a0c8e84%26relation%3Dparent.parent&container_width=0&href=https%3A%2F%2Fwww.facebook.com%2FMalaysiaWarrants%2F&layout=button&locale=en_US&sdk=joey&share=true&show_faces=false&size=small"
                                                                            style={{
                                                                                border: "none",
                                                                                visibility: "visible",
                                                                                width: "120px",
                                                                                height: "28px",
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="td-fb-share">
                                                                <div
                                                                    className="fb-like fb_iframe_widget"
                                                                    data-href={`${Base_Url}/SuccessSpeaks`}
                                                                    data-layout="button"
                                                                    data-action="like"
                                                                    data-size="small"
                                                                    data-show-faces="false"
                                                                    data-share="true"
                                                                    fb-xfbml-state="rendered"
                                                                    fb-iframe-plugin-query="action=like&app_id=1598647610447450&container_width=54&href=https%3A%2F%2Fwww.malaysiawarrants.com.my%2FSuccessSpeaks&layout=button&locale=en_US&sdk=joey&share=true&show_faces=false&size=small"
                                                                >
                                                                    <span
                                                                        style={{
                                                                            verticalAlign: "bottom",
                                                                            width: "120px",
                                                                            height: "28px",
                                                                        }}
                                                                    >
                                                                        <iframe
                                                                            name="f1b6f0059b97d4c"
                                                                            width="1000px"
                                                                            height="1000px"
                                                                            data-testid="fb:like Facebook Social Plugin"
                                                                            title="fb:like Facebook Social Plugin"
                                                                            frameBorder={0}
                                                                            // allowTransparency="true"
                                                                            allowFullScreen={true}
                                                                            scrolling="no"
                                                                            allow="encrypted-media"
                                                                            src="https://www.facebook.com/v2.10/plugins/like.php?action=like&app_id=1598647610447450&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1acb33dc45fdf4%26domain%3Dt1www5.trkd-hs.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Ff14cef4772deec%26relation%3Dparent.parent&container_width=54&href=https%3A%2F%2Fwww.malaysiawarrants.com.my%2FSuccessSpeaks&layout=button&locale=en_US&sdk=joey&share=true&show_faces=false&size=small"
                                                                            style={{
                                                                                border: "none",
                                                                                visibility: "visible",
                                                                                width: "120px",
                                                                                height: "28px",
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="fb-txt text-bold">Like us on Facebook</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 bttm-disclaimer-holder">
                                <p className="bttm-disclaimer small">
                                    By clicking “Submit”, you confirm that you would like to receive
                                    Macquarie’s daily newsletter and invitations for warrant seminars,
                                    and you acknowledge that Macquarie’s website administrators may have
                                    access to your personal data for the processing of such data on
                                    behalf of Macquarie Capital Securities Malaysia Sdn Bhd and other
                                    companies of Macquarie group (“Macquarie”). You may view Macquarie’s
                                    privacy policy with regard to collection and management of personal
                                    data via the links at the bottom of this page.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SuccessSpeaksModal
                Url={url} Open={open} OpenModal={() => setOpen(!true)} />
            {openMsg && <SpeakModal open={openMsg} />}
        </>
    );
}
export default SuccessSpeaks;
