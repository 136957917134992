import React, { useState, useEffect,Fragment } from 'react';
import InvestorAcademies from './InvestorAcademies';
import "./common.css";
import "./investorAcademy.css" 


const InvestorAcademy = (props) => {
    return ( 
        <Fragment> 
        <InvestorAcademies/>
        </Fragment> 
     );
}


export default InvestorAcademy;