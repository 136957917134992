import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./common.css";
import "./investorAcademy.css";
import seminar_img from "../../../assets/images/education/button-static.png"



const InvestorAcademies = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div id="investor_academy">
            <div className="imgbg" />
            <div
                id="main"
            >
                <div id="home" style={{ width: "100%", height: "807px" }}>
                {/* <div className="ia-seminarbtn">
                    <Link
                        className="seminarbtn"
                        to="/education/seminar"
                    >
                        <img src={seminar_img} alt="seminar" />
                    </Link>
                </div> */}
                <table
                    id="hometable"
                    style={{ width: "100%", height: "100%", tableLayout: "fixed" }}
                >
                    <tbody>
                    <tr>
                        <td style={{ textAlign: "center" }}>
                        <div id="nine">
                            <div id="n1" className="ninep">
                                <div><Link to="/edu/why-trade-warrant"></Link> </div>
                            </div>
                            <div id="n2" className="ninep">
                                <div><Link to="/edu/what-are-warrant"></Link> </div>
                            </div>
                            <div id="n3" className="ninep">
                                <div><Link to="/edu/how-are-warrant-priced"></Link> </div>
                            </div>
                            <div id="n4" className="ninep">
                                <div><Link to="/edu/understanding-warrant-structure"></Link> </div>
                            </div>
                            <div id="n5" className="ninep">
                                <div><Link to="/edu/monitoring-price-changes"></Link> </div>
                            </div>
                            <div id="n6" className="ninep">
                                <div><Link to="/edu/useful-warrant-terms"></Link> </div>
                            </div>
                            <div id="n7" className="ninep">
                                <div><Link to="/edu/select-warrant"></Link> </div>
                            </div>
                            <div id="n8" className="ninep">
                                <div><Link to="/edu/single-share-calls"></Link> </div>
                            </div>
                            <div id="n9" className="ninep">
                                <div><Link to="/edu/index-calls"></Link> </div>
                            </div>
                            <div id="n10" className="ninep">
                                <div><Link to="/edu/risks"></Link> </div>
                            </div>
                            <div id="n11" className="ninep">
                                <div><Link to="/edu/market-marker"></Link> </div>
                            </div>
                        </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
      );
}
export default InvestorAcademies;