import React, { useState, useEffect } from "react";
import "./highlight.css";
import { getHighlight } from "../../../utils/Highlight.api";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios"
import { Base_Url } from "../../../utils/apiUrl";
import {Buffer} from 'buffer';

export default function Highlights() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const cat = queryParams.get("cat");
  const [Id, setId] = useState(id);
  const [Cat, setCat] = useState(cat);
  const [result, setResult] = useState();
  const [nav, setNav] = useState();
  const [ric, setRic] = useState("");
  const [chartInfo, setChartInfo] = useState([]);
  const [contentTitle,setContentTitle] = useState('')
  const navPrev = nav != undefined ? nav.prev : "";
  const navNext = nav != undefined ? nav.next : "";

  const facebookLogin = () => {
    var url_page = window.location.href
    var title = contentTitle
    var url_share = 'http://www.facebook.com/share.php?u=' + escape(url_page) + '&p[summary]=' + title;
    window.open(url_share, 'share', 'width=560,height=650');
  }

  useEffect(() => getHighlightData(), [Id, Cat]);
  function getHighlightData() {
    getHighlight(Cat, Id).then(async(res) => {
      const cinfo = res?.data?.newsData[0]?.chartinfolist
      if(cinfo){
      for(var i = 0;i<cinfo.length;i++){
      var j = i
      const url = `${Base_Url}/apimqmy/ChartImageServlet?chartid=${cinfo[j].id}`
      const response = await axios
      .get(url, {
        responseType: 'arraybuffer'
      })
      var imgres =  Buffer.from(response.data, 'binary').toString('base64')
        cinfo[j].base64img = atob(imgres)
      }
    }
      setResult(res.data.newsData[0]);
      setContentTitle(res.data.newsData[0].en_title)
      setNav(res.data.nav);
      setRic(res?.data?.newsData[0]?.chartinfolist?.ric);
      setChartInfo(cinfo);
    });
  }

  function renderText(){
    if(cat == 'dailycommentary'){
       return <Link id="pachor" to="/marketnews/eodinsights">End of day insights </Link>;
    }else if(cat == 'overnightmarkets'){
      return <Link id="pachor" to="/marketnews/overnightmarkets">Overnight market wrap </Link>;
    }
    return <Link id="pachor" to="/marketnews/dailyhighlights">Daily highlights </Link>;
 }


  const chartInfoData = chartInfo;
  return (
    <div id="highlight" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?news">Market news</Link>
            </li>
            <li>
            {renderText()}
            </li>
            <li className="active" id="breadcrumb-label" dangerouslySetInnerHTML={{
            __html: result != undefined ? result.en_title : "",
            }}>
              
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <p id="highlight-date">
            {result != undefined ? moment(result.publish_date).format("D MMMM YYYY") : ""}
          </p>
          <div className="page-header">
            <h1 id="page-title" dangerouslySetInnerHTML={{
            __html: result != undefined ? result.en_title : "",
        }}>
               
            </h1>
           <div className="facebook_btn_sec">
           <div className="btn-area">
              <button onClick={facebookLogin}>
                <div className="fbSharebtn">
                  <i className="fa fa-facebook" aria-hidden="true" />
                  Share on Facebook
                </div>
              </button>
            </div>
            </div>
          </div>
          <div id="highlight-chart">
          { chartInfo && chartInfo.map((chartInfoData,i) => {
            return(
              <Link
              key={chartInfoData.id}
                to={`/tools/recognia?sym=${chartInfoData.symbol}&exch=${chartInfoData.reco_exchange}&ticker=${chartInfoData.ticker}&ric=${chartInfoData.ric}`}
              >
              <img src={chartInfoData.base64img} />
              </Link>
            )})
          }
              
          </div>
          <div id="highlight-content">
            <p
              dangerouslySetInnerHTML={{
                __html: result != undefined ? result.engLong : "",
              }}
            />
          </div>
          <div className="pnbar">
            <div className="pnbar-prev">
              
              <Link
                id="prev-achor"
                to={"/marketnews/highlight?cat=" + Cat + "&id=" + navPrev}
                className={nav?.prev === -1 ? "disabled" : ""}
              >
                &lt; Previous day
              </Link>
            </div>
            <div className="pnbar-vl"></div>
            <div className="pnbar-next">
              
              <Link
                id="next-achor"
                to={"/marketnews/highlight?cat=" + Cat + "&id=" + navNext}
                className={nav?.next === -1 ? "disabled" : ""}
              >
                Next day &gt;
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

