import React, { useState, useEffect } from 'react';
import "./wingrabVoucher.css";



const WingrabVouche = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    

    return (
        <div id="wingrab" className="pageobj">
            <div className="stripe-bg" />
            <div className="container-fluid" style={{backgroundColor: '#000', padding: 0}}>
            <img style={{width: '100%', height: '500px'}} src="/img/education/USIW_image.jpg" />
            <div style={{padding: '15px 15px'}}>
                <h3 style={{color: 'white', marginTop: 0}}><b> U.S. index warrants: </b>&nbsp; Follow,answer and win!
                <br />Questions for the week of 11 July 2022
                </h3>
                <div style={{color: 'white'}}>
                First <b>100</b> to correctly answer these questions and subscribe to our newsletter via the “Subscribe &amp; submit” button below will each
                win a Grab voucher worth <b>RM50</b>.
                </div>
                <div className="row">
                <div className="col-md-6" style={{color: 'white', marginTop: '10px'}}>
                    <p className="question-header" style={{}}>Question 1:</p>
                    <h5 className="question-title">
                    What are the new warrants over foreign indices that Macquarie indices that Macquarie Warrants Malaysua has issued from December 2021?
                    </h5>
                    <ul style={{listStyle: 'none', padding: '0px'}} id="question1">
                    <li id="answer_1_A" className="answer answer1">
                        <span className="answer-list">A</span>
                        <span className="answer-area">Straits Times Index and Nikkei 225 Index</span>
                    </li>
                    <li id="answer_1_B" className="answer answer1">
                        <span className="answer-list">B</span>
                        <span className="answer-area">Hang Seng China Enterprises Index and FBM KLCI</span>
                    </li>
                    <li id="answer_1_C" className="answer answer1">
                        <span className="answer-list">C</span>
                        <span className="answer-area">Dow Jones Industrial Average® Index and NASDAQ-100® Index</span>
                    </li>
                    <li id="answer_1_D" className="answer answer1">
                        <span className="answer-list">D</span>
                        <span className="answer-area">Hang Seng Index and Hang Seng TECH Index</span>
                    </li>
                    </ul>
                    <p className="question-header">Question 2:</p>
                    <h5 className="question-title">
                    Select the correct market making hours for Macquarie's warrants over US indicesexes listed on Bursa:
                    </h5>
                    <ul style={{listStyle: 'none', padding: '0px'}} id="question2">
                    <li id="answer_2_A" className="answer answer2">
                        <span className="answer-list">A</span>
                        <span className="answer-area">9:15am to 11:58am,then 2:30pm to 4:28pm</span>
                    </li>
                    <li id="answer_2_B" className="answer answer2">
                        <span className="answer-list">B</span>
                        <span className="answer-area">9:30am to 11:58am,then 2:30am to 3:58pm</span>
                    </li>
                    <li id="answer_2_C" className="answer answer2">
                        <span className="answer-list">C</span>
                        <span className="answer-area">9:00am to 12:29pm,then 2:30pm to 4:43pm</span>
                    </li>
                    <li id="answer_2_D" className="answer answer2">
                        <span className="answer-list">D</span>
                        <span className="answer-area">9:00am to 12:29pm,then 2:30pm to 4:43pm</span>
                    </li>
                    </ul>
                    {/*<div id="subscribeBtn" >Subscribe & submit*</div>*/}
                </div>
                <div className="col-md-6 email_input">
                    <p style={{color: '#FFFFFF', margin: '0px 0px 10px 0px', fontSize: '21px'}}>Full name</p>
                    <input type="text" className="inputWidth" placeholder="First Name,Last Name" style={{color: '#FFFFFF', paddingLeft: '5px', background: '#000', border: '1px solid #FFFFFF', width: '100%', height: '30px'}} />
                    <p style={{color: '#FFF', margin: '10px 0px 10px 0px', fontSize: '21px'}}>Email address</p>
                    <input type="email" className="inputWidth" placeholder="qwerty@domain.com" style={{color: '#FFFFFF', paddingLeft: '5px', background: '#000', border: '1px solid #FFFFFF', width: '100%', height: '30px'}} />
                    <p style={{color: '#ABADB0', marginTop: '80px', fontStyle: 'italic'}}>
                    * Email address provided must be valid for receipt of prize. By participating in this contest, you confirm that you agree to the terms of conditions of this contest. View full terms and conditions of the contest <b style={{cursor: 'pointer', color: '#00b7fc', textDecoration: 'underline'}}>here</b>.
                    </p>
                </div>
                </div>
                <div className="row">
                <div className="col-md-6 text-btn-center">
                    <div id="subscribeBtn">Subscribe &amp; submit*</div>
                </div>
                <div className="col-md-6 learn_more text-btn-center">
                    <div id="learnMore" onClick={() => openInNewTab('/my/education/wingrabvouchers')}>Learn more</div>
                </div>
                </div>
            </div>
            </div>
            <div id="hshSubscribe_area" className="hsh-overlay" style={{display: 'none'}}>
            <div className="hsh-overlay-inner">
                <div style={{position: 'relative'}}>
                <div className="popupbox-content">
                    <img className="btn-close" style={{top: '40px', right: '40px'}} src="/img/bullet/icon-close-white.png" />
                    <p className="small">
                    Thank you for your participation.We will contact you within 5 business days after end of this week if you are one of the 100 winners
                    </p>
                    <input type="button" id="submitBtn" defaultValue="Return to contest page" />  
                    <span>
                    <a target="_black" className="sharebtn-fb btn" type="button" href="https://www.facebook.com/MalaysiaWarrants/" style={{height: '30px', width: '30px'}} />
                    <a target="_black" className="sharebtn-tl btn" type="button" href="https://web.telegram.org/#/im?p=@Malaysiawarrants" style={{height: '30px', width: '30px', marginLeft: '10px'}} />
                    </span>
                </div>
                </div>
            </div>
            <div id="text-content" style={{display: 'none'}}>
                <div className="text_subscribe_registration_success">You have successfully registered for our daily newsletter!</div>
            </div>
            </div>
        </div>
      );
}
export default WingrabVouche;