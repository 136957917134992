import React, { useState, useEffect,Fragment } from 'react';
import HowUseLiveMatrixs from './HowUseLiveMatrixs';
import "./howUseLiveMatrix.css" 


const HowUseLiveMatrix = (props) => {
    return ( 
        <Fragment> 
        <HowUseLiveMatrixs/>
        </Fragment> 
     );
}


export default HowUseLiveMatrix;