import React, { useState, useEffect,Fragment } from 'react';
import PutExpiryFormulas from './PutExpiryFormulas';
import "../../common.css";
import "../../investorAcademy.css";


const PutExpiryFormula = (props) => {
    return ( 
        <Fragment> 
            <PutExpiryFormulas/>
        </Fragment> 
     );
}


export default PutExpiryFormula;