import React from "react"
import Chart from 'react-apexcharts'

export default function PieChart({ result,date }) {
    const sectorName = result.map((d) => d.underlying_ticker)
    const sectorCall = result.map((d) => parseFloat(d[date].replace(/,/g, '')))
    
    const series = sectorCall
    const options = {
        series: sectorCall,
        labels: sectorName,
        colors: ["#0675C2", "#00A1E0", "#6CC263", "#F7941E", "#FECC0A", "#C61C2D"],
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            }
        },
        chart: {
            type: 'pie',
        },
        stroke: {
            show: false,
            width:0
        },
    }

    return (
        <>
            <Chart
                options={options}
                series={series}
                type="pie"
                width="100%"
            />
        </>
    )
}
