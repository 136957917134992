export const Base_Url = "";
export const Api_MqMy = "/apimqmy/";

export const urlPath = "/";

export const config = {
  // HOME  photo //
  homePhoto: `${Base_Url}${Api_MqMy}photo`,
  // HOME  Apis //
  homePopup: `${Base_Url}${Api_MqMy}HomePopup`,
  advImgPopup: `${Base_Url}${Api_MqMy}homepageImageSlider`,
  advImgData: `${Base_Url}${Api_MqMy}photo`,
  homeQuickHeadlines: `${Base_Url}${Api_MqMy}HomeJSON`,
  homeTopTradedStock: `${Base_Url}${Api_MqMy}MarketDataJSON`,
  homeHotlist: `${Base_Url}${Api_MqMy}MarketDataJSON`,
  homeWarrantSearch: `${Base_Url}${Api_MqMy}ScreenerJSONServlet`,
  homeEducationSeminar: `${Base_Url}${Api_MqMy}HomeJSON`,
  homeLiveIndexFutures: `${Base_Url}${Api_MqMy}LiveIndexJSON`,
  homeLiveIndexFuturesSymbol: `${Base_Url}${Api_MqMy}SymbolsJSON`,
  homeLiveMatrix: `${Base_Url}${Api_MqMy}LiveMatrixJSON`,
  getUnderlying: `${Base_Url}${Api_MqMy}GetUnderlyingData`,
  getHighlight: `${Base_Url}${Api_MqMy}NewsDataJSON`,
  getRegistration: `${Base_Url}${Api_MqMy}registration`,
  seminarReg: `${Base_Url}${Api_MqMy}seminarreg`,
  getPhotoUrl: `${Base_Url}${Api_MqMy}PhotoGalleryJSON`,
  // Warrant Tools //
  warrantSearch: `${Base_Url}${Api_MqMy}SymbolsJSON`,
  warrantIssue: `${Base_Url}/apimqmy`,

  // Warrant Tools ==> Live Matrix //
  warrantComparison: `${Base_Url}${Api_MqMy}ComparisonDataJSON`,
  warrantUnderlying: `${Base_Url}${Api_MqMy}UnderlyingRankingJSON`,
  warrantDocuments: `${Base_Url}${Api_MqMy}DocumentJSON`,
  warrantTermsAlert: `${Base_Url}${Api_MqMy}alert`,
  warrantPowerSearch: `${Base_Url}${Api_MqMy}PowerSearchJSON`,

  // Warrant Statstics Apis//
  turnOverDistribution: `${Base_Url}${Api_MqMy}MarketDataJSON`,
  marketTurnOver: `${Base_Url}${Api_MqMy}MarketDataJSON`,
  marketTurnOverList: `${Base_Url}${Api_MqMy}MarketDataJSON`,
  moneyFlow: `${Base_Url}${Api_MqMy}MoneyFlowJSON`,

  // Warrant Education  //
  gainExposure: `${Base_Url}${Api_MqMy}HKUnderlyingWarrantsJSON`,
  warrentEducationsSeminar: `${Base_Url}${Api_MqMy}SeminarJSON`,

  // Market News Apis//
  photoGallery: `${Base_Url}${Api_MqMy}PhotoGalleryJSON`,
  marketDailyHighLight: `${Base_Url}${Api_MqMy}NewsTableServlet`,

  //pageSearch
  pageSearch: `${Base_Url}${Api_MqMy}PageSearchJSON`,

  //Warrant Announcements
  warrantAnnouncement: `${Base_Url}${Api_MqMy}StaticData`,

  // Common Apis //
  subscription: `${Base_Url}${Api_MqMy}registration`,
  regLocation: `${Base_Url}${Api_MqMy}RegLocation`,

  //Recognia API //
  recognia: `${Base_Url}${Api_MqMy}RecogniaJSON`,

  noRicApi: `${Base_Url}${Api_MqMy}alert/getTicker`,
};
