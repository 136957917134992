import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./warrantWarrior.css"



const WarrantWarrior = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return (
      <div id="warrior2017" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li><Link to="/home?news">Market news</Link></li>
            <li><Link to="/marketnews/activities">Activities</Link></li>
            <li className="active">Warrant Warrior 2017</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="section">
              <div className="page-header">
                <h1>Warrant Warrior 2017</h1>
                
              </div>
              <img className="photo-main" src="/img/activities/warrant_warrior_2017.jpg" alt="warrant warrior 2017" />
              <p className="section-p">
                The Warrant Warrior 2017 challenge ran for 8 weeks from 9 Jan 2017 to 5 March 2017, during which participants answered up to 32 questions related to warrants, the equity market and others. Our top Warrant Warriors collectively took home RM100,000 worth of prizes!
              </p>
              <div className="prize-row row">
                <div className="prize-col col-xs-12 col-sm-6">
                  <h3 className="prize-ttl">Grand</h3>
                  <div className="prize-cell first">
                    <img src="/img/activities/prize_1st.png" />
                    <div className="prize-cell-ttl">Winner</div>
                    <div className="prize-cell-val">RM15,000</div>
                  </div>
                  <div className="prize-cell">
                    <img src="/img/activities/prize_2nd.png" />
                    <div className="prize-cell-ttl">1st Runner Up</div>
                    <div className="prize-cell-val">RM10,000</div>
                  </div>
                  <div className="prize-cell last">
                    <img src="/img/activities/prize_3rd.png" />
                    <div className="prize-cell-ttl">2nd Runner Up</div>
                    <div className="prize-cell-val">RM7,000</div>
                  </div>
                </div>
                <div className="prize-col col-xs-12 col-sm-6">
                  <h3 className="prize-ttl">Weekly Prize x 8</h3>
                  <div className="prize-cell first">
                    <img src="/img/activities/prize_1st.png" />
                    <div className="prize-cell-ttl">Winner</div>
                    <div className="prize-cell-val">iPhone 7</div>
                  </div>
                  <div className="prize-cell">
                    <img src="/img/activities/prize_2nd.png" />
                    <div className="prize-cell-ttl">1st Runner Up</div>
                    <div className="prize-cell-val">RM2,888</div>
                  </div>
                  <div className="prize-cell last">
                    <img src="/img/activities/prize_3rd.png" />
                    <div className="prize-cell-ttl">2nd Runner Up</div>
                    <div className="prize-cell-val">RM1,888</div>
                  </div>
                </div>
              </div>
              <div className="gallery">
                <img className="gallery-img" src="/img/activities/photo_1st.jpg" />
                <img className="gallery-img" src="/img/activities/photo_2nd.jpg" />
                <img className="gallery-img last" src="/img/activities/photo_3rd.jpg" />
              </div>
              <img className="photo-follow" src="/img/activities/followus.jpg" alt="Follow us on malaysiawarrant" />
              <p className="bottom-p">
                A special thank you to all who participated in Warrant Warrior 2017 and made this event a success!
              </p>
              <div className="bottom-row row">
                <div className="bottom-col col-xs-12 col-sm-6 order_bottom_warrant">
                  <div className="right-cell">
                    <div className="bottom-pbox">
                      <p>Want to find out more about warrants and trading? Click to visit our Investor Academy.</p>
                    </div>
                  </div>
                </div>
                <div className="bottom-col col-xs-12 col-sm-6">
                  <Link to="/edu" target="_blank"><img src="/img/activities/investoracademy.png" alt="Investor Academy" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      );
}
export default WarrantWarrior;