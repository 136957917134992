import React, { useState, useEffect } from 'react';
import HkUnderlying from './HkUnderlying';
import './hkUnderlying.css';


const HkUnderlyings = (props) => {
    return ( 
        <HkUnderlying/>
     );
}
 
export default HkUnderlyings;