import React, { useState, useEffect,Fragment } from 'react';
import TimeFrames from './TimeFrames';
import "../../../common.css";
import "../../../investorAcademy.css";


const TimeFrame = (props) => {
    return ( 
        <Fragment> 
            <TimeFrames/>
        </Fragment> 
     );
}


export default TimeFrame;