import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import "../../../src/pages/WarrantEducation/HowToStart/howToStarted.css"
import validator from 'validator'
import SubmitModal from '../../pages/WarrantEducation/Seminar/SubmitModal';
import { config } from '../../utils/apiUrl'
import API from '../../utils/apiCalling';

const SeminarLastPopUp = (props) => {
  const {
    OpenModal, Url,
    Open,
    id,
  } = props;

  const api = new API()
  const [emailError, setEmailError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [email, setEmail] = useState()
  const [name, setName] = useState()
  const [submitModal, setSubmitModal] = useState(false)
  const [text, setText] = useState('')

  const validateEmail = (e) => {
    var email = e.target.value
    if (validator.isEmail(email)) {
      setEmail(email)
    } else {
      setEmailError(true)
      setEmail()
    }
  }

  function valueSubmit(e) {
    if (!name && !email) {
      setNameError(true)
      setEmailError(true)
    }
    else if (!name) {
      setNameError(true)
    }
    else if (!email) {
      setEmailError(true)
    }
    else {
      onAgreeHandle(e)
    }
  }

  const regStatus = async () => {
    let seminarData = {
      firstName: name,
      email,
      seminar: id,
      lang: "en"
    }
    let res = await api.post(`${config.seminarReg}`, seminarData)
      .then((res) => {
        OpenModal()
        setSubmitModal(true)
        setText(res)
        setTimeout(() => setSubmitModal(false), [2500])
      })
      .catch((error) => console.log(error))
  }
  const onAgreeHandle = async (e) => {
    e.preventDefault();
    let data = {
      email
    }
    let res = await api.post(`${config.getRegistration}${"?p=register"}`, data)
      .then((res) => {
        regStatus()
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <div id="ppboxHTML" className="overlay" style={{ display: "none" }}>
        <div className="overlay-inner">
          <Modal style={{ position: 'relative' }} centered isOpen={Open} toggle={OpenModal} className="modal-lg mt-5 subSemiModal">
            <ModalBody >
              <div style={{ position: 'relative' }}>
                <div className="popupbox-content">
                  <div className="ppc" key="subscribe-newsletter">
                    <div className="signup-layer">
                      <div className="page-header">
                        <h3>Register now</h3>
                          <img onClick={OpenModal} className="close" src="/img/bullet/icon-close-white.png" alt="Image" />
                      </div>
                      <input type="text" placeholder='Name' value={name} onChange={(e) => {
                        setName(e.target.value)
                        setNameError(false)
                      }} /> 
                      <div className="warning">
                        {nameError && <span>Name is required. Please fill in.</span>}
                      </div>
                      <input type="text" placeholder='Email' value={email} onChange={(e) => {
                        validateEmail(e)
                        setEmailError(false)
                      }} />
                      <div className="warning">
                        {emailError && <span>Email is required. Please fill in.</span>}
                      </div>
                      <p className='small'>By clicking ”Submit”, you confirm that you would like to subscribe to Macquarie’s daily warrant newsletter. By subscribing, you will also 
                      automatically receive invitations or marketing material from Macquarie for warrant seminars and other warrant related initiatives. Macquarie website 
                      administrators may have access to your personal data for the processing of such data on behalf of Macquarie Capital Securities (Malaysia) Sdn. Bhd. and other 
                      companies of Macquarie Group (“Macquarie”). <span className='webinar'>Please note that your username, email address and/or participation during the Q&A or polling sessions, where 
                      applicable, may be recorded and/or published.</span> <span className="recorded">This webinar will be recorded. By attending the webinar, you are 
                      consenting to be recorded and that your username and participation during the Q&A or polling sessions may be published. The recording will be for record-keeping, 
                      assurance, training and educational purposes.</span> You may view Macquarie’s privacy notice with regard to collection and management of personal data via the links at the 
                      bottom of this page.</p>
                      <div style={{ textAlign: 'center' }}>
                        <a href={Url} target="_blank">
                          <input style={{ marginRight: '30px' }} onClick={(e) => { valueSubmit(e) }} className="btn btn-primary continue" defaultValue="Agree & Submit" type="button" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
      {submitModal && <SubmitModal open={submitModal} text={text}/>}
    </>
  );
}
export default SeminarLastPopUp;
