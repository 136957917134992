import React, { useState, useEffect,Fragment } from 'react';
import IndexCallsPuts from './IndexCallsPuts';
import "../common.css";
import "../investorAcademy.css" 


const IndexCallsPut = (props) => {
    return ( 
        <Fragment> 
        <IndexCallsPuts/>
        </Fragment> 
     );
}


export default IndexCallsPut;