import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import WarrantTermsModal from '../../../components/common/warrantTermsModal';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import GaEventsTracker from "../../../components/common/GaEventsTracker";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HowToStarted = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  const useGaEventsTracker = GaEventsTracker("External Links")

  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState("");
  const api = new API();
  const [advImgVal, setAdvImgVal] = useState([])

  const onClickPopHandle = (url, value) => {
    setOpen(value)
    setUrl(url)
  }

  useEffect(() => {
    getHomePopup()
  }, [])
  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };


  function AffinHwang() {
    useGaEventsTracker('event', 'Click', {'event_category': 'AHIB'})
    onClickPopHandle("https://einvest.affinhwang.com/ahib-theme-1.0.4/index.html",true) 
  }

  function HongLeong() {
    useGaEventsTracker('event', 'Click', {'event_category': 'HLIB'})
    onClickPopHandle("https://www.hlebroking.com/v3/",true) 
  }

  function MalaccaSecurities() {
    useGaEventsTracker('event', 'Click', {'event_category': 'MalaccaSec'})
    onClickPopHandle("https://www.mplusonline.com.my/macsecos/",true) 
  }

  function PublicBank() {
    useGaEventsTracker('event', 'Click', {'event_category': 'PublicBank'})
    onClickPopHandle("https://www.pbebank.com/Personal-Banking/Invest/Share-Trading-Financing/Application-for-PB-Sharelink.aspx",true) 
  }

  function UobKayHian() {
    useGaEventsTracker('event', 'Click', {'event_category': 'UOB'})
    onClickPopHandle("https://www.utrade.com.my/",true) 
  }


  return (
    <>
      <div id="getstarted" className="pageobj" style={{}}>
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home?edu">Warrant education</Link>
              </li>
              <li className="active">How to get started</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <div className="page-header">
                  <h1>How to get started</h1>
                </div>
                <p style={{ marginBottom: "9px", fontSize: "15px" }}>
                  Tap on one of the steps below or scroll down for more.
                </p>
                <div className="area_top pagination_area1">
                  <div className="row">
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section1">1</a>
                      </div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section2">2</a>
                      </div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section3">3</a>
                      </div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section4">4</a>
                      </div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section5">5</a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2 top-img">
                      <a href="#section1">
                        <div>
                          <img src="/img/started/1.png" />
                        </div>
                        <div className="top_img_text">Knowledge</div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a href="#section2">
                        <div>
                          <img src="/img/started/2.png" />
                        </div>
                        <div className="top_img_text">CDS account</div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a href="#section3">
                        <div>
                          <img src="/img/started/3.png" />
                        </div>
                        <div className="top_img_text">Selecting a warrant</div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a href="#section4">
                        <div>
                          <img src="/img/started/4.png" />
                        </div>
                        <div className="top_img_text">
                          How to buy or sell warrants
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a href="#section5">
                        <div>
                          <img src="/img/started/5.png" />
                        </div>
                        <div className="top_img_text">Constant support</div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="area_top pagination_area2">
                  <div className="row">
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section1">1</a>
                      </div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section2">2</a>
                      </div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section3">3</a>
                      </div>
                      <div className="top_line" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2 top-img">
                      <a href="#section1">
                        <div>
                          <img src="/img/started/1.png" />
                        </div>
                        <div className="top_img_text">Knowledge</div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a href="#section2">
                        <div>
                          <img src="/img/started/2.png" />
                        </div>
                        <div className="top_img_text">CDS account</div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a href="#section3">
                        <div>
                          <img src="/img/started/3.png" />
                        </div>
                        <div className="top_img_text">Selecting a warrant</div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="area_top pagination_area2">
                  <div className="row">
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section4">4</a>
                      </div>
                      <div className="top_line" />
                    </div>
                    <div className="col-sm-2 top-pointer">
                      <div>
                        <a href="#section5">5</a>
                      </div>
                      <div className="top_line" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2 top-img">
                      <a href="#section4">
                        <div>
                          <img src="/img/started/4.png" />
                        </div>
                        <div className="top_img_text">
                          How to buy or sell warrants
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-2 top-img">
                      <a href="#section5">
                        <div>
                          <img src="/img/started/5.png" />
                        </div>
                        <div className="top_img_text">Constant support</div>
                      </a>
                    </div>
                  </div>
                </div>

                <div id="section1" className="pointer_tiger" />
                <div className="section1 section_area">
                  <div className="row">
                    <div className="col-sm-2 section_number1">
                      <p style={{ marginLeft: "15px", marginTop: "-25px" }}>
                        1
                      </p>
                    </div>
                    <div className="col-sm-10">
                      <div className="section_title">
                        <p className="section_number2">1</p> Knowledge
                      </div>
                      <p className="section_content">
                        Before you start trading, the first thing you need to do
                        is improve your knowledge in structured warrants! Never
                        buy something you don’t understand. Check out some of
                        our learning resources:
                      </p>
                      <div className="row" style={{ marginRight: "0px" }}>
                        <div className="col-4">
                          <div>
                            <Link to="/edu" target="_blank">
                              <img src="/img/started/Knowledge1.png" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-4">
                          <div>
                            <Link to="/education/seminar" target="_blank">
                              <img src="/img/started/Knowledge2.png" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-4">
                          <div>
                            <Link
                              to="/education/warrantvideosind/1"
                              target="_blank"
                            >
                              <img src="/img/started/Knowledge3.png" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="section2" className="pointer_tiger" />
                <div className="section2 section_area">
                  <div className="row">
                    <div className="col-sm-10 col-xs-12 move-right-sm">
                      <div className="section_title">
                        <p className="section_number2">2</p>CDS account{" "}
                      </div>
                      <p className="section_content">
                        To start trading, you’ll need to have a Central
                        Depository System (CDS) account with a stockbroking
                        company. If you already have a CDS account, proceed to
                        no. 3. Otherwise, here is a list of advisors from
                        various stockbroking companies in Malaysia that you
                        could contact:
                      </p>
                    </div>
                    <div className="col-sm-2 section_number1">
                      <p style={{ marginTop: "-25px", marginLeft: "10px" }}>
                        2
                      </p>
                    </div>
                  </div>
                  <div className="broker-table-holder">
                    <table className="broker-table table table-responsive table-striped">
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Name</th>
                          <th>Contact number</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="broker-td-logo">
                            <div onClick={AffinHwang} className="broker_img">
                              <img src="/img/started/AffinHwang_logo.png" />
                            </div>
                          </td>
                          <td className="broker-td-contact">
                            <span>Angeline Lai</span>
                          </td>
                          <td className="broker-td-contact">
                            <a href="tel:+60321458273">
                              <span className="call_no">+603 2145 8273</span>
                              <span className="call_icon"></span>
                            </a>
                          </td>
                          <td className="broker-td-email">
                            <a href="mailto:premiumbroking@affinhwang.com">
                              <span className="call_no">
                                premiumbroking@affinhwang.com
                              </span>
                              <span className="mail_icon"></span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="broker-td-logo">
                            <div className="broker_img" onClick={HongLeong}>
                              <img src="/img/started/HongLeong_logo.png" />
                            </div>
                          </td>
                          <td className="broker-td-contact">
                            <span>Yee Edd Mund</span>
                          </td>
                          <td className="broker-td-contact">
                            <a href="tel:+60320808609">
                              <span className="call_no">+603 2080 8609</span>
                              <span className="call_icon"></span>
                            </a>
                          </td>
                          <td className="broker-td-email">
                            <a href="mailto:emyee@hlib.hongleong.com.my">
                              <span className="call_no">
                                emyee@hlib.hongleong.com.my
                              </span>
                              <span className="mail_icon"></span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="broker-td-logo">
                            <div
                              className="broker_img"
                              onClick={MalaccaSecurities}
                            >
                              <img src="/img/started/MalaccaSec_logo.png" />
                            </div>
                          </td>
                          <td className="broker-td-contact">
                            <span>Elky Liam</span>
                          </td>
                          <td className="broker-td-contact">
                            <a href="tel:+60322012012">
                              <span className="call_no">+603 2201 2012</span>
                              <span className="call_icon"></span>
                            </a>
                          </td>
                          <td className="broker-td-email">
                            <a href="mailto:elkyliam@malaccasecurities.com.my">
                              <span className="call_no">
                                elkyliam@malaccasecurities.com.my
                              </span>
                              <span className="mail_icon"></span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="broker-td-logo">
                            <div className="broker_img" onClick={PublicBank}>
                              <img src="/img/started/PublicInvest_logo.png" />
                            </div>
                          </td>
                          <td className="broker-td-contact">
                            <span>Boo Wei Chai</span>
                          </td>
                          <td className="broker-td-contact">
                            <a href="tel:+60321766003">
                              <span className="call_no">+603 2176 6003</span>
                              <span className="call_icon"></span>
                            </a>
                          </td>
                          <td className="broker-td-email">
                            <a href="mailto:booweichai@publicbank.com.my">
                              <span className="call_no">
                                booweichai@publicbank.com.my
                              </span>
                              <span className="mail_icon"></span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="broker-td-logo">
                            <div className="broker_img" onClick={UobKayHian}>
                              <img src="/img/started/UOB_logo.png" />
                            </div>
                          </td>
                          <td className="broker-td-contact">
                            <span>Edmund Lew</span>
                          </td>
                          <td className="broker-td-contact">
                            <a href="tel:+60362056127">
                              <span className="call_no">+603 6205 6127</span>
                              <span className="call_icon"></span>
                            </a>
                          </td>
                          <td className="broker-td-email">
                            <a href="mailto:edmundlew@uobkayhian.com.my">
                              <span className="call_no">
                                edmundlew@uobkayhian.com.my
                              </span>
                              <span className="mail_icon"></span>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="section3" className="pointer_tiger" />
                <div className="section3 section_area">
                  <div className="row">
                    <div className="col-sm-2 section_number1">
                      <p style={{ marginLeft: "20px", marginTop: "-25px" }}>
                        3
                      </p>
                    </div>
                    <div className="col-sm-10 col-xs-12">
                      <div className="section_title">
                        <p className="section_number2">3</p>
                        <span>Selecting a warrant</span>
                      </div>
                      <p className="section_content">
                        Now that you’re all set up, how do you select a
                        structured warrant that is right for you? Check out
                        Episode 3 of our animated videos or refer to the warrant
                        search tool to see a list of all available warrants.
                        <br />
                        <br />
                        If you are trading a structured warrant listed by
                        Macquarie, you should always refer to the live matrix to
                        see where Macquarie’s bid and offer prices will be in
                        the warrant for various levels in the underlying.
                      </p>
                      <div className="row">
                        <div className="col-4">
                          <div>
                            <Link
                              to="/education/warrantvideosind/4"
                              target="_blank"
                            >
                              <img src="/img/started/Selecting1.png" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-4">
                          <div>
                            <Link to="/tools/warrantsearch" target="_blank">
                              <img src="/img/started/Selecting2.png" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-4">
                          <div>
                            <Link to="/tools/livematrix/" target="_blank">
                              <img src="/img/started/Selecting3.png" />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <p
                        className="section_content"
                        style={{ paddingTop: "10px" }}
                      >
                        You may also refer to the trending warrants list on our
                        home page for trading ideas.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div id="section4" className="pointer_tiger" />
                <div className="section4 section_area">
                  <div className="row">
                    <div className="col-sm-10 col-xs-12 move-right-sm">
                      <div className="section_title">
                        <p className="section_number2">4</p>
                        <span>How to buy or sell structured warrants? </span>
                      </div>
                      <p className="section_content">
                        On your broker’s trading platform, you will be able to
                        view all structured warrants listed on Bursa Malaysia,
                        just like shares. Once you have used the tools on
                        Macquarie’s website to select the right structured
                        warrant for you, you can simply buy or sell in the same
                        way that you buy or sell shares. If you are unsure of
                        how to buy or sell securities, please refer to your
                        broker.{" "}
                      </p>
                    </div>
                    <div className="col-sm-2 section_number1 ">
                      <p style={{ marginTop: "-25px" }}>4</p>
                    </div>
                  </div>
                </div>
                <div id="section5" className="pointer_tiger" />
                <div className="section5 section_area">
                  <div className="row">
                    <div className="col-sm-2 section_number1">
                      <p style={{ marginLeft: "20px", marginTop: "-25px" }}>
                        5
                      </p>
                    </div>
                    <div className="col-sm-10 col-xs-12">
                      <div className="section_title">
                        <p className="section_number2">5</p>
                        <span>Constant support and updates.</span>
                      </div>
                      <p className="section_content">
                        If you need any assistance, please contact us at +603
                        2059 8840 or info@malaysiawarrants.com.my.
                        <br />
                        <br />
                        You can also subscribe to our newsletter and follow us
                        on Facebook and Telegram, for daily highlights and
                        important updates of the warrants market!
                      </p>
                      <div className="btn-subscribe-area">
                        <Link to="/marketnews/subscribe" target="_blank">
                          <img src="/img/started/Subscribe.png" />
                        </Link>
                        <a
                          className="logo-fb"
                          href="https://www.facebook.com/MalaysiaWarrants"
                          target="_blank"
                        >
                          <img src="/img/started/FB-logo.png" />
                        </a>
                        <a
                          className="logo-telegram"
                          href="https://t.me/Malaysiawarrants"
                          target="_blank"
                        >
                          <img src="/img/started/Telegram-logo.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <p style={{ fontStyle: "italic" }}>
                  Important notice: Investment in warrants involves risk. These
                  links to third party websites do not constitute an offer, a
                  solicitation of an offer, or any advice or recommendation to
                  participate in any transaction. You should consult your own
                  professional advisors to ensure that any decision made is
                  suitable with regards to your circumstances and financial
                  position. Please note that the list of brokers is not
                  exhaustive and the inclusion of the brokers on the list does
                  not imply that the brokers or their services are endorsed by
                  Macquarie. The brokers mentioned on this page are independent
                  and Macquarie is not responsible for any services provided by
                  them.
                </p>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <Slider {...settings} className="ad-section custom">
                {advImgVal &&
                  advImgVal.map((data, index) => {
                    return (
                      <a target="_blank" href={data.link} key={index}>
                        <div>
                          <img src={data.imgSrc} />
                        </div>
                      </a>
                    );
                  })}
              </Slider>

              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Warrant search</h2>
                </div>
                <p className="section-p">
                  Find the right warrant according to your investment criteria.
                </p>
                <div className="section-qlink-img section-qlink-dwsearch">
                  <Link
                    to="/tools/warrantsearch"
                    className="section-qlink-achor"
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Warrant terms</h2>
                </div>
                <p className="section-p">
                  Everything you need to know about your selected warrant is
                  found here!
                </p>
                <div className="section-qlink-img section-qlink-dwterm">
                  <Link
                    to="/tools/warrantterms"
                    className="section-qlink-achor"
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">Warrant calculator</h2>
                </div>
                <p className="section-p">
                  Estimates your future warrant price given a change in
                  underlying price, time or implied volatility
                </p>
                <div className="section-qlink-img section-qlink-dwcalculator">
                  <Link
                    to="/tools/warrantcalculator"
                    className="section-qlink-achor"
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WarrantTermsModal
        className="how-to-start"
        Url={url}
        Open={open}
        OpenModal={() => setOpen(!true)}
      />
    </>
  );
}
export default HowToStarted;
