import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import { Carousel } from 'react-responsive-carousel';
import Sidebar from './Sidebar'
import FaqHeader from './FaqHeader'
import { config } from '../../../utils/apiUrl';
import API from '../../../utils/apiCalling'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FreqAskedQues = (props) => {

  const api = new API();
  const [advImgVal, setAdvImgVal] = useState([])

  useEffect(() => {
    getHomePopup()
  }, [])

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };

  return (
    <div id="faq" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <FaqHeader />
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 hidden-sm hidden-xs">
            <img style={{ width: "100%" }} src="/img/education/u18433.jpg" />
          </div>
          <div className="col-md-8">
            <div className="section">
              <div id="page-header" className="page-header">
                <h1>Frequently asked questions</h1>
              </div>
              <div
                id="question-header"
                className="question-header color-primary"
              >
                Questions you may have
              </div>
              <div className="question-list">
                <div id="question_1">
                  <span>What is a Structured Warrant?</span>
                  <Link
                    activeClass="active"
                    to="answer_1"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_2">
                  <span>What happens at expiry?</span>
                  <Link
                    activeClass="active"
                    to="answer_2"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_3">
                  <span>How is the warrant’s settlement price determined?</span>
                  <Link
                    activeClass="active"
                    to="answer_3"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_4">
                  <span>
                    What is the difference between a company warrant and a
                    structured warrant?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_4"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_5">
                  <span>
                    Are warrants a ‘win-lose’ game? Does the issuer always
                    profit when an investor loses, and vice versa?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_5"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_6">
                  <span>What is a Market Maker (MM)?</span>
                  <Link
                    activeClass="active"
                    to="answer_6"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_7">
                  <span>
                    How can I tell if a warrant issuer is actively market making
                    a particular warrant? Can I still buy the warrant if the
                    market maker is on a wide spread?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_7"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_8">
                  <span>
                    The underlying has moved, so why has the warrant not moved
                    accordingly?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_8"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_9">
                  <span>
                    What is the correct / incorrect way to track the performance
                    of a warrant over time?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_9"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_10">
                  <span>
                    What is the “Live price matrix” and why is it so important?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_10"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_11">
                  <span>
                    What is the exercise ratio and how does it affect the price
                    and movement of a warrant?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_11"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_12">
                  <span>
                    I understand that changes in the implied volatility levels
                    can sometimes affect warrant prices. How can I know if the
                    volatility level is reasonable?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_12"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_13">
                  <span>
                    Is the liquidity of a warrant dependant solely on the
                    turnover of the warrant? Should you buy the warrant if there
                    is no traded volume?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_13"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_14">
                  <span>How can I use warrants as a hedging tool?</span>
                  <Link
                    activeClass="active"
                    to="answer_14"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
                <div id="question_15">
                  <span>
                    What should I consider when choosing a warrant issuer?
                  </span>
                  <Link
                    activeClass="active"
                    to="answer_15"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
              <div className="answer-area" id="answer_1">
                <div className="content-question">
                  What is a Structured Warrant?
                </div>
                <p>
                  A Structured Warrant (SW) is a security that gives the holder
                  the right but not the obligation to buy or sell a specific
                  underlying asset at an agreed price (strike price) on the
                  expiry date. As an investment tool, it provides investors’
                  exposure to an underlying asset for a fraction of the price.
                  On the Bursa Malaysia, warrants are currently available over
                  shares and indices.
                </p>
                <Link
                  activeClass="active"
                  to="question_1"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_2">
                <div className="content-question">What happens at expiry?</div>
                <ul>
                  <li>
                    Call Warrants: if the settlement price of the underlying is
                    above the strike price at expiry, the call warrant is deemed
                    to be “in-the-money” and the holder will receive a cash
                    payment. Otherwise the warrant will expire worthless.
                    <br />
                    <br />
                  </li>
                  <li>
                    Put Warrants: if the settlement price of the underlying is
                    below the strike price at expiry, the put warrant is deemed
                    to be “in-the-money” and the holder will receive a cash
                    payment. Otherwise the warrant will expire worthless.
                  </li>
                </ul>
                <Link
                  activeClass="active"
                  to="question_2"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_3">
                <div className="content-question">
                  How is the warrant’s settlement price determined?
                </div>
                <ul>
                  <li>
                    Single stock warrants: for warrants over shares, the
                    settlement price of a Macquarie warrant is calculated by
                    using the average closing price of the shares for the five
                    (5) Market Days prior to the expiry date.
                    <br />
                    <br />
                  </li>
                  <li>
                    Index warrants: for warrants over indices, the settlement
                    price of a warrant is calculated by using the final
                    settlement price of the relevant spot-month index futures
                    contract on the expiry date.
                  </li>
                </ul>
                <Link
                  activeClass="active"
                  to="question_3"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_4">
                <div className="content-question">
                  What is the difference between a company warrant and a
                  structured warrant?
                </div>
                <p>
                  Company warrants are call warrants issued by companies (with
                  their own stock as the underlying) for the purpose of raising
                  capital. There are no designated market makers for company
                  warrants, and they are typically only suitable for longer term
                  investment as liquidity is uncertain. They are often held
                  until expiry.
                  <br />
                  <br />
                  SW are an investment tool issued by third-party financial
                  instituitions (issuers) who are obliged to make continuous
                  bid/offer prices in the warrant to ensure liquidity (i.e. To
                  “make markets”). They are designed specifically as a short
                  term investment tool and are usually not held to expiry.
                </p>
                <Link
                  activeClass="active"
                  to="question_4"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_5">
                <div className="content-question">
                  Are warrants a ‘win-lose’ game? Does the issuer always profit
                  when an investor loses, and vice versa?
                </div>
                <p>
                  No, warrants are not a ‘zero sum game’. The aim of the issuer
                  is to make a profit on the risk management of the warrants
                  sold, in doing so they also take on risk. When issuers sell
                  warrants, they will normally buy shares or other derivatives
                  to ‘hedge’ their positions and attempt to capture a margin
                  whether the share price goes up or down.
                  <br />
                  <br />
                  For example, when an issuer sells a call warrant they will
                  usually go into the underlying market and buy the shares to
                  hedge themselves. Thus, if the share price increases, and
                  investors profit on their call warrants, the issuer will also
                  gain on their shareholding.
                  <br />
                  <br />
                  It is a common misunderstanding that issuers want investors to
                  lose money. In fact, it is quite the opposite. If investors
                  lose money, they will likely not trade warrants again, whereas
                  if they profit from trading warrants they are more likely to
                  continue trading and the market will grow.
                </p>
                <Link
                  activeClass="active"
                  to="question_5"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_6">
                <div className="content-question">
                  What is a Market Maker (MM)?
                </div>
                <p>
                  Every warrant issuer must appoint a market maker (MM). It is
                  the MM’s role to provide continuous bid and offer prices in
                  the warrant so that there is sufficient liquidity for
                  investors to enter and exit their trade. The MM will also
                  assist in price discovery, and will often be the primary
                  influence in determining the market price for the warrant.
                </p>
                <Link
                  activeClass="active"
                  to="question_6"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_7">
                <div className="content-question">
                  How can I tell if a warrant issuer is actively market making a
                  particular warrant? Can I still buy the warrant if the market
                  maker is on a wide spread?
                </div>
                <p>
                  If an issuer is providing ‘active’ market making in a warrant,
                  it means that they are keeping a tight bid and offer spread
                  with the aim of selling this warrant to investors. There are
                  situations however, where an issuer may not be keen (or able)
                  to sell a particular warrant. For example, if the issuer has
                  already sold a large number of warrants in a particular
                  underlying and their risk position is large, they may widen
                  the offer spread to discourage further selling to investors.
                  Similarly, if an issuer sells out of their warrant inventory,
                  they may not be able to sell any more warrants.
                  <br />
                  <br />
                  Macquarie always widens the offer spread in such situations,
                  so that existing holders can still sell at a fair price.
                  However, in such instances, other investors may bid above the
                  market maker’s bid price, thereby increasing the warrant price
                  and making it appear as though it is on a tight spread.
                  Investors who buy such warrants are taking a high risk, as
                  these ‘other investors’ may not be there when they want to
                  sell the warrant, and the price may therefore fall to meet the
                  market makers bid.
                  <br />
                  <br />
                  As orders on the Bursa Malaysia are anonymous, the only way to
                  determine whether a market maker is on a tight spread is to
                  get this information from the market maker directly. Macquarie
                  are currently the only issuer in Malaysia that provide a “Live
                  price matrix”. The Live matrix has a direct feed to the market
                  making trading system, meaning investors can see exactly which
                  prices the warrant will be bid and offered for each
                  corresponding level in the underlying. Therefore, it is easy
                  to see if the issuer is on a tight or a wide spread.
                </p>
                <Link
                  activeClass="active"
                  to="question_7"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_8">
                <div className="content-question">
                  The underlying has moved, so why has the warrant not moved
                  accordingly?
                </div>
                <p>
                  There are a number of factors that can affect the price of a
                  warrant. Other than the underlying price and market
                  demand/supply, the price of a warrant may also be affected by
                  factors like implied volatility, time to maturity and foreign
                  exchange rates, among other things.
                  <br />
                  <br />
                  Macquarie always widens the offer spread in such situations,
                  so that existing holders can still sell at a fair price.
                  However, in such instances, other investors may bid above the
                  market maker’s bid price, thereby increasing the warrant price
                  and making it appear as though it is on a tight spread.
                  Investors who buy such warrants are taking a high risk, as
                  these ‘other investors’ may not be there when they want to
                  sell the warrant, and the price may therefore fall to meet the
                  market makers bid. Typically, when an underlying share or
                  index moves and the warrant price does not move accordingly,
                  it may be due to:
                </p>
                <ul>
                  <li>
                    Low delta – When a warrant’s exercise price is a long way
                    from the underlying price, the warrant is deemed to be “deep
                    out-of-the-money” and has a low delta. Meaning it requires a
                    large movements in the underlying to change the price of the
                    warrant.
                    <br />
                    <br />
                  </li>
                  <li>
                    Time decay – The warrant price will slowly decay in value as
                    it approaches maturity. If you hold the warrant for a period
                    of time, this decay may offset some or all of the gain
                    caused by a movement in the underlying.
                    <br />
                    <br />
                  </li>
                  <li>
                    Implied volatility (“IV”) – Changes in IV may affect the
                    price of a warrant. A rise in IV will increase the price of
                    a warrant, whereas a fall in IV will cause a warrant price
                    to fall.
                    <br />
                    <br />
                  </li>
                  <li>
                    Conversion ratios - If a warrant has a high conversion ratio
                    it will tend to be less responsive to movements in the
                    underlying (than a similar warrant with a lower conversion
                    ratio).
                    <br />
                    <br />
                  </li>
                  <li>
                    Exchange rates - Foreign index warrants will be effected by
                    relevant exchange rate. A change in the exchange rate can
                    either increase or decrease the price of a warrant.
                  </li>
                </ul>
                <p>
                  It is also very important to understand how to track a warrant
                  price over time. Many investors are confused about how to
                  accurately compare a warrant price change with changes in the
                  underlying, leading to some misunderstanding.
                  <br />
                  <br />
                  You can learn more about warrants from Macquarie’s warrant
                  website at <a>www.malaysiawarrants.com.my</a>
                </p>
                <Link
                  activeClass="active"
                  to="question_8"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_9">
                <div className="content-question">
                  What is the correct / incorrect way to track the performance
                  of a warrant over time?
                </div>
                <p>
                  Price changes in listed securities are commonly published on a
                  “daily price change” basis, which are derived using the
                  difference between the current traded price and the closing
                  price of the previous day. However, using this same method to
                  track the performance of a warrant is often inaccurate, as
                  warrants do not trade as often as shares. The previous closing
                  price for a warrant may relate to a trade done the previous
                  morning, or even days or weeks ago.
                  <br />
                  <br />
                  The best way to track the performance of a warrant is to look
                  at the change in its bid price over the period, and compare
                  this to the price change in the underlying stock (over the
                  same corresponding period of time). The warrant bid price is
                  the price at which investors can sell their warrant and thus
                  is the best reference for the value of a warrant at any point
                  in time.
                  <br />
                  <br />
                  Investors can use the “Warrant Charts” function under “tools
                  &amp; Charts” at www.malaysiawarrants.com.my to see how a
                  warrant’s bid price has changed over time, along with the
                  underlying share price or index.
                </p>
                <Link
                  activeClass="active"
                  to="question_9"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_10">
                <div className="content-question">
                  What is the “Live price matrix” and why is it so important?
                </div>
                <p>
                  The live matrix has a direct feed to Macquarie’s market making
                  system, showing investors exactly where the market maker’s
                  current bid and offer price in the warrant will be, for
                  various levels in the stock.
                  <br />
                  <br />
                  The live matrix is a very useful tool for two reasons.
                  Firstly, it allows investors to see how the warrant price will
                  move in line with movements in the underlying stock or index,
                  providing transparency and also better understanding.
                  Secondly, it allows investors to see whether Macquarie is
                  maintaining a tight spread in our bid and offer quotes.
                  <br />
                  <br />
                  There are a few instances where a market maker may widen their
                  spread for a warrant, such as when the delta of the warrant
                  becomes very high or low, or when the issuer sells out of
                  inventory. Investors should take great caution when buying
                  warrants that are on a wide spread, however this is not always
                  visible as other investors’ orders can make the warrant appear
                  as if it is on a tight spread. Now with the live matrix,
                  investors can see exactly where Macquarie’s bid and offer
                  prices are and whether we are keeping tight spreads.
                </p>
                <Link
                  activeClass="active"
                  to="question_10"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_11">
                <div className="content-question">
                  What is the exercise ratio and how does it affect the price
                  and movement of a warrant?
                </div>
                <p>
                  The exercise ratio is the number of warrants needed to
                  exchange for one underlying share or index futures at expiry.
                  The sole purpose of the exercise ratio is to break down the
                  warrant into smaller units, so a warrant which is priced at
                  RM1.00 would then be worth RM0.50 with an exercise ratio of 2.
                  <br />
                  <br />
                  The sensitivity of a warrant price to the changes in the
                  underlying price can be estimated by “delta per warrant”.
                  <br />
                  <br />A warrant with smaller exercise ratio is more expensive,
                  but will have higher delta per warrant. While a warrant with
                  higher exercise ratio is cheaper but will have lower delta per
                  warrant. If all else is equal, both warrants would give you
                  the same return in percentage terms. Therefore, the exercise
                  ratio should not be a major differentiating factor in the
                  process of choosing a warrant.
                </p>
                <Link
                  activeClass="active"
                  to="question_11"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_12">
                <div className="content-question">
                  I understand that changes in the implied volatility levels can
                  sometimes affect warrant prices. How can I know if the
                  volatility level is reasonable?
                </div>
                <p>
                  The implied volatility (“IV”) for a warrant reflects the
                  comparative price versus other similar warrants. You can
                  compare the IV of a warrant against similar warrants over the
                  same underlying only. If the warrant in question has a much
                  higher IV than others, it may be that this warrant is
                  relatively overpriced.
                  <br />
                  <br />
                  Sometimes the IV of a warrant can change and effect the
                  warrant price. This is usually due to the supply/demand for
                  the warrant, or because there is a change in the volatility of
                  the underlying share or index. An increase in IV will cause an
                  increase in the warrant price, where a fall will cause the
                  warrant price to decrease.
                </p>
                <Link
                  activeClass="active"
                  to="question_12"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_13">
                <div className="content-question">
                  Is the liquidity of a warrant dependant solely on the turnover
                  of the warrant? Should you buy the warrant if there is no
                  traded volume?
                </div>
                <p>
                  No, a warrant’s liquidity is not calculated in the same way as
                  it is for stocks. You can’t simply look at the recent traded
                  volume. In fact, a SW can have no volume traded at all and
                  still be highly liquid. This is because of the existence of
                  market makers, who provide bid and offer prices, and typically
                  contribute the majority of the liquidity in a warrant.
                  <br />
                  <br />
                  The liquidity of a warrant is primarily determined by two
                  factors: the liquidity in the underlying stock, and the
                  quality of the market maker. If the underlying stock is highly
                  liquid, the market maker will typically provide larger volumes
                  on the bid and offer of the SW, which will translate into more
                  liquidity for the SW itself. However, it also depends on the
                  quality of market maker. Most market makers use a computer
                  trading system to provide bid and offer prices. The more
                  sophisticated the trading system and the more experienced the
                  market maker, the more liquidity they’re normally able to
                  provide in their warrants. The easiest way to determine which
                  market makers provide greater liquidity is to compare the bid
                  and offer volumes of their warrants. The warrants with the
                  larger bid and offer volumes are typically the most liquid.
                  <br />
                  <br />
                  If a warrant is "in-the-money" at expiration, the holder will
                  receive a cash payment of an amount equal to the difference
                  between underlying price and exercise price (multiplied by the
                  conversion ratio) within 5 working days after expiry date.
                </p>
                <Link
                  activeClass="active"
                  to="question_13"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_14">
                <div className="content-question">
                  How can I use warrants as a hedging tool?
                </div>
                <p>
                  Put warrants allow investors to profit from downward moves in
                  the underlying share or index. Meaning, put warrants can be
                  used as a form of insurance to protect an existing
                  shareholding or portfolio against a falling market. This is
                  called “hedging”.
                  <br />
                  <br />
                  The owner of a particular share could hedge against market
                  uncertainty by buying a corresponding put warrant. In case
                  where the share price falls, the profit made from the put
                  warrant can then be used to offset the losses from the share.
                  This way, investors can retain share ownership while at the
                  same time protecting themselves against short term falls in
                  price.
                  <br />
                  <br />
                  More commonly, investors tend to use index warrants to hedge
                  their portfolio. For example, if an investor owns a portfolio
                  of Malaysian equities, they might buy a FBMKLCI put warrant to
                  hedge their position. If the market falls, the gain on the put
                  should offset some of the losses on their equity position.
                  However investors need to be aware that their portfolio may
                  not be correlated to the FBMKLCI index, so the hedge is not
                  likely to be perfect, and in some cases may be ineffective.
                  Say for example if their shares fall by more than the index,
                  the hedge will not fully offset their losses.
                </p>
                <Link
                  activeClass="active"
                  to="question_14"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <div className="answer-area" id="answer_15">
                <div className="content-question">
                  What should I consider when choosing a warrant issuer?
                </div>
                <p>
                  Every SW has a designated market maker that provides
                  competitive buy and sell quotes on behalf of the warrant
                  issuer. It is important to choose a high quality and reputable
                  warrant issuer that provides consistent and competitive
                  bid-offer quotes so that investors can easily enter and exit
                  their trades.
                  <br />
                  <br />
                  Investors should also consider the credit rating of the
                  issuer, as they would be exposed to losses if the issuer were
                  unable to fulfil their obligations.
                  <br />
                  <br />
                  Macquarie is the leading issuer in Asia with the largest
                  market share in Singapore and Malaysia, and a leading position
                  in Thailand and Hong Kong. We proudly attribute much of our
                  success to our commitment to providing high quality market
                  making for investors.
                </p>
                <Link
                  activeClass="active"
                  to="question_15"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <img src="/img/bullet/qlink-achor-sm.png" />
                </Link>
              </div>
              <hr className="hr-hmgbm" />
            </div>
          </div>
          <div className="hidden-xs hidden-sm col-md-4">
            <Slider {...settings} className="ad-section custom">
              {advImgVal &&
                advImgVal.map((data, index) => {
                  return (
                    <a target="_blank" href={data.link} key={index}>
                      <div>
                        <img src={data.imgSrc} />
                      </div>
                    </a>
                  );
                })}
            </Slider>
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  );
}
export default FreqAskedQues;
