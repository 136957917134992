import React from "react";
import WarrantCalculators from "./WarrantCalculators";

const WarrantCalculator = () => {

  return (
   <WarrantCalculators/>
  );
};

export default WarrantCalculator;
