import React, {useState} from "react"

export default function FooterData() {

  const [boxVisibility, setBoxVisibility] = useState("hide");
  const showNewBox = () => {
      if(boxVisibility === "hide"){
        setBoxVisibility("show")
      }else if(boxVisibility === "show"){
        setBoxVisibility("hide")
      }else{
        setBoxVisibility("hide")
      }
  }
  const [boxVisibility1, setBoxVisibility1] = useState("show");
  const showNewBox1 = () => {
      if(boxVisibility1 === "hide"){
        setBoxVisibility1("show")
      }else if(boxVisibility1 === "show"){
        setBoxVisibility1("hide")
      }else{
        setBoxVisibility1("hide")
      }
  }

  return (
    <>
      <div id="lmatrix_tbl_f" className="sm-none xs-none">
        <ol>
          <li>
            The warrant prices displayed are indicative. Only
            continuous trading session prices are shown. Warrant
            prices fluctuate from time to time and there is no
            guarantee of returns on the warrants. The actual price
            of the warrants can be different from the prices
            displayed on the website and Macquarie reserves the
            rights to consider the terms and the conditions on
            buying back or selling warrants on case-by-case basis.
          </li>
          <li>
            The actual prices of the warrants may be different from
            the prices displayed due to factors such as 1) the
            liquidity and the volatility of the warrant and the
            underlying as well as the relevant market conditions 2)
            the buying and the selling that is driven by the supply
            and the demand in the market 3) the delay in displaying
            the price 4) the various rights of the underlying and
            other factors that are beyond the control of derivative
            warrant’s issuer, the market maker, the relevant parties
            or the unexpected situations. Investors should study the
            relevant information carefully and thoroughly before
            making investment decisions.
          </li>
          <li>
            The warrant price is provided for your personal use and
            is intended for information purpose only and does not
            constitute an offer, a solicitation, an advice, a
            recommendation or an indication to buy or sell the
            underlying securities or warrant. Macquarie shall not be
            liable for the accuracy or completeness of any
            information or for any loss or damage however caused
            arising in connection with any information, including
            without limitation any direct, indirect, special or
            consequential loss and loss of profits whether in part
            or in full.
          </li>
          <li>
            Macquarie Capital Securities (Malaysia) Sdn Bhd.
            (“MCSM”) is not an authorised deposit-taking institution
            for the purposes of the Banking Act (Cth) 1959, and
            MCSM’s obligations do not represent deposits or other
            liabilities of Macquarie Bank Limited ABN 46 008 583
            542. Macquarie Bank Limited does not guarantee or
            otherwise provide assurance in respect of the
            obligations of MCSM.
          </li>
        </ol>
        <hr />
        <p className="small">
          All market data is delayed 15 mins unless otherwise
          indicated by timestamp.
        </p>
      </div>
      <div id="defination" className="panel-group md-up-none xs-none">
        <div className="panel panel-default">
          <div className="panel-heading">
            <button type="button" onClick={() => showNewBox()}>
              <span className="glyphicon glyphicon glyphicon-question-sign pull-right" />
              <div id="collapse_title3" className="panel-title text-center color-primary">
                <span>Definition &amp; disclaimer</span>
                <img src="/img/bullet/question.png" alt="Image" />
              </div>
            </button>
          </div>
          <div id="collapse_dwcompare3" className={`${boxVisibility}`} aria-expanded="true" style={{}}>
            <div id="lmatrix_tbl_f">
              <ol>
                <li>The warrant prices displayed are indicative. Only continuous trading session prices are shown. Warrant prices fluctuate from time to time and there is no guarantee of returns on the warrants. The actual price of the warrants can be different from the prices displayed on the website and Macquarie reserves the rights to consider the terms and the conditions on buying back or selling warrants on case-by-case basis. </li>
                <li>The actual prices of the warrants may be different from the prices displayed due to factors such as 1) the liquidity and the volatility of the warrant and the underlying as well as the relevant market conditions 2) the buying and the selling that is driven by the supply and the demand in the market 3) the delay in displaying the price 4) the various rights of the underlying and other factors that are beyond the control of derivative warrant’s issuer, the market maker, the relevant parties or the unexpected situations. Investors should study the relevant information carefully and thoroughly before making investment decisions.</li>
                <li>The warrant price is provided for your personal use and is intended for information purpose only and does not constitute an offer, a solicitation, an advice, a recommendation or an indication to buy or sell the underlying securities or warrant. Macquarie shall not be liable for the accuracy or completeness of any information or for any loss or damage however caused arising in connection with any information, including without limitation any direct, indirect, special or consequential loss and loss of profits whether in part or in full.</li>
                <li>Macquarie Capital Securities (Malaysia) Sdn Bhd. (“MCSM”) is not an authorised deposit-taking institution for the purposes of the Banking Act (Cth) 1959, and MCSM’s obligations do not represent deposits or other liabilities of Macquarie Bank Limited ABN 46 008 583 542. Macquarie Bank Limited does not guarantee or otherwise provide assurance in respect of the obligations of MCSM.</li>
              </ol>
              <hr />
              <p className="small">All market data is delayed 15 mins unless otherwise indicated by timestamp.</p>
            </div>
          </div>
        </div>
      </div>
      <div id="defination" className="panel-group xs-view">
        <div className="panel panel-default">
          <div className="panel-heading">
            <button type="button" onClick={() => showNewBox1()}>
              <span className="glyphicon glyphicon glyphicon-question-sign pull-right" />
              <div id="collapse_title3" className="panel-title text-center color-primary">
                <span>Definition &amp; disclaimer</span>
                <img src="/img/bullet/question.png" alt="Image" />
              </div>
            </button>
          </div>
          <div id="collapse_dwcompare3" className={`${boxVisibility1}`} aria-expanded="true" style={{}}>
            <div id="lmatrix_tbl_f">
              <ol>
                <li>The warrant prices displayed are indicative. Only continuous trading session prices are shown. Warrant prices fluctuate from time to time and there is no guarantee of returns on the warrants. The actual price of the warrants can be different from the prices displayed on the website and Macquarie reserves the rights to consider the terms and the conditions on buying back or selling warrants on case-by-case basis. </li>
                <li>The actual prices of the warrants may be different from the prices displayed due to factors such as 1) the liquidity and the volatility of the warrant and the underlying as well as the relevant market conditions 2) the buying and the selling that is driven by the supply and the demand in the market 3) the delay in displaying the price 4) the various rights of the underlying and other factors that are beyond the control of derivative warrant’s issuer, the market maker, the relevant parties or the unexpected situations. Investors should study the relevant information carefully and thoroughly before making investment decisions.</li>
                <li>The warrant price is provided for your personal use and is intended for information purpose only and does not constitute an offer, a solicitation, an advice, a recommendation or an indication to buy or sell the underlying securities or warrant. Macquarie shall not be liable for the accuracy or completeness of any information or for any loss or damage however caused arising in connection with any information, including without limitation any direct, indirect, special or consequential loss and loss of profits whether in part or in full.</li>
                <li>Macquarie Capital Securities (Malaysia) Sdn Bhd. (“MCSM”) is not an authorised deposit-taking institution for the purposes of the Banking Act (Cth) 1959, and MCSM’s obligations do not represent deposits or other liabilities of Macquarie Bank Limited ABN 46 008 583 542. Macquarie Bank Limited does not guarantee or otherwise provide assurance in respect of the obligations of MCSM.</li>
              </ol>
              <hr />
              <p className="small">All market data is delayed 15 mins unless otherwise indicated by timestamp.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
