import React, { useState, useEffect,Fragment } from 'react';
import TimeDecays from './TimeDecays';
import "../../common.css";
import "../../investorAcademy.css";


const TimeDecay = (props) => {
    return ( 
        <Fragment> 
            <TimeDecays/>
        </Fragment> 
     );
}


export default TimeDecay;