import React from 'react';
import { Link } from 'react-router-dom';



const WarrantIndex = ({TopIndexList}) => {
  
// const strConvert =(a)=>{
//   let tempNum =  a.toString();
//   const tempStr = tempNum.slice(0,tempNum.length-4)
//   return tempStr;
// }


const setClass = (val) => {
  let className = " col PCTCHNG "
  if ((val).toString().includes("+")) {

    className += "up"
  }
  else if ((val).toString().includes("-")) {
    className += "down"
  }
  return className;
}


    return ( 
        <div id="topTbl">
        <table className="table table-striped table-closed">
          <thead><tr className="rowtmpl_h">
              <th>Warrant name</th>
              <th className='xs-none'>Underlying</th>
              <th className='xs-none'>Issuer</th>
              <th>Type</th>
              <th>Bid<br />(MYR)</th>
              <th>Change<br />(%)</th>
              <th>Volume<br />('000)</th>
            </tr></thead>
          <tbody>
           { TopIndexList && TopIndexList.map((data,idx)=>(
           <tr className="rowtmpl" key={idx} style={{}} idx={idx}>
              <td className="col ticker"><Link to={`${"/tools/livematrix/"}${data.ticker}`}>{data.dsply_nmll}</Link></td>
              <td className="col underlying_name xs-none">{data.underlying_name}</td>
              <td className="col issuer_code xs-none">{data.issuer_code}</td>
              <td className="col type">{data.type}</td>
              <td className="col BID">{data.BID}</td>
             <td 
                       className={
                          setClass(data && data.BID_PCTCHNG)
                  }
                      >{data.BID_PCTCHNG}</td>
                      <td className="col ACVOL_1">{data.ACVOL_1_SCALED}</td>
            </tr> 
            ))}
            </tbody>
        </table>
        <div style={{display: 'none'}} className="checkbox-mqonly"><input type="checkbox" defaultChecked /><label>Macquarie only</label></div>        
        <p className="small">
          The above price is the indicative value.
          <br /><br />
          All market data is delayed 15 mins unless otherwise indicated by timestamp.
        </p>
      </div>
     );
}
 
export default WarrantIndex;