import React, { useState, useEffect } from 'react';
import "./highlight.css";
import { Link } from 'react-router-dom';
import { Base_Url } from '../../../utils/apiUrl';



const Highlights = (props) => {

    return (
        <div id="highlight" className="pageobj">
            <div id="nid" style={{ display: "none" }}>
                7032
            </div>
            <div id="cat" style={{ display: "none" }}>
                todayhighlight
            </div>
            <div className="breadcrumbbar">
                <div className="container-fluid">
                <ol className="breadcrumb">
                    <li>
                    <Link to="/home">Market news</Link>
                    </li>
                    <li>
                    <Link id="pachor" to="/marketnews/dailyhighlights">
                        Daily highlights
                    </Link>
                    </li>
                    <li className="active" id="breadcrumb-label">
                    How do changes in volatility impact structured warrants?
                    </li>
                </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="section">
                    <p id="highlight-date">13 September 2021</p>
                    <div className="page-header">
                        <h1 id="page-title">
                        How do changes in volatility impact structured warrants?
                        </h1>
                        <div className="btn-area">
                        <div className="fbSharebtn">
                            <i className="fa fa-facebook" aria-hidden="true" />
                            Share on Facebook
                        </div>
                        </div>
                    </div>
                    <div id="highlight-chart"></div>
                    <div id="highlight-content">
                        
                    
                    <p>
                        The Hang Seng Index (HSI) has been going through huge fluctuations
                        lately as the crackdown by Chinese regulators continue to impact various
                        sectors within the Hong Kong market. Last week, the index started the
                        week on a rather positive note but fell 2.3% on Thursday before
                        rebounding 1.9% to 26,205.91 on Friday, 1.2% higher week-on-week.
                        <br />
                        &nbsp;
                        <br />
                        Warrant investors should know that when there are moves in volatility,
                        the market makers may{" "}
                        <strong>adjust the implied volatility levels</strong> of warrants to
                        ensure that they reflect the market’s changes in volatility accordingly,
                        which in turn <strong>impacts warrant prices</strong>.<br />
                        &nbsp;
                        <br />
                        Read on to find out more.
                        <br />
                        &nbsp;
                        <br />
                        &nbsp;
                        <br />
                        <strong>What is ‘volatility’? </strong>
                        <br />
                        &nbsp;
                        <br />
                        Warrant prices are impacted when the market sees changes in volatility.
                        With a <strong>jump in volatility in the underlying</strong>,
                        market-makers typically{" "}
                        <strong>raise the implied volatility (IV) levels</strong> of warrants
                        for the warrants to reflect the market conditions accordingly.
                        Similarly, market-makers typically <strong>lower the IV levels</strong>{" "}
                        of warrants when there is a <strong>dip in volatility</strong>.<br />
                        &nbsp;
                        <br />
                        Volatility can be defined as a “measure of uncertainty about the future
                        returns provided by a security”. The more uncertain the returns, the
                        more volatile the security’s price can be. A high volatility means the
                        price of the security can change dramatically over a short period of
                        time in either direction. A lower volatility suggests that a security’s
                        value does not fluctuate much. In warrant markets, there are two types
                        of volatility that are often referred to:
                        <br />
                        &nbsp;
                        <br />
                    </p>
                    <ol style={{ fontSize: "inherit", fontFamily: "inherit" }}>
                        <li style={{ fontSize: "inherit", fontFamily: "inherit" }}>
                        <strong>Historical volatility</strong> is backward looking and
                        measures how volatile the underlying was over a past period of time.
                        It is often used as a reference to estimate or decide on an
                        appropriate implied volatility for the price of a warrant.
                        </li>
                        <li style={{ fontSize: "inherit", fontFamily: "inherit" }}>
                        <strong>Implied volatility (IV)</strong> is the level of volatility
                        “implied” by the current market price of a particular security.
                        Meaning, it is incorporated into the pricing formula for the warrant.
                        The higher the volatility, the higher the probability that the warrant
                        will expire in the money, hence the higher the price of the warrant.
                        </li>
                    </ol>
                    <p>
                        &nbsp;
                        <br />
                        &nbsp;
                        <br />
                        <strong>Why does the IV of warrants need to change?</strong>
                        <br />
                        &nbsp;
                        <br />
                        One of the ways issuers hedge their risks from selling warrants is via
                        the over the counter (OTC) or exchange traded option (ETO) markets.
                        <br />
                        &nbsp;
                        <br />
                        For example, for Hang Seng Index (HSI) warrants, issuers will look to
                        the ETO market for HSI options as a benchmark of where they would buy
                        and sell HSI warrants. Any changes in the IV in the Hong Kong ETO market
                        for the HSI can have an impact on the prices for the Malaysian listed
                        HSI warrants. Likewise, for warrants over the S&amp;P500® Index (SP500),
                        issuers benchmark their warrants IV levels to the ETO market for the
                        SP500 trades both overnight and during Asian hours.
                        <br />
                        &nbsp;
                        <br />
                        In the warrant markets, issuers make profits primarily by selling
                        warrants at an IV above where they believe they can manage or hedge
                        their risk in option markets such as the ETO market, or by trading the
                        underlying security itself. In addition, for warrants over the SP500,
                        warrant issuers will also need to consider and manage both the overnight
                        volatility changes as well as that of during Asian hours.
                        <br />
                        &nbsp;
                        <br />
                        If this is done successfully, issuers will make a margin on their
                        outstanding warrant positions over time. However, issuers are also at
                        risk of losses if volatilities in the ETO markets spikes. This is why
                        issuers will need to adjust the IV levels of warrants upwards,
                        especially in volatile markets. Similarly, issuers will need to adjust
                        the IV levels of warrants downwards if volatility declines, in order for
                        the warrants to reflect the market conditions accordingly.
                        <br />
                        &nbsp;
                        <br />
                        &nbsp;
                        <br />
                        <strong>How do IV changes impact warrants?</strong>
                        <br />
                        &nbsp;
                        <br />
                        With that, investors should be aware that warrant prices are impacted
                        when the market sees changes in volatility. For warrants to reflect the
                        market conditions accordingly, Macquarie as the market maker may adjust
                        the IV levels of warrants upwards or downwards. An{" "}
                        <strong>increase in IV</strong> leads to an{" "}
                        <strong>increase in warrant prices</strong>, whereas a{" "}
                        <strong>decrease in IV</strong> leads to a{" "}
                        <strong>decrease in warrant prices</strong>. If the market maker does
                        not perform adjustments to IV levels, the warrant’s market price may be
                        a lot lower or higher than its price computed based on current
                        volatilities, which is not reflective of the market.
                        <br />
                        &nbsp;
                        <br />
                        Macquarie tries to make all adjustments prior to the start of the market
                        making hours for the relevant underlying each day, which are as below.
                        <br />
                        &nbsp;
                        <br />
                    </p>
                    <ul style={{ fontSize: "inherit", fontFamily: "inherit" }}>
                        <li style={{ fontSize: "inherit", fontFamily: "inherit" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hong Kong shares and
                        China A50 warrants: 9.30am - 11.58am; 2.30pm - 3.58pm
                        </li>
                        <li style={{ fontSize: "inherit", fontFamily: "inherit" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; FBM KLCI and Malaysia
                        underlying warrants: 9.00am - 12.29pm; 2.30pm - 4.43pm
                        </li>
                        <li style={{ fontSize: "inherit", fontFamily: "inherit" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; HSI and Hang Seng
                        TECH Index warrants: 9.15am - 11.58am; 2.30pm - 4.28pm
                        </li>
                        <li style={{ fontSize: "inherit", fontFamily: "inherit" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SP500 warrants:
                        9.00am - 12.29pm; 2.30pm - 4.43pm
                        </li>
                    </ul>
                    <p>
                        &nbsp;
                        <br />
                        This table of market making hours is also available below the{" "}
                        <a
                        target="_blank"
                        href={`${Base_Url}/tools/livematrix`}
                        >
                        <strong>Live Matrix Plus</strong>
                        </a>{" "}
                        for your ease of reference.
                        <br />
                        &nbsp;
                        <br />
                        However, <strong>
                        do note that there could be intraday changes
                        </strong>{" "}
                        in the IV levels, consequently affecting the warrant price and the live
                        matrix during the trading day, especially for times when there are
                        spikes in the underlying’s volatility. This will be reflected when you
                        refresh the live matrix. We generally avoid reducing the IV levels of
                        warrants intraday.
                        <br />
                        &nbsp;
                        <br />
                        &nbsp;
                        <br />
                        To read more about IV, visit our{" "}
                        <a
                        target="_blank"
                        href={`${Base_Url}/edu/impliedvolatility`}
                        >
                        investor academy
                        </a>
                        . Alternatively, investors can call our hotline at 03-2059 8840 or email
                        us at info@malaysiawarrants.com.my to enquire on prices or spread
                        changes, or if you have any other questions.
                        <br />
                    </p>
                    </div>
                    <div className="pnbar">
                        <div className="pnbar-prev">
                            <Link
                            id="prev-achor"
                            className
                            to="/marketnews/highlight?cat=todayhighlight&id=7031"
                            >
                            &lt; Previous day
                            </Link>
                        </div>
                        <div className="pnbar-vl" />
                        <div className="pnbar-next">
                            <a id="next-achor" className="disabled">
                            Next day &gt;
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
}
export default Highlights;