import React, { useState, useEffect } from 'react';
import API from '../../../utils/apiCalling';
import { Link } from 'react-router-dom';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import moment from 'moment';
import { getMoneyFlowData } from '../../../utils/moneyFlow.api' 
import DonutChart from './DonutChartHome';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WarrantStatistics = () => {
  const api = new API()
  const [result, setResult] = useState()
  const [topIndexWarrant, setTopIndexWarrant] = useState([])
  const [topStockWarrant, setTopStockWarrant] = useState([])
  const [date, setDate] = useState()
  const [moneyFlowData, setMoneyFlowData] = useState([])

  const [advImgVal, setAdvImgVal] = useState([])

  function getMoneyFlow(val) {
    var finalDate = moment(new Date(val)).format("YYYY-MM-DD")
    getMoneyFlowData("all", finalDate, "top5")
      .then((res) => {
        setMoneyFlowData(res.data.mfin.slice(0, 5))
      })
  }


  useEffect(() => {
    getHomePopupStat()
  }, [])
  const getHomePopupStat = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };

  useEffect(() => {
    getHomeTopTradedWarrants()
  }, [])

  const getHomeTopTradedWarrants = async () => {
    let homeTopTradedWarrantsResult = await api.get(config.homeEducationSeminar, true, { m: "dwstat", qid: Date.now() })

    let topIndexWarrantArr = get(homeTopTradedWarrantsResult, "topindices", [])
    let topStockWarrantArr = get(homeTopTradedWarrantsResult, "topdw", [])
    function compare(a, b) {
      let comparison = 0;
      if (a.ACVOL_1_SCALED > b.ACVOL_1_SCALED) {
        comparison = -1;
      } else if (a.ACVOL_1_SCALED < b.ACVOL_1_SCALED) {
        comparison = 1;
      }
      return comparison;
    }
    setTopIndexWarrant(topIndexWarrantArr.sort().slice(0, 5))
    setTopStockWarrant(topStockWarrantArr.sort().slice(0, 5))
    setDate(homeTopTradedWarrantsResult.mdate)
    setResult(homeTopTradedWarrantsResult.dwturnover.reverse())
    getMoneyFlow(homeTopTradedWarrantsResult.mdate)
  }

  const setClass = (val) => {
    let className = "pct_dis val "
    if ((val).toString().includes("+")) {

      className += "up"
    }
    else if ((val).toString().includes("-")) {
      className += "down"
    }
    return className;
  }

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  return (

    <div id="dwstat" className="tabcontent">
      <p className="nav-dtxt show md-up-none" data-value="dwstat">
        Our customised graphs and charts provide excellent insight into
        the markets.
      </p>
      <div className="frame-lgmd">
        <div className="row">
          <div className="holder-moneyflow col-md-6">
            <div id="moneyflow" className="section">
              <div className="page-header">
                <h1 className="small">Money flow </h1>
              </div>
              <p>
                Find out which Macquarie warrants other investors are buying and selling.
              </p>

              <div className="chartbox">
                {moneyFlowData && <div className="chartbox-legend">
                  <div className="chartbox-legend-item">
                    <span className="chartbox-legend-square cbg00" />
                    <span className="chartbox-legend-txt lgnm_0">{moneyFlowData[0]?.name}</span>
                  </div>
                  <div className="chartbox-legend-item">
                    <span className="chartbox-legend-square cbg01" />
                    <span className="chartbox-legend-txt lgnm_1">{moneyFlowData[1]?.name}</span>
                  </div>
                  <div className="chartbox-legend-item">
                    <span className="chartbox-legend-square cbg02" />
                    <span className="chartbox-legend-txt lgnm_2">{moneyFlowData[2]?.name}</span>
                  </div>
                  <div className="chartbox-legend-item">
                    <span className="chartbox-legend-square cbg03" />
                    <span className="chartbox-legend-txt lgnm_3">{moneyFlowData[3]?.name}</span>
                  </div>
                  <div className="chartbox-legend-item">
                    <span className="chartbox-legend-square cbg04" />
                    <span className="chartbox-legend-txt lgnm_4">{moneyFlowData[4]?.name}</span>
                  </div>
                </div>}

                {!moneyFlowData && <div className="pie-overlay" style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, textAlign: 'center', }}>
                  <div className="position:relative;width:100%;height:100%">
                    <img className="pie-overlay-outer" style={{ position: 'absolute', top: '50%', left: '50%',width: '100%', maxWidth: '275px',transform: 'translate(-50%, -50%)' }} src="/img/bullet/pie-overlay.png" />
                    <img className="pie-overlay-inner" style={{ position: 'absolute', top: '50%', left: '50%', zIndex: '20',width: '100%', maxWidth: '120px',transform: 'translate(-50%, -50%)' }} src="/img/bullet/pie-overlay.png" />
                    <div className="pie-overlay-blank" style={{
                      position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 30, backgroundColor: '#fff',
                      display: 'none'
                    }} />
                  </div>
                </div>}
                
                <div id="topInPieChart-lgmd" style={{ width: '100%', height: '300px' }}>
                  {!moneyFlowData && <p>{date}</p>}
                  {moneyFlowData && <DonutChart
                      result={moneyFlowData}
                      date={moment(new Date(date)).format('DD MMM YY')}
                    />}
                </div>
              </div>
              <div className="section-btn-wapper">
                <Link to="/marketdata/moneyflow" style={{ minWidth: '190px' }} type="button"
                  className="btn btn-primary">View more</Link>
              </div>
            </div>
          </div>
          <div id="video-dwstat-lgmd" className="col-md-6 holder-advertisment" style={{marginTop: '20px'}}>          
          <Slider {...settings} className="ad-section custom">
            {advImgVal && advImgVal.map((data, index) => { return (
              <a
                target="_blank"
                href={data.link}
                key={index}
              >
                <div>
                  <img src={data.imgSrc} />
                </div>
              </a>)})}
          </Slider>
        </div>
        </div>
        
        <div className="row">
          <div className="col-md-12 holder-toptradeddw"><div id="toptradeddw" className="section">
            <div className="page-header">
              <h1 className="small">Top traded warrants</h1>
            </div>
            <p>
              Shows the top traded warrants with the highest volume for the current day.
            </p>
            <div className="row">
              <div id="topindices" className="col-sm-6">
                <h2 className="small">Top 5 index warrants</h2>
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>Warrant name</th>
                      <th className="sm-none xs-none">Underlying</th>
                      <th>Type</th>
                      <th>Bid (MYR)</th>
                      <th>Change (%)</th>
                      <th>Volume ('000)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topIndexWarrant && topIndexWarrant.map((dataIndex, indexIdx) => (
                      <tr className="tt_rowtmpl" key={indexIdx} idx={indexIdx}>
                        <td className="ticker val"><Link to={`${"/tools/livematrix/"}${dataIndex.ticker}`}>{dataIndex.dsply_nmll}</Link></td>
                        <td className="underlying val sm-none  xs-none"><Link to={`${"/tools/underlying/"}${dataIndex.underlying_ric}`}>{dataIndex.underlying_name}</Link></td>
                        <td className="type val">{dataIndex.type}</td>
                        <td className="bid val">{dataIndex.BID}</td>
                        <td
                         className={dataIndex.BID_PCTCHNG.includes("-") ? "tw_BID_PCTCHNG down" : dataIndex.BID_PCTCHNG.includes("+") ? "tw_BID_PCTCHNG up" : "tw_BID_PCTCHNG"}
                         >{dataIndex.BID_PCTCHNG}</td>
                        <td className="vol val">{dataIndex.ACVOL_1_SCALED}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div id="topdw" className="col-sm-6">
                <h2 className="small">Top 5 stock warrants</h2>
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>Warrant name</th>
                      <th className='sm-none xs-none'>Underlying</th>
                      <th>Type</th>
                      <th>Bid (MYR)</th>
                      <th>Change (%)</th>
                      <th>Volume ('000)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topStockWarrant && topStockWarrant.map((stockIndex, stockIdx) => (
                      <tr className="tt_rowtmpl" key={stockIdx} idx={stockIdx}>
                        <td className="ticker val"><Link to={`${"/tools/livematrix/"}${stockIndex.ticker}`}>{stockIndex.dsply_nmll}</Link></td>
                        <td className="underlying val sm-none xs-none"><Link to={`${"/tools/underlying/"}${stockIndex.underlying_ric}`}>{stockIndex.underlying_name}</Link></td>
                        <td className="type val">{stockIndex.type}</td>
                        <td className="bid val">{stockIndex.BID}</td>
                        <td 
                         className={stockIndex.BID_PCTCHNG.includes("-") ? "tw_BID_PCTCHNG down" : stockIndex.BID_PCTCHNG.includes("+") ? "tw_BID_PCTCHNG up" : "tw_BID_PCTCHNG"}
                         >{stockIndex.BID_PCTCHNG}</td>
                        <td className="vol val">{stockIndex.ACVOL_1_SCALED}</td>
                      </tr>
                    ))}


                  </tbody>
                </table>
                <div className="section-btn-wapper">
                  <Link to="/marketdata/toptradedwarrants" style={{ minWidth: '190px' }} type="button" className="btn btn-primary">View more</Link>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 holder-turnover">
            <div id="turnover" className="section">
              <div className="page-header">
                <h1 className="small">Warrant market turnover</h1>
              </div>
              <p>
                How active was the warrant market today and how did it compare to previous days?
              </p>
              <div>
                Total warrants turnover (MYR)
              </div>
              {result && 
              <div style={{position: "relative", height:300, width:"100%"}}>
              <Bar
                data={
                  {
                    labels: result.map((d) => moment(new Date(d.stats_date_obj)).format("DD MMM")),
                    datasets: [{
                      label: "turnover",
                      data: result.map((d) => parseFloat((d.dw).replace(/,/g, ""))),
                      backgroundColor: ["#F7941E"]
                    }]
                  }
                }
                options={{
                  maintainAspectRatio:false,
                  plugins: {
                    legend: {
                      display: false
                    },
                  },
                  scales: {
                    y: { 
                      ticks: { 
                        color: '#00000', 
                        beginAtZero: true, 
                      }
                    },
                    x: {
                      ticks: { 
                        color: '#F7941E', 
                        fontFamily: '"MCQGlobal_W_Lt", "MCQGlobal_W_Bd", "Roboto", Arial,sans-serif', 
                        beginAtZero: true, 
                      }
                    }
                  }
                }}
              />
              </div>
              }
              <div className="section-btn-wapper">
                <Link to="/marketdata/turnover" style={{ minWidth: '190px' }} type="button"
                  className="btn btn-primary">View more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default WarrantStatistics;
