import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MarketNews = () => {
  const api = new API()
  const [load, setLoad] = useState(false)
  const [photoGallery, setPhotoGallery] = useState([])
  const [photoGallery1, setPhotoGallery1] = useState([])
  const [highlight, setHighlight] = useState([])
  const [commentary, setCommentary] = useState([])
  const [overnight, setOvernight] = useState([])
  // const {highlight,commentary,overnight,albums} = data

  const [advImgVal, setAdvImgVal] = useState([])




  useEffect(() => {
    getHomePopupStat()
    getphotoGalleryResult1()
    getHomeQuickHeadlines()
  }, [])

  const getHomeQuickHeadlines = async () => {
    let homeQuickHeadlinesResult = await api.get(
      config.homeQuickHeadlines,
      true,
      { m: "dwnews", qid: Date.now() }
    );
    setHighlight(homeQuickHeadlinesResult.highlight)
    setCommentary(homeQuickHeadlinesResult.commentary)
    setOvernight(homeQuickHeadlinesResult.overnight)
  };

  const getphotoGalleryResult1 = async () => { 
    let photoGalleryResult = await api.get(config.photoGallery, true,{qid:Date.now()} )
    let items = [];
    if(photoGalleryResult.length > 0){
        for (let i = 0; i < photoGalleryResult.length; i++) {
            let advImgData = await api.get(config.advImgData, true, {album_id:photoGalleryResult[i].id});
            items.push({
                imgSrc: advImgData,
                id: photoGalleryResult[i].id,
                en_title: photoGalleryResult[i].en_title
            })
        }
        setPhotoGallery1(items)
    }
    setLoad(true)
  }

  const getHomePopupStat = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };


  useEffect(() => {
    getphotoGalleryResult()
  }, [])

  const getphotoGalleryResult = async () => {
    let photoGalleryResult = await api.get(config.photoGallery, true, { qid: Date.now() })
    let photoGalleryList = get(photoGalleryResult, [])
    setPhotoGallery(photoGalleryResult)
    setLoad(true)
  }

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };


  return (

    <div id="dwnews" className="tabcontent">
      <p className="nav-dtxt show md-up-none" data-value="dwnews">
        Market news as it breaks. Stay informed and ready.
      </p>
      <div className="frame-lgmd">
        <div className="row">
          <div className="col-md-6">
            <div className="holder-todayhighlight">
              <div id="todayhighlight" className="section">
                <div className="page-header">
                  <h1 className="small">Daily highlights</h1>
                </div>
                <div className="news-title">
                  <span dangerouslySetInnerHTML={{__html:highlight?.en_title}}></span>
                </div>
                {highlight && highlight.chartinfolists && highlight.chartinfolists.map((d, index) =>
                  <div className='news-chart' key={index}>
                    <img
                      src={`https://t1mq.trkd-hs.com/mqmy_aws/ChartImageServlet?chartid=${d.id}`}
                      style={{ width: "500px" }}
                    />
                  </div>
                )}
                <div className="news-table">
                  {highlight && highlight.warrantlist != "" ? <table>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Expiry</th>
                      <th>Ex. Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    {highlight && highlight.warrantlist && highlight.warrantlist.map((data, index) =>
                      <tr key={index}>
                        <td>{data.ticker}</td>
                        <td>{data.dsply_nmll}</td>
                        <td>{data.type}</td>
                        <td>{data.maturity}</td>
                        <td>{data.exercise_price}</td>
                      </tr>
                    )}
                    </tbody>
                  </table> : ''}
                </div>
                <p className='news-summary' dangerouslySetInnerHTML={{ __html: highlight?.en_short_content }}></p> 
                <div className="section-btn-wapper">
                  <Link
                    style={{ minWidth: 190 }}
                    to={`/marketnews/highlight?cat=todayhighlight&id=${highlight.id}`}
                    type="button"
                    className="btn btn-primary"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
            <div className="holder-qlink-subscribe xs-view">
              <div id="qlink-subscribe" className="section">
                <div className="page-header">
                  <h1 className="small">Subscribe</h1>
                </div>
                <p>
                  Don't want to miss any important news? Subscribe to our newsletter
                  today!
                </p>
                <div className="section-qlink section-qlink-subscribe">
                  <div className="section-qlink-achor xs-none">
                    <Link
                      to="/marketnews/subscribe"
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div> 
                </div>
                <div className="section-btn-wapper xs-view">
                    <Link to="/marketnews/subscribe" type="button" className="btn btn-primary">View more</Link>
                </div>
              </div>
            </div>
            <div className="holder-marketcommentary">
              <div id="marketcommentary" className="section">
                <div className="page-header">
                  <h1 className="small">End of day insights</h1>
                </div>
                <div className="news-title">
                <span dangerouslySetInnerHTML={{__html:commentary?.en_title}}></span>
                </div>
                <p className='news-summary' dangerouslySetInnerHTML={{__html:commentary.en_short_content}}></p>
                <p />
                <div className="section-btn-wapper">
                  <Link
                    style={{ minWidth: 190 }}
                    to={`/marketnews/highlight?cat=dailycommentary&id=${commentary.id}`}
                    type="button"
                    className="btn btn-primary"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
            <div className="holder-marketwrap xs-view">
              <div id="marketwrap" className="section">
                <div className="page-header">
                  <h1 className="small">Overnight market wrap</h1>
                </div>
                <div className="news-title">
                <span dangerouslySetInnerHTML={{__html:overnight?.en_title}}></span>
                </div>
                <p className='news-summary' dangerouslySetInnerHTML={{__html:overnight?.en_short_content}}></p>
                <div className="section-btn-wapper">
                  <Link
                    style={{ minWidth: 190 }}
                    to={`/marketnews/highlight?cat=overnightmarkets&id=${overnight.id}`}
                    type="button"
                    className="btn btn-primary"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
            <div className="holder-activity">
              <div id="activity" className="section">
                <div className="page-header">
                  <h1 className="small">Activities</h1>
                </div>
                <p>Hear about the latest events and activities from Macquarie.</p>
                <img
                  src="/img/home/home-activities.jpg"
                  style={{ width: "100%" }}
                />
                <div className="section-btn-wapper">
                  <Link
                    to="/marketnews/activities"
                    style={{ minWidth: 190 }}
                    type="button"
                    className="btn btn-primary"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="holder-qlink-subscribe xs-none">
              <div id="qlink-subscribe" className="section">
                <div className="page-header">
                  <h1 className="small">Subscribe</h1>
                </div>
                <p>
                  Don't want to miss any important news? Subscribe to our newsletter
                  today!
                </p>
                <div className="section-qlink section-qlink-subscribe">
                  <div className="section-qlink-achor">
                    <Link
                      to="/marketnews/subscribe"
                      type="button"
                      className="btn btn-primary"
                    >
                      View more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div id="video-dwstat-lgmd" className="col-md-6 holder-advertisment xs-none">
            <Slider {...settings} className="ad-section custom">
            {advImgVal && advImgVal.map((data, index) => { return (
              <div className="gallery-item" key={index}>
                <Link 
                  className="homeimg-achor home_banner1"
                  target="_blank"
                  to={data.link}
                >
                  <img
                    style={{ width: "100%", }}
                    src={data.imgSrc}
                  />
                </Link>
              </div>
              )})}
          </Slider>
            </div>
            <div className="holder-marketwrap xs-none">
              <div id="marketwrap" className="section">
                <div className="page-header">
                  <h1 className="small">Overnight market wrap</h1>
                </div>
                <div className="news-title">
                <span dangerouslySetInnerHTML={{__html:overnight?.en_title}}></span>
                </div>
                <p className='news-summary' dangerouslySetInnerHTML={{__html:overnight?.en_short_content}}></p>
                <div className="section-btn-wapper">
                  <Link
                    style={{ minWidth: 190 }}
                    to={`/marketnews/highlight?cat=overnightmarkets&id=${overnight.id}`}
                    type="button"
                    className="btn btn-primary"
                  >
                    View more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="photogallery" className="gallery swiper-container section advertising-banner swiper-initialized swiper-horizontal swiper-pointer-events">
          <div style={{ position: "relative" }}>
            {(load && photoGallery.length === 0) && "No photo gallery album"}
            <Carousel
              showArrows={true}
              infiniteLoop={true}
              autoPlay={true}
              showIndicators={false}
              emulateTouch
              showThumbs={false}
            >
              {load && photoGallery1 && photoGallery1.length > 0 && photoGallery1.map((d, index) =>
                <div className="gallery-item" key={index}>
                  <div className="gallery-item-wapper">
                    <div className="gallery-item-overlay"></div>
                    <div className="gallery-item-body">
                        <div className="gallery-item-txt">{d.en_title}</div>
                        <Link type="button" className="btn btn-primary gallery-item-btn xs-none swiper-no-swiping" to={`/marketnews/individualgallery?album_id=${d.id}`}>View more</Link>
                        <Link type="button" className="btn btn-primary gallery-item-btn xs-view swiper-no-swiping" to={`/marketnews/individualgallery?album_id=${d.id}`}></Link>
                    </div>
                    <img className="gallery-item-img" src={d.imgSrc} />
                  </div>
                </div>
              )}
            </Carousel>
          </div>
        </div>

        
      </div>
    </div>
  )
}
export default MarketNews;
