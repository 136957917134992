import React, { useState, useEffect } from 'react';
import GainExpo from './GainExpo';
import './gainExposure.css';


const GainExposure = (props) => {
    return ( 
        <GainExpo/>
     );
}
 
export default GainExposure;