import React, { useState, useEffect } from 'react';
import API from '../../../utils/apiCalling';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { config } from '../../../utils/apiUrl';
import moment from 'moment';

const TopTradedStock = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const api = new API()
  const [topTradedStock, setTopTradedStock] = useState([])

  useEffect(() => {
    getStasticsTopTradedStock()
  }, [])

  const getStasticsTopTradedStock = async () => {
    let stasticsTopTradedStockResult = await api.get(config.homeTopTradedStock, true, { type: "toptradedstock", qid: Date.now() })
    let topList = get(stasticsTopTradedStockResult, "topList", [])
    setTopTradedStock(topList)
  }

  // const strConvert = (a) => {
  //   let tempNum = a.toString();
  //   const tempStr = tempNum.split(',')
  //   let finalAmount = ""
  
  //   if (tempStr.length > 2) {
  //     finalAmount = `${tempStr[0]},${tempStr[2][0] >= 5 ? +tempStr[1] + 1 : tempStr[1]}`
  //   }
  //   if (tempStr.length == 2) {
  //     finalAmount = `${tempStr[0]},${tempStr[1][0] >= 5 ? +tempStr[1] + 1 : tempStr[1]}`
  //   }
  //   return finalAmount;
  // }

  const setClass = (val) => {
    let className = "right-b01 col_PCTCHNG "
    if ((val).toString().includes("+")) {
      className += "up"
    }
    else if ((val).toString().includes("-")) {
      className += "down"
    }
    return className;
  }
  return (
    <div id="toptradedstock1" className="pageobj" style={{}}>
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li><Link to="/home?dwstat">Warrant statistics</Link></li>
            <li className="active">Top traded stocks</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>Top traded stocks</h1>
          </div>
          <p className="section-txt">View the most active underlying shares (by value) and the corresponding structured warrant with the highest liquidity.</p>
          
          <div id="topTbl1" className="calldw">
            <table className="table-striped table-responsive">
              <thead>
                <tr className='xs-none'>
                  <th className="bgcolor-04" colSpan={4}>Underlying</th>
                  <th className="col_call bgcolor-06" colSpan={4}>Call Warrant</th>
                </tr>
                <tr className='xs-view'>
                  <th className="bgcolor-04" colSpan={3}>Underlying</th>
                  <th className="col_call bgcolor-06" colSpan={3}>Call Warrant</th>
                </tr>
                <tr>
                  <th className="bgcolor-04 underlying">Underlying</th>
                  <th className="bgcolor-04 turnover">Turnover <span className='xs-none'>(MYR '000)</span> <span className='xs-view'>(MYR mil)</span></th>
                  <th className="bgcolor-04 xs-none">Last price (MYR)</th>
                  <th className="bgcolor-04">Change (%)</th>
                  <th className="col_call bgcolor-06">Warrant name</th>
                  <th className="col_call bgcolor-06">Bid (MYR)</th>
                  <th className="col_call xs-none bgcolor-06">Eff. gearing (x)</th>
                  <th className="col_call bgcolor-06 expiry">Expiry</th>
                  <th className="col_put bgcolor-06">Warrant name</th>
                  <th className="col_put bgcolor-06">Bid (MYR)</th>
                  <th className="col_put hidden-xs bgcolor-06">Eff. gearing (x)</th>
                  <th className="col_put bgcolor-06 expiry">Expiry</th>
                </tr>
              </thead>
              <tbody>
                {topTradedStock && topTradedStock.map((data, idx) => (
                  <tr className="rowtmpl" key={idx} idx={idx}>
                    <td className="col_underlying_ticker">{data.underlying_ticker}</td>
                    <td className="col_long xs-none col_undelrying_turnover">{Math.round(data.undelrying_turnover / 1000).toLocaleString()}</td>
                    <td className="col_long xs-view col_undelrying_turnover">{(data.undelrying_turnover / 1000000).toFixed(2)}</td>
                    <td className="xs-none col_TRDPRC_1">{data.TRDPRC_1}</td>
                    <td
                      className={data && data.PCTCHNG.includes("-") ? "val BID_PCTCHNG down" :data.PCTCHNG > 0 ? "val BID_PCTCHNG up" : "val BID_PCTCHNG"}
                    >{data.PCTCHNG > 0 ? "+"+data.PCTCHNG : data.PCTCHNG}</td>
                    <td className="col_call col_warrant_price">
                      <Link to={`${"/tools/livematrix/"}${data.dw_ticker_c}`}>{data.dw_dsply_nmll_c}</Link>
                    </td>
                    <td className="col_call col_dw_ticker">{data.warrant_price_c}</td>
                    <td className="col_call xs-none col_eff_gearing">{data.eff_gearing_c !== "-"?(parseFloat(data.eff_gearing_c)).toFixed(1):"-"}</td>
                    <td className="col_call col_expiry">{(moment(new Date(data.maturity)).format("DD MMM YY"))}</td>
                    <td className="col_put col_dw_ticker"><a href='#'></a></td>
                    <td className="col_put col_warrant_price"/>
                    <td className="col_put hidden-xs col_eff_gearing" />
                    <td className="col_put col_expiry" />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="lastupdatebox-1">
            Last update : <span>{(moment(new Date()).format("DD MMM YY"))}</span>
          </div>
          <p className="small">
            The above table shows the day's most actively traded shares (by value) over which Macquarie issues warrants. The structured warrant highlighted for that underlying is the Macquarie warrant with the largest volume on the current bid and offer, indicating the highest liquidity. This is often also the most actively traded warrant.
            <br /><br />
            All market data is delayed 15 mins unless otherwise indicated by timestamp.
          </p>
        </div>
      </div>
    </div>
  );
}
export default TopTradedStock;
