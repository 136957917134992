import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import GaEventsTracker from "../../../components/common/GaEventsTracker";


function DataLayout({
  Maintitle,
  title,
  para,
  divData,
  para1,
  para2,
  link,
  linkurl,
  link1,
  link1url,
  ul1,
  ul2,
  ul3,
  ul4,
  btnShow,
  authorTitle,
  authorImg,
  authorDes,
  
}) {
  const [isActive, setIsActive] = useState(false);

  const useGaEventsTracker = GaEventsTracker("External Links")

  const buttonChangeHandler = () => {
    setIsActive(!isActive);
  };

  const StructuredWarrants = () => {
    useGaEventsTracker('event', 'Episode 1', {'event_category': 'Animated Videos'})
  }

  const LiveMatrix = () => {
    useGaEventsTracker('event', 'Live Matrix', {'event_category': 'Animated Videos'})
  }

  const TelegramChannel = () => {
    useGaEventsTracker('event', 'Telegram', {'event_category': 'Animated Videos'})
  }

  const GetStarted = () => {
    useGaEventsTracker('event', 'Get Started', {'event_category': 'Animated Videos'})
  }

  return (
    <div className="videoinfo">
      {btnShow ? (
        <Link
          to={{
            pathname:
              "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fmqmy_aws%252Feducation%252Fwarrantvideosind%253Flang%253Den%2526playlistId%253DPLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3%2526index%253D5&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB",
          }}
          target="_blank"
        >
          <div className="fbSharebtn">
            <i className="fa fa-facebook" aria-hidden="true" />
            Share on Facebook
          </div>
        </Link>
      ) : (
        ""
      )}
      <div
        style={{
          fontFamily: "MCQGlobal_W_Md",
          marginBottom: "10px",
          fontSize: "24px",
        }}
      >
        {Maintitle}
      </div>
      <div className="videoinfo-title">
      
        {title} 
        </div>
      <p className="videoinfo-p" dangerouslySetInnerHTML={{__html:  para }}>
        
      </p>
      <p className="videoinfo-p" dangerouslySetInnerHTML={{__html:  para1 }}></p>
      <p className="videoinfo-p">
        {para2}
        <Link to={link1url == undefined ? "#" : link1url} target="_blank">
          {link1}
        </Link>
      </p>
      {ul1 ? (
        <ol>
          <li>{ul1}</li>
          <li>{ul2}</li>
          <li>{ul3}</li>
          <li>{ul4}</li>
        </ol>
      ) : (
        ""
      )}
      <p>
        <Link to={linkurl == undefined ? "#" : linkurl} target="_blank">
          {link}
        </Link>
      </p>
      {divData ? (
        <div className="mock-up">
          <div className="mock-up-row row">
            <div className="mock-up-btn mock-up-wasw">
              <Link
                className="innerbtn"
                to="/education/warrantvideosind/StructuredWarrants"
                target="_blank"
                onClick={StructuredWarrants}
              >
                What are structured warrants?
              </Link>
            </div>
            <div className="mock-up-btn mock-up-vtlm">
              <Link
                to="/tools/livematrix/"
                target="_blank"
                className="innerbtn"
                onClick={LiveMatrix}
              >
                Visit the Live Matrix
              </Link>
            </div>
            <div className="mock-up-btn mock-up-jotc">
              <Link
                to={{
                  pathname: "https://web.telegram.org/#/im?p=@Malaysiawarrants",
                }}
                target="_blank"
                className="innerbtn"
                onClick={TelegramChannel}
              >
                Join our Telegram channel
              </Link>
            </div>
          </div>
          <div className="mock-up-row row">
            <div className="mock-up-btn mock-up-jotc visible-xs">
              <Link
                to={{
                  pathname: "https://web.telegram.org/#/im?p=@Malaysiawarrants",
                }}
                target="_blank"
                className="innerbtn"
                onClick={TelegramChannel}
              >
                Join our Telegram channel
              </Link>
            </div>
            <div className="mock-up-btn mock-up-hts visible-xs">
              <Link
                to="/education/getstarted"
                target="_blank"
                className="innerbtn"
                onClick={GetStarted}
              >
                How to start trading warrants?
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <p style={{ marginBottom: "10px" }}></p>
      {btnShow ? (
        ""
      ) : (
        <Link
          to={{
            pathname:
              "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fmqmy_aws%252Feducation%252Fwarrantvideosind%253Flang%253Den%2526playlistId%253DPLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3%2526index%253D5&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB",
          }}
          target="_blank"
        >
          <div className="fbSharebtn">
            <i className="fa fa-facebook" aria-hidden="true" />
            Share on Facebook
          </div>
        </Link>
      )}
      {authorImg ? (
          <Accordion >
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={buttonChangeHandler}>
              <div  onClick={buttonChangeHandler}>{isActive ? "Hide" : "Show more"}</div>
            </Accordion.Header>
            <Accordion.Body>
              <hr/>
              <div className="img-sec">
                <img alt="img" src={authorImg} style={{ width: "120px" }} />
              </div>
              <div className="text-sec">
                <h2>{authorTitle}</h2>
                <p dangerouslySetInnerHTML={{__html:  authorDes }}></p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        ""
      )}
    </div>
  );
}

export default DataLayout;
