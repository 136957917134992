import React, { useState, useEffect,Fragment } from 'react';
import Highlights from './Highlights';
import "./highlight.css" 


const Highlight = (props) => {
    return ( 
        <Fragment> 
            <Highlights/>
        </Fragment> 
     );
}


export default Highlight;