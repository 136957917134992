import React, { useState, useEffect,Fragment } from 'react';
import Seminar from './Seminar';
import "./seminar.css"


const Seminars = (props) => {
    return ( 
        
        <Seminar/>
        
     );
}
 

export default Seminars;