import React, { useState, useEffect,Fragment } from 'react';
import UnderLyingCharts from './UnderLyingCharts';
import "./underlyingcharts.css"


const WarrantUnderLyingChart = (props) => {
    return ( 
        <Fragment>
        <UnderLyingCharts/>
        </Fragment> 
     );
}
 

export default WarrantUnderLyingChart;