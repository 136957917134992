import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"
import { get } from 'lodash'


Timeline(Highcharts);
const ColumnBarChart = (props) => {

    const { MarketTurnOverStock, MarketTurnOverIndex } = props;


    const arrTurnOverDate = MarketTurnOverIndex && MarketTurnOverIndex.map((data, idx) => (
        data && data.stats_date_obj && data.stats_date_obj.slice(0, 6)
    ))
    const arrTurnOverIndex = MarketTurnOverIndex && MarketTurnOverIndex.map((data, idx) => (
        data && parseFloat(data.pct)
    ))
    const arrTurnOverStock = MarketTurnOverStock && MarketTurnOverStock.map((data, idx) => (
        data && parseFloat(data.pct)
    ))

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: arrTurnOverDate,
            crosshair: true
        },
        yAxis: {
            min: "0",
            max: "100",
            tickInterval: 20,
            title: {
                text: "percentage (%)"
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.0,
                borderWidth: 0,
            }
        },
        credits: false,
        series: [{
            name: 'Index Warrants',
            color: '#DD5C10',
            data: arrTurnOverIndex,

        }, {
            name: 'Stock Warrants',
            color: '#F7941E',
            data: arrTurnOverStock

        }]
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
}
export default ColumnBarChart;
