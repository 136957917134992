import React, { useState, useEffect } from "react"
import "./photoDetail.css";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import { getGallery, getGallerybyId } from "../../../utils/photoGallrey.api";
import axios from "axios"
import { Base_Url } from "../../../utils/apiUrl";


const PhotoDetail = (props) => {

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('album_id');
    const [result, setResult] = useState()
    const [albumResult, setAlbumResult] = useState()
    const [albumImageResult, setAlbumImageResult] = useState()
    const qid = Date.now()

    useEffect(() => {
        getPhotoGallery()
        getPhotoGalleryById()
    }, [])

    function getPhotoGallery() {
        getGallery(qid)
            .then((res) => {
                var response = res.data.filter((d) => d.id === parseInt(id))
                setResult(response)
            })
    }

    const getPhotoGalleryById = async () => {
        const albumdata = await getGallerybyId(id, qid)
        setAlbumResult(albumdata.data)
        var resultData = albumdata.data
        var imgData = []
        if(resultData){                       
            for(var i = 0; i < resultData.length; i++){
                const response = await fetch(`${Base_Url}/apimqmy/photo?id=${resultData[i].id}`)
                const json = await response.json()
                imgData.push(json)
            }
            setAlbumImageResult(imgData)
        }
        
    }


    return (
        <>
            <div id="individualgallery" className="pageobj">
                <div className="breadcrumbbar">
                    <div className="container-fluid">
                        <ol className="breadcrumb">
                            <li><Link to="/home?news">Market news</Link></li>
                            <li><Link to="/marketnews/photogallery">Photo gallery </Link></li>
                            <li className="active">{result && result.map((d) => d.en_title)}</li>
                        </ol>
                    </div>
                </div>
                <div className="stripe-bg" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section">
                                <div className="page-header">
                                    <h1>{result && result.map((d) => d.en_title)}</h1>
                                </div>

                                <div id="photos" style={{ position: "relative" }}>
                                    <div id="photo-list" className="row">
                                        {albumImageResult && albumImageResult.map((d, index) =>
                                            <div className="col-xs-6 col-sm-3 photo-item" key={index}>
                                                <div className="photo-holder holder-center">
                                                    <ModalImage
                                                        small={`${d}`}
                                                        large={`${d}`}
                                                        hideDownload ={true}
                                                        hideZoom ={true}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr style={{ marginTop: "20px" }} className="hr-hmgbm" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PhotoDetail
