import React, { useState, useEffect } from 'react';
import './yourFirstSteps.css';
import YourFirstStepsModal from '../../../components/common/YourFirstStepsModal';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import SpeakModal from '../../WarrantEducation/SuccessSpeak/SpeakModal'
import FirstStepModal from './FirstStepModal';
import validator from 'validator';
import ErrorModal from '../../WarrantEducation/Seminar/ErrorModal';
import LocationSelect from '../Seminar/LocationSelect';

const YourFirstSteps = (props) => {
  const api = new API()
  const [email, setEmail] = useState();
  const [name, setName] = useState("");
  const [subEmail, setSubEmail] = useState();
  const [subName, setSubName] = useState("");
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState("");
  const [openMsg2, setOpenMsg2] = useState(false)
  const [openMsg, setOpenMsg] = useState(false)
  const [openMsg1, setOpenMsg1] = useState(false)
  const [emailMsg, setEmailMsg] = useState(false)
  const [locationData, setLocationData] = useState("")

  const onClickPopHandle = (url, value) => {
    setOpen(value)
    setUrl(url)
  }

  const validateEmail1 = (e) => {
    var email = e.target.value
    if (validator.isEmail(email)) {
      setSubEmail(email)
    } else {
      setEmailMsg(true)
      setSubEmail()
    }
  }

  function valueSubmit1(e) {
    if (!subEmail) {
      setEmailMsg(true)
    }
    else {
      submit(e)
    }
  }

  const submit = async (e) => {
    e.preventDefault();
    let data = {
      email: email
    }
    let res = await api.post(`${config.subscription}${"?p=register"}`, data)
      .then((res) => {
        setOpenMsg(true)
        setTimeout(() => setOpenMsg(false), [2500])
      })
      .catch((error) => error)
  }

  const validateEmail = (e) => {
    var email = e.target.value
    if (validator.isEmail(email)) {
      setEmail(email)
    } else {
      setEmail()
    }
  }

  function valueSubmit(e) {
    if (!email) {
      setOpenMsg2(true)
      setTimeout(() => setOpenMsg2(false), [1000])
    }
    else {
      submitLocation(e)
    }
  }

  const submitLocation = async (e) => {
    e.preventDefault();
    let data = {
      email
    }
    let res = await api.post(`${config.subscription}${"?p=register"}`, data)
      .then((res) => {
        regLocationCall(e)
        setOpenMsg1(true)
        setTimeout(() => setOpenMsg1(false), [2500])
      })
      .catch((error) => console.log(error))
  }

  const regLocationCall = async (e) => {
    e.preventDefault();
    let data = {
      email,
      name,
      location: locationData != "" ? locationData.join(",") : ""
    }
    let res = await api.post(config.regLocation, data)
    if (res) {
      console.log(res);
    }
  }

  return (
    <>
      <div id="firstStep" className="pageobj">
        <div className="welcometop">
          <div className="video-wrapper">
            <div className="welcomeimgtop">
              <img src="/img/landing/topBannerImg.jpg" />
            </div>
            

          </div>
        </div>
        <div className="jumbotron">
          <div className="container-fluid">
            <p className="lead" /><h1>Welcome to Macquarie Warrants.</h1><p />
            <p className="lead">Choose which option describes you best.</p>
          </div>
        </div>
        <nav className="tabnav container-fluid my-0">
          <div className="row">
            <div className="col-lg-4">
              <div className="navbox">
                <a href="#section1"><img className="imgtab" style={{ width: '100%' }} src="/img/landing/A1.png" /></a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="navbox">
                <a href="#section2"><img className="imgtab" style={{ width: '100%' }} src="/img/landing/A2.png" /></a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="navbox">
                <a href="#section3"><img className="imgtab" style={{ width: '100%' }} src="/img/landing/A3.png" /></a>
              </div>
            </div>
          </div>
        </nav>
        <div className="container-fluid">
          <div className="row section-bg">
            <div className="section-bg-left col-sm-6 col-xs-6" /><div className="section-bg-right col-sm-6 col-xs-6" />
          </div>
          <div className="section" id="tab1"><div id="section1" />
            <div className="page-header">
              <h1 style={{ textAlign: 'center', width: '100%' }} className="small">
                Structured warrants: the fastest growing investor product on Bursa Malaysia.
              </h1>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <div
                  className="video-img"
                  onClick={() => onClickPopHandle("https://www.youtube.com/embed/ogxKPL8YZwY", true)}
                >
                  <img style={{ width: '100%' }} src="/img/landing/B1.png" />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <div
                  className="video-img"
                  onClick={() => onClickPopHandle("https://www.youtube.com/embed/HEyp66NNBNY", true)}
                >
                  <img style={{ width: '100%' }} src="/img/landing/B2.png" />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <div
                  className="video-img"
                  onClick={() => onClickPopHandle("https://www.youtube.com/embed/wb7EiQfdvxc", true)}
                >
                  <img style={{ width: '100%' }} src="/img/landing/B3.png" />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <div
                  className="video-img"
                  onClick={() => onClickPopHandle("https://www.youtube.com/embed/5tUCAnOopqg", true)}
                >
                  <img style={{ width: '100%' }} src="/img/landing/B4.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="row section-bg">
            <div className="section-bg-left col-sm-6 col-xs-6" /><div className="section-bg-right col-sm-6 col-xs-6" />
          </div>
          <div className="section" id="tab2">
            <div id="section2" />
            <div className="page-header">
              <h1 style={{ textAlign: 'center', width: '100%' }} className="small">
                Are structured warrants suitable for you?<br />Find out how structured warrants differ from other investment options.
              </h1>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <div
                  className="video-img"
                  onClick={() => onClickPopHandle("https://www.youtube.com/embed/2itgroD4DGw", true)}
                >
                  <img style={{ width: '100%' }} src="/img/landing/C1.png" />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <div
                  className="video-img"
                  onClick={() => onClickPopHandle("https://www.youtube.com/embed/meI6OjbjkKM", true)}
                >
                  <img style={{ width: '100%' }} src="/img/landing/C2.png" />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <div
                  className="video-img"
                  onClick={() => onClickPopHandle("https://www.youtube.com/embed/0D4C5tp8wCc", true)}
                >
                  <img style={{ width: '100%' }} src="/img/landing/C3.png" />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <div
                  className="video-img"
                  onClick={() => onClickPopHandle("https://www.youtube.com/embed/t9J1GLrOMTk", true)}
                >
                  <img style={{ width: '100%' }} src="/img/landing/C4.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="row section-bg">
            <div className="section-bg-left col-sm-6 col-xs-6" /><div className="section-bg-right col-sm-6 col-xs-6" />
          </div>
          <div className="section" id="tab3">  <div id="section3" />
            <div className="row">
              <div className="col-sm-6 col-xs-12 tab3-left">
                <div className="register-text">
                  <p><span className="color-y">Watch now:</span> What do people</p><p>think about our seminars?</p>
                  <div className="row register-bg">
                    <div className="register-bg-left col-sm-6 col-xs-6" /><div className="register-bg-right col-sm-6 col-xs-6" />
                  </div>
                </div>
                <div
                  className="video-img"
                  onClick={() => onClickPopHandle("https://www.youtube.com/embed/lcy-UwR-g6c", true)}
                >
                  <img style={{ width: '100%' }} src="/img/landing/seminarVideo.png" />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12 tab3-right">
                <div className="register-text">
                  <p>Missed our previous seminars or</p><p>want to join one closer to you?</p>
                  <div className="row register-bg">
                    <div className="register-bg-left col-sm-6 col-xs-6" /><div className="register-bg-right col-sm-6 col-xs-6" />
                  </div>
                </div>
                <div className="location-seminar">
                  <img src="/img/landing/background.png" />
                  <div className="location-form">
                    <p className="location-form-title">Tell us your preferred location for our next seminar, and we will try our best to come!</p>
                    <input type="text" id="name" name="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <input type="text" id="email" name="email" placeholder="Email address" value={email} onChange={(e) => {
                      validateEmail(e)
                      setOpenMsg(false)
                    }} />
                    <span className="multiselect-native-select">
                      <div className="Input-tag">
                      <LocationSelect setLocationDataVal={setLocationData} />
                      </div>
                    </span>
                    <button
                      className="btn btn-primary"
                      id="submit-location-form"
                      onClick={(e) => valueSubmit(e)}
                    >Submit</button>
                    <div>
                    </div>
                    <p style={{ textAlign: 'center', marginTop: '10px' }} className="small">Also signs you up for our daily newsletter. Be the first to receive market updates and seminar invitations.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section" id="tab4">
          <p>Subscribe to our newsletter and follow us on Facebook and Telegram!
            Receive updates on upcoming seminars and daily market highlights.</p>
        </div>
        <div className="section" id="tab5">
          <div className="subscribe-box">
            <div className="signup-layer">
              <div>
                <h3>Subscribe now!</h3>
              </div>
              <input type="text" id="name" name="name" placeholder="Name" value={subName} onChange={(e) => setSubName(e.target.value)} />
              <input type="text" id="email" name="email" placeholder="Email address" value={subEmail} onChange={(e) => {
                validateEmail1(e)

                setEmailMsg(false)
              }} />
              {emailMsg && <div className="warning">Invalid email</div>}
              <button
                className="btn btn-primary"
                id="submitBtn"
                onClick={(e) => valueSubmit1(e)}
              >Submit</button>
            </div>
            <div style={{ paddingBottom: '30px' }}>
              <table style={{ margin: 'auto' }}>
                <tbody><tr>
                  <td>
                    <a href="https://www.facebook.com/MalaysiaWarrants/"><img style={{ width: '40px', margin: '0 4px 0 0' }} src="/img/bullet/facebook_icon.png" /></a>
                  </td>
                  <td>
                    <a href="https://t.me/MalaysiaWarrants"><img style={{ width: '40px', margin: '0 8px 0 4px' }} src="/img/landing/telegramLogo.png" /></a>
                  </td>
                  <td>
                    <div>
                      <p style={{ marginBottom: '0px' }}>Follow us on</p>
                      <p style={{ marginBottom: '0px' }}>Facebook and Telegram</p>
                    </div>
                  </td>
                </tr>
                </tbody></table>
            </div>
          </div>
          <div className="subscribe-disclaimer">
            <p className="small">By clicking “Submit”, you confirm that you would like to receive Macquarie’s daily newsletter and invitations for warrant seminars, and you acknowledge that Macquarie’s website administrators may have access to your personal data for the processing of such data on behalf of Macquarie Capital Securities Malaysia Sdn Bhd and other companies of Macquarie group (“Macquarie”). You may view Macquarie’s privacy policy with regard to collection and management of personal data via the links at the bottom of this page.</p>
          </div>
          <div id="text-content" style={{ display: 'none' }}>
            <div className="text_subscribe_registration_success">You have successfully registered for our daily newsletter! btgfr</div>
          </div>
        </div>
      </div>
      <YourFirstStepsModal
        Url={url} Open={open} OpenModal={() => setOpen(!true)} />
      {openMsg && <SpeakModal open={openMsg} />}
      {openMsg1 && <FirstStepModal open={openMsg1} />}
      {openMsg2 && <ErrorModal
        open={openMsg2}
        setOpenMsg={setOpenMsg2}
      />}
    </>
  );
}
export default YourFirstSteps;
