import React, { useState, useEffect, useRef } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import NewsComp from '../../../components/common/NewsComp';


const DailyHighlights = () => {
    const api = new API();
    const [dailyHighlight, setDailyHighlight] = useState([]);
    const [selectDate,setSelectDate] = useState("")
    const param = "todayhighlight"
    const dataslider = useRef()
    const getDayHighlight = async () => {
        let dailyHighlight = await api.get(config.marketDailyHighLight, true, { sp: param, start: "0",... selectDate && {startdate: selectDate} , qid: Date.now() })
        let dailyHighlightt = get(dailyHighlight, 'newsList', [])
        setDailyHighlight(dailyHighlightt)
        dataslider.current.slickGoTo(0)
    };
    useEffect(() => {
        getDayHighlight()
    }, [])

    useEffect(()=>{
    if(selectDate !== "")
    {
    getDayHighlight()
    }
}
    ,[selectDate])

    return (
     <NewsComp 
     param={param}
     data={dailyHighlight}
     section="Market news"
     breadcrumb="Daily highlights" 
     title="Daily highlights"
     subtitle="Keep up to date with the latest news, with updates from Macquarie research and more."
     dataIndex={1}
     setSelectDate={setSelectDate}
     dataslider={dataslider}
     />   
    );
}
export default DailyHighlights;
