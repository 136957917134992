import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import WhyTarde from './WhyTrade';
import HowTarde from './HowTrade';
import HowCalculate from './HowCalculate';
import WarrantIssue from './WarrantIssue';
import OutsideClickHandler from "react-outside-click-handler";

const IndexModal = ({ open, setShow, keyValue }) => {
    return (
        <>
            
            <Modal
                centered isOpen={open}
                className='btm-modal-index'
            >
                <OutsideClickHandler>
                    <ModalBody>
                        <div className="overlay-inner">
                                <img
                                    className="btn-close"
                                    style={{}}
                                    src="/img/bullet/icon-close-black.png"
                                    onClick={() => setShow(false)}
                                />
                                {keyValue === "Why-trade-index-warrants" && <WhyTarde />}
                                {keyValue === "How-to-trade-index-warrants" && <HowTarde />}
                                {keyValue === "How-to-calculate" && <HowCalculate />}
                                {keyValue === "Index-warrants-issued-mq" && <WarrantIssue />}
                        </div>
                    </ModalBody>
                </OutsideClickHandler>
            </Modal>
        </>
    );
};
export default IndexModal;
