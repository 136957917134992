import React, { useState, useEffect } from 'react';
import HowToStarted from './HowToStarted';
import './howToStarted.css';


const HowToStart = (props) => {
    return ( 
        <HowToStarted/>
     );
}
 
export default HowToStart;