import React, { useState, useEffect, useRef } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import NewsComp from '../../../components/common/NewsComp';


const OvernightMarkets = () => {
    const api = new API();
    const [overnightMarket, setOvernightMarket] = useState([]);
    const [selectDate,setSelectDate] = useState("")
    const param = "overnightmarkets"
    const dataslider = useRef()
    const getOvernightMarket = async () => {
        let overnightMarket = await api.get(config.marketDailyHighLight, true, { sp: param, start: "0",... selectDate && {startdate: selectDate}, qid: Date.now() })
        let overnightMarkett = get(overnightMarket, 'newsList', [])
        setOvernightMarket(overnightMarkett)
        dataslider.current.slickGoTo(0)
    };
    useEffect(() => {
        getOvernightMarket()
    }, [])

    useEffect(()=>{
        if(selectDate !== "")
        {
        getOvernightMarket()
        }
    }
    ,[selectDate])

    return (
     <NewsComp 
     param={param}
     data={overnightMarket}
     section="Market news"
     breadcrumb="Overnight market wrap"
     title="Overnight market wrap"
     subtitle="Hear about the latest news in Global markets that might affect your trading day."
     dataIndex={2}
     setSelectDate={setSelectDate}
     dataslider={dataslider}
     />   
    );
}
export default OvernightMarkets;
