import React, { useState, useEffect } from 'react';
import '../TradedWarrant/tradedWarrant.css';
import { trededTab, trededGainerTab } from '../../../utils/constants'
import Gainer from './gainer';
import Loser from './Loser';
import WarrantIndex from './warrantIndex';
import WarrantStock from './warrantStock';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import moment from 'moment';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const TopTradedWarrant = (props) => {

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const api = new API()

  const [activeTab, setActiveTab] = useState(trededTab['topIndex'])
  const [activeTab1, setActiveTab1] = useState(trededGainerTab['topGainers'])
  const [topTradedWarrantList, setTopTradedWarrantList] = useState([])
  const [topLosersList, setTopLosersList] = useState([])
  const [topGainersList, setTopGainersList] = useState([])
  const [topIndexList, setTopIndexList] = useState([])
  const [topStockList, setTopStockList] = useState([])

  const [advImgVal, setAdvImgVal] = useState([])


  useEffect(() => {
    getHomePopup()
  }, [])
  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };


  const isActive = (tab) => {
    if (activeTab === tab) {
      return "active";
    }
    return "";
  }

  const isActive1 = (tab) => {
    if (activeTab1 === tab) {
      return "active";
    }
    return "";
  }


  const renderTab = () => {
    switch (activeTab) {
      case trededTab.topStock:
        return <WarrantStock TopStockList={topStockList} />
        break;
      case trededTab.topIndex:
        return <WarrantIndex TopIndexList={topIndexList} />
        break;
      default:
        return null
        break;
    }
  }

  const renderTab1 = () => {
    switch (activeTab1) {
      case trededGainerTab.topGainers:
        return <Gainer TopGainersList={topGainersList} />
        break;
      case trededGainerTab.topLosers:
        return <Loser TopLosersList={topLosersList} />
        break;
      default:
        return null
        break;
    }
  }


  useEffect(() => {
    getTopTradedWarrantData()
  }, [])


  const getTopTradedWarrantData = async () => {
    const timestamp = Date.now(); // This would be the timestamp you want to format
    let topTradedWarrantResult = await api.get(config.marketTurnOver, true, { type: "ranking", qid: timestamp })
    let topTradedWarrantRes = get(topTradedWarrantResult, [])
    let topIndexRes = get(topTradedWarrantResult, "topindex", [])
    let topStockRes = get(topTradedWarrantResult, "topstock", [])
    let topGainersRes = get(topTradedWarrantResult, "gainer", [])
    let topLosersRes = get(topTradedWarrantResult, "loser", [])
    setTopTradedWarrantList(topTradedWarrantRes)
    setTopIndexList(topIndexRes)
    setTopStockList(topStockRes)
    setTopGainersList(topGainersRes)
    setTopLosersList(topLosersRes)
  }

  return (
    <div id="toptradewarrant" className="pageobj" style={{}}>
      <h1 id="ric" style={{ display: "none" }} />
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="mr-1">
              <Link to="/home?dwstat">Warrant statistics </Link>
            </li>
            <li className="active">Top traded warrants</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="topimg sm-none xs-none">
          <img src="/img/bg/top-toptradeddw.jpg" alt="top-toptradeddw" />
        </div>
        <div className="row">
          <div className="col-md-8 sm-w-100">
            <div className="section">
              <div className="page-header">
                <h1>Top traded warrants</h1>
              </div>
              <p>
                Shows the top traded warrants with the highest volume for the
                current day.
              </p>
              <div className="tablist">
                <div id="topTabs" className="tablist-left">
                  <table>
                    <tbody>
                      <tr>
                        <td
                          onClick={() => setActiveTab(trededTab.topIndex)}
                          className={`${isActive(trededTab.topIndex)}`}
                        >
                          <div
                            data-toggle="tab"
                            className="tablist-item"
                            value="index"
                          >
                            <div className="tablist-item-txt">
                              Top 10 index warrants
                            </div>
                          </div>
                        </td>
                        <td
                          className={`${isActive(trededTab.topStock)} `}
                          onClick={() => setActiveTab(trededTab.topStock)}
                        >
                          <div
                            data-toggle="tab"
                            className="tablist-item"
                            value="stock"
                          >
                            <div className="tablist-item-txt">
                              Top 10 stock warrants
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="tablist-right sm-none xs-none">
                  <div className="lastupdatebox hidden-xs hidden-sm">
                    Last update :{" "}
                    <span>
                      {moment(new Date(topIndexList && topIndexList[0] && topIndexList[0].update_time)).format("DD MMM YY")}
                    </span>
                  </div>
                </div>
              </div>
              {renderTab()}
            </div>
            <div className="section">
              <div className="page-header">
                <h1>Top movers</h1>
              </div>
              <p>
                Shows warrants with the biggest price changes for the current
                day.
              </p>
              <div id="glTabs" className="tablist">
                <div className="tablist-left">
                  <table>
                    <tbody>
                      <tr>
                        <td
                          onClick={() =>
                            setActiveTab1(trededGainerTab.topGainers)
                          }
                          className={`${isActive1(trededGainerTab.topGainers)}`}
                        >
                          <div
                            data-toggle="tab"
                            className="tablist-item"
                            value="gainer"
                          >
                            <div className="tablist-item-txt">Top gainers</div>
                          </div>
                        </td>
                        <td
                          onClick={() =>
                            setActiveTab1(trededGainerTab.topLosers)
                          }
                          className={`${isActive1(trededGainerTab.topLosers)}`}
                        >
                          <div
                            data-toggle="tab"
                            className="tablist-item"
                            value="loser"
                          >
                            <div className="tablist-item-txt">Top losers</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="tablist-right  sm-none xs-none">
                  <div className="lastupdatebox hidden-xs hidden-sm">
                    Last update :{" "}
                    <span>
                      {moment(new Date(topGainersList && topGainersList[0] && topGainersList[0].update_time)).format("DD MMM YY")}
                    </span>
                  </div>
                </div>
              </div>
              {renderTab1()}
            </div>
          </div>
          <div className="col-md-4 sm-none xs-none">
            <Slider {...settings} className="ad-section custom">
              {advImgVal &&
                advImgVal.map((data, index) => {
                  return (
                    <a target="_blank" href={data.link} key={index}>
                      <div>
                        <img src={data.imgSrc} />
                      </div>
                    </a>
                  );
                })}
            </Slider>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Live matrix</h2>
              </div>
              <p className="section-p">
                Makes trading warrant easy by showing investors where our bid
                prices will be throughout the day
              </p>
              <div className="section-qlink-img section-qlink-lmatrix">
                <Link to="/tools/livematrix/" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" alt="arrow" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant search</h2>
              </div>
              <p className="section-p">
                Find the right warrant according to your investment criteria.
              </p>
              <div className="section-qlink-img section-qlink-dwsearch">
                <Link to="/tools/warrantsearch" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant calculator</h2>
              </div>
              <p className="section-p">
                Estimates your future warrant price given a change in underlying
                price, time or implied volatility
              </p>
              <div className="section-qlink-img section-qlink-dwcalculator">
                <Link
                  to="/tools/warrantcalculator"
                  className="section-qlink-achor"
                >
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopTradedWarrant;