import React from "react"
function HowTarde(){
    return(
        <>
        
                        <h2  className="ppc-h">
                            How to trade index warrants?
                        </h2>
                        <h5 className="text-bold">Index call warrant</h5>
                        <p>If you have a bullish view on the S&amp;P 500®, you can buy a call warrant e.g. SP500-CA. Example as follow:</p>
                        <img className="ppc-table" src="/img/education/graphnew4t.png"/>
                        <h5 className="text-bold">Index put warrant</h5>
                        <p>If you have a bearish view on the FBMKLCI, you can buy a put warrant over FBMKLCI e.g. FBMKLCI-HW. Example as follow:</p>
                        <img className="ppc-table" src="/img/education/graphnew3t.png"/>
                        <p>
                           Assuming your view was right, if you have bought the put warrant instead of shorting the underlying index, you would have made a 12.5% return, almost 5 times that of the index level move.<br /><br />
                           These are examples of gearing at work and the advantage that warrants offer i.e. a greater potential profit as a percentage of your invested capital. However, gearing works in both directions, so greater potential returns also come with greater risk.
                        </p>
                    
        </>
    )
}
export default HowTarde