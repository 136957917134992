import React,{useState,useEffect} from 'react'
import "./news.css";
import { Link } from 'react-router-dom';
import '../../../assets/css/swiper.css';
import { Carousel } from 'react-responsive-carousel';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import DatePicker from 'react-date-picker';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import moment from "moment";

const linkData = [
    {
    name:"Subscribe",
    link:"/marketnews/subscribe",
    short:"Don't want to miss any important news? Subscribe to our newsletter today!",
    imgClass:"section-qlink-subscribe"},
    {
    name:"Daily highlights",
    link:"/marketnews/dailyhighlights",
    short:"Keep up to date with the latest news, with updates from Macquarie research and more.",
    imgClass:"section-qlink-todayhighlight"},
    {
    name:"Overnight market wrap",
    link:"/marketnews/overnightmarkets",
    short:"Hear about the latest news in Global markets that might affect your trading day.",
    imgClass:"section-qlink-marketwrap"
    },
    {
    name:"End of day insights",
    link:"/marketnews/eodinsights",
    short:"What happened on the warrant market today? Our exclusive commentary is updated by 6pm each day.",
    imgClass:"section-qlink-marketcommentary"},
    
]
const banners = [
    "",
    "/img/bg/top-todayhighlight.jpg",
    "/img/bg/top-marketwrap.jpg",
    "/img/bg/top-commentary.jpg"
]



export default function NewsComp(props) {
    const {param,data,section,breadcrumb,title,subtitle,dataIndex,setSelectDate,dataslider} = props
    const api = new API();
    const [advImgVal, setAdvImgVal] = useState([])

    useEffect(() => {
        getHomePopup()
      }, [])
      const getHomePopup = async () => {
        let homePopupResult = await api.get(
          config.advImgPopup,
          true,
        );
        let items = [];
        if(homePopupResult.length > 0){
          for (let i = 0; i < homePopupResult.length; i++) {
            let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
            items.push({
              imgSrc: advImgData,
              link: homePopupResult[i].link
            })
          }
          setAdvImgVal(items)
        }
      };
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [myNightMarketWrap, setmYNightMarketWrap] = useState([]);
    const [startDate, setStartDate] = useState(null)
    const settings = {
        autoplay: false,
        infinite: false,
        rows:5,
        slidesToShow:1,
        slidesToScroll: 1,
        dots: true,
        //vertical: true,
    };
    const settingss = {
        autoplay: true,
        infinite: true,
        slidesToShow:1,
        slidesToScroll: 1,
        dots: false,
        arrow: false,
    };
    let currentData = myNightMarketWrap.length == 0 ? data : myNightMarketWrap
    useEffect(() => {
        currentData = myNightMarketWrap.length == 0 ? data : myNightMarketWrap
      return () => {
      }
    }, [myNightMarketWrap])

    const dateHandler = (date) => {
        // setStartDate(date)
        
        setSelectDate(moment(date).format('YYYY-MM-DD'))
        // var myCheck = data.filter((dateData) => {
        //     return new Date(dateData.date).getTime() <= new Date(date).getTime()
        // })
        // setmYNightMarketWrap(myCheck)
        
    }
    const extractContent = (s) => {
        var span = document.createElement("span");
        span.innerHTML = s;
        return span.textContent || span.innerText;
    }
  return (
    <div id="dailycommentary" className="pageobj" style={{}}>
      <h1 id="ric" style={{ display: "none" }} />
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?news">{section}</Link>
            </li>
            <li className="active">{breadcrumb}</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="topimg hidden-sm hidden-xs">
          <img src={banners[dataIndex]} />
        </div>
        <div className="row">
          <div className="col-md-8">
            <div
              id="newssection"
              style={{ position: "relative" }}
              className="section"
            >
              <div className="page-header">
                <h1>{title}</h1>
              </div>
              <p className="section-p">{subtitle}</p>
              <div id="commentarytbl" className="newstbl">
                <div className="newstbl-htxt">Latest News</div>
                <div className="newstbl-content">
                  <div
                    id="newsswiper"
                  >
                    <Slider {...settings} ref={dataslider}>
                      {currentData &&
                        currentData.map((curValue, curIdx) => (
                          <div key={curIdx} className="newstbl-row rowtmpl">
                            <div className="newstbl-col-left">
                              <span className="newstbl-col-date">
                                {curValue.date}
                              </span>
                            </div>
                            <div className="newstbl-col-right">
                              <h3
                                className="newstbl-col-dt"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    curValue != undefined
                                      ? curValue.en_title
                                      : "",
                                }}
                              />
                              <p
                                className="newstbl-col-dd"
                                style={{ overflowWrap: "break-word" }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    curValue != undefined
                                      ? curValue.en_short
                                      : "",
                                }}
                              />

                              <div className="newstbl-col-rm">
                                <Link
                                  to={`/marketnews/highlight?cat=${param}&id=${curValue.id}`}
                                  className="btn btn-primary btn-default swiper-no-swiping"
                                  target="_blank"
                                >
                                  Read more
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Slider>
                  </div>
                </div>
                <div className="newstbl-bttm">
                  <div
                    id="datepicker"
                    className="newstbl-cal-icon input-group date"
                  >
                    <input type="hidden" className="form-control" />
                    <div className="input-group-addon">
                      <label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => dateHandler(date)}
                          maxDate={new Date()}
                          minDate={
                            new Date(
                              Date.parse(
                                `01/01/${moment(new Date()).year() - 3}`
                              )
                            )
                          }
                          value={startDate}
                        />
                        <img src="/img/bullet/icon-cal.png" />
                      </label>
                    </div>
                  </div>
                  {/* <ActivePagination currentPage={currentPage} onPageChange={(selectedIndex) => setCurrentPage(selectedIndex)} dataLength={NUM_OF_RECORDS} limit={LIMIT}/> */}
                </div>
              </div>
              <hr className="hr-hmgbm"></hr>
            </div>
          </div>
          <div className="hidden-xs hidden-sm col-md-4">
            <Slider {...settingss} className="ad-section custom">
              {advImgVal &&
                advImgVal.map((data, index) => {
                  return (
                    <a target="_blank" href={data.link} key={index}>
                      <div>
                        <img src={data.imgSrc} />
                      </div>
                    </a>
                  );
                })}
            </Slider>
            {linkData.map((item, index) => {
              if (index !== dataIndex)
                return (
                  <div className="section qlink-section" key={index}>
                    <div className="page-header">
                      <h2 className="small">{item.name}</h2>
                    </div>
                    <p>{item.short}</p>
                    <div className={`section-qlink-img ${item.imgClass}`}>
                      <Link to={item.link} className="section-qlink-achor">
                        <img src="/img/bullet/qlink-achor.png" />
                      </Link>
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
