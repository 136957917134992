import React, { useEffect } from "react";
import "./FooterInfo.css";


const ImportantInformation =()=> {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <div id="importantinformation" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              Macquarie
            </li>
            <li className="active">Important information</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>Important information</h1>
          </div>

          <p className="section-txt">
            <div className="title">This Website</div>
            <p>This website is operated by and on behalf of Macquarie Capital Securities (Malaysia) Sdn.
              Bhd. ("<strong>MCSM</strong>").</p>
            <p>The information on this site is subject to change without notice and,
              accordingly, MCSM recommends that you make direct contact with its staff if you have
              questions on the content of this site.</p>
            <p>The information on this site is directed and available to
              residents of Malaysia only, and is not an offer or solicitation for the purchase of securities,
              units or investments, unless expressly stated otherwise. While MCSM provides the information
              in good faith, it accepts no responsibility for the accuracy, completeness or timeliness
              of the information.</p>
          </p>

          <div className="title">Website links</div>
          <p>This site may contain links to other web sites operated by entities which do not belong to the Macquarie
            group. These links have been provided solely for you to obtain further information about other relevant
            products and entities in the market. MCSM has no control over the information on these sites or the products
            or services on them, and therefore makes no representations regarding the accuracy or suitability of the
            information, services, or products described on them. You are advised to make your own enquiries in relation
            to third parties described or linked on this site. Inclusion of a link to a third party site should not be
            construed as that party's endorsement of this site.</p>

          <p>By linking to sites operated by third parties, MCSM is not authorising the reproduction of any material
            on such sites, as such material may be the subject of intellectual property rights.</p>

          <p>The opinions, ideas and strategies expressed by any third parties on these sites represent the views
            of the third parties and not of Macquarie or its affiliates. Neither Macquarie nor any of its affiliates
            shall be liable for the content of information provided by the third parties.</p>

          <div className="title">Software applications accessible from this site</div>
          <p>Some of the software programs that may be downloaded via links from this site are products belonging to
            third parties. You use such third party software at your own risk. Such software is likely to be subject
            to licensing terms imposed by the owner of the software.</p>

          <p>To the full extent permitted by law, the Macquarie group excludes all liability in relation to using
            or downloading any software (third party or otherwise) that may be accessed from this site.
            The Macquarie group makes no representations and provides no warranties in relation to such software.
            In particular, to the full extent permitted by law, the Macquarie group excludes all liability for any
            damage (including, without limitation, loss of data, interruption to business and loss of profits)
            resulting from viruses or any other consequence of using or downloading any software (third party
            or otherwise) accessible via this site.</p>

          <div className="title">Copyright and Trademarks</div>
          <p>MSCM is either the owner or licensed user of the copyright in the material on this site. You may
            not reproduce, adapt, upload, link, frame, broadcast, distribute or in any way transmit the material on
            this site without the written consent of MCSM, other than to the extent necessary to view the material
            or as permitted by law.</p>

          <p>The Macquarie name and all its associated trademarks (including, without limitation, the Holey Dollar
            device mark) are trademarks of the Macquarie group.</p>

          <div className="title">Privacy</div>
          <p>Macquarie group is committed to providing the highest quality of financial services within a trusted
            environment. Please read the privacy policy statement herein to understand how your personal information
            will be treated when you use this site.</p>
        </div>
      </div>


    </div>

  );
}
export default ImportantInformation;