import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import UnAuthorisedApp from "./UnAuthorisedApp";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <React.Fragment>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:100,300,400"
        rel="stylesheet"
      />
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css"
        rel="stylesheet"
      />

      <ToastContainer />
      <Router>
        <UnAuthorisedApp />
      </Router>
    </React.Fragment>
  );
};

export default App;
