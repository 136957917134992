import React from "react"
import { Modal, ModalBody } from 'reactstrap';
function SubmitModal({ open ,text}) {
    var alert = '';
    if(typeof text == 'string'){
        alert =1;
    }else{
        if(text.error){
            alert=text.error.message.indexOf('duplicate')!=-1?2:3;
        }
    }
    return (
        <>
            <Modal style={{ position: 'relative' }} centered isOpen={open} className="modal-lg mt-5 success-speak">
                <ModalBody >
                    <h4  style={{ display: alert==3?'block':'none' }}>We apologize that the seminar is FULL. We will contact you if there are any further seats available.</h4>
                    <h4 style={{ display: alert==1?'block':'none' }}>Your seminar registration was successful. Thank you</h4>
                    <h4 style={{ display: alert==2?'block':'none' }}>You have already registered this seminar.</h4>
                </ModalBody>
            </Modal>
        </>
    )
}
export default SubmitModal;
