import React, { useState, useEffect } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import moment from "moment"
import { Spinner } from "reactstrap"
import PieChart from './PieChart';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TurnOverDist = (props) => {

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const api = new API()
  const date = new Date();
  const [topTradedUnderlyingsList, setTopTradedUnderlyingsList] = useState([])
  const [topTradedUnderlyingsDate, setTopTradedUnderlyingsDate] = useState([])
  const [result, setResult] = useState()
  const [selectDate1, setSelectDate1] = useState()
  const [selectDate2, setSelectDate2] = useState()
  const [selectDate3, setSelectDate3] = useState()
  const [selectDate4, setSelectDate4] = useState()
  const [selectDate5, setSelectDate5] = useState()

  const [advImgVal, setAdvImgVal] = useState([])


  useEffect(() => {
    getHomePopup()
  }, [])

  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };

  useEffect(() => {
    getTurnOverDistributionResult()
  }, [])

  useEffect(() => {
    if (result) {
    }
  }, [result])

  const getTurnOverDistributionResult = async () => {
    let turnOverDistributionResult = await api.get(config.turnOverDistribution, true, { type: "turnoverdist", qid: Date.now() })
    let turnOverDistList = get(turnOverDistributionResult, "turnoverdist", [])
    let topTradedUnderlyingsListRes = get(turnOverDistList, 'content', [])
    let topTradedUnderlyingsDateRes = get(turnOverDistList, 'stats_date_list', [])
    
    

    var dateArray = turnOverDistributionResult.turnoverdist.stats_date_list
    var contentArray = turnOverDistributionResult.turnoverdist.content
    setSelectDate1(dateArray[0].dateKey)
    setSelectDate2(dateArray[1].dateKey)
    setSelectDate3(dateArray[2].dateKey)
    setSelectDate4(dateArray[3].dateKey)
    setSelectDate5(dateArray[4].dateKey)
    
    var newData = contentArray.slice(0, 5)
    var cost = newData.map((d) => parseFloat(d[dateArray[0].dateKey].replace(/,/g, '')))
    const sum = cost.reduce((partialSum, a) => partialSum + a, 0);
    const value = 100 - sum
    
    var newObject = {
      "underlying_ticker": "Others",
    }
      newObject[`${dateArray[0].dateKey}`]= `${value.toFixed(2)}`
    var finalData = [...newData, newObject]
    setResult(finalData)
    setTopTradedUnderlyingsList(topTradedUnderlyingsListRes)
    setTopTradedUnderlyingsDate(topTradedUnderlyingsDateRes) 
   

  }
  return (
    <div id="turnoverdist" className="pageobj" style={{}}>
      <h1 id="ric" style={{ display: "none" }} />
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwstat">Warrant statistics</Link>
            </li>
            <li className="active">Turnover distribution</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="topimg sm-none xs-none">
          <img src="/img/bg/top-turnoverdist.jpg" />
        </div>
        <div className="row">
          <div className="col-md-8 sm-w-100">
            <div className="section">
              <div className="page-header">
                <h1>Turnover distribution</h1>
              </div>
              <p>
                The most popular underlyings in the warrant market for the day.
              </p>
              <div className="chartbox-turn-over-dist">
                <h2 className="chartdate small">
                  {" "}
                  Today's underlying -{" "}
                  <span>{moment(date).format("DD MMM YY")}</span>
                </h2>
                {!result && <Spinner />}
                {result && (
                  <div className="chartbox-legend-turn-over-dist ">
                    <div
                      className="chartbox-legend-item-turn-over-dist"
                      style={{}}
                    >
                      <span className="chartbox-legend-square-turn-over-dist cbg00" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_0">
                        {result && result.map((d) => d.underlying_ticker)[0]}
                      </span>
                    </div>
                    <div
                      className="chartbox-legend-item-turn-over-dist"
                      style={{}}
                    >
                      <span className="chartbox-legend-square-turn-over-dist cbg01" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_1">
                        {result && result.map((d) => d.underlying_ticker)[1]}
                      </span>
                    </div>
                    <div
                      className="chartbox-legend-item-turn-over-dist"
                      style={{}}
                    >
                      <span className="chartbox-legend-square-turn-over-dist cbg02" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_2">
                        {result && result.map((d) => d.underlying_ticker)[2]}
                      </span>
                    </div>
                    <div
                      className="chartbox-legend-item-turn-over-dist"
                      style={{}}
                    >
                      <span className="chartbox-legend-square-turn-over-dist cbg03" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_3">
                        {result && result.map((d) => d.underlying_ticker)[3]}
                      </span>
                    </div>
                    <div
                      className="chartbox-legend-item-turn-over-dist"
                      style={{}}
                    >
                      <span className="chartbox-legend-square-turn-over-dist cbg04" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_4">
                        {result && result.map((d) => d.underlying_ticker)[4]}
                      </span>
                    </div>
                    <div
                      className="chartbox-legend-item-turn-over-dist"
                      style={{}}
                    >
                      <span className="chartbox-legend-square-turn-over-dist cbg05" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_5">
                        Others
                      </span>
                    </div>
                  </div>
                )}
                <div className="chart_sec">
                  {" "}
                  {result && <PieChart result={result} date={selectDate1} />}
                </div>
              </div>
              <div className="tblh">
                <div className="tblh-left">
                  <p
                    style={{ marginBottom: "0px", fontSize: "16px" }}
                    className="small"
                  >
                    Top traded underlyings
                  </p>
                </div>
              </div>
              <div id="topTbl" style={{ position: "relative" }}>
                {topTradedUnderlyingsDate.length === 0 && <Spinner />}
                <table className="table table-striped table-closed">
                  <thead>
                    {topTradedUnderlyingsDate.length !== 0 && (
                      <tr>
                        <th>Underlying</th>
                        {topTradedUnderlyingsDate.map(
                          (underLyingData, underLyingIndex) => (
                            <th
                              className={`${"date_"} ${underLyingIndex}`}
                              key={underLyingIndex}
                            >
                              {underLyingData.dateKey}
                            </th>
                          )
                        )}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {topTradedUnderlyingsList &&
                      topTradedUnderlyingsList.map(
                        (underLyingListData, underLyingListIndex) => (
                          <tr
                            className="rowtmpl"
                            style={{}}
                            key={underLyingListIndex}
                          >
                            <td>
                              <Link
                                className="underlying_ticker"
                                to={`/tools/underlying/${underLyingListData.underlying_ric}`}
                              >
                                {underLyingListData.underlying_name}
                              </Link>
                            </td>
                            <td>
                              {underLyingListData[`${selectDate1}`] || "-"}%
                            </td>
                            <td>
                              {underLyingListData[`${selectDate2}`] || "-"}%
                            </td>
                            <td>
                              {underLyingListData[`${selectDate3}`] || "-"}%
                            </td>
                            <td>
                              {underLyingListData[`${selectDate4}`] || "-"}%
                            </td>
                            <td>
                              {underLyingListData[`${selectDate5}`] || "-"}%
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
                <p>
                  Last updated: <span>{moment(date).format("DD MMM YY")}</span>
                </p>
                <p>
                  Note: The above tables show the percentage of total warrant
                  turnover contributed by each of these underlyings on the
                  listed dates.
                </p>
              </div>
              <hr className="hr-hmgbm" />
              <p className="small">
                All market data is delayed 15 mins unless otherwise indicated by
                timestamp.
              </p>
            </div>
          </div>
          <div className="col-md-4 sm-none xs-none">
            <Slider {...settings} className="ad-section custom">
              {advImgVal &&
                advImgVal.map((data, index) => {
                  return (
                    <a target="_blank" href={data.link} key={index}>
                      <div>
                        <img src={data.imgSrc} />
                      </div>
                    </a>
                  );
                })}
            </Slider>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Live matrix</h2>
              </div>
              <p className="section-p">
                Makes trading warrant easy by showing investors where our bid
                prices will be throughout the day
              </p>
              <div className="section-qlink-img section-qlink-lmatrix">
                <Link to="/tools/livematrix/" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant search</h2>
              </div>
              <p>
                Find the right warrant according to your investment criteria.
              </p>
              <div className="section-qlink-img section-qlink-dwsearch">
                <Link to="/tools/warrantsearch" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant calculator</h2>
              </div>
              <p>
                Estimates your future warrant price given a change in underlying
                price, time or implied volatility
              </p>
              <div className="section-qlink-img section-qlink-dwcalculator">
                <Link
                  to="/tools/warrantcalculator"
                  className="section-qlink-achor"
                >
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TurnOverDist;
