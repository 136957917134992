import "./technicalevent.css";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom"
import { config, Base_Url } from "../../../utils/apiUrl";
import { ArrowRight } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import moment from "moment"
import SelectComp from "./selectComp";
// import "./resizer"


const TechnicalEvent = (props) => {
  const [data, setData] = useState([]);
  const [compData, setCompData] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [url, setUrl] = useState("");
  const [chartAPI, setChartAPI] = useState("");
  const [recUrl, setRecUrl] = useState(`${Base_Url}/apimqmy/RecogniaJSON?list=1&qid=${Date.now()}`)
  const [ric, setRic] = useState("");
  const { id } = useParams();
  const [ricId, setRicId] = useState(id);
  const qid = Date.now()
  const [exchVal, setExchVal] = useState("")
  const [short, setShort] = useState("")
  const [longOptions, setlongOptions] = useState([])
  const [tOptions, settOptions] = useState([])
  const [searchKey, setsearchKey] = useState("")
  const [oData, setoData] = useState([])
  const [sortA, setsortA] = useState(null)
  const [sortB, setsortB] = useState(null)
  const [urlData, setUrlData] = useState([])

  useEffect(() => {
    fetch(recUrl)
      .then((resp) => resp.json())
      .then((data) => {
                      let queryString = window.location.search;
                      if(queryString != ""){
                        let urlParams = new URLSearchParams(queryString);
                        let sym = urlParams.get('sym')
                        let exch = urlParams.get('exch')
                        let ric = urlParams.get('ric')
                        if(sym != "" || sym != null || sym != undefined){
                          data.list[0].recosym = sym
                          setExchVal(sym)
                        }
                        if(exch != "" || exch != null || exch != undefined){
                          data.list[0].recoexch = exch
                          setShort(exch)
                        }
                        if(ric != "" || ric != null || ric != undefined){ 
                          if(ric ==".KLSE"){
                            ric="FKLIc1";
                          } 
                          else if(ric==".HSI"){
                           ric="HSIc1";
                          }
                          else if(ric==".HSTECH"){
                            ric="HHTIc1";
                          }
                          data.list[0].ric = ric
                          setRic(ric)
                        }
                      }
                      else{
                        setRic(data.list[0].ric);
                        setShort(data.list[0].recoexch);
                        setExchVal(data.list[0].recosym);
                        if(data.list[0].ric==".KLSE"){
                          data.list[0].ric="FKLIc1";
                        } 
                        else if(data.list[0].ric==".HSI"){
                          data.list[0].ric="HSIc1";
                        }
                        else if(data.list[0].ric==".HSTECH"){
                          data.list[0].ric="HHTIc1";
                        }
                      }
                      // 去掉 S&P500
                      data.list.map((item,index)=>{
                        if(item['ric']=='.INX'){
                          data.list.splice(index,1);
                        }
                      });
                      setData(data.list);
                      setoData(data.list);
                      fetch(
                        `${Base_Url}/apimqmy/ComparisonDataJSON?type=u&ric=${data.list[0].ric}&qid=${qid}`
                      )
                        .then((resp) => resp.json())
                        .then((data) => setCompData(data.comparison))
                        .catch((error) => console.log(error));
                      fetch(
                        `${Base_Url}/apimqmy/RecogniaJSON?url=1&sym=${data.list[0].recosym}&exch=${data.list[0].recoexch}&qid=${qid}`
                      )
                        .then((resp) => resp.json())
                        .then((data) => setUrl(data))
                        .catch((error) => console.log(error));})
      .catch((error) => console.log(error));
  }, [recUrl]);

  const getUnderlyingTickerDisplayName = (val,name,type)=>{
    let UnderlyingtickerdisplaynameMap = JSON.parse(window.localStorage.getItem('UnderlyingtickerdisplaynameMap'))
    let value = UnderlyingtickerdisplaynameMap.filter((v)=>{
      return v.underlying_ric == val
    })
    if(value.length>0){
      if(type==1){
        return value[0].underlying_name
      }else{
        return value[0].underlying_ticker
      }
    }else{
      return name
    }
  }

  const sortTable = (type, sortBy) => {
    fetch(
      `${Base_Url}/apimqmy/ComparisonDataJSON?type=u&ric=${ricId === undefined ? ric : ricId
      }&sortBy=${sortBy}&sortOrder=${type}&qid=${qid} `
    )
      .then((resp) => resp.json())
      .then((data) => setCompData(data.comparison))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setCompData(sortData);
  }, [sortData]);

  const onSelectChangeHandler = (ric, recosym, recoexch, index) => {
    if(ric==".KLSE"){
      ric="FKLIc1";
    } else if(ric==".HSI"){
      ric="HSIc1";
    } else if(ric==".HSTECH"){
      ric="HHTIc1";
    }

    fetch(
      `${Base_Url}/apimqmy/ComparisonDataJSON?type=u&ric=${ric}&qid=${qid}`
    )
      .then((resp) => resp.json())
      .then((data) => setCompData(data.comparison))
      .catch((error) => console.log(error));
    fetch(
      `${Base_Url}/apimqmy/RecogniaJSON?url=1&sym=${recosym}&exch=${recoexch}&qid=${qid}`
    )
      .then((resp) => resp.json())
      .then((data) => setUrl(data))
      .catch((error) => console.log(error));

    setRicId(ric);
  };

  const shortDataHandlerA = (type, sortBy) => {
    const bUrl = `${Base_Url}/apimqmy/RecogniaJSON?list=1`
    var u = type==="all"?`${bUrl}&qid=${qid}`:sortB?`${bUrl}&${type}=${sortBy}&${sortB}&qid=${qid}`:`${bUrl}&${type}=${sortBy}&qid=${qid}`
    var sort = type==="all"?null:`${type}=${sortBy}`
    setsortA(sort)
    setRecUrl(u)
  }
  const shortDataHandlerB = (type, sortBy) => {
    const bUrl = `${Base_Url}/apimqmy/RecogniaJSON?list=1`
    var u = type==="all"?`${bUrl}&qid=${qid}`:sortA?`${bUrl}&${type}=${sortBy}&${sortA}&qid=${qid}`:`${bUrl}&${type}=${sortBy}&qid=${qid}`
    var sort = type==="all"?null:`${type}=${sortBy}`
    setsortB(sort)
    setRecUrl(u)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const longData = [
    {
      value: "all",
      label: "all",
      name: "All technical indicators"
    },
    {
      value: "bearish",
      label: "short",
      name: "Short term - bearish"
    },
    {
      value: "bullish",
      label: "short",
      name: "Short term - bullish"
    },
    {
      value: "neutral",
      label: "short",
      name: "Short term - neutral"
    },
    {
      value: "bearish",
      label: "inter",
      name: "Intermediate term - bearish"
    },
    {
      value: "bullish",
      label: "inter",
      name: "Intermediate term - bullish"
    },
    {
      value: "neutral",
      label: "inter",
      name: "Intermediate term - neutral"
    },
    {
      value: "bearish",
      label: "long",
      name: "Long term - bearish"
    },
    {
      value: "bullish",
      label: "long",
      name: "Long term - bullish"
    },
    {
      value: "neutral",
      label: "long",
      name: "Long term - neutral"
    }
  ]

  const targetPrice = [
    {
      value: "all",
      label: "all",
      name: "All target price"
    },
    {
      value: "yes",
      label: "tp",
      name: "Yes"
    },
    {
      value: "no",
      label: "tp",
      name: "No"
    }
  ]

  useEffect(() => {
    var options = []
    longData.forEach(el=>{
      options.push({
        label:el.name,
        value:el.label+","+el.value
      })
    })
    var topt = []
    targetPrice.forEach(el=>{
      topt.push({
        label:el.name,
        value:el.label+","+el.value
      })
    })
    setlongOptions(options)
    settOptions(topt)
      return () => {
      }
    }, [])

    const updateData = (value) =>{
      try {
      if(value === ""){
        setData(oData)
        return
      }
      var v = value.toLowerCase()
      var fdata = oData.filter(el =>{
        return el.ticker && el.ticker.toLowerCase().includes(v)
      })
      setData(fdata)
      } catch (error) {
        console.log(error)
      }
    }
    
    const iframeLoaded = (e) => {
      var iFrameID = document.getElementById('rciframe');
      if(iFrameID) {
        console.log("iframe data", iFrameID.contentWindow)
      }   
    }

    

  return (
    <div id="recognia" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home">Warrant tools</Link>
            </li>
            <li className="active">Technical Event® Lookup</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>Technical Event® Lookup</h1>
          </div>
          <p>
            Click through to check out Recognia's Technical Event breakdown for
            each underlying
          </p>
          <div id="recogdiv" style={{ position: "relative" }}>
            <div className="filter">
              <div className="filter-row row">
                <div className="filter-col left-col col-xs-12 col-sm-6">
                  <SelectComp
                    options={longOptions}
                    placeholder={"All technical indicators"}
                    onChange={(e) => {
                      shortDataHandlerA(e.value.split(",")[0], e.value.split(",")[1])
                    }}
                  />
                  

                </div>
                <div className="filter-col right-col col-xs-12 col-sm-6">
                <SelectComp
                    options={tOptions}
                    placeholder={"All target price"}
                    onChange={(e) => {
                      shortDataHandlerB(e.value.split(",")[0], e.value.split(",")[1])
                    }}
                  />
                  

                </div>
              </div>
              <div className="filter-row row">
                <div className="filter-col col-xs-12">
                  <div id="underlying_combobox">
                    <div className="combobox">
                      <input
                        placeholder="Select underlying"
                        className="combobox-input ui-autocomplete-input"
                        autoComplete="off"
                        data-original-title
                        value={searchKey}
                        onChange={(e)=>{
                          setsearchKey(e.target.value)
                          updateData(e.target.value)
                          document.getElementById("collapseStock").classList.add("show")
                        }}
                      />
                      <a
                        data-bs-toggle="collapse"
                        href="#collapseStock"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseStock"
                        className="combobox-toggle d-flex justify-content-center align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                          style={{ float: "right" }}
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div
                    id="underlying"
                    className="panel panel-default selectable"
                    style={{ position: "relative" }}
                  >
                    <div
                      id="collapseStock"
                      className="panel-collapse collapse"
                      style={{ position: "relative" }}
                    >
                      <div>
                        <table className="table table-striped-inverse">
                          <thead>
                            <tr>
                              <th className="text-center">Underlying</th>
                              <th className="text-center">Short term (ST)</th>
                              <th className="text-center">
                                Intermediate term (IT)
                              </th>
                              <th className="text-center">Long term (LT)</th>
                              <th className="text-center">Target price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((value, index) => {
                              const { ticker, price, inter, ric, long, short, stscr, itscr, ltscr } =
                                value;
                              return (
                                <tr
                                  data-bs-toggle="collapse"
                                  href="#collapseStock"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapseStock"
                                  className="rowtmpl"
                                  style={{}}
                                  idx={0}
                                  key={index}
                                  sym=".FTFBMKLCI"
                                  exch="INDEX"
                                  ric=".KLSE"
                                  ticker="FBMKLCI"
                                  onClick={() =>
                                    onSelectChangeHandler(ric,value.recosym, value.recoexch, index)
                                  }
                                >
                                  <td className="col-ticker">
                                    <a href="#" className="color-hyperlink">
                                      {getUnderlyingTickerDisplayName(ric,ticker,1)}
                                    </a>
                                    <span>
                                      Last trade:{" "}
                                      <span
                                        className="price"
                                        style={{ display: "inline" }}
                                      >
                                        {price}
                                      </span>
                                    </span>
                                  </td>
                                  <td className="col-short">
                                    <span
                                      className={
                                        short === "Bearish"
                                          ? "taval bearish"
                                          : short === "Neutral"
                                            ? "taval neutral"
                                            : short === "Bullish"
                                              ? "taval bullish"
                                              : "taval bullish"
                                      }
                                    >
                                      {short}
                                    </span>
                                    <div
                                      className={
                                        short === "Bearish"
                                          ? "idctr bearish"
                                          : short === "Neutral"
                                            ? "idctr neutral"
                                            : short === "Bullish"
                                              ? "idctr bullish"
                                              : "idctr bullish"
                                      }
                                    >
                                      {parseInt(stscr) > 0 ? Array.from(Array(parseInt(stscr)).keys()).map((d, index) =>
                                        <span className={`idctr-s${d} fill`} key={"first"+index} />) :
                                        Array.from(Array(parseInt(4)).keys()).map((d, index) =>
                                          <span className={`idctr-s${d}`} key={"second"+index} />
                                        )}
                                    </div>
                                  </td>
                                  <td className="col-inter">
                                    <span
                                      className={
                                        inter === "Bearish"
                                          ? "taval bearish"
                                          : inter === "Neutral"
                                            ? "taval neutral"
                                            : inter === "Bullish"
                                              ? "taval bullish"
                                              : "taval bullish"
                                      }
                                    >
                                      {inter}
                                    </span>
                                    <div
                                      className={
                                        inter === "Bearish"
                                          ? "idctr bearish"
                                          : inter === "Neutral"
                                            ? "idctr neutral"
                                            : inter === "Bullish"
                                              ? "idctr bullish"
                                              : "idctr bullish"
                                      }

                                    >

                                      {parseInt(itscr) > 0 ? Array.from(Array(parseInt(itscr)).keys()).map((d, index) =>
                                        <span className={`idctr-s${d} fill`} key={"third"+index} />) :
                                        Array.from(Array(parseInt(4)).keys()).map((d, index) =>
                                          <span className={`idctr-s${d}`} key={"four"+index} />
                                        )}


                                    </div>
                                  </td>
                                  <td className="col-long">
                                    <span
                                      className={
                                        long === "Bullish"
                                          ? "taval bullish"
                                          : long === "Neutral"
                                            ? "taval neutral"
                                            : "taval bearish"
                                      }
                                    >
                                      {long}
                                    </span>
                                    <div
                                      className={
                                        long === "Bullish"
                                          ? "idctr bullish"
                                          : long === "Neutral"
                                            ? "idctr neutral"
                                            : "idctr bearish"
                                      }
                                    >


                                      {parseInt(ltscr) > 0 ? Array.from(Array(parseInt(ltscr)).keys()).map((d, index) =>
                                        <span className={`idctr-s${d} fill`} key={"five"+index} />) :
                                        Array.from(Array(parseInt(4)).keys()).map((d, index) =>
                                          <span className={`idctr-s${d}`} key={"six"+index} />
                                        )}


                                    </div>
                                  </td>
                                  <td className="col-tp">
                                    <table className="tptbl">
                                      <tbody>
                                        <tr>
                                          <td className="tplbl">
                                            ST: 
                                          </td>
                                          <td className="tpst">{value.tpstlo == "-" ? null : value.tpstlo} - {value.tpsthi  == "-" ? null : value.tpsthi}</td>
                                        </tr>
                                        <tr>
                                          <td className="tplbl">
                                            IT: 
                                          </td>
                                          <td className="tpit">{value.tpitlo == "-" ? null : value.tpitlo} - {value.tpithi == "-" ? null : value.tpithi}</td>
                                        </tr>
                                        <tr>
                                          <td className="tplbl">
                                            LT:
                                          </td>
                                          <td className="tplt">{value.tpltlo == "-" ? null : value.tpltlo} - {value.tplthi == "-" ? null : value.tplthi}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="rciframediv">
              <iframe
                id="rciframe"
                style={{
                  width: "100%",
                  borderStyle: "none",
                  overflow: "hidden",
                  minHeight:  "1140px"
                }}
                src={url?.url}
                scrolling="no"
                // onLoad={iframeLoaded(e)}
              ></iframe>
            </div>
          </div>
        </div>
        <div className="section">
          <div id="dwtable">
            <h3>Related warrants</h3>
            <table className="table table-striped table-noHeaderborder">
              <thead>
                <tr className="htxt">
                  <th sortby="sl">
                    Warrant
                    <br />
                    name
                  </th>
                  <th sortby="type">
                    Type
                  </th>
                  <th sortby="ep" >
                    Exercise
                    <br />
                    price
                  </th>
                  <th sortby="er" className="xs-none">
                    Exercise
                    <br />
                    ratio
                  </th>
                  <th sortby="ltd">Maturity</th>
                  <th sortby="eg">
                    Effective
                    <br />
                    gearing
                    <br />
                    (x)
                  </th>
                  <th sortby="bd" className="xs-none">Bid</th>
                  <th sortby="as" className="xs-none">Ask</th>
                  <th sortby="iv" className="xs-none">IV</th>
                </tr>
                <tr className="sort">
                  <th>
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() => sortTable("desc", "sl")}
                      className="result-sort-down"
                    />
                    <FontAwesomeIcon
                      icon={faSortUp}
                      onClick={() => sortTable("asc", "sl")}
                      className="result-sort-up"
                    />
                  </th>
                  <th>
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() => sortTable("desc", "type")}
                      className="result-sort-down"
                    />
                    <FontAwesomeIcon
                      icon={faSortUp}
                      onClick={() => sortTable("asc", "type")}
                      className="result-sort-up"
                    />
                  </th>
                  <th>
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() => sortTable("desc", "ep")}
                      className="result-sort-down"
                    />
                    <FontAwesomeIcon
                      icon={faSortUp}
                      onClick={() => sortTable("asc", "ep")}
                      className="result-sort-up"
                    />
                  </th>
                  <th className="xs-none">
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() => sortTable("desc", "er")}
                      className="result-sort-down"
                    />
                    <FontAwesomeIcon
                      icon={faSortUp}
                      onClick={() => sortTable("asc", "er")}
                      className="result-sort-up"
                    />
                  </th>
                  <th>
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() => sortTable("desc", "ltd")}
                      className="result-sort-down"
                    />
                    <FontAwesomeIcon
                      icon={faSortUp}
                      onClick={() => sortTable("asc", "ltd")}
                      className="result-sort-up"
                    />
                  </th>
                  <th>
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() => sortTable("desc", "eg")}
                      className="result-sort-down"
                    />
                    <FontAwesomeIcon
                      icon={faSortUp}
                      onClick={() => sortTable("asc", "eg")}
                      className="result-sort-up"
                    />
                  </th>
                  <th className="xs-none">
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() => sortTable("desc", "bd")}
                      className="result-sort-down"
                    />
                    <FontAwesomeIcon
                      icon={faSortUp}
                      onClick={() => sortTable("asc", "bd")}
                      className="result-sort-up"
                    />
                  </th>
                  <th className="xs-none">
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() => sortTable("desc", "as")}
                      className="result-sort-down"
                    />
                    <FontAwesomeIcon
                      icon={faSortUp}
                      onClick={() => sortTable("asc", "as")}
                      className="result-sort-up"
                    />
                  </th>
                  <th className="xs-none">
                    <FontAwesomeIcon
                      icon={faSortDown}
                      onClick={() => sortTable("desc", "iv")}
                      className="result-sort-down"
                    />
                    <FontAwesomeIcon
                      icon={faSortUp}
                      onClick={() => sortTable("asc", "iv")}
                      className="result-sort-up"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
              {compData
                ?.filter(
                  (value) =>
                    value.issuer_name ===
                    "Macquarie Capital Securities (Malaysia) Sdn Bhd"
                )
                .map((value, index) => {
                  const {
                    dsply_nmll,
                    type,
                    maturity,
                    exercise_price,
                    conv_ratio,
                    effective_gearing,
                    BID,
                    ASK,
                    implied_volatility,
                    ric,
                    issuer_name,
                    ticker
                  } = value;

                  return (
                    
                      <tr className="rowtmpl" key={"seven"+index}>
                        <td className="ticker">
                          <a
                            href={`/tools/livematrix/${ticker}`}
                          >
                            {dsply_nmll}
                          </a>
                        </td>
                        <td className="type">{type}</td>
                        <td className="exercise_price">{exercise_price}</td>
                        <td className="conv_ratio xs-none">{conv_ratio}</td>
                        <td className="maturity">{moment(new Date(maturity)).format('DD MMM YY')}</td>
                        <td className="effective_gearing">
                          {effective_gearing}
                        </td>
                        <td className="BID xs-none">{BID}</td>
                        <td className="ASK xs-none">{ASK}</td>
                        <td className="implied_volatility xs-none">
                          {implied_volatility}
                        </td>
                      </tr>
                    
                  );
                })}
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TechnicalEvent;
