import React, { useState, useEffect,Fragment } from 'react';
import BidPrices from './BidPrices';
import "../../common.css";
import "../../investorAcademy.css";


const BidPrice = (props) => {
    return ( 
        <Fragment> 
            <BidPrices/>
        </Fragment> 
     );
}


export default BidPrice;