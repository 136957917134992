import React from 'react';
import { Modal } from 'reactstrap';
const HotlineModal = ({ open, setShow }) => {
    return (
        <>
            <div className="inner-area">
                <Modal centered isOpen={open} toggle={() => setShow(false)} className="imgModal hotline-modal mt-0 mb-0">
                    <div id="hlbox" >
                        <div className="hlbox-close" onClick={() => setShow(false)} />
                        <div className="hl-txt">Hotline</div>
                        <a href="tel:+60 3 2059 8840" className="btn btn-primary"><span className="hl-icon" /><span className="hl-num">+60 3 2059 8840</span></a>
                    </div>
                </Modal>
            </div>
        </>

    );
};
export default HotlineModal;
