import React from 'react';
const Hotline = () => {
  return (
   
    <div id="hlbox" style={{display: 'none'}}>
      <div className="hlbox-close" />
      <div className="hl-txt">Hotline</div>
      <a href="tel:+60 3 2059 8840" className="btn btn-primary"><span className="hl-icon" /><span className="hl-num">+60 3 2059 8840</span></a>
    </div>
  
  );
};

export default Hotline;
