import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const SubscribeModalImg = (props) => {
    const {
      OpenModel,
        Open,
      } = props;

     

      
    return ( 
      <div className="inner-area">
       <Modal  centered isOpen={Open} className="imgModal mt-0 mb-0 SubscribeModalImg">
        
        <img className="btn-close" onClick={OpenModel} src="/img/bullet/icon-close-white.png"
                         />
       
        <img
                        className="image"
                        src="/img/marketnews/sample_new.jpg"
                    />
        
      </Modal>
      </div> 
     );
}
 
export default SubscribeModalImg;