import React, { useEffect } from "react";
import "./FooterInfo.css";

const TermsOfUse=()=> {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (

        <div id="termsofuse" className="pageobj">
            <div className="breadcrumbbar">
                <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li>
                            Macquarie
                        </li>
                        <li className="active">Terms of use</li>
                    </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="section">
                    <div className="page-header">
                        <h1>Terms of use</h1>
                    </div>



                    <p>This internet site is produced by Macquarie Capital Securities (Malaysia) Sdn. Bhd. (“MCSM”).</p>

                    <p>The information on this internet site is directed and available for general circulation to residents of
                        Malaysia only and is not provided to any person who is a resident of the United States or any other
                        country. Any material provided herein, including any indicative terms are provided for information
                        purposes only and do not constitute an offer, a solicitation of an offer, or any advice or recommendation
                        to conclude any transaction. The Warrants will not be offered to any US persons.</p>

                    <p>The price of securities may go down as well as up, and under certain circumstances an investor may
                        sustain a total loss of investment. As past performance is not an indication of future performance,
                        an investor should ensure that they understand the nature of the investment product and in particular
                        the risk factors before investing.</p>

                    <div className="title">Macquarie and the Material on this website</div>
                    <p>Macquarie Malaysia warrants ("Warrants") are issued by MCSM and guaranteed by Macquarie Financial Holdings
                        Limited. Except where expressly stated, the information and services on this website (the "Material") are
                        provided by MCSM as at the date indicated on the relevant material.</p>

                    <p>MCSM is not an authorised deposit-taking institution for the purposes of the Banking Act (Cth) 1959,
                        and MCSM's obligations do not represent deposits or other liabilities of Macquarie Bank Limited
                        (ABN 46 008 583 542) ("MBL"). MBL does not guarantee or otherwise provide assurance in respect of the
                        obligations of MCSM.</p>

                    <p>In relation to Warrants which are listed on Bursa Malaysia Securities Berhad ("Bursa Securities"),
                        a base prospectus has been prepared. In addition a termsheet will or has been prepared by MCSM for
                        the purpose of obtaining a listing for a series of Warrants on Bursa Securities. Copies of the
                        base prospectus and termsheet (together the “Listing Documents”) may be obtained on this web-site.</p>

                    <p>To the maximum extent permitted by law, none of MCSM or its affiliates or the directors,
                        officers employees or agents, of those entities gives any representation or warranty as to the
                        reliability, completeness, timeliness, accuracy or fitness for any purpose of the Material, or
                        any third party website linked to this website, nor does any of them accept any responsibility
                        or liability for any loss or damage arising in any way (including by reason of negligence) out
                        of errors or misstatements in, omissions from, or the reliance of any person on, the Material.</p>

                    <div className="title">Third Party Information</div>
                    <p>The Material in this site has been prepared in good faith with reasonable care. However, certain parts of
                        this Material is obtained or is based upon information obtained from third parties which may not have been
                        checked or verified. Except to the extent permitted by law, and only to the extent so required, no member
                        of the Macquarie group makes any warranty in relation to, or accepts any responsibility or liability for
                        any loss or damage suffered by any person arising out of or in relation to the Material. The Material on
                        this site is not for circulation or publication without the prior written consent of MCSM. MCSM may not,
                        and has no obligation to, update the Material or correct any inaccuracy which subsequently becomes
                        apparent. Opinions, estimates and other information in the Material may be changed or withdrawn without
                        notice.</p>

                    <div className="title">Opinions, ideas and strategies</div>
                    <p>The opinions, ideas and strategies in this document are based on certain assumptions and current market
                        conditions. If those underlying assumptions or market conditions change, the opinions, ideas and
                        strategies will change. All quotations are indicative only. A quotation can be provided by contacting
                        MCSM directly. The strategies outlined are not intended to be specific and/or personal advice and MCSM
                        expressly disclaims all or any liability and responsibility to any person in respect to anything
                        (and the consequences of anything) done or omitted to be done by any person in reliance on the whole
                        or any part of this publication.</p>

                    <div className="title">No personal investment advice – seek personalised advice from a qualified
                        adviser before investing.</div>
                    <p>Any general advice contained on this website has been prepared by MCSM and does not take account of your
                        objectives, financial situation or needs. Before acting on this general advice, you should consider the
                        appropriateness of the advice having regard to your situation. We recommend you obtain financial, legal
                        and taxation advice before making any financial investment decision. Macquarie or its associates,
                        officers or employees may have interests in the financial products referred to in this information by
                        acting in various roles including as investment banker, underwriter or dealer, holder of principal
                        positions, broker, lender or adviser. Macquarie or its associates may receive fees, brokerage or
                        commissions for acting in those capacities. In addition, Macquarie or its associates, officers or
                        employees may buy or sell the financial products as principal or agent and as such may effect
                        transactions which are not consistent with any recommendations in the information. All investors
                        should obtain the Listing Documents for the products discussed and should consider it in full before
                        making a decision.</p>

                </div>
            </div >

        </div>

    );
}

export default TermsOfUse;