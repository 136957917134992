const tabName = {
    warrantTool: "Warrant tools",
    warrantStatistics: "Warrant statistics",
    warrantEducation: "Warrant education",
    marketNews: "Market news",
    MobileTab: "Mobile Tab",
    DesktopTab: "Desktop Tab",
    NoticeTab: "Notice",
    TermsheetTab: "Termsheet",
    ProspectusTab: "Prospectus",
}
const moneyFlowTab = {
    stock: 'Stock',
    index: "Index"
}

const trededTab = {
    topStock: 'Top 10 stock warrants',
    topIndex: "Top 10 index warrants"
}

const trededGainerTab = {
    topGainers: 'Top gainers',
    topLosers: "Top losers",
}


const encodeQuery = (data) => {
    let query = ""
    for (let d in data)
        query += encodeURIComponent(d) + '=' +
            encodeURIComponent(data[d]) + '&'
    return query.slice(0, -1)

}

export { tabName, moneyFlowTab, trededTab, trededGainerTab, encodeQuery }